import React, { useEffect, useState } from "react";
import WeiterButton from "../utils/WeiterButton";

import ContentTitle from "../utils/ContentTitle";
import UserContext from "../../../context/UserContext";
import Input from "../utils/Input";
import ApplicationRoute from "../../../config/routes";
import { isPhoneValidation } from "../../../utils/validation";
import { useHistory } from "react-router-dom";
import Form from "../utils/Form";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import HeaderContext from "../../../context/HeaderContext";
import InputValidation from "../../../common/InputValidaiton";
import { registrationValidationErrorMessages } from "../../../utils/validationErrorMessages";
import { phoneFormatter, prefilledUserData } from "../../../utils/helpers";
import MetaDecorator from "../../../utils/MetaDecorator";
import HorizontalRule from "../../../common/HorizontalRule";

const applicationPageTitles = require("../../../utils/pageTitles.json");
//HINT* NOT REGISTRATION
const MobilePhonePage = ({ text }) => {
  const history = useHistory();
  const [phoneNumber, setPhoneNumber] = useState("");

  const { user, setUser } = React.useContext(UserContext);
  const { setHeader } = React.useContext(HeaderContext);

  const activate = () => {
    setActive(() => true);
  };

  const [active, setActive] = useState(false);

  useEffect(() => {
    document.getElementsByTagName("HTML")[0].addEventListener("click", activate);

    return () => document.getElementsByTagName("HTML")[0].removeEventListener("click", activate);
  }, []);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((6 / 19) * 100),
        title: "Neue Reise anlegen",
      };
    });
  }, []);

  const isValid = () => {
    return isPhoneValidation(phoneNumber);
  };

  const updateUser = () => {
    setUser((curr) => {
      return { ...curr, PhoneNumber: phoneNumber };
    });
  };

  const onChangeHandler = (e) => {
    let phone = phoneFormatter(phoneNumber, e.target.value);
    // phone = e.target.value;

    setPhoneNumber(phone);
  };

  const onClickHandler = () => {
    updateUser();
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateUser();
      history.push(ApplicationRoute.registerEmail);
    }
  };

  useEffect(() => {
    setPhoneNumber(user.PhoneNumber ? user.PhoneNumber : "");
  }, [user]);

  return (
    <div className="registration-middle-container">
      <MetaDecorator title={applicationPageTitles.createTripPages} />
      <ContentTitle id="register-phone" text={prefilledUserData("Bitte geben Sie Ihre Mobiltelefonnummer ein:")} />
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form className="max-width-380" id="form" onSubmit={onSubmitHandler} ariaLabelledBy="register-phone">
        <Input
          id="phoneNumber"
          name="phoneNumber"
          htmlFor="phoneNumber"
          value={phoneNumber}
          onChange={onChangeHandler}
          placeholder="Mobiltelefonnummer"
          type="tel"
          labelText="Mobiltelefonnummer (inkl. Ländervorwahl)"
          labelStyle={{ display: "inline-block" }}
          showValidationBorder={active && !isValid()}
        />

        <InputValidation
          isHidden={!active || isValid()}
          text={registrationValidationErrorMessages.notValidPhoneNumber}
        />

        <HiddenSubmitInput />
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.registerEmail}
        onClick={onClickHandler}
        ariaLabel={text}
        className={"floating-weiter-buton"}
      />
    </div>
  );
};

export default MobilePhonePage;
