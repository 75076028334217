import React, { useEffect, useState } from "react";
import WeiterButton from "../../registration/utils/WeiterButton";
import TravelContext from "../../../context/TravelContext";
import ApplicationRoute from "../../../config/routes";
import { getKeyByValue } from "../../../utils/helpers";
import { useHistory } from "react-router-dom";
import Form from "../utils/Form";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import HeaderContext from "../../../context/HeaderContext";
import ContentTitle from "../../registration/utils/ContentTitle";
import Info from "../../registration/utils/Info";
import ControledRadioInput from "../../registration/utils/ControledRadioInput";
import MetaDecorator from "../../../utils/MetaDecorator";
import HorizontalRule from "../../../common/HorizontalRule";

const applicationPageTitles = require("../../../utils/pageTitles.json");

const formLabel = "Reisegruppe eingeben.";

const compaion = {
  yes: "Ich reise alleine",
  no: "Es gibt mitreisende Angehörige",
};

let initSelectValue = {
  yes: false,
  no: false,
};

const AddTravelCompanion = ({ text }) => {
  const history = useHistory();
  const { travel, setTravel } = React.useContext(TravelContext);

  const [weiterLink, setWeiterLink] = useState("");

  const { setHeader } = React.useContext(HeaderContext);
  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: "Neue Reise anlegen",
        progress: parseInt((11 / 19) * 100),
      };
    });
  }, []);

  const [selectedValue, setSelectedValue] = useState(initSelectValue);

  const isValid = () => {
    return getKeyByValue(selectedValue, true);
  };

  const updateTravel = () => {
    const isGroup = getKeyByValue(selectedValue, true) === "no";

    setTravel((curr) => {
      return {
        ...curr,
        IsAlone: !isGroup,
        TravelGroupMember: [],
      };
    });
  };

  const onClickHandler = () => {
    updateTravel();
  };

  const onChangeHandler = (e) => {
    setSelectedValue(() => {
      return { ...initSelectValue, [e.target.id]: true };
    });

    if (e.target.id === "yes") {
      setWeiterLink(ApplicationRoute.addTravelEdit);
    } else {
      setWeiterLink(ApplicationRoute.addTravelCompanionName);
    }
  };

  const onsubmitHandler = (e) => {
    e.preventDefault();
    if (isValid()) {
      updateTravel();

      history.push(weiterLink);
    }
  };

  return (
    <div className="registration-middle-container">
      <MetaDecorator title={applicationPageTitles.createTripPages} />
      <ContentTitle
        id={formLabel}
        style={{ marginTop: "1.6rem", marginBottom: "0px" }}
        text="Reisen Angehörige mit Ihnen mit?"
      />
      <Info
        style={{ marginTop: "0.8rem", marginBottom: "2.4rem" }}
        text="Bitte legen Sie nur dann Mitreisende an, wenn diese keine eigene Reise anlegen können. Das hilft uns Reisende zuzuordnen und im Notfall besser unterstützen zu können."
      />
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form id="form" onSubmit={onsubmitHandler} ariaLabelledBy={formLabel}>
        <ControledRadioInput
          id="yes"
          value="Yes"
          name="compainion"
          textInfo={compaion.yes}
          checked={selectedValue.yes}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.yes}
        />
        <ControledRadioInput
          id="no"
          value="No"
          name="compainion"
          textInfo={compaion.no}
          checked={selectedValue.no}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.no}
          style={{ marginTop: "18px" }}
        />

        <HiddenSubmitInput />
        <HorizontalRule className="horizontal-rule-desktop" />
        <WeiterButton
          disabled={!isValid()}
          path={weiterLink}
          onClick={onClickHandler}
          ariaLabel={text}
          className={"floating-weiter-buton"}
        />
      </Form>
    </div>
  );
};

export default AddTravelCompanion;
