const ClearContanctDiv = ({ id = "clearContactId", text, onClick, description }) => {
  return (
    <>
      <h2 className="clear-contact-div">
        <div className="clear-contact-div__title">{text}</div>
        {/* <button
          id={id}
          type="button"
          className="clear-contact-div__clear-link"
          onClick={onClick}
        >
          {description ? description : "löschen"}
        </button> */}
      </h2>
    </>
  );
};

export default ClearContanctDiv;
