import React from "react";
import { Helmet } from "react-helmet";
import { isMobileEnv } from "./helpers";

const MetaDecorator = ({ title, description }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="description" content={description} />
      {!isMobileEnv() && (
      <meta httpEquiv="Content-Security-Policy"
            content="default-src 'self';script-src 'self' 'unsafe-inline' maps.googleapis.com;connect-src 'self' www.bmeia.gv.at testapp.bmeia.gv.at data: devapp;img-src 'self' data: www.bmeia.gv.at maps.googleapis.com maps.gstatic.com;style-src 'self' 'unsafe-inline' fonts.googleapis.com;font-src 'self' fonts.gstatic.com;object-src 'none'"/>
      )}
    </Helmet>
  );
};
export default MetaDecorator;
