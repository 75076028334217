import apipath from "../../../apipath";
import {isBlockedFlag} from "../../../utils/helpers";

const localStorageKey = "countries";
const localStorageKeyVB = "countriesVB";

export function getIsoCodeFromId(id) {
    let countries = localStorage.getItem(localStorageKey);
    let isoCode = "";

    if (countries) {
        countries = JSON.parse(countries);
        for (let country of countries) {
            if (country.Id == id) {
                isoCode = country.IsoCode;
                break;
            }
        }
    }

    return isoCode;
}

export function getIsoCodeFromName(name) {
    let countries = localStorage.getItem(localStorageKey);
    let isoCode = "";

    if (countries) {
        countries = JSON.parse(countries);
        for (let country of countries) {
            if (country.Name === name) {
                isoCode = country.IsoCode;
                break;
            }
        }
    }

    return isoCode;
}

export function getCountryNameFromId(id) {
    let countries = localStorage.getItem(localStorageKey);
    let name = "";

    if (countries) {
        countries = JSON.parse(countries);
        for (let country of countries) {
            if (country.Id == id) {
                name = country.Name;
                break;
            }
        }
    }

    return name;
}

export function getCountryFlag(id) {
    if (isBlockedFlag(id)) {
        return `${apipath.pathBMEIAgvat_API}flags/.png`;
    }

    return `${apipath.pathBMEIAgvat_API}flags/${getIsoCodeFromId(id).toLowerCase()}.png`;
}

export function getCountryFlagFromName(name) {
    if (isBlockedFlag(name)) {
        return `${apipath.pathBMEIAgvat_API}flags/.png`;
    }

    return `${apipath.pathBMEIAgvat_API}flags/${getIsoCodeFromName(name).toLowerCase()}.png`;
}

export function getCountryUidFromName(name) {
    let isoCode = getIsoCodeFromName(name);

    let countriesVB = localStorage.getItem(localStorageKeyVB);
    let countryUid = "";

    if (countriesVB) {
        countriesVB = JSON.parse(countriesVB).countries;
        for (let country of countriesVB) {
            if (country.countryIsoCode == isoCode) {
                countryUid = country.countryUid;
                break;
            }
        }
    }

    return countryUid;
}

export function getCountryRiskLvlFromId(id) {
    let isoCode = getIsoCodeFromId(id);

    let countriesVB = localStorage.getItem(localStorageKeyVB);

    let countryRiskLvl = "";
    if (countriesVB) {
        countriesVB = JSON.parse(countriesVB).countries;
        for (let country of countriesVB) {
            if (country.countryIsoCode === isoCode) {
                countryRiskLvl = country.countryRiskLvl;
                break;
            }
        }
    }

    return countryRiskLvl;
}

export function getCountryPicture(name) {
    // if (isBlockedFlag(name)) {
    //   return `${apipath.pathBMEIAgvat_API}country/.jpg`;
    // }
    return `${apipath.pathBMEIAgvat_API}country/${getCountryUidFromName(name)}.jpg`;
}

export function getCountryNameFromUid(uid) {
    let countriesVB = localStorage.getItem(localStorageKeyVB);
    let name = "";

    if (countriesVB) {
        countriesVB = JSON.parse(countriesVB).countries;
        for (let country of countriesVB) {
            if (country.countryUid == uid) {
                name = country.country;
                break;
            }
        }
    }
    return name;
}

export function getCountryAliasFromUid(uid) {
    let countriesVB = localStorage.getItem(localStorageKeyVB);
    let alias = "";

    if (countriesVB) {
        countriesVB = JSON.parse(countriesVB).countries;
        for (let country of countriesVB) {
            if (country.countryUid == uid) {
                alias = country.countryAlias;
                break;
            }
        }
    }
    return alias;
}

export function getCountryFlagFromCountryVB(name) {
    if (isBlockedFlag(name)) {
        return `${apipath.pathBMEIAgvat_API}flags/.png`;
    }

    let countries = localStorage.getItem(localStorageKeyVB);
    let isoCode = "";

    if (countries) {
        countries = JSON.parse(countries).countries;
        for (let country of countries) {
            if (country.country === name && country.countryIsoCode) {
                isoCode = country.countryIsoCode;
                break;
            }
        }
    }

    return `${apipath.pathBMEIAgvat_API}flags/${isoCode.toLowerCase()}.png`;
}

export function getRegionsByCountryName(name) {
    let countries = localStorage.getItem(localStorageKey);

    if (countries) {
        countries = JSON.parse(countries);

        for (let country of countries) {
            if (country.Name === name) {
                return country.Regions;
            }
        }
    }
}

export function getRegionsByCountryId(id) {
    let countries = localStorage.getItem(localStorageKey);

    if (countries) {
        countries = JSON.parse(countries);

        for (let country of countries) {
            if (country.Id === id) {
                return country.Regions;
            }
        }
    }
}

