import localforage from "localforage";
import dayjs from "dayjs";
import { defaultLanguage } from "../languages/config";
import { onlineStatus } from "../components/OfflineManager";

const DEBUG_MODE = process.env.REACT_APP_CACHING_DEBUG == 'true'
const cacheDebugLog = (...message) => {
    if (DEBUG_MODE) console.log(...message)
}

const { fetch: origFetch } = window;

window.fetch = async (...args) => {
    let options = args[1] ?? null // Second Param fetch options
    if (options == null || typeof options !== "object") {
        args.splice(1, 0, { method: "GET" });
        options = args[1];
    }
    let cacheRequest = !!args[2] // Third param, tell the function to cache
    let autoCacheOnLoad = !!args[3] // Fourth param, tell the function to reload again when online
    let type = args[4] ?? "json" // Fifth param, tell the function that you want text or json
    let cacheKey = args[5] // Sixth param, custom caching key for easier look up

    options.headers = { ...options.headers, "Accept-Language": defaultLanguage.get() }

    let response = null;
    let data;

    try {
        if (!onlineStatus.get() && cacheRequest) {
            data = await getCachedResponse(args, cacheKey);
            if (data) {
                return new Response(data)
            }
        }

        response = await origFetch(...args)
        if(!cacheRequest) return response

        let cloned = response.clone();
        if(!cloned.ok) return response

        if (type === "text") {
            data = await cloned.text();
        } else {
            data = await cloned.json();
        }

        await storeRequestData(args, data, autoCacheOnLoad, cacheKey);
    } catch (error) {
        if(!cacheRequest) throw error

        data = await getCachedResponse(args, cacheKey);
        if (data) {
            return new Response(data)
        }

        throw error;
    }

    return response;
};

const storeRequestData = async (fullFetchObject, response, autoCacheOnLoad, cacheKey) => {
    const key = (cacheKey ? cacheKey + "_" : "") + "Caching_" + JSON.stringify(fullFetchObject);
    const data = JSON.stringify(response)

    await localforage.setItem(key, JSON.stringify({
        data: data,
        time: dayjs().add(-10, "seconds").unix()
    }));

    cacheDebugLog("Item cached", key, data, autoCacheOnLoad ? "Auto cache enabled" : "");

    if (!autoCacheOnLoad) return

    let all = await localforage.getItem("Caching_Autoload") ?? [];
    if (!all.includes(key)) {
        all.push(key);
        await localforage.setItem("Caching_Autoload", all);
    }
}

const getFullCachedItem = async (fullFetchObject, cacheKey) => {
    const key = (cacheKey ? cacheKey + "_" : "") + "Caching_" + JSON.stringify(fullFetchObject);
    const item = await localforage.getItem(key);
    if (item === null) {
        return null
    }
    try {
        const cachedItem = JSON.parse(item);
        cacheDebugLog("Item read from cache", key, cachedItem);
        return cachedItem;
    } catch (err) {
        console.error(err)
    }
}

const getCachedResponse = async (fullFetchObject, cacheKey) => {
    const item = await getFullCachedItem(fullFetchObject, cacheKey);
    if (item) {
        return item.data
    }
    return null
}

export const autoCacheStoredCalls = async () => {
    let all = await localforage.getItem("Caching_Autoload") ?? [];
    if (all.length > 0) {
        cacheDebugLog("Auto caching items");
    }
    for (const item of all) {
        let params = item.split("Caching_")[1];
        params = JSON.parse(params);
        await fetch(...params);
    }
}

export const addToAutoCache = (...args) => {
    fetch(...args)
}

export const checkIfCached = async (key) => {
    let all = await localforage.getItem("Caching_Autoload") ?? [];
    const item = all.find(x => x.startsWith(key));
    if (!item) return {
        cached: false
    }
    const obj = {
        cached: true
    };
    cacheDebugLog("Checking if cached", key, obj);
    return obj;
}
