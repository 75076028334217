const CheckBox = ({ id, state, onChangeHandler, text, jsx }) => {
  return (
    <div className="checkbox__container">
      <input id={id} type="checkbox" value={state} onChange={onChangeHandler} />
      {state ? (
        <div
          onClick={() => {
            try {
              document.getElementById(id).click();
            } catch (error) {}
          }}
          className="checkbox__container__image-div-clone"
        >
          <img
            style={{ position: "absolute" }}
            className="checkbox__container__img-false"
            src="images/common/checkboxfalse.png"
            alt="Momentan angeklickt"
            onClick={onChangeHandler}
          />
        </div>
      ) : (
        <div
          onClick={() => {
            try {
              document.getElementById(id).click();
            } catch (error) {}
          }}
          className="checkbox__container__image-div-clone"
        >
          <img
            style={{ position: "absolute" }}
            className="checkbox__container__img-true"
            src="images/common/checkboxtrue.png"
            alt="Momentan nicht angeklickt"
            onClick={onChangeHandler}
          />
        </div>
      )}
      {jsx ? (
        <label className="checkbox__container__label" htmlFor={id} dangerouslySetInnerHTML={{ __html: jsx }}></label>
      ) : (
        <label className="checkbox__container__label" htmlFor={id}>
          {text}
        </label>
      )}
    </div>
  );
};

export default CheckBox;
