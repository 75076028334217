import React from "react";

const AnswerDeleteInfos = () => {

    return (
        <>
            <p className="answer-text">
                Als Auslandsösterreicher können die personenbezogenen Daten nach der Anmeldung im Profil bearbeitet
                oder gelöscht werden.
                Der Auslandsaufenthalt und die darin angegebenen Daten können mittels Klick auf den Stift bearbeitet werden.
                Gelöscht werden die Daten mittels klick auf „Permanenten Auslandsaufenthalt löschen“, dazu muss ganz
                nach unten gescrollt werden. Die Löschung muss mit nochmaligem Klick auf „Permanenten Auslandsaufenthalt löschen“
                bestätigt werden.
            </p>
            <div className="answer-image-container">
                <div className="answer-image-box">
                    <img className="" src="./images/faqs/delete-data-1.png" alt="" />
                </div>
                <div className="answer-image-box">
                    <img className="" src="./images/faqs/delete-data-2.png" alt="" />
                </div>
                <div className="answer-image-box">
                    <img className="" src="./images/faqs/delete-data-3.png" alt="" />
                </div>
            </div>
        </>
    );
};

export default AnswerDeleteInfos;
