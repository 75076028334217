import * as React from "react";

function ArrowRight(props) {
  return (
    <svg width="4.5em" height="4.5em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd" opacity={0.9}>
        <path d="M0 24V0h24v24z" />
        <path
          fill="#9E0529"
          fillRule="nonzero"
          d="M8.58 16.59L13.17 12 8.58 7.41 10 6l6 6-6 6z"
        />
      </g>
    </svg>
  );
}

const MemoIconsArrowDown = React.memo(ArrowRight);
export default MemoIconsArrowDown;
