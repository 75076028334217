import React, { useEffect, useState } from "react";
import WeiterButton from "../utils/WeiterButton";
import ContentTitle from "../utils/ContentTitle";
import UserContext from "../../../context/UserContext";
import SelectList from "../utils/SelectList";
import { useHistory } from "react-router-dom";
import ApplicationRoute from "../../../config/routes";
import Form from "../utils/Form";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import HeaderContext from "../../../context/HeaderContext";
import MetaDecorator from "../../../utils/MetaDecorator";

const applicationPageTitles = require("../../../utils/pageTitles.json");
const options = ["Austria", "Austria2", "Austria3"];

const NationalityPage = ({ text }) => {
  const history = useHistory();
  const [nationality, setNationality] = useState("");

  const { user, setUser } = React.useContext(UserContext);
  const { setHeader } = React.useContext(HeaderContext);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: "80",
        title: "Registrierung",
      };
    });
  }, []);

  const isValid = () => {
    return !!nationality;
  };

  const updateUser = () => {
    setUser((curr) => {
      return { ...curr, Nationality: nationality };
    });
  };

  const onChangeHandler = (e) => {
    setNationality(e.target.value);
  };

  const onClickHandler = () => {
    updateUser();
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateUser();
      history.push(ApplicationRoute.registerForeignCountry);
    }
  };

  useEffect(() => {
    setNationality(user.Nationality ? user.Nationality : "");
  }, [user]);

  return (
    <>
      <MetaDecorator title={applicationPageTitles.registrationPages} />
      <ContentTitle id="contentTitle" text="Staatsangehörigkeit angeben" />
      <Form
        id="form"
        onSubmit={onSubmitHandler}
        ariaLabelledBy="contentTitleId"
      >
        <SelectList
          id="selectListId"
          ariaLabelledBy="contentTitle"
          placeHolder="Nationalität auswählen"
          state={nationality}
          onChangeHandler={onChangeHandler}
          options={options}
        />

        <HiddenSubmitInput />

        <WeiterButton
          disabled={!isValid()}
          path={ApplicationRoute.registerForeignCountry}
          onClick={onClickHandler}
          ariaLabel={text}
        />
      </Form>
    </>
  );
};

export default NationalityPage;
