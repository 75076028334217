import React, { useEffect, useState } from "react";
import WeiterButton from "../../../registration/utils/WeiterButton";
import ContentTitle from "../../../registration/utils/ContentTitle";
import Input from "../../../registration/utils/Input";
import ApplicationRoute from "../../../../config/routes";
import { useHistory } from "react-router-dom";
import {
  isDateValidation,
  isOlderOrIsEighteenYearsOlds,
  IsDateBiggerThanTodayValidation,
  IsDateSmallerThan_1900_1_1_Validation,
} from "../../../../utils/validation";
import Form from "../../../registration/utils/Form";
import HiddenSubmitInput from "../../../../common/HiddenSubmitInput";
import HeaderContext from "../../../../context/HeaderContext";
import InputValidation from "../../../../common/InputValidaiton";
import { registrationValidationErrorMessages } from "../../../../utils/validationErrorMessages";
import { dateFormatter, prefilledUserData } from "../../../../utils/helpers";
import UserContext from "../../../../context/UserContext";
import MetaDecorator from "../../../../utils/MetaDecorator";
import HorizontalRule from "../../../../common/HorizontalRule";

const applicationPageTitles = require("../../../../utils/pageTitles.json");
//HINT* NOT REGISTRATION
export function ForeignBirhtdate({ text }) {
  const history = useHistory();

  const { user, setUser } = React.useContext(UserContext);
  const { setHeader } = React.useContext(HeaderContext);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((3 / 19) * 100),
        title: "Als Auslandsösterreicher/in registrieren",
        ariaLabel: "Als Auslandsösterreicher:in registrieren",
      };
    });
  }, []);

  const [date, setDate] = useState("");
  const [placeHolder, setPlaceHolder] = useState("Geburtsdatum");

  const isValidDate = () => {
    return isDateValidation(date);
  };

  const IsDateSmallerThan1900 = () => {
    return IsDateSmallerThan_1900_1_1_Validation(date);
  };

  const IsDateBiggerThanToday = () => {
    return IsDateBiggerThanTodayValidation(date);
  };

  const isOlderThan_18_years = () => {
    return isOlderOrIsEighteenYearsOlds(date);
  };

  const isValid = () => {
    return (
      isValidDate() &&
      isOlderThan_18_years() &&
      IsDateBiggerThanToday() &&
      !IsDateSmallerThan1900()
    );
  };

  const updateUser = () => {
    setUser((curr) => {
      return { ...curr, BirthDate: date };
    });
  };

  const onChangeHandler = (e) => {
    let newDate = dateFormatter(date, e.target.value);

    setDate(newDate);
  };

  const onClickInputHandler = () => {
    setPlaceHolder("TT.MM.JJJJ");
  };

  const onClickHandler = () => {
    updateUser();
  };

  const onsubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateUser();
      history.push(ApplicationRoute.foreignRegistrationRegistrationCounty);
    }
  };

  useEffect(() => {
    setDate(user.BirthDate ? user.BirthDate : "");
  }, [user]);

  return (
    <section className="foreign-registration registration-middle-container">
      <MetaDecorator title={applicationPageTitles.permanentOtsideCountry} />
      <ContentTitle
        id="foreign-registration-birthdate"
        text={prefilledUserData("Bitte geben Sie Ihr Geburtsdatum ein:")}
      />
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form
        className="max-width-380"
        id="form"
        onSubmit={onsubmitHandler}
        ariaLabelledBy="foreign-registration-birthdate"
      >
        <Input
          id="date"
          name="data"
          type="text"
          htmlFor="date"
          value={date}
          onClick={onClickInputHandler}
          onChange={onChangeHandler}
          placeholder={placeHolder}
          labelText="Geburtsdatum"
          showValidationBorder={date.length > 0 && !isValid()}
        />
        <InputValidation
          isHidden={!date.length > 0 || isValidDate()}
          text={registrationValidationErrorMessages.notValidDate}
        />
        <InputValidation
          isHidden={
            !date.length > 0 ||
            !IsDateBiggerThanToday() ||
            isOlderThan_18_years() ||
            !isValidDate()
          }
          text={registrationValidationErrorMessages.ageLessThan_18}
        />

        <InputValidation
          isHidden={
            !date.length > 0 || !IsDateSmallerThan1900() || !isValidDate()
          }
          text={
            registrationValidationErrorMessages.dateOfBirthLessThan_01_01_1900
          }
        />

        <InputValidation
          isHidden={
            !date.length > 0 || IsDateBiggerThanToday() || !isValidDate()
          }
          text={registrationValidationErrorMessages.dateBiggerThanToday}
        />

        <HiddenSubmitInput />
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content"> </div>
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.foreignRegistrationRegistrationCounty}
        onClick={onClickHandler}
        ariaLabel={text}
        className="hide-weiter-on-keyboard floating-weiter-buton"
      />
    </section>
  );
}
