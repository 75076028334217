import React from "react";
import { Link } from "react-router-dom";

const RedButton = ({
  onClick,
  text = "Weiter",
  path = "",
  ariaLabel = "",
  style,
  icon
}) => {
  return (
    <Link
      role="button"
      to={path}
      aria-label={ariaLabel}
      className="red-button"
      id="red-button"
      onClick={onClick}
      style={style}
    >
      <span className="red-button__text">
        {
          icon ?? <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="14"
            viewBox="0 0 15 14"
          >
            <g fill="none" fillRule="evenodd">
              <g fill="#FFF" fillRule="nonzero">
                <g>
                  <g>
                    <g>
                      <path
                        d="M6 7.667c.442 0 .866-.176 1.179-.488.312-.313.488-.737.488-1.179 0-.442-.176-.866-.488-1.179-.313-.312-.737-.488-1.179-.488-.92 0-1.667.747-1.667 1.667S5.08 7.667 6 7.667m0-6.334c2.573 0 4.667 2.087 4.667 4.667C10.667 9.5 6 14.667 6 14.667S1.333 9.5 1.333 6c0-2.577 2.09-4.667 4.667-4.667m4 10h2v-2h1.333v2h2v1.334h-2v2H12v-2h-2v-1.334z"
                        transform="translate(-107 -474) translate(22 431) translate(84 37) translate(0 5)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        }
        <span className="red-button__text-text"> {text} </span>
      </span>
    </Link>
  );
};

export default RedButton;
