import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import HeaderContext from "../../../context/HeaderContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import CountryInfoHeader from "../utils/CountryInfoHeader";
import HorizontalRule from "../../../common/HorizontalRule";
import InfoCard from "../utils/InfoCard";
import { Link } from "react-router-dom";
import ArrowRight from "../../../svg/ArrowRight.js";
import {
  getCountryNameFromUid,
  getCountryFlagFromCountryVB,
  getCountryAliasFromUid,
} from "../../travel-registration/utils/countryInfo";
import LoadingPage from "../../travel-registration/LoadingPage";

const applicationPageTitles = require("../../../utils/pageTitles.json");

const BotschaftenKonsulate = () => {
  let { countryUid } = useParams();
  const { setHeader } = React.useContext(HeaderContext);

  const [countryName, setCountryName] = React.useState(getCountryNameFromUid(countryUid));
  const [representations, setRepresentations] = useState(null);
  const [countryUidState, setCountryUidState] = useState(countryUid);
  const [countryAlias] = React.useState(getCountryAliasFromUid(countryUid));

  const [alertnateRepresentations, setAlternateRepresentations] = useState(null);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "content",
        title: "Botschaften und Konsulate",
      };
    });

    if (representations == null) {
      const arr = localStorage.getItem("countriesVB") ? JSON.parse(localStorage.getItem("countriesVB")).countries : [];
      let data = [];
      let alternatesSearchIds = [];

      for (let i = 0; i < arr.length; i++) {
        if (arr[i].countryUid == countryUid) {
          data = arr[i].representations;

          if (data.length == 0 && arr[i].alternativeOrgaUnitUids.length) {
            alternatesSearchIds = arr[i].alternativeOrgaUnitUids;
          }
          break;
        }
      }

      if (alternatesSearchIds.length) {
        let obj = [];
        for (let i = 0; i < arr.length; i++) {
          if (!alternatesSearchIds.length) break;

          if (arr[i].representations.length) {
            for (let j = 0; j < arr[i].representations.length; j++) {
              if (!alternatesSearchIds.length) break;

              if (alternatesSearchIds.indexOf(arr[i].representations[j].orgaUnitUid) >= 0) {
                // data.push(arr[i].representations[j]);
                // setCountryName(arr[i].country);
                // setCountryUidState(arr[i].countryUid);
                delete alternatesSearchIds[alternatesSearchIds.indexOf(arr[i].representations[j].orgaUnitUid)];

                if (obj.length == 0) {
                  obj.push({
                    countryName: arr[i].country,
                    countryUid: arr[i].countryUid,
                    countryAlias: arr[i].countryAlias,
                    representations: [arr[i].representations[j]],
                  });
                } else {
                  let filteredObj = obj.filter((alt) => alt.countryName == arr[i].country);

                  if (filteredObj.length) filteredObj.representations.push(arr[i].representations[j]);
                  else
                    obj.push({
                      countryName: arr[i].country,
                      countryUid: arr[i].countryUid,
                      countryAlias: arr[i].countryAlias,
                      representations: [arr[i].representations[j]],
                    });
                }
              }
            }
          }
        }

        setAlternateRepresentations(obj);
      }

      setRepresentations(data);
    }
  }, []);

  if (representations == null) return <LoadingPage />;
  return (
    <section style={{ marginBottom: "56px" }}>
      <MetaDecorator title="Botschaften und Konsulate" />
      {representations && representations.length > 0 ? (
        <>
          <CountryInfoHeader
            flag={getCountryFlagFromCountryVB(countryName)}
            Country={countryAlias.length > 0 ? countryAlias + " (" + countryName + ")" : countryName}
            className="country__flag-no-image"
          />

          {representations.map((rep, index) => {
            return (
              <div key={rep.locationUid}>
                <HorizontalRule />
                <Link
                  style={{ textDecoration: "none" }}
                  key=""
                  to={`/country-city/${countryUidState}/${rep.orgaUnitUid}`}
                >
                  <InfoCard key={rep.locationUid} type={rep.type} city={rep.city} ArrowRight={<ArrowRight />} />
                </Link>
              </div>
            );
          })}
        </>
      ) : null}

      {alertnateRepresentations && alertnateRepresentations.length
        ? alertnateRepresentations.map((alt) => (
            <>
              <CountryInfoHeader
                flag={getCountryFlagFromCountryVB(alt.countryName)}
                Country={
                  alt.countryAlias.length > 0 ? alt.countryAlias + " (" + alt.countryName + ")" : alt.countryName
                }
                className="country__flag-no-image"
              />

              {alt.representations.map((rep, index) => {
                return (
                  <div key={rep.locationUid}>
                    <HorizontalRule />
                    <Link
                      style={{ textDecoration: "none" }}
                      key=""
                      to={`/country-city/${alt.countryUid}/${rep.orgaUnitUid}`}
                    >
                      <InfoCard key={rep.locationUid} type={rep.type} city={rep.city} ArrowRight={<ArrowRight />} />
                    </Link>
                  </div>
                );
              })}
            </>
          ))
        : null}
    </section>
  );
};

export default BotschaftenKonsulate;
