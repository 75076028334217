import React from "react";
import { Link } from "react-router-dom";

const WhiteButton = ({
  onClick,
  text = "Weiter",
  path = "",
  ariaLabel = "",
}) => {
  return (
    <Link
      role="button"
      to={path}
      aria-label={ariaLabel}
      id="white-button"
      className="white-button"
      onClick={onClick}
    >
      <span className="white-button__text">{text}</span>
    </Link>
  );
};

export default WhiteButton;
