import React, {useEffect} from "react";
import ApplicationRoute from "../../../config/routes";
import {useHistory} from "react-router-dom";
import HeaderContext from "../../../context/HeaderContext";
import MetaDecorator from "../../../utils/MetaDecorator.jsx";
import WeiterButton from "../utils/WeiterButton.jsx";
import HorizontalRule from "../../../common/HorizontalRule.jsx";
import UserContext from "../../../context/UserContext.js";
import Mail from "../../../common/Unions/Mail.jsx";

const applicationPageTitles = require("../../../utils/pageTitles.json");
//HINT* REGISTRATION
const IdaLinkExistingPage = () => {
    const history = useHistory();

    const {setHeader} = React.useContext(HeaderContext);
    const {user} = React.useContext(UserContext);

    useEffect(() => {
        setHeader((curr) => {
            return {
                ...curr,
                inUse: "registration",
                progress: (1 / 5) * 100,
                title: applicationPageTitles.linkAccountPage + "%noarrow%",
                ariaLabel: "Bestehenden Account verknüpfen",
            };
        });
    }, [setHeader]);

    const onSkipHandler = (e) => {
        e.preventDefault();

        history.push(ApplicationRoute.linkAccountManual)
    };

    const onSendMailHandler = (e) => {
        e.preventDefault();

    };

    const combineStrings = (txt1, txt2, idxList) => {
        let ret = "";
        for (let i = 0; i < txt1.length; i++) {
            if (i >= txt2.length) {
                ret += txt1.substring(i);
                break;
            }

            ret += (idxList.includes(i)) ? txt1[i] : txt2[i];
        }

        return ret;
    }

    const cipherAlphaNumerical = (txt) => {
        return txt.replaceAll(/[a-z0-9]/ig, '*');
    }

    const cipherMail = (mail) => {
        if (!mail) return mail;

        const at = mail.indexOf('@');
        const minus = mail.indexOf('-');
        const n = mail.length;

        return combineStrings(mail, cipherAlphaNumerical(mail), [0, minus + 1, at - 1, at + 1, n - 3, n - 2, n - 1])
    }

    return (
        <div className="registration-middle-container">
            <MetaDecorator title={applicationPageTitles.linkAccountPage}/>
            <p className="content-title" style={{marginBottom: "3.2rem"}}>
                Wir haben einen bestehenden Account gefunden der mit Ihren Daten übereinstimmt.
            </p>
            {/*
            <div style={{marginBottom: "1.6rem"}}>
                <p>Vorname: {user.FirstName}</p>
                <p>Nachname: {user.LastName}</p>
                <p>Geburtsdatum: {user.Birthdate}</p>
            </div>
            */}
            <div style={{marginBottom: "1.6rem"}}>
                <p>
                    Wir haben eine E-Mail mit Bestätigungslink an die E-Mail-Adresse
                    &nbsp;({user.mail ? cipherMail(user.mail) : cipherMail('ui@ikt-tests.at')})&nbsp;
                    des gefundenen Accounts verschickt.
                </p>
                <p>
                    Bitte klicken Sie auf diesen Link, um die Verknüpfung zu bestätigen.
                    Der Link ist 48 Stunden gültig.
                </p>
            </div>
            <HorizontalRule className="horizontal-rule-desktop"/>
            <Mail type="notification" className="global-flex-center" style={{ marginTop: "4rem" }} />
            <HorizontalRule className="horizontal-rule-desktop" />
            <WeiterButton
                path={ApplicationRoute.linkAccountManual}
                text="Verknüpfung überspringen"
                className="second-button"
                classNameLink="desktop-300"
                onClick={onSkipHandler}
                color="transperant"
            />
            <WeiterButton
                text="E-Mail erneut senden"
                ariaLabel="E-Mail erneut senden"
                classNameLink="desktop-300"
                onClick={onSendMailHandler}
            />
        </div>
    );
};

export default IdaLinkExistingPage;