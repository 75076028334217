import React, {useState, useEffect, useCallback} from "react";
import ApplicationRoute from "../../../config/routes.js";
import {useHistory} from "react-router-dom";
import HeaderContext from "../../../context/HeaderContext.js";
import fetcher from "../../../services/fetcher.js";
import apipath from "../../../apipath.js";
import DataConfirmationForm from "../../registration/utils/DataConfirmationForm.jsx";
import UserContext from "../../../context/UserContext.js";

const applicationPageTitles = require("../../../utils/pageTitles.json");
//HINT* REGISTRATION
const DseConfirmationPage = ({text}) => {
    const history = useHistory();
    const [checked, setChecked] = useState(false);

    const {user, setUser} = React.useContext(UserContext);
    const {setHeader} = React.useContext(HeaderContext);

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    const confirmUser = () => {
        fetcher(
            // Change api call when backend/middleware are modified
            `${apipath.path}users/CURRENT/confirm`,
            "POST",
            setData,
            setError,
            setLoading,
            setSubmitting,
            false,
            "application/json",
            JSON.stringify("") // add content
        );
    };

    const submittedWithNoErrors = useCallback(
        () => {
            return !loading && !error;
        }, [loading, error]);

    useEffect(() => {
        if (user.GDPRAccountLatestVersion) {
            history.push(ApplicationRoute.TravelDashboardNoPermissions);
        }
    }, [user, history]);

    useEffect(() => {
        setHeader((curr) => {
            return {
                ...curr,
                inUse: "dse",
                title: applicationPageTitles.completeAccount + "%noarrow%",
                ariaLabel: "Einwilligung",
                progress: "no",
            };
        });
    }, [setHeader]);

    //After submit
    useEffect(() => {
        if (submittedWithNoErrors()) {
            setUser((curr) => {
                return {
                    ...curr,
                    GDPRAccountLatestVersion: true
                };
            });
            history.push(ApplicationRoute.TravelDashboardNoPermissions);
        }
    }, [loading, error, data, history, submittedWithNoErrors, setUser]);

    const submittingNow = () => {
        return submitting && loading;
    };

    const onChangeHandler = () => {
        setChecked(!checked);
    };

    const onSubmitHandler = (e) => {
        e.preventDefault();

        confirmUser();
    };

    return (
        <DataConfirmationForm text={text} onChangeHandler={onChangeHandler} onClickHandler={onSubmitHandler}
                              submittingNow={submittingNow} checked={checked} onSubmitHandler={onSubmitHandler}
                              pageTitle={applicationPageTitles.completeAccount}
                              route={ApplicationRoute.TravelDashboardNoPermissions}/>
    );
};

export default DseConfirmationPage;