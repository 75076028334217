//Different id is a must!
const EmbassyServiceCheckBox = ({ id, text, active, onClick, className, style }) => {
  return (
    <div onClick={onClick} id={id} className={`choicebox-container ${className ?? ""}`} style={style}>
      <p id={id} className="choicebox-container__text">
        {text}
      </p>
      {active ? (
        <svg
          id={id}
          className="choicebox-container__img"
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          viewBox="0 0 28 28"
          role="img"
        >
          <title>vorhanden</title>
          <g fill="none" fillRule="evenodd">
            <g stroke="rgb(158, 5, 41)" transform="translate(1 1)">
              <path d="M26 13c0 7.18-5.82 13-13 13S0 20.18 0 13 5.82 0 13 0s13 5.82 13 13z" />
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.21 9.85L11.47 17.58 8.07 14.19" />
            </g>
          </g>
        </svg>
      ) : (
        <svg
          id={id}
          className="choicebox-container__img"
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          viewBox="0 0 27.5 27.5"
          role="img"
        >
          <title>nicht vorhanden</title>
          <g transform="translate(9.000000, 9.000000)">
            <line stroke="#000" x1="9.1" y1="0.8" x2="0.8" y2="9.1" />
            <line stroke="#000" x1="0.8" y1="0.8" x2="9.1" y2="9.1" />
          </g>
          <circle fill="none" stroke="#000" cx="13.8" cy="13.8" r="13" />
        </svg>
      )}
    </div>
  );
};

export default EmbassyServiceCheckBox;
