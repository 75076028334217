import {useContext, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import apipath from "../apipath";
import InputValidation from "../common/InputValidaiton";
import ApplicationRoute from "../config/routes";
import HeaderContext from "../context/HeaderContext";
import fetcher from "../services/fetcher";
import {
    isPasswordValid,
    arePasswordsValid,
} from "../utils/validation";
import {registrationValidationErrorMessages} from "../utils/validationErrorMessages";
import HiddenSubmitInput from "../common/HiddenSubmitInput";
import Info from "./registration/utils/Info";
import Input from "./registration/utils/Input";
import ValidationChecks from "./registration/utils/ValidationChecks.jsx";
import WeiterButton from "./registration/utils/WeiterButton";
import MetaDecorator from "../utils/MetaDecorator";
import Form from "./registration/utils/Form";

const applicationPageTitles = require("../utils/pageTitles.json");
const NewPassword = () => {
    const {setHeader} = useContext(HeaderContext);

    const location = useLocation();
    const history = useHistory();

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const resetPassword = () => {
        fetcher(
            `${apipath.path}users/updatepasswordviatoken`,
            "POST",
            () => {},
            setError,
            setLoading,
            () => {},
            false,
            "application/json",
            JSON.stringify({
                NewPassword: passwords.password,
                Token: location.pathname.replace("/newpassword/", ""),
            })
        );
    };

    const [passwords, setPasswords] = useState({
        password: "",
        confirmPassword: "",
    });

    const onChangeHandler = (e) => {
        setPasswords((curr) => {
            return {...curr, [e.target.id]: e.target.value};
        });
    };

    useEffect(() => {
        setHeader((curr) => {
            return {
                ...curr,
                inUse: "registration",
                progress: "no",
                title: "Passwort wiederherstellen%noarrow%",
            };
        });
    }, []);

    const onClickHandler = (e) => {
        e.preventDefault();
        if (arePasswordsValid(passwords)) {
            resetPassword();
        }
    };

    useEffect(() => {
        if (!loading && !error) {
            history.push(ApplicationRoute.home);
        }
        if (!loading
            && error != null
            && error instanceof Response
            && error.status === 412) {
            history.push(ApplicationRoute.errorPageResetToken);
        }
    }, [loading, error]);

    return (
        <div className="forgotten-password-container registration-middle-container">
            <MetaDecorator title={applicationPageTitles.forgotenPassword}/>
            <h1 className="forgotten-password-container__heading">
                Passwort wiederherstellen
            </h1>
            <Form id="newpasswordform" onSubmit={onClickHandler}>
                <Input
                    id="password"
                    value={passwords.password}
                    onChange={onChangeHandler}
                    placeholder="Passwort"
                    type="password"
                    text="Passwort"
                    labelText="Passwort"
                    labelStyle={{backgroundColor: "#f0f4f5"}}
                    showValidationBorder={!isPasswordValid(passwords.password)}
                />

                <Info
                    style={{marginTop: "32px", marginBottom: "16px"}}
                    text="Ihr Passwort sollte folgende Kriterien erfüllen:"
                />

                <div style={{marginBottom: "32px"}} role="alert" aria-relevant="all">
                    <ValidationChecks passwords={passwords}></ValidationChecks>
                </div>

                <Input
                    id="confirmPassword"
                    value={passwords.confirmPassword}
                    onChange={onChangeHandler}
                    placeholder="Passwort wiederholen"
                    type="password"
                    labelText="Passwort wiederholen"
                    labelStyle={{backgroundColor: "#f0f4f5"}}
                    showValidationBorder={
                        passwords.confirmPassword.length > 0 &&
                        passwords.confirmPassword !== passwords.password
                    }
                    error={
                        passwords.confirmPassword.length > 0 &&
                        passwords.confirmPassword !== passwords.password
                    }
                />
                <InputValidation
                    isHidden={
                        !(
                            passwords.confirmPassword.length > 0 &&
                            passwords.confirmPassword !== passwords.password
                        )
                    }
                    text={
                        registrationValidationErrorMessages.passwordAndConfirmationPasswordNotEqual
                    }
                />

                <HiddenSubmitInput/>

                <div className="hide-weiter-on-keyboard-content"></div>
                <WeiterButton
                    disabled={!arePasswordsValid(passwords)}
                    path="./"
                    text="Neues Passwort erstellen"
                    positioned="static"
                    onClick={onClickHandler}
                    className="hide-weiter-on-keyboard"
                />
            </Form>
        </div>
    );
};

export default NewPassword;
