import React, { useEffect, useState } from "react";
import WeiterButton from "../utils/WeiterButton";
import ContentTitle from "../utils/ContentTitle";
import UserContext from "../../../context/UserContext";
import Input from "../utils/Input";
import ApplicationRoute from "../../../config/routes";
import {
  isEmailValidation,
  hasAtSymbolEmailValidation,
  hasTwoCharactersAfterAtSymbolEmailValidation,
} from "../../../utils/validation";
import { useHistory } from "react-router-dom";
import Form from "../utils/Form";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import HeaderContext from "../../../context/HeaderContext";
import InputValidation from "../../../common/InputValidaiton";
import { registrationValidationErrorMessages } from "../../../utils/validationErrorMessages";
import HorizontalRule from "../../../common/HorizontalRule";

import MetaDecorator from "../../../utils/MetaDecorator";
import { prefilledUserData } from "../../../utils/helpers";
const applicationPageTitles = require("../../../utils/pageTitles.json");
//HINT* REGISTRATION
const EmailAdress = ({ text }) => {
  const history = useHistory();
  const [email, setEmail] = useState("");

  const { user, setUser } = React.useContext(UserContext);
  const { setHeader } = React.useContext(HeaderContext);

  //
  //
  const activate = () => {
    setActive(() => true);
  };

  const [active, setActive] = useState(false);

  useEffect(() => {
    document.getElementsByTagName("HTML")[0].addEventListener("click", activate);

    return () => document.getElementsByTagName("HTML")[0].removeEventListener("click", activate);
  }, []);
  //
  //

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((7 / 19) * 100),
        title: "Neue Reise anlegen",
      };
    });
  }, []);

  const isValid = () => {
    return isEmailValidation(email) && hasTwoCharactersAfterAtSymbolEmailValidation(email);
  };

  const updateUser = () => {
    setUser((curr) => {
      return { ...curr, Email: email };
    });
  };

  const onChangeHandler = (e) => {
    setEmail(e.target.value);
  };

  const onClickHandler = () => {
    updateUser();
  };

  const onsubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateUser();
      history.push(ApplicationRoute.registerPassNumber);
    }
  };

  useEffect(() => {
    setEmail(user.Email ? user.Email : "");
  }, [user]);

  return (
    <>
      <div className="registration-middle-container">
        <MetaDecorator title={applicationPageTitles.createTripPages} />
        <ContentTitle
          id="register-email"
          text={prefilledUserData("Bitte geben Sie Ihre E-Mail-Adresse ein:")}
          style={{ marginBottom: "32px" }}
        />
        <HorizontalRule className="horizontal-rule-desktop" />
        <Form
          className="max-width-380"
          id="register-email-form"
          onSubmit={onsubmitHandler}
          ariaLabelledBy="register-email"
        >
          <Input
            id="email"
            value={email}
            onChange={onChangeHandler}
            placeholder="E-Mail-Adresse"
            type="email"
            labelText="E-Mail-Adresse"
            showValidationBorder={active && (!isValid() || !hasTwoCharactersAfterAtSymbolEmailValidation(email))}
          />

          <InputValidation
            isHidden={
              !active ||
              !hasAtSymbolEmailValidation(email) ||
              !hasTwoCharactersAfterAtSymbolEmailValidation(email) ||
              isValid()
            }
            text={registrationValidationErrorMessages.notValidEmail}
          />

          <InputValidation
            isHidden={!active || hasAtSymbolEmailValidation(email)}
            text={registrationValidationErrorMessages.missingAtSymbolEmail}
          />

          <InputValidation
            isHidden={
              !active || !hasAtSymbolEmailValidation(email) || hasTwoCharactersAfterAtSymbolEmailValidation(email)
            }
            text={registrationValidationErrorMessages.charactersAfterAtSymbolLessThanTwoEmail}
          />

          <HiddenSubmitInput />
        </Form>
        <HorizontalRule className="horizontal-rule-desktop" />
        <WeiterButton
          disabled={!isValid()}
          path={ApplicationRoute.registerPassNumber}
          onClick={onClickHandler}
          ariaLabel={text}
          className={"floating-weiter-buton"}
        />
      </div>
    </>
  );
};

export default EmailAdress;
