import {dateFormatter, phoneFormatter} from "./helpers.js";
import {isPhoneValidation} from "./validation.js";

export const optionalDefault = {
    FirstLastName: false,
    PhoneNumber: false,
    Email: false,
}

export const setOptionalPhoneToTrue = (phone, email) => {
    if (email) {
        return true;
    }
    return isPhoneValidation(phone);
};

export function editOnChangeGroupMembers(e, curr) {
        let newArray = [...curr];
        let index = parseInt(e.target.id);
        let id = e.target.id.toString().replace(index, "");

        let value = e.target.value;

        if (id === "Birthdate") {
            value = dateFormatter(curr[index].Birthdate, value);
        } else if (id === "MobilePhone") {
            value = phoneFormatter(curr[index].MobilePhone, value);
        }

        newArray[index] = {
            ...newArray[index],
            [id]: value,
        };

        return newArray;
}

export function effectOptionals(setOptionals, contactPerson) {
    setOptionals((curr) => {
        return {
            ...curr,
            FirstLastName: !!contactPerson.FirstLastName,
            PhoneNumber: setOptionalPhoneToTrue(contactPerson.PhoneNumber, contactPerson.Email),
            Email: !!contactPerson.Email
        };
    });
}