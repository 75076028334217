import { atom, computed } from "nanostores";
import React, { useEffect, useState } from "react";
import apipath from "../../apipath";
import { autoCacheStoredCalls } from "../../services/caching";
import style from "./OfflineManager.module.scss";
import UserContext from "../../context/UserContext";

const $onlineStatus = atom(true)
export const onlineStatus = computed($onlineStatus, v => v);
export const offlineStatus = computed($onlineStatus, v => !v)

async function checkOnlineStatus() {
    const url = `${apipath.path}healthcheck`
    const result = await new Promise((resolve) => {
        setTimeout(() => {
            resolve(false)
        }, 2000)
        fetch(url).then(() => {
           resolve(true)
        })
    });
    $onlineStatus.set(result)
}

const OfflineManager = () => {
    const { offline, setOffline } = React.useContext(UserContext);
    useEffect(() => {
        offlineStatus.subscribe(x => {
            setOffline(x)
        })
    }, [setOffline])

    const [retry, setRetry] = useState();

    useEffect(() => {
        clearInterval(retry);
        setRetry(setInterval(checkOnlineStatus, 3000));
        checkOnlineStatus();
        autoCacheStoredCalls();

        return () => clearInterval(retry);
    }, [])

    if (!offline) return <></>

    return <>
        <div className={style.OfflineManager}>
            <div>
                <svg version="1.1" id="Layer_1" x="0px" y="0px" width={47}
                    viewBox="0 0 30 30" style={{ enableBackground: "new 0 0 30 30" }}>
                    <polygon className={style.IconStroke} points="27.92,27 2.08,27 15,3 " />
                    <line className={style.IconStroke} x1="15" y1="9.93" x2="15" y2="17.77" />
                    <line className={style.IconStroke} x1="15" y1="21.46" x2="15" y2="23.31" />
                </svg>
            </div>
            <div>
                <p>Sie sind gerade offline!</p>
                <p>Informationen könnten nicht mehr aktuell sein.</p>
            </div>
        </div>
    </>
}

export default OfflineManager;