//Different id is a must!
const ChoiceBox = ({ id, text, active, onClick, className }) => {
  return (
    <div
      onClick={onClick}
      id={id}
      className={`choicebox-container ${className ?? ""}`}
    >
      <p id={id} className="choicebox-container__text">
        {text}
      </p>
      {active ? (
        <img
          id={id}
          className="choicebox-container__img"
          src="./images/common/successred.svg"
          alt=""
        ></img>
      ) : (
        <img
          id={id}
          src={"./images/common/successgray.svg"}
          className="choicebox-container__img"
          alt=""
        ></img>
      )}
    </div>
  );
};

export default ChoiceBox;
