import React, { useEffect } from "react";
import apipath from "../../../apipath";
import HeaderContext from "../../../context/HeaderContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import LoadingPage from "../../travel-registration/LoadingPage";

function InfoDatenschutz() {
  const { setHeader } = React.useContext(HeaderContext);
  const [content, setContent] = React.useState("");

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "content",
        title: "Datenschutz",
      };
    });

    fetch(`${apipath.path}app-inhalte/datenschutz/type/json/`, {
      method: "GET",
    }).then(data => data.json()).then(data => setContent(data.data));

  }, []);
  const data = JSON.parse(localStorage.getItem("countries"));
  if(content.length === 0) {
    return <LoadingPage />
  }
  return (
    <div className="content-pages-container">
      <MetaDecorator title="Info Auslandsregistrierung" />
      {
        content.map(headerBody => 
          <>
            <div className="content-pages-heading" dangerouslySetInnerHTML={{__html: headerBody.header}}></div>
            <div className="content-pages-paragraph" dangerouslySetInnerHTML={{__html: headerBody.bodytext}}></div>
          </>
        )
      }
    </div>
  );
}

export default InfoDatenschutz;
