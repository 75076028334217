import React, { useEffect, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";
import apipath from "../../../apipath";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import fetcher from "../../../services/fetcher";
import MetaDecorator from "../../../utils/MetaDecorator";

const applicationPageTitles = require("../../../utils/pageTitles.json");
//HINT* REGISTRATION
const EmailVerifiedPage = ({ text }) => {
  const history = useHistory();

  const { setHeader } = React.useContext(HeaderContext);

  const location = useLocation();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const activateAccount = () => {
    fetcher(
      `${apipath.path}users/activate`,
      "POST",
      () => {},
      setError,
      setLoading,
      () => {},
      false,
      "application/json",
      JSON.stringify({
        Token: location.pathname.substring("/registrierung/verifiedemail/".length),
      }),
      false
    );
  };

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: 100,
        title: "Registrierung",
      };
    });

    activateAccount();
  }, []);

  useEffect(() => {
    if (!loading && !error) {
      history.push(ApplicationRoute.activatedAccount);
    } else if (error) {
      history.push(ApplicationRoute.errorPageVerified);
    }
  }, [loading, error]);

  return (
    <div className="registration-middle-container registration-middle-container--se">
      <MetaDecorator title={applicationPageTitles.registrationPages} />
    </div>
  );
};

export default EmailVerifiedPage;
