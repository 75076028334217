import HorizontalRule from "../../../common/HorizontalRule.jsx";
import React from "react";
import MetaDecorator from "../../../utils/MetaDecorator.jsx";
import Form from "./Form.jsx";
import CheckBox from "./CheckBox.jsx";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput.jsx";
import WeiterButton from "./WeiterButton.jsx";

const DataConfirmationForm = (
    {
        checked,
        text,
        submittingNow,
        onSubmitHandler,
        onChangeHandler,
        onClickHandler,
        route,
        pageTitle
    }) => {
    return (<div className="login-middle-container">
        <MetaDecorator title={pageTitle}/>
        <p className="content-title" style={{margin: "3.2rem auto"}}>
            Ziel dieser Datenverarbeitung ist die bessere Unterstützung in einem größeren Krisen- und Notfall oder bei
            Gefahr für Leben und Gesundheit. Zu diesem Zweck darf aufgrund der angegebenen personenbezogenen Daten in
            Situationen, in denen mein Leben oder meine Gesundheit gefährdet ist oder die Ausübung konsularischen
            Schutzes
            erforderlich wird, durch die jeweils zuständige österreichische Konsularbehörde an mich und angegebene
            Kontaktpersonen herangetreten werden.
        </p>
        <HorizontalRule className="horizontal-rule-desktop"/>
        <Form className="" id="form" onSubmit={onSubmitHandler} ariaLabelledBy="confirmitation">
            <div>
                <CheckBox
                    htmlFor="confirmBox"
                    id="confirmBox"
                    state={checked}
                    jsx={`
                Ich nehme die <a href="https://www.bmeia.gv.at/reise-aufenthalt/auslandsservice/datenschutz/"  target="_blank" rel="noopener noreferrer" style="color: #000;">Datenschutzerklärung</a> 
                zur Kenntnis und stimme der Verarbeitung meiner personenbezogenen Daten
                durch das BMEIA zu. Zweck der Datenverarbeitung ist die Registrierung zur Erleichterung der Gewährung konsularischen Schutzes.`}
                    onChangeHandler={onChangeHandler}
                />
            </div>
            <HiddenSubmitInput/>
            {/* <Link
          className="data-protection-link"
          to={ApplicationRoute.dataProtection}
          aria-label="gehe zu Datenschutzinformationen (tbd)"
        >
          Datenschutzinformation (tbd)
        </Link> */}
        </Form>
        <HorizontalRule className="horizontal-rule-desktop"/>
        <WeiterButton
            text="Weiter"
            disabled={!checked || submittingNow()}
            onClick={onClickHandler}
            ariaLabel={text}
            path={route}
            positioned="static"
        />
    </div>)
};

export default DataConfirmationForm;