import React from "react";
import { Link } from "react-router-dom";
import Star from "../../../svg/Star";
import ArrowRight from "../../../svg/ArrowRight";
import { isBlockedFlag } from "../../../utils/helpers";
import apipath from "../../../apipath";

export default function CountryCard({
  flag,
  countryName,
  riskLvl,
  id,
  Id,
  favourite = false,
  favouriteToggle,
  onClick,
  countryUid,
  hasStar = true,
  role = "",
}) {
  function savePosition(ev) {
    localStorage.setItem("countryOverviewPosition", window.scrollY);
  }

  if (isBlockedFlag(countryName)) {
    flag = `${apipath.pathBMEIAgvat_API}flags/.png`;
  }

  return (
    <>
      <div className="country__card" role={role}>
        <Link
          style={{
            textDecoration: "none",
            color: "#000000",
          }}
          aria-label={`gehe zu ${countryName} ${riskLvl}`}
          role="button"
          to={`/country/${countryUid}`}
          onClick={savePosition}
        >
          <div className="country__flag">
            <img className="country__flag--round" src={flag} alt="" onError={(ev) => ev.currentTarget.remove()} />
          </div>
          <div className="country__name">
            <p>{countryName}</p>
            {riskLvl ? <p className="country__risk">{riskLvl}</p> : <> </>}
          </div>

          <div className="arrow__right" id={Id} onClick={onClick}>
            <ArrowRight />
          </div>
        </Link>{" "}
        <div
          className="favorit__icon"
          onClick={(ev) => {
            favouriteToggle(ev, id);
          }}
        >
          {hasStar && <Star favourite={favourite} />}
        </div>
      </div>
    </>
  );
}
