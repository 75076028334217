import TravelMember from "./TravelMember.jsx";
import {getGenderFromEnumGerman} from "../../../utils/helpers.js";

const TravelGroup = (
    {
        groupMembers,
        onChange,
        foreign = false,
        deleteHandler,
        buttonText = 'löschen',
    }) => {
    return (
        <>
            {groupMembers.map((tg, i) => {
                return (
                    <TravelMember
                        key={i}
                        id={i}
                        FirstLastName={tg.FirstLastName ? tg.FirstLastName : ""}
                        Birthdate={tg.Birthdate ? tg.Birthdate : ""}
                        MobilePhone={tg.MobilePhone ? tg.MobilePhone : ""}
                        Email={tg.Email ? tg.Email : ""}
                        Gender={getGenderFromEnumGerman(tg.Gender)}
                        Nationality={tg.Nationality}
                        DegreeOfRelationship={tg.DegreeOfRelationship}
                        onChange={onChange}
                        foreign={foreign}
                        delete={deleteHandler}
                        buttonText={buttonText}
                        buttonClickHandler={deleteHandler ? () => deleteHandler(i) : null}
                    />
                );
            })}
        </>
    )
}
export default TravelGroup;