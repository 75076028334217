import "../../../App.scss";
import React, { useEffect, useState } from "react";
import TravelContext from "../../../context/TravelContext";
import ApplicationRoute from "../../../config/routes";
import { useHistory } from "react-router-dom";
import {
  IsDateBiggerThanTodayValidation,
  IsDateSmallerThan_1900_1_1_Validation,
  isDateValidation,
} from "../../../utils/validation";
import Form from "../utils/Form";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import PageTitle from "../utils/PageTitle";
import HeaderContext from "../../../context/HeaderContext";
import {
  convertAppDateToMiddlewareDateFormat,
  convertMiddlewareDateToAppDateFormat,
  dateFormatter,
  getTravelGroupMemberLastIndex_Array,
} from "../../../utils/helpers";
import { registrationValidationErrorMessages } from "../../../utils/validationErrorMessages";
import InputValidation from "../../../common/InputValidaiton";
import Input from "../../registration/utils/Input";
import WeiterButton from "../../registration/utils/WeiterButton";
import MetaDecorator from "../../../utils/MetaDecorator";
import HorizontalRule from "../../../common/HorizontalRule";

const applicationPageTitles = require("../../../utils/pageTitles.json");
const formLabel = "Geben Sie mitreisende:r Geburtsdatum ein";

const AddTravelCompanionDateOfBirth = ({ text }) => {
  const { setHeader } = React.useContext(HeaderContext);

  const history = useHistory();
  const { travel, setTravel } = React.useContext(TravelContext);

  const groupMemberIndex = getTravelGroupMemberLastIndex_Array(travel);
  let initDate = travel?.TravelGroupMember[groupMemberIndex]?.Birthdate;
  initDate = initDate ? convertMiddlewareDateToAppDateFormat(initDate) : "";

  const [date, setDate] = useState(initDate);
  const [placeHolder, setPlaceHolder] = useState("Geburtsdatum");

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: "Neue Reise anlegen",
        progress: parseInt((13 / 19) * 100),
      };
    });
  }, []);

  const isValidDate = () => {
    return isDateValidation(date);
  };

  const IsDateSmallerThan1900 = () => {
    return IsDateSmallerThan_1900_1_1_Validation(date);
  };

  const IsDateBiggerThanToday = () => {
    return IsDateBiggerThanTodayValidation(date);
  };

  const isValid = () => {
    return isValidDate() && IsDateBiggerThanToday() && !IsDateSmallerThan1900();
  };
  const updateTravel = () => {
    const groupMemberIndex =
      travel.TravelGroupMember.length > 0
        ? travel.TravelGroupMember.length - 1
        : 0;
    let newArray = [...travel.TravelGroupMember];
    newArray[groupMemberIndex] = {
      ...newArray[groupMemberIndex],
      Birthdate: convertAppDateToMiddlewareDateFormat(date),
    };

    setTravel((curr) => {
      return {
        ...curr,
        TravelGroupMember: newArray,
      };
    });
  };

  const onChangeHandler = (e) => {
    let newDate = dateFormatter(date, e.target.value);

    setDate(newDate);
  };

  const onClickInputHandler = () => {
    setPlaceHolder("TT.MM.JJJJ");
  };
  const onClickHandler = () => {
    updateTravel();
  };

  const onsubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateTravel();
      history.push(ApplicationRoute.addTravelGender);
    }
  };

  return (
    <div className="registration-middle-container">
      <MetaDecorator title={applicationPageTitles.createTripPages} />
      <PageTitle text="Bitte geben Sie das Geburtsdatum ein:" />

      <p
        className="page-paragraph page-paragraph--small"
        aria-label="Mitreisende:r"
      >
        Mitreisende/r
      </p>

      {/* <p className="page-paragraph page-paragraph--small">Mitreisende/r</p> */}
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form
        className="max-width-380"
        id="form"
        onSubmit={onsubmitHandler}
        ariaLabel={formLabel}
      >
        <Input
          id="date"
          value={date}
          onChange={onChangeHandler}
          onClick={onClickInputHandler}
          placeholder={placeHolder}
          labelText="Geburtsdatum"
          showValidationBorder={date.length > 0 && !isValid()}
        />
        <InputValidation
          isHidden={!date.length > 0 || isValidDate()}
          text={registrationValidationErrorMessages.notValidDate}
        />

        <InputValidation
          isHidden={
            !date.length > 0 || !IsDateSmallerThan1900() || !isValidDate()
          }
          text={
            registrationValidationErrorMessages.dateOfBirthLessThan_01_01_1900
          }
        />

        <InputValidation
          isHidden={
            !date.length > 0 || IsDateBiggerThanToday() || !isValidDate()
          }
          text={registrationValidationErrorMessages.dateBiggerThanToday}
        />

        <HiddenSubmitInput />
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.addTravelGender}
        onClick={onClickHandler}
        ariaLabel={text}
        className={"floating-weiter-buton"}
      />
    </div>
  );
};

export default AddTravelCompanionDateOfBirth;
