import React from "react";
const PageTitle = ({ text, ariaLabel, id }) => {
  return (
    <p
      id={id}
      className="page-title"
      role="heading"
      aria-label={ariaLabel}
    >
      {text}
    </p>
  );
};

export default PageTitle;
