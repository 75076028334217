import { useHistory } from "react-router";
import ApplicationRoute from "../../../config/routes";
import Cross from "../../../svg/Cross";
import CrisisCard from "./CrisisCard";
import CrisisCardDefault from "./CrisisCardDefault";

const SecondPushStepComponent = ({
  needHelp,
  crisisCardObj,
  showTopPart,
  showBottomText,
  className,
  showCrisisCard,
  longitude,
  latitude,
}) => {
  const { country, img, phones, address, flag, type } = crisisCardObj;
  const history = useHistory();

  const onCrossClickHandler = () => {
    history.push(ApplicationRoute.TravelDashboardNoPermissions);
  };

  return (
    <div className={`second-push-container ${className ?? ""}`}>
      {showTopPart && (
        <div>
          <Cross onClick={onCrossClickHandler} color="black" className="second-push-container__cross" />
          {!needHelp ? (
            <div className="second-push-container__img-text">
              <img className="second-push-container__img-text__img" src="images/common/crisishuman.svg" alt="" />
              <p className="second-push-container__img-text__text">Danke für Ihre Nachricht.</p>
            </div>
          ) : (
            <div className="second-push-container__img-text">
              <img className="second-push-container__img-text__img" src="images/common/telephonecrisis.svg" alt="" />
              <p className="second-push-container__img-text__text">
                Danke für Ihre Nachricht. Wir werden uns so rasch wie möglich mit Ihnen in Verbindung setzen.
              </p>
            </div>
          )}
          <p className="second-push-container__img-text__text-support">
            Bitte wenden Sie sich bei Fragen an folgenden Kontakt:
          </p>
        </div>
      )}

      <CrisisCard
        style={!showCrisisCard ? { display: "none" } : {}}
        country={country}
        img={img}
        phones={phones}
        address={address}
        flag={flag}
        longitude={longitude}
        latitude={latitude}
        type={type}
      />

      <CrisisCardDefault />
      {showBottomText && (
        <p className="second-push-container__footer-text">Bitte folgen Sie den Anweisungen der örtlichen Behörden.</p>
      )}
    </div>
  );
};

export default SecondPushStepComponent;
