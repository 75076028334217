import React from "react";
import ControledRadioInput from "../../registration/utils/ControledRadioInput.jsx";

const InfoMailForm = (
    {
        GDPRForeignAustrianOfficialInfo,
        setGDPRForeignAustrianOfficialInfo,
        GDPRForeignAustrianInofficialInfo,
        setGDPRForeignAustrianInofficialInfo
    }) => {

    const onGDPRForeignAustrianOfficialInfoChange = (e) => {
        setGDPRForeignAustrianOfficialInfo(e.target.value === "ja");
    };
    const onGDPRForeignAustrianInofficialInfoChange = (e) => {
        setGDPRForeignAustrianInofficialInfo(e.target.value === "ja");
    };

    return (<div style={{margin: "1.6rem"}}>
        <div>
            <p>
                Einwilligung zur Zusendung von Informationen durch die zuständige Vertretungsbehörde:
                <br/> Möchten Sie von Ihrer zuständigen Vertretungsbehörde allgemeine Informationen zu für
                Auslandsösterreicher/innen relevanten Themen wie zum Beispiel zu Wahlen, wichtigen
                Gesetzesänderungen oder
                Einreisebestimmungen erhalten?
            </p>
            <ControledRadioInput
                role="radio"
                id="GDPRForeignAustrianOfficialInfo_yes"
                value="ja"
                name="gdprforeignaustrianofficialinfo"
                textInfo="Ja"
                ariaLabel=""
                checked={GDPRForeignAustrianOfficialInfo}
                onChange={onGDPRForeignAustrianOfficialInfoChange}
                ariaChecked={GDPRForeignAustrianOfficialInfo}
            />
            <ControledRadioInput
                role="radio"
                id="GDPRForeignAustrianOfficialInfo_no"
                value="nein"
                name="gdprforeignaustrianofficialinfo"
                textInfo="Nein"
                ariaLabel=""
                checked={GDPRForeignAustrianOfficialInfo != null && !GDPRForeignAustrianOfficialInfo}
                onChange={onGDPRForeignAustrianOfficialInfoChange}
                ariaChecked={!GDPRForeignAustrianOfficialInfo}
            />
        </div>
        <div style={{marginTop: "2.4rem"}}>
            <p>
                Möchten Sie von Ihrer zuständigen Vertretungsbehörde Informationen und Einladungen zu
                kulturellen und/oder
                gesellschaftlichen Veranstaltungen erhalten?
            </p>
            <ControledRadioInput
                role="radio"
                id="GDPRForeignAustrianInofficialInfo_yes"
                value="ja"
                name="gdprforeignaustrianinofficialinfo"
                textInfo="Ja"
                ariaLabel=""
                checked={!!GDPRForeignAustrianInofficialInfo}
                onChange={onGDPRForeignAustrianInofficialInfoChange}
                ariaChecked={GDPRForeignAustrianInofficialInfo}
            />
            <ControledRadioInput
                role="radio"
                id="GDPRForeignAustrianInofficialInfo_no"
                value="nein"
                name="gdprforeignaustrianinofficialinfo"
                textInfo="Nein"
                ariaLabel=""
                checked={GDPRForeignAustrianInofficialInfo != null && !GDPRForeignAustrianInofficialInfo}
                onChange={onGDPRForeignAustrianInofficialInfoChange}
                ariaChecked={!GDPRForeignAustrianInofficialInfo}
            />
        </div>
        <p style={{marginTop: "2.4rem"}}>
            Sie können diese Auswahl jederzeit in den Einstellungen über Ihren Web-Zugriff oder die App
            ändern oder Ihre
            Einwilligung widerrufen. Wichtige Informationen im Krisenfall erhalten Sie auf jeden Fall.
        </p>

        <p style={{marginTop: "1.2rem"}}>
            Weitere Informationen zu Ihren Rechten und zum Datenschutz im BMEIA finden Sie in der{" "}
            <a
                href="https://www.bmeia.gv.at/reise-aufenthalt/auslandsservice/datenschutz"
                target="_blank"
                style={{color: "black"}}
                aria-label="Datenschutzinformation."
                rel="noopener noreferrer"
            >
                Datenschutzerklärung
            </a>
            .
        </p>
    </div>)
};

export default InfoMailForm;