import React, { useEffect, useState } from "react";
import WeiterButton from "../../../registration/utils/WeiterButton";
import UserContext from "../../../../context/UserContext";
import ContentTitle from "../../../registration/utils/ContentTitle";
import Input from "../../../registration/utils/Input";
import ApplicationRoute from "../../../../config/routes";
import { useHistory } from "react-router-dom";
import Form from "../../../registration/utils/Form";
import HiddenSubmitInput from "../../../../common/HiddenSubmitInput";
import HeaderContext from "../../../../context/HeaderContext";
import InputValidation from "../../../../common/InputValidaiton";
import { registrationValidationErrorMessages } from "../../../../utils/validationErrorMessages";
import {
  isPassnumberValidation,
  isPersonalNumberValidation,
} from "../../../../utils/validation";
import MetaDecorator from "../../../../utils/MetaDecorator";
import HorizontalRule from "../../../../common/HorizontalRule";
import { prefilledUserData } from "../../../../utils/helpers";

const applicationPageTitles = require("../../../../utils/pageTitles.json");
export function ForeignPassport({ text }) {
  const history = useHistory();
  const [passNumber, setPassNumber] = useState("");
  const [IdNumber, setIdNumber] = useState("");

  const { user, setUser } = React.useContext(UserContext);
  const { setHeader } = React.useContext(HeaderContext);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((8 / 19) * 100),
        title: "Als Auslandsösterreicher/in registrieren",
        ariaLabel: "Als Auslandsösterreicher:in registrieren",
      };
    });
  }, []);

  const isValid = () => {
    return isPassnumberValidation(passNumber);
  };

  const updateUser = () => {
    setUser((curr) => {
      return { ...curr, PassNumber: passNumber, IdNumber: IdNumber };
    });
  };

  const onChangeHandler = (e) => {
    setPassNumber(e.target.value);
  };

  const onChangeHandlerPersonalId = (e) => {
    setIdNumber(e.target.value);
  };

  const onClickHandler = () => {
    updateUser();
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateUser();
      history.push(
        ApplicationRoute.foreignRegistrationRegistrationEmergencyContact
      );
    }
  };

  useEffect(() => {
    setPassNumber(user.PassNumber ? user.PassNumber : "");
    setIdNumber(user.IdNumber ? user.IdNumber : "");
  }, [user]);

  return (
    <section className="foreign-registration registration-middle-container">
      <MetaDecorator title={applicationPageTitles.foreignRegistration} />
      <ContentTitle
        id="foreign-registration-passport"
        text={
          passNumber || IdNumber
            ? prefilledUserData(
                "Bitte geben Sie Ihre Reisepass- oder Personalausweisnummer ein (optional):"
              )
            : "Bitte geben Sie Ihre Reisepass- oder Personalausweisnummer ein (optional):"
        }
      />
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form
        id="form"
        onSubmit={onSubmitHandler}
        ariaLabelledBy="contentTitleId"
        className="max-width-380"
      >
        <Input
          id="passNumber"
          value={passNumber}
          onChange={onChangeHandler}
          placeholder="Reisepassnummer"
          ariaRequired={false}
          labelText="Reisepassnummer"
          showValidationBorder={!isPassnumberValidation(passNumber)}
        />

        <InputValidation
          isHidden={isPassnumberValidation(passNumber)}
          text={registrationValidationErrorMessages.notValidPassnumber}
        />

        <Input
          id="IdNumber"
          value={IdNumber}
          onChange={onChangeHandlerPersonalId}
          placeholder="Personalausweisnummer"
          ariaRequired={false}
          labelText="Personalausweisnummer"
          showValidationBorder={!isPersonalNumberValidation(IdNumber)}
        />

        <InputValidation
          isHidden={isPersonalNumberValidation(IdNumber)}
          text={registrationValidationErrorMessages.notValidPersonalNumber}
        />

        <HiddenSubmitInput />
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content"> </div>
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.foreignRegistrationRegistrationEmergencyContact}
        onClick={onClickHandler}
        ariaLabel={text}
        className="hide-weiter-on-keyboard floating-weiter-buton"
      />
    </section>
  );
}
