import {useEffect, useContext, useState, useRef} from "react";
import {Link, useHistory} from "react-router-dom";
import Separater from "../../../common/Separater";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import TravelContext from "../../../context/TravelContext";
import EditInput from "../../../common/EditInput";
import HorizontalRule from "../../../common/HorizontalRule";
import {
    convertAppDateToMiddlewareDateFormat,
    convertMiddlewareDateToAppDateFormat,
    pluck,
    postalCodeFormatter,
    setApiObjectAddTravel,
    setApiUserObjectUpdate2,
    setApiUserObjectUpdate3,
    setUserValuesOnLogin,
    getUIID,
    getEnumFromGender,
    isValidContactPerson,
    getSecondAfterSpace,
} from "../../../utils/helpers";
import Form from "../../registration/utils/Form";
import fetcher from "../../../services/fetcher";
import apipath from "../../../apipath";
import UserContext from "../../../context/UserContext";
import AlertBox from "../../../common/AlertBox";

import ProfileEditComponent from "../../travel-registration/steps/ProfileEditComponent";
import {travelGroupMemberValidation} from "../../../utils/validation";
import InputValidation from "../../../common/InputValidaiton";
import {
    getCountryFlag,
    getCountryNameFromId,
    getCountryPicture,
    getRegionsByCountryId,
} from "../../travel-registration/utils/countryInfo";
import RelativeModal from "../../../common/RelativeModal";
import {TravelGroupMember} from "../../../config/defaultTravelValues";
import SuccessContext from "../../../context/SuccessContext";
import LoadingPage from "../../travel-registration/LoadingPage";
import MainButton from "../../../common/MainButton";
import MetaDecorator from "../../../utils/MetaDecorator";
import {
    editOnChangeGroupMembers,
    effectOptionals,
    optionalDefault,
} from "../../../utils/editUtils.js";
import TravelGroup from "../../travel-registration/utils/TravelGroup.jsx";
import InfoMailForm from "../utils/InfoMailForm.jsx";

const applicationPageTitles = require("../../../utils/pageTitles.json");
export default function DashboardForeignEditTravel() {
    const history = useHistory();

    const {travel,} = useContext(TravelContext);
    const {setHeader} = useContext(HeaderContext);
    const {user, setUser} = useContext(UserContext);
    const {setSuccessBox} = useContext(SuccessContext);

    const [userState, setUserState] = useState(user);
    const [error,] = useState(null);
    const [loading,] = useState(true);

    const [updateProfile, setUpdateProfile] = useState(null);
    const [updateProfileError, setUpdateProfileError] = useState(null);
    const [updateProfileLoading, setUpdateProfileLoading] = useState(true);
    const [submttingUpdateProfile, setSubmttingUpdateProfile] = useState(false);

    const [relativeModal, setRelativeModal] = useState(false);
    const [alertBoxHidden, setAlertBoxHidden] = useState(true);
    const [unsavedChangesAlertHidden, setUnsavedChangesAlertHidden] = useState(true);

    const [GDPRDataRetention,] = useState(
        userState.GDPRDataRetention ? userState.GDPRDataRetention : false
    );
    const [GDPRForeignAustrian,] = useState(
        userState.GDPRForeignAustrian ? userState.GDPRForeignAustrian : false
    );
    const [GDPRForeignAustrianOfficialInfo, setGDPRForeignAustrianOfficialInfo] = useState(
        userState.GDPRForeignAustrianOfficialInfo ? userState.GDPRForeignAustrianOfficialInfo : false
    );
    const [GDPRForeignAustrianInofficialInfo, setGDPRForeignAustrianInofficialInfo] = useState(
        userState.GDPRForeignAustrianInofficialInfo ? userState.GDPRForeignAustrianInofficialInfo : false
    );

    const [deleteRelativeIndex, setDeleteRelativeIndex] = useState(-1);

    const [countries] = useState(JSON.parse(localStorage.getItem("countries")));

    const [regions, setRegions] = useState(
        userState.Country ? pluck(getRegionsByCountryId(userState.Country), "Name") : []
    );

    useEffect(() => {
        setRegions(userState.Country ? pluck(getRegionsByCountryId(userState.Country), "Name") : [])
    }, [userState.Country]);

    const [changed, setChanged] = useState(true);
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        setCounter(counter + 1);

        if (counter + 1 > 2) {
            setChanged(true);
        }
    }, [userState]);

    const [optionals, setOptionals] = useState(optionalDefault);
    useEffect(() => {
        effectOptionals(setOptionals, userState.ContactPerson);
    }, [userState.ContactPerson]);

    const updateUserMiddleware = () => {
        let userCopy = Object.assign({}, userState);
        userCopy.Relatives = [];

        for (const memberItem of groupMembers) {
            userCopy.Relatives.push(Object.assign({}, memberItem));
        }

        let apiObj = setApiUserObjectUpdate3(userCopy);

        if ("FamilyGroupMember" in apiObj && !!apiObj.FamilyGroupMember && apiObj.FamilyGroupMember.length > 0) {
            apiObj.FamilyGroupMember = apiObj.FamilyGroupMember.map((fgm) => {
                return {
                    ...fgm,
                    MobilePhone: fgm.MobilePhone ? fgm.MobilePhone : null,
                    Email: fgm.Email ? fgm.Email : null,
                    Gender: getEnumFromGender(fgm.Gender),
                    Birthdate: convertAppDateToMiddlewareDateFormat(fgm.Birthdate),
                    LastName: getSecondAfterSpace(fgm.FirstLastName.trim()).trim(),
                };
            });
        }

        fetcher(
            `${apipath.path}users/CURRENT`,
            "PUT",
            setUpdateProfile,
            setUpdateProfileError,
            setUpdateProfileLoading,
            setSubmttingUpdateProfile,
            false,
            "application/json",
            JSON.stringify(apiObj)
        );
    };
    const [groupMembers, setGroupMembers] = useState(
        userState.Relatives.map((tg) => {
            return {
                ...tg,
                Birthdate: tg.Birthdate ? convertMiddlewareDateToAppDateFormat(tg.Birthdate) : "",
            };
        })
    );

    const [validPersonalInfo, setValidPersonalInfo] = useState(true);
    const [validGroupMembers, setValidGroupMembers] = useState(true);

    useEffect(() => {
        if (groupMembers.length === 0) {
            setValidGroupMembers(true);
        } else {
            let valid = true;
            groupMembers.forEach((tgm) => {
                if (!travelGroupMemberValidation(tgm)) valid = false;
            });

            setValidGroupMembers(valid);
        }
    }, [groupMembers]);

    useEffect(() => {
        ref.current = userState;
    }, [userState]);

    const goBackHeaderFunc = (usst) => {
        if (JSON.stringify(usst.current) !== localStorage.getItem("tempUser")) {
            setUnsavedChangesAlertHidden(false);
        } else {
            history.goBack();
        }
    };

    const ref = useRef("");
    const saveChangeHeaderFunc = () => {
        setUnsavedChangesAlertHidden(true);

        window.scrollTo(0, document.body.scrollHeight);
    };

    useEffect(() => {
        setHeader((curr) => {
            return {
                ...curr,
                inUse: "editAO",
                title: "Permanenter Auslandsaufenthalt",
                progress: "no",
                customGoBackFunc: () => goBackHeaderFunc(ref),
            };
        });

        localStorage.setItem("tempUser", JSON.stringify(user));

        return () => {
            localStorage.setItem("tempUser", "");
        };
    }, []);

    useEffect(() => {
        if (!updateProfileLoading && !updateProfileError) {
            let userCopy = Object.assign({}, userState);
            userCopy.TravelGroupMember = groupMembers;

            userCopy.TravelGroupMember.forEach((item) => {
                item.Birthdate = convertAppDateToMiddlewareDateFormat(item.Birthdate);
            });

            userCopy = setApiUserObjectUpdate2(userCopy, travel);
            userCopy.Email = userState.Email;
            setUser(setUserValuesOnLogin(userCopy));

            setSuccessBox((curr) => {
                return {
                    ...curr,
                    hidden: false,
                    text: "Ihre Änderungen wurden gespeichert!",
                    trigger: getUIID(),
                };
            });

            history.push(ApplicationRoute.TravelDashboardNoPermissions);
        }
    }, [updateProfile, updateProfileError, updateProfileLoading]);


    const isValid = () => {
        return (
            // !!GDPRForeignAustrian &&
            validGroupMembers &&
            validPersonalInfo &&
            isValidContactPerson(optionals) &&
            userState.Street !== "" &&
            userState.PostalCode !== "" &&
            userState.City !== ""
        );
    };

    const submit = (e) => {
        e.preventDefault();
        if (isValid()) {
            updateUserMiddleware();
        }
    };
    //HINT* POST TRAVELL
    useEffect(() => {
        if (!loading && !error) {
            updateUserMiddleware();
        }
    }, [loading]);

    useEffect(() => {
        if (!updateProfileLoading && !updateProfileError) {
            let userCopy = Object.assign({}, userState);
            userCopy.Relatives = [];

            for (const memberItem of groupMembers) {
                userCopy.Relatives.push(Object.assign({}, memberItem));
            }

            userCopy = setApiUserObjectUpdate3(userCopy);
            userCopy.Email = userState.Email;

            setUser(setUserValuesOnLogin(userCopy));

            if (userState.Country == null) {
                successBox(false, "Ihr permanenter Auslandsaufenthalt wurde gelöscht!");
            }

            history.push(ApplicationRoute.TravelDashboardNoPermissions);
        }
    }, [updateProfile, updateProfileError, updateProfileLoading]);

    useEffect(() => {
        setUserState((curr) => {
            return {
                ...curr,
                GDPRDataRetention: GDPRDataRetention,
                GDPRForeignAustrian: GDPRForeignAustrian,
                GDPRForeignAustrianOfficialInfo: GDPRForeignAustrianOfficialInfo,
                GDPRForeignAustrianInofficialInfo: GDPRForeignAustrianInofficialInfo,
            };
        });
    }, [GDPRDataRetention, GDPRForeignAustrian, GDPRForeignAustrianOfficialInfo, GDPRForeignAustrianInofficialInfo]);

    const onClickHandler = (e) => {
        e.preventDefault();
        submit(e);
    };

    const onChangeGroupMembers = (e) => {
        setGroupMembers((curr) => {
            let newArray = editOnChangeGroupMembers(e, curr);

            setUserState((curr) => {
                curr.Relatives = newArray;

                return curr;
            });

            return newArray;
        });
    };

    const updateUser = (userInfoState, contactPersonInfoState) => {
        setUserState(() => {
            return {
                ...userInfoState,
                ContactPerson: {...contactPersonInfoState},
                TempDeletedContact: [],
                TempPerson: "",
                HasPersonInit: "",
                TempDeletedRelatives: [],
                TempRelatives: [],
                SaveChanges: true,
            };
        });
    };

    function deletion(e) {
        e.preventDefault();

        let userCopy = Object.assign({}, userState);
        userCopy.IsLivingAbroad = false;
        userCopy.Country = null;
        userCopy.County = null;
        userCopy.City = null;
        userCopy.PostalCode = null;
        userCopy.Street = null;
        userCopy.Relatives = [];
        userCopy.FamilyGroupMember = [];

        setUserState(() => userCopy);

        fetcher(
            `${apipath.path}users/CURRENT`,
            "PUT",
            setUpdateProfile,
            setUpdateProfileError,
            setUpdateProfileLoading,
            setSubmttingUpdateProfile,
            false,
            "application/json",
            JSON.stringify(setApiUserObjectUpdate3(userCopy))
        );
    }

    function CountryChange(country, region, street, postalCode, city) {
        let userCopy = Object.assign({}, userState);

        userCopy.Country = country;
        userCopy.County = region;
        userCopy.Street = street;
        userCopy.PostalCode = postalCode;
        userCopy.City = city;

        setUserState(userCopy);
        successBox(false, "Ihr permanenter Auslandsaufenthalt wurde geändert!");
    }

    function setRegion(regionName) {
        let userCopy = Object.assign({}, userState);

        for (const countryItem of countries) {
            if (countryItem.Id === userCopy.Country) {
                for (const regionItem of countryItem.Regions) {
                    if (regionItem.Name === regionName) {
                        userCopy.County = regionItem.Id;
                        break;
                    }
                }
                break;
            }
        }

        setUserState(userCopy);
    }

    function getRegionNameByCountryId(countryId, regionId) {
        let regionName = "";
        for (const countryItem of countries) {
            if (countryItem.Id === countryId) {
                for (const regionItem of countryItem.Regions) {
                    if (regionItem.Id === regionId) {
                        regionName = regionItem.Name;
                        break;
                    }
                }
                break;
            }
        }
        return regionName;
    }

    function addRelativeModal() {
        setRelativeModal(!relativeModal);

        if (document.getElementsByTagName("body").item(0).classList.contains("overflow-hidden")) {
            document.getElementsByTagName("body").item(0).classList.remove("overflow-hidden");
        } else {
            document.getElementsByTagName("body").item(0).classList.add("overflow-hidden");
        }
    }

    function addRelative(FirstLastName, DegreeOfRelationship, Gender, Nationality, Birthdate, Email, MobilePhone) {
        addRelativeModal();
        let groupMembersCopy = Object.assign([], groupMembers);
        let travelGroupMemberCopy = Object.assign({}, TravelGroupMember);

        travelGroupMemberCopy.FirstLastName = FirstLastName;
        travelGroupMemberCopy.DegreeOfRelationship = DegreeOfRelationship;
        travelGroupMemberCopy.Gender = Gender;
        travelGroupMemberCopy.Birthdate = Birthdate;
        travelGroupMemberCopy.Nationality = Nationality;
        travelGroupMemberCopy.Email = Email;
        travelGroupMemberCopy.MobilePhone = MobilePhone;

        groupMembersCopy.push(travelGroupMemberCopy);

        setGroupMembers(groupMembersCopy);

        setUserState((curr) => {
            curr.Relatives = groupMembersCopy;

            return curr;
        });

        successBox(false, "Angehörige Person wurde hinzugefügt!");
    }

    function deleteRelative(index) {
        let groupMembersCopy = Object.assign([], groupMembers);
        groupMembersCopy = groupMembersCopy.filter((element, i) => i !== index);

        setGroupMembers(groupMembersCopy);
        setDeleteRelativeIndex(-1);

        setUserState((curr) => {
            curr.Relatives = groupMembersCopy;

            return curr;
        });

        successBox(false, "Angehörige Person wurde gelöscht!");
    }

    function openDeleteRelativeModal(index) {
        setDeleteRelativeIndex(index);
    }

    function successBox(hidden, text) {
        setSuccessBox((curr) => {
            return {
                ...curr,
                hidden,
                text,
                trigger: getUIID(),
            };
        });
    }

    function changeTravel(id, value) {
        let userCopy = Object.assign({}, userState);

        if (id === "PostalCode") userCopy[id] = postalCodeFormatter(userCopy.PostalCode, value);
        else userCopy[id] = value;

        setUserState(userCopy);
    }

    return (
        <>
            <MetaDecorator title={applicationPageTitles.foreignRegistration}/>
            {updateProfileLoading && submttingUpdateProfile ? (
                <LoadingPage/>
            ) : (
                <>
                    {error && <h1> {JSON.stringify(setApiObjectAddTravel(travel), null, 2)} </h1>}
                    <AlertBox
                        id="alertBox"
                        level="attention"
                        hidden={alertBoxHidden}
                        onClickLeftButton={() => setAlertBoxHidden(true)}
                        onClickRightButton={deletion}
                        title="Ihr permanenter Auslandsaufenthalt wird gelöscht. Dies kann nicht rückgängig gemacht werden."
                        message=" "
                        leftButtonText="Abbrechen"
                        rightButtonText="Permanenten Auslandsaufenthalt löschen"
                        twoRows={true}
                    />
                    <AlertBox
                        id="alertBox"
                        level="attention"
                        hidden={deleteRelativeIndex === -1}
                        onClickLeftButton={() => setDeleteRelativeIndex(-1)}
                        onClickRightButton={() => deleteRelative(deleteRelativeIndex)}
                        title={
                            deleteRelativeIndex >= 0
                                ? `${groupMembers[deleteRelativeIndex].FirstLastName} wird als Angehörige/r im Ausland gelöscht.`
                                : ""
                        }
                        message=" "
                        leftButtonText="Abbrechen"
                        rightButtonText="Löschen"
                    />

                    <AlertBox
                        id="alertBox"
                        level="attention"
                        hidden={unsavedChangesAlertHidden}
                        onClickLeftButton={() => history.goBack()}
                        onClickRightButton={saveChangeHeaderFunc}
                        title="Möchten Sie Ihre Änderungen speichern?"
                        message=" "
                        leftButtonText="Abbrechen"
                        rightButtonText="Änderungen speichern"
                    />

                    <div className="edit-page__title-image">
                        <img alt="" src={getCountryPicture(getCountryNameFromId(userState.Country))}/>
                    </div>
                    <Form onSubmit={onClickHandler} className="content-container max-width-780">
                        <div className="specialAlertContainer edit-page">
                            <div className="edit-page__flag-image">
                                <img alt="" src={getCountryFlag(userState.Country)}/>
                            </div>
                            <p className="edit-page__subtitle">Sie sind als Auslandsösterreicher/in registriert in:</p>

                            <EditInput
                                id="Country"
                                placeholder="Land wählen"
                                value={getCountryNameFromId(userState.Country)}
                                labelText=""
                                ariaRequired={true}
                                modal="country"
                                backGroundClassName="country-edit"
                                onChange={CountryChange}
                                filtered={true}
                            />
                            <EditInput
                                id="Region"
                                placeholder="Region"
                                value={getRegionNameByCountryId(userState.Country, userState.County)}
                                labelText="Region"
                                ariaRequired={true}
                                options={regions}
                                onChange={(value) => setRegion(value)}
                                filtered={true}
                            />

                            <HorizontalRule/>
                            <EditInput
                                id="street"
                                placeholder="Straße"
                                value={userState.Street}
                                labelText="Straße"
                                ariaRequired={false}
                                onChange={(e) => changeTravel("Street", e.target.value)}
                            />
                            <InputValidation text="Bitte geben Sie Ihre Adresse an."
                                             isHidden={userState.Street !== ""}/>

                            <HorizontalRule/>
                            <EditInput
                                id="postalCode"
                                placeholder="PLZ"
                                value={userState.PostalCode}
                                labelText="PLZ"
                                ariaRequired={false}
                                onChange={(e) => changeTravel("PostalCode", e.target.value)}
                            />
                            <InputValidation text="Bitte geben Sie Ihre Adresse an."
                                             isHidden={userState.PostalCode !== ""}/>

                            <HorizontalRule/>
                            <EditInput
                                id="city"
                                placeholder="Ort"
                                value={userState.City}
                                labelText="Ort"
                                ariaRequired={false}
                                onChange={(e) => changeTravel("City", e.target.value)}
                            />
                            <InputValidation text="Bitte geben Sie Ihre Adresse an." isHidden={userState.City !== ""}/>
                            <Separater text={"Ihre Daten"}/>

                            <ProfileEditComponent
                                user={userState}
                                updateUser={updateUser}
                                noInitialHorizontalLine={true}
                                setValid={setValidPersonalInfo}
                            />

                            {/* Change to Country not Id */}

                            {relativeModal ? <RelativeModal close={addRelativeModal} save={addRelative}/> : null}
                            {groupMembers.length ? (
                                <>
                                    <TravelGroup
                                        groupMembers={groupMembers}
                                        onChange={onChangeGroupMembers}
                                        foreign={true}
                                        deleteHandler={openDeleteRelativeModal}
                                    />
                                    <button
                                        className="add-relative-link add-relative-link--align-right"
                                        type="button"
                                        onClick={addRelativeModal}
                                    >
                                        + Angehörige Person hinzufügen
                                    </button>
                                </>
                            ) : (
                                <Separater
                                    text={"Angehörige/r im Ausland (Keine Angabe)"}
                                    buttonText="Angehörige Person im Ausland hinzufügen"
                                    buttonClickHandler={addRelativeModal}
                                />
                            )}

                            <InfoMailForm
                                GDPRForeignAustrianOfficialInfo={GDPRForeignAustrianOfficialInfo}
                                setGDPRForeignAustrianOfficialInfo={setGDPRForeignAustrianOfficialInfo}
                                GDPRForeignAustrianInofficialInfo={GDPRForeignAustrianInofficialInfo}
                                setGDPRForeignAustrianInofficialInfo={setGDPRForeignAustrianInofficialInfo}
                            />

                            <div className={changed ? "fixed" : ""}>
                                <MainButton
                                    disabled={!isValid()}
                                    ariaLabel={`Senden und gehen Sie zu ${ApplicationRoute.TravelDashboardNoPermissions}`}
                                    path={ApplicationRoute.TravelDashboardNoPermissions}
                                    positioned="static"
                                    text="Änderungen speichern"
                                    style={{marginTop: "3.2rem", marginBottom: "0.8rem"}}
                                />
                            </div>
                            {changed && <div style={{paddingBottom: "1.6rem"}}></div>}
                            <p className="small">Sie kehren nach Österreich zurück?</p>
                            <Link
                                to={ApplicationRoute.TravelDashboardNoPermissions}
                                aria-label={ApplicationRoute.TravelDashboardNoPermissions}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setAlertBoxHidden(false);
                                }}
                                style={{display: "block"}}
                            >
                                <div
                                    className="delete-button"
                                    onClick={() => setAlertBoxHidden(false)}
                                    style={{marginBottom: "90px"}}
                                >
                  <span>
                    <svg
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>Icons / Trash Icon Delete</title>
                      <g id="Icons-/--Trash-Icon-Delete" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <rect id="ViewBox" x="0" y="0" width="24" height="24"></rect>
                        <g
                            id="icon-bcms_loeschen-2"
                            transform="translate(3.000000, 1.000000)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                          <g id="icon-bcms_loeschen">
                            <g>
                              <polyline
                                  id="Path"
                                  stroke="#9e0529"
                                  points="15.6429971 2.36076923 14.6856024 22 2.31439765 22 1.35700294 2.36076923"
                              ></polyline>
                              <g id="Group" stroke="#9e0529">
                                <path
                                    d="M5.25318315,2.36076923 C5.25318315,0.533076923 6.71008815,0 8.5,0 C10.2899119,0 11.7468168,0.533076923 11.7468168,2.36076923"
                                    id="Path"
                                ></path>
                                <line x1="5.25318315" y1="8.58846154" x2="5.25318315" y2="15.7638462" id="Path"></line>
                                <line x1="8.5" y1="8.58846154" x2="8.5" y2="15.7638462" id="Path"></line>
                                <line x1="11.7468168" y1="8.58846154" x2="11.7468168" y2="15.7638462" id="Path"></line>
                                <line x1="7.39423758e-16" y1="2.36076923" x2="17" y2="2.36076923" id="Path"></line>
                                <line x1="2.65572968" y1="19.6392308" x2="14.3442703" y2="19.6392308" id="Path"></line>
                                <line x1="1.69833497" y1="4.56076923" x2="15.301665" y2="4.56076923" id="Path"></line>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                    Permanenten Auslandsaufenthalt löschen
                  </span>
                                </div>
                            </Link>
                        </div>
                    </Form>
                </>
            )}
        </>
    );
}
