const registrationValidationErrorMessagesOrig = {
  firstNameLessCharacters: "Ihr Vorname sollte mindestens zwei Zeichen lang sein.",
  lastNameLessCharacters: "Ihr Familienname sollte mindestens zwei Zeichen lang sein.",
  middleName: "Der Vorname und jeder Familienname muss mindestens zwei Zeichen haben.",
  notValidDate: "Bitte geben Sie ein gültiges Datum ein.",
  notValidPhoneNumber: "Bitte geben Sie eine gültige Mobiltelefonummer ein.",
  notValidEmail: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
  passwordNotInRange: "mindestens 8 Zeichen",
  passwordNoSpaces: "keine Leerzeichen",
  passwordNoCapitalLetter: "1 Großbuchstabe (ausgenommen Umlaute)",
  passwordNoDigit: "1 Ziffer",
  passwordNoSpecialLetter: "1 Sonderzeichen",
  passwordNoSpecialLetterTooltip: "!@#$%^&*()_+-=[]{};':\"\\|,.<>/?~§",
  paswwordNoLowerLetter: "1 Kleinbuchstabe (ausgenommen Umlaute)",
  passwordAndConfirmationPasswordNotEqual: "Die Passwörter stimmen nicht überein.",
  notValidAustrianPassportOrIdentityCardNumberFormat: "Bitte geben Sie eine gültige Reisepassnummer ein.",
  notValidContactPersonFirstAndLastName: "Vorname und Familienname müssen mindestens zwei Zeichen haben.",
  dateOfBirthLessThan_01_01_1900: "Bitte geben Sie ein gültiges Datum ein.",
  ageLessThan_18:
    "Sie müssen volljährig sein, um sich zu registrieren. Bitte wenden Sie sich an Ihre/n Erziehungsberechtigte/n.",
  dateBiggerThanToday: "Bitte geben Sie ein gültiges Datum ein.",
  notValidPassnumber: "Bitte geben Sie eine gültige Reisepassnummer ein.",
  notValidPersonalNumber: "Bitte geben Sie eine gültige Personalausweisnummer ein.",
  missingAtSymbolEmail: "Eine gültige E-Mail-Adresse muss das @-Zeichen enthalten.",
  charactersAfterDotLessThanTwoEmail: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
  charactersAfterAtSymbolLessThanTwoEmail: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
  theNewPasswordWasEnterIdenticallyTwice: "Neues Passwort wurde zweimal identisch eingegeben.",
  currentPasswordEnteredCorrectly: "Sie haben Ihr jetziges Passwort richtig eingegeben.",
  datesAreOverlapsing: "Bitte passen Sie den Reisezeitraum an, um Überschneidungen zu vermeiden.",
  nameHasNumber: "Ihr Name darf keine Zahlen enthalten.",
};

const createTravellersAndRelativesValidationErrorMessagesOrig = {
  firstNameLessCharacters: "Der Vorname sollte mindestens zwei Zeichen lang sein.",
  lastNameLessCharacters: "Der Familienname sollte mindestens zwei Zeichen lang sein.",
  dateOfBirthLessThan_01_01_1900: "Bitte geben Sie ein gültiges Datum ein.",
  notValidEmail: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
  notValidPhoneNumber: "Bitte geben Sie eine gültige Mobiltelefonummer ein.",
  notValidAustrianPassportOrIdentityCardNumberFormat: "Bitte geben Sie ein gültige Dokumentennummer ein.",
};

const apiRequestErrorsOrig = {
  "401-login": `Es gab ein Problem mit Ihren Zugangsdaten. E-Mail oder Passwort stimmen nicht. Falls Sie Ihr Passwort vergessen haben, klicken Sie auf "Passwort vergessen oder nicht bekannt", um ein Passwort anzufordern.`,
};

export const registrationValidationErrorMessages = {
  ...registrationValidationErrorMessagesOrig,
};

export const createTravellersAndRelativesValidationErrorMessages = {
  ...createTravellersAndRelativesValidationErrorMessagesOrig,
};

export const apiRequestErrors = {
  ...apiRequestErrorsOrig,
};
