import { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import apipath from "../../../../apipath";
import ApplicationRoute from "../../../../config/routes";
import UserContext from "../../../../context/UserContext";
import { addToAutoCache } from "../../../../services/caching";
import PageNotFound from "../../../PageNotFound";
import NotfalltippView from "./NotfalltippView";

const notfalltipps = [
    { path: ApplicationRoute.erreichbarkeitInNotfallen, title: "Erreichbarkeit in Notfällen", tippId: "1" },
    { path: ApplicationRoute.finanzielleNotlage, title: "Finanzielle Notlage", tippId: "4" },
    { path: ApplicationRoute.katastrophenPolitischeUnruhen, title: "Katastrophen und politische Unruhen", tippId: "7" },
    { path: ApplicationRoute.ministeriumAuswartige, title: "In welchen Fällen das Außenministerium nicht helfen kann", tippId: "10" },
    { path: ApplicationRoute.passverlust, title: "Passverlust", tippId: "2" },
    { path: ApplicationRoute.regressforderungenSchutzmassnahmen, title: "Regressforderungen für Schutzmaßnahmen bzw. Hilfeleistungen", tippId: "9" },
    { path: ApplicationRoute.reisewarnungen, title: "Reisewarnungen", tippId: "8" },
    { path: ApplicationRoute.unfallErkrankungUndSterbefalle, title: "Unfall, Erkrankung und Sterbefälle", tippId: "5" },
    { path: ApplicationRoute.verloreneFahrzeugpapiereFuhrerschein, title: "Verlorene Fahrzeugpapiere / Führerschein", tippId: "3" },
    { path: ApplicationRoute.vermissteAngehorige, title: "Vermisste Angehörige", tippId: "6" }
]

const NotfalltippsRouter = () => {
    return (
        <>
            <Switch>
                {notfalltipps.map((route) => (
                    <Route exact path={route.path} key={route.path}>
                        <NotfalltippView title={route.title} tippId={route.tippId} />
                    </Route>
                ))}
                <Route path="*" component={PageNotFound} />
            </Switch>
        </>
    )
}

export const usePrefetchNotfallTipps = () => {
    const { offline } = useContext(UserContext);

    const prefetchFunction = () => {
        if(offline) return;
        for (const tipp of notfalltipps) {
            addToAutoCache(
                `${apipath.pathOrig}app-inhalte/tipp-${tipp.tippId}/type/json/`,
                {
                    method: "GET",
                },
                true
            )
        }
    }

    return prefetchFunction
}

export default NotfalltippsRouter