import React, { useEffect, useState } from "react";
import apipath from "../../../../apipath";
import HeaderContext from "../../../../context/HeaderContext";
import MetaDecorator from "../../../../utils/MetaDecorator";

const NotfalltippView = (props) => {
    const { setHeader } = React.useContext(HeaderContext);
    useEffect(() => {
        setHeader((curr) => {
            return {
                ...curr,
                inUse: "content",
                title: props.title,
            };
        });
    }, []);
    useEffect(() => {
        fetchData();
    }, []);
    const [data, setData] = useState([]);
    const fetchData = async () => {
        const response = await fetch(
            `${apipath.pathOrig}app-inhalte/tipp-${props.tippId}/type/json/`,
            {
                method: "GET",
            }, true
        );
        let json = await response.json();
        setData(json.data);
    };
    return (
        <>
            <MetaDecorator title={props.title} />
            <div className="content-pages-container">
                {data?.map((info, index) => {
                    return (
                        <div
                            key={index}
                            dangerouslySetInnerHTML={{ __html: info.bodytext }}
                            className="content-pages-paragraph"
                        ></div>
                    );
                })}
            </div>
        </>
    );
}

export default NotfalltippView