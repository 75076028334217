// use => after import HeaderContext
// const { setHeader } = React.useContext(HeaderContext);

// description
// inUse    => header to be used
// progress => percentage of the progress bar for registration header
// title    => title of the header
// events   => events to be used in the header

// use => set the desired header
//
// setHeader((curr) => {
//   return {
//     ...curr,
//     inUse: "registration",
//     progress: "10",
//     title: "Registrierung",
//     events: {}
//   };
// });

// events =>
// events.onClickLogout

import React, { useContext} from "react";
import HeaderContext from "../context/HeaderContext";
import { useHistory} from "react-router-dom";
import RegistrationHeader from "./Headers/RegistrationHeader";
import EditProfileHeader from "./Headers/EditProfileHeader";
import MainHeader from "./Headers/MainHeader";
import ContentHeader from "./Headers/ContentHeader";
import RegistrationHeaderCustomBackButton from "./Headers/RegistrationHeaderCustomBackButton";
import MapHeader from "./Headers/MapHeader";
import CountryListHeader from "./Headers/CountryListHeader";
import EditAOHeader from "./Headers/EditAOHeader";
import DseHeader from "./Headers/DseHeader.jsx";

const getHeight = () => {
  let lsH = localStorage.getItem("h");
  if (lsH) {
    return lsH;
  }

  let body = document.body,
    html = document.documentElement;

  let height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

  height = height - 226;

  if (height) {
    height += "px";
  } else {
    height = "calc(100vh - 276px)";
  }

  return height;
};

const headers = {
  main: "main",
  registration: "registration",
  registrationCustomGoBack: "registrationCustomGoBack",
  editProfile: "editProfile",
  noHeader: "noHeader",
  content: "content",
  map: "map",
  countriesList: "countriesList",
  editAO: "editAO",
  dse: "dse"
};

const Header = ({ value, setValue, hidden, setHidden, valueC, setValueC, hiddenC, setHiddenC, vb }) => {
  const history = useHistory();
  const { header } = useContext(HeaderContext);

  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  if (header.inUse === headers.registration) {
    const currentProgress = header.progress + "%";
    return (
        <RegistrationHeader
            title={header.title}
            ariaLabel={header.ariaLabel}
            currentProgress={currentProgress}
            onBackButtonClickHandler={goBack}
        />
    );
  }

  if (header.inUse === headers.dse) {
    const currentProgress = header.progress + "%";
    return (
        <DseHeader
            title={header.title}
            ariaLabel={header.ariaLabel}
            currentProgress={currentProgress}
            onBackButtonClickHandler={goBack}
        />
    );
  }

  if (header.inUse === headers.map) {
    const currentProgress = header.progress + "%";
    return (
      <MapHeader
        title={header.title}
        ariaLabel={header.ariaLabel}
        currentProgress={currentProgress}
        onBackButtonClickHandler={goBack}
        setMapCenter={header.setMapCenter}
        data={vb}
        value={value}
        setValue={setValue}
        hidden={hidden}
        setHidden={setHidden}
        appHeight={getHeight()}
      />
    );
  }

  if (header.inUse === headers.registrationCustomGoBack) {
    const currentProgress = header.progress + "%";
    return (
      <RegistrationHeaderCustomBackButton
        title={header.title}
        ariaLabel={header.ariaLabel}
        currentProgress={currentProgress}
        onBackButtonClickHandler={header.customGoBackFunc}
      />
    );
  }

  if (header.inUse === headers.editAO) {
    const currentProgress = header.progress + "%";
    return (
      <EditAOHeader
        title={header.title}
        ariaLabel={header.ariaLabel}
        currentProgress={currentProgress}
        onBackButtonClickHandler={header.customGoBackFunc}
      />
    );
  }

  if (header.inUse === headers.editProfile) {
    return (
      <EditProfileHeader
        title={header.title}
        onClickCloseHandler={goBack}
        onClickLogoutHandler={header.events.onClickLogout}
      />
    );
  }

  if (header.inUse === headers.main) {
    return <MainHeader title={header.title} description={header.description} />;
  }

  if (header.inUse === headers.content) {
    return <ContentHeader title={header.title} onBackButtonClickHandler={goBack} />;
  }

  if (header.inUse === headers.countriesList) {
    return (
      <CountryListHeader
        title={header.title}
        onBackButtonClickHandler={goBack}
        value={valueC}
        setValue={setValueC}
        hidden={hiddenC}
        setHidden={setHiddenC}
      />
    );
  }

  if (header.inUse === headers.noHeader) {
    return <header></header>;
  }

  return <header></header>;
};

export default Header;
