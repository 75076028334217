import React, { useEffect, useState } from "react";
import ContentTitle from "../utils/ContentTitle";
import HeaderContext from "../../../context/HeaderContext";
import Info from "../../registration/utils/Info";
import Mail from "../../../common/Unions/Mail";
import WeiterButton from "../utils/WeiterButton";
import ApplicationRoute from "../../../config/routes";
import UserContext from "../../../context/UserContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import fetcher from "../../../services/fetcher";
import apipath from "../../../apipath";
import HorizontalRule from "../../../common/HorizontalRule";

const applicationPageTitles = require("../../../utils/pageTitles.json");
//HINT* REGISTRATION
const SentEmailPage = ({ text }) => {
  const { setHeader } = React.useContext(HeaderContext);
  const { user, setUser } = React.useContext(UserContext);

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const [timerDissable, setTimerDissable] = useState(false);

  const resendInvitation = () => {
    fetcher(
      `${apipath.path}users/resendinvitation`,
      "POST",
      setData,
      setError,
      setLoading,
      setSubmitting,
      false,
      "application/json",
      JSON.stringify({ Email: user.Email }),
      false
    );
  };

  const timerDissabling = () => {
    setTimeout(() => {
      setTimerDissable(() => false);
    }, 30000);
  };

  useEffect(() => {
    if (!loading && !error) {
      timerDissabling();
    }
  }, [loading, error]);

  const onClickHandler = (e) => {
    setTimerDissable(() => true);

    e.preventDefault();
    resendInvitation();
  };

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((5 / 6) * 100),
        title: "Registrierung",
        ariaLabel: "Registrierung step 5 of 6",
      };
    });
  }, []);

  const submittingNow = () => {
    return submitting && loading;
  };

  return (
    <div className="registration-middle-container registration-middle-container--se">
      <MetaDecorator title={applicationPageTitles.registrationPages} />
      <ContentTitle
        id="sent-email"
        style={{ marginTop: "1.6rem", marginBottom: "1.6rem" }}
        text="Sie haben eine E-Mail von uns bekommen."
      />
      <Info
        style={{ lineHeight: "normal", marginBottom: "0px" }}
        id="textInfo"
        text={`Wir haben eine E-Mail mit Bestätigungslink an die angegebene E-Mail-Adresse ${
          user.Email ? "(" + user.Email + ")" : ""
        } verschickt. Bitte klicken Sie auf diesen Link, um Ihre Adresse zu bestätigen.`}
        role=" "
      />
      <Info
        style={{ lineHeight: "normal", marginBottom: "0px" }}
        id="textInfo"
        text={"Ihre E-Mail-Adresse wird binnen 48 Stunden gelöscht, falls Sie sie nicht bestätigen."}
        role=" "
      />

      <HorizontalRule className="horizontal-rule-desktop" />

      <Mail type="notification" className="global-flex-center" style={{ marginTop: "4rem" }} />
      <HorizontalRule className="horizontal-rule-desktop" />

      <WeiterButton
        color="transperant"
        style={{ bottom: "12.5rem" }}
        text="Zurück zur Anmeldung"
        path={ApplicationRoute.home}
      />

      <WeiterButton
        id="sentemailPage"
        text="E-Mail erneut senden"
        path={ApplicationRoute.registerVerifiedEmail}
        ariaLabel={text}
        onClick={onClickHandler}
        disabled={submittingNow() || timerDissable}
      />
    </div>
  );
};

export default SentEmailPage;
