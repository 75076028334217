const HorizontalRule = ({ className, isHidden }) => {
  return (
    <hr
      className={`horizontal-rule ${className ? className : ""}`}
      aria-hidden="true"
      hidden={isHidden}
    />
  );
};

export default HorizontalRule;
