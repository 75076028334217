const ControledRadioInput = ({
  id,
  value,
  name,
  checked,
  onChange,
  textInfo,
  ariaChecked,
  style,
}) => {
  return (
    <div style={style} className="controlled-radio-input__container">
      <input
        className=""
        id={id}
        type="radio"
        value={value}
        name={name}
        checked={checked}
        onChange={onChange}
        aria-checked={ariaChecked}
      />
      <label className="" htmlFor={id}>
        {textInfo}
      </label>
    </div>
  );
};

export default ControledRadioInput;
