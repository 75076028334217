import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import "../src/App.scss";
import Footer from "./common/Footer";
import Header from "./common/Header";
import ForgottenPassword from "./components/ForgottenPassword";
import ForgottenPasswordSecondPage from "./components/ForgottenPasswordSecondPage";
import PageNotFound from "./components/PageNotFound";
import LoginSignInPage from "./components/registration/pages/LoginSignInPage";
import RegistrationNamePage from "./components/registration/pages/NamePage";
import UserContext from "./context/UserContext";

import ActionInformation from "./common/ActionInformation";
import BodyContainer from "./common/BodyContainer";
import AustrianCitizenshipPage from "./components/registration/pages/AustrianCitizenshipPage";
import ConfirmationPage from "./components/registration/pages/ConfirmationPage";
import ContactPersonPage from "./components/registration/pages/ContactPersonPage";
import DatePage from "./components/registration/pages/DatePage";
import EmailAdressPage from "./components/registration/pages/EmailAdress";
import EmailVerifiedPage from "./components/registration/pages/EmailVerifiedPage";
import GenderPage from "./components/registration/pages/GenderPage";
import LivingInPage from "./components/registration/pages/LivingInPage";
import MobilePhonePage from "./components/registration/pages/MobilePhonePage";
import NationalityPage from "./components/registration/pages/NationalityPage";
import PassNumberPage from "./components/registration/pages/PassNumberPage";
import PasswordPage from "./components/registration/pages/PasswordPage";
import SentEmailPage from "./components/registration/pages/SentEmailPage";
import ApplicationRoute from "./config/routes.js";
import HeaderContext from "./context/HeaderContext";
import LoggedContext from "./context/LoggedContext";
import { areIdaRoutesEnabled, compare, getIsLogged, removeIsLoggedStorage, setLocalStorage } from "./utils/helpers";

import TravelDashboard from "./components/travel-registration/dashboard.jsx";
import defaultTravelValue from "./config/defaultTravelValues.js";
import defaultUserValue from "./config/defaultUserValues.js";
// import TravelDashboardEmpty from "./components/travel-registration/dashboard-empty";
import AddTravel from "./components/travel-registration/add-travel";
import AddTravelCompanion from "./components/travel-registration/steps/1-step-companion";
import AddTravelCompanionName from "./components/travel-registration/steps/2-step-companion-details";
import AddTravelCompanionDateOfBirth from "./components/travel-registration/steps/3-step-companion-date-of-birth";
import AddTravelGender from "./components/travel-registration/steps/4-step-gender";
import AddTravelMobilePhone from "./components/travel-registration/steps/5-step-mobile-phone";
import AddTravelEmail from "./components/travel-registration/steps/6-step-email";
import AddTravelNationality from "./components/travel-registration/steps/7-step-nationality";
import AddTravelCompanionAddedSuccess from "./components/travel-registration/steps/8-step-companion-added-success";
import TourOperator from "./components/travel-registration/steps/tour-operator";
import SuccessContext from "./context/SuccessContext";
import TravelContext from "./context/TravelContext";

import EditTravel from "./components/travel-registration/steps/9-step-edit";

import EditTravelFromDashboard from "./components/EditTravel/EditTravel";
import AddRelative1 from "./components/EditTravel/utils/AddRelative-1";
import AddRelative2 from "./components/EditTravel/utils/AddRelative-2";
import AddRelative3 from "./components/EditTravel/utils/AddRelative-3";
import AddRelative4 from "./components/EditTravel/utils/AddRelative-4";
import AddRelative5 from "./components/EditTravel/utils/AddRelative-5";
import AddRelative6 from "./components/EditTravel/utils/AddRelative-6";
import ErrorPage from "./components/ErrorPage";
import ErrorPageNotVerified from "./components/ErrorPageNotVerified";
import ErrorPageResetTokenInvalid from "./components/ErrorPageResetTokenInvalid";
import NewPassword from "./components/NewPassword";
import PushLogin from "./components/PushLogin/PushLogin";
import PushLoginBeforeNF from "./components/PushLogin/PushLoginBeforeNF";
import BotschaftenKonsulate from "./components/countryOverview/pages/BotschaftenKonsulate";
import CountryDetailedInfo from "./components/countryOverview/pages/CountryDetailedInfo";
import CountryList from "./components/countryOverview/pages/CountryList";
import Notfalltipps from "./components/countryOverview/pages/Notfalltipps";
import DataProtection from "./components/dataProtection/DataProtection";
import DataProtectionBeforeLogin from "./components/dataProtection/DataProtectionBeforeLogin";
import ChangePassword from "./components/edit-profile/ChangePassword";
import EditProfileSecond from "./components/edit-profile/EditProfileSecond";
import DashboardForeignEditTravel from "./components/foreign-registration/pages/dashboard-edit-page";
import ForeignEditTravel from "./components/foreign-registration/pages/edit-page";
import RegionSelection from "./components/foreign-registration/pages/region-selection";
import { ForeignBirhtdate } from "./components/foreign-registration/pages/registration-steps/birthdate";
import { ForeignCounty } from "./components/foreign-registration/pages/registration-steps/county";
import { ForeignEmergencyContact } from "./components/foreign-registration/pages/registration-steps/emergencyContact";
import { ForeignGender } from "./components/foreign-registration/pages/registration-steps/gender";
import { ForeignName } from "./components/foreign-registration/pages/registration-steps/name";
import { ForeignNumber } from "./components/foreign-registration/pages/registration-steps/number";
import { ForeignPassport } from "./components/foreign-registration/pages/registration-steps/passport";
import AddRelatives from "./components/foreign-registration/pages/relatives/add-relatives";
import { AddRelativesBirthdate } from "./components/foreign-registration/pages/relatives/add-relatives-birthdate";
import { AddRelativesEmail } from "./components/foreign-registration/pages/relatives/add-relatives-email";
import { AddRelativesGender } from "./components/foreign-registration/pages/relatives/add-relatives-gender";
import { AddRelativesName } from "./components/foreign-registration/pages/relatives/add-relatives-name";
import { AddRelativesNationality } from "./components/foreign-registration/pages/relatives/add-relatives-nationality";
import { AddRelativesNumber } from "./components/foreign-registration/pages/relatives/add-relatives-number";
import { AddRelativesSuccess } from "./components/foreign-registration/pages/relatives/add-relatives-success";
import FirstPushStep from "./components/push-notifications/1-push-step";
import SecondPushStep from "./components/push-notifications/2-push-step";
import PushEditFromDashBoard from "./components/push-notifications/PushEditFromDashboard";
import PushContext from "./context/PushContext";

import DotWebsite from "./components/DotWebsite";
import OfflineManager from "./components/OfflineManager";
import RedirectPage from "./components/RedirectPage";
import AllgemeineInformationen from "./components/countryOverview/pages/AllgemeineInformationen";
import Bildquellen from "./components/countryOverview/pages/Bildquellen";
import BotschaftenKonsulateContacts from "./components/countryOverview/pages/BotschaftenKonsulateContacts";
import CountryInformation from "./components/countryOverview/pages/CountryInformation";
import DashboardInfos from "./components/countryOverview/pages/DashboardInfos";
import DashboardInfosBodytext from "./components/countryOverview/pages/DashboardInfosBodytext";
import ImpresumInfos from "./components/countryOverview/pages/ImpresumInfos";
import InfoBarrierefreiheit from "./components/countryOverview/pages/InfoBarrierefreiheit";
import InfoDatenschutz from "./components/countryOverview/pages/InfoDatenschutz";
import InfoPass from "./components/countryOverview/pages/InfoPass";
import InfoReiseregistrierung from "./components/countryOverview/pages/InfoReiseregistrierung";
import Vertretungen from "./components/countryOverview/pages/Vertretungen";
import { ForeignEmail } from "./components/foreign-registration/pages/registration-steps/email";
import DseConfirmationPage from "./components/login/pages/DseConfirmationPage.jsx";
import InfoMailPage from "./components/login/pages/InfoMailPage.jsx";
import ActivatedAccountPage from "./components/registration/pages/ActivatedAccountPage";
import EmailAdressRegistration from "./components/registration/pages/EmailAdressRegistration";
import IdaEmailPage from "./components/registration/pages/IdaEmailPage.jsx";
import IdaLinkExistingPage from "./components/registration/pages/IdaLinkExistingPage.jsx";
import IdaLinkLoginPage from "./components/registration/pages/IdaLinkLoginPage.jsx";
import IdaLinkManualPage from "./components/registration/pages/IdaLinkManualPage.jsx";
import IdaWelcomePage from "./components/registration/pages/IdaWelcomePage.jsx";
import LoginPage from "./components/registration/pages/LoginPage.jsx";
import Loader from "./components/travel-registration/utils/Loader";
import cvb from "./components/travel-registration/utils/countriesVB.json";
import "./services/caching";
import NotfalltippsRouter, { usePrefetchNotfallTipps } from "./components/countryOverview/pages/notfalltipps/NotfalltippsRouter";
import './languages/config';
import localforage from "localforage";
import FAQsPage from "./components/faqs/FAQsPage.jsx";

function App() {
  const [isSet, setIsSet] = useState(false);
  const isDotEnabled = process.env.REACT_APP_DOT_ENABLED == 'true';
  const notfallTippsPrefetchFn = usePrefetchNotfallTipps();

  useEffect(() => {
    localforage.setItem("init", "true");
  }, [localforage])

  useEffect(() => {
    if (!isSet) {
      setIsSet(true);
  
      if (window.innerHeight > window.innerWidth) {
        localStorage.setItem("orient", "norm");
      } else {
        localStorage.setItem("orient", "x");
      }
  
      let body = document.body,
        html = document.documentElement;
  
      let height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
  
      localStorage.setItem("fh", height);
      height = height - 226;
  
      if (height) {
        height += "px";
      } else {
        height = "calc(100vh - 276px)";
      }
  
      localStorage.setItem("h", height);
  
      //width
      let width = Math.max(body.scrollWidth, body.offsetWidth, html.clientWidth, html.scrollWidth, html.offsetWidth);
  
      if (width) {
        width += "px";
      } else {
        width = "100vw";
      }
  
      localStorage.setItem("w", width);
    }

    setTimeout(notfallTippsPrefetchFn, 1000);
  }, [isSet])

  let travelValue = defaultTravelValue;
  try {
    const localStorageAddTravel = JSON.parse(localStorage.getItem("addTravel"));

    if (localStorageAddTravel) {
      travelValue = localStorageAddTravel;
    }
  } catch { }

  let localStorageUser = defaultUserValue;
  try {
    localStorageUser = JSON.parse(localStorage.getItem("registerUser"));

    localStorageUser
      ? setLocalStorage("registerUser", localStorageUser)
      : setLocalStorage("registerUser", defaultUserValue);
  } catch {
    setLocalStorage("registerUser", defaultUserValue);
  }

  const resetPushLocalStorage = () => {
    try {
      const localStoragePush = JSON.parse(localStorage.getItem("pushNotifcations"));

      setPushNotifications(() => localStoragePush);
    } catch { }
  };

  const [user, setUser] = useState(localStorageUser);
  const [offline, setOffline] = useState(false);
  const [pushNotifcations, setPushNotifications] = useState({});
  const [successBox, setSuccessBox] = useState({ hidden: true, text: "" });
  const [isLogged, setIsLogged] = useState();
  const [travel, setTravel] = useState(travelValue);
  const [header, setHeader] = useState({
    inUse: "",
    title: "",
    progress: "",
    events: {},
  });

  const resetStateFromLocalStorageUser = () => {
    try {
      const localStorageUser = JSON.parse(localStorage.getItem("registerUser"));

      if (!localStorageUser) {
        setLocalStorage("registerUser", user);
      } else {
        setUser(() => localStorageUser);
      }
    } catch {
      setLocalStorage("registerUser", user);
    }
  };

  //Keeping alive the user and addtravel states on pages refresh
  useEffect(() => {
    resetStateFromLocalStorageUser();
    resetPushLocalStorage();
  }, []);

  //Keeping the login state on page refresh
  useEffect(() => {
    try {
      const isLoggedIn = getIsLogged();
      setIsLogged(isLoggedIn);
    } catch {
      setIsLogged(false);
      removeIsLoggedStorage();
    }
  }, []);

  useEffect(() => {
    setLocalStorage("registerUser", user);
  }, [user]);

  useEffect(() => {
    setLocalStorage("addTravel", travel);
  }, [travel]);

  useEffect(() => {
    setLocalStorage("pushNotifcations", pushNotifcations);
  }, [pushNotifcations]);

  const [value, setValue] = useState("");

  //INPUT SEARCH MAP*
  const [hidden, setHidden] = useState(true);

  ///country list
  const [valueC, setValueC] = useState("");

  //INPUT SEARCH MAP*
  const [hiddenC, setHiddenC] = useState(true);

  const resetInputsCountryMapSearch = () => {
    setValue("");
    setHidden(true);
    setValueC("");
    setHiddenC(true);
  };

  const cActive = "#9e0529";
  const cInActive = "#919191";

  const initColor = {
    1: cInActive,
    2: cInActive,
    3: cInActive,
    4: cInActive,
    5: cInActive,
    6: cInActive,
  };

  const [color, setColor] = useState(initColor);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes("travel-dashboard")) {
      setColor(() => {
        return { ...initColor, 1: cActive };
      });
    } else {
      switch (location.pathname) {
        case "/":
          setColor(() => {
            return { ...initColor, 1: cActive };
          });
          break;
        case ApplicationRoute.countryList:
          setColor(() => {
            return { ...initColor, 2: cActive };
          });
          break;
        case ApplicationRoute.notfalltipps:
          setColor(() => {
            return { ...initColor, 3: cActive };
          });
          break;
        case ApplicationRoute.vertretungen:
          setColor(() => {
            return { ...initColor, 4: cActive };
          });
          break;
        case ApplicationRoute.dashboardInfos:
          setColor(() => {
            return { ...initColor, 5: cActive };
          });
          break;
        case ApplicationRoute.dot:
          setColor(() => {
            return { ...initColor, 6: cActive };
          });
          break;

        default:
          setColor((curr) => {
            return { ...curr };
          });
      }
    }
  }, [location]);

  const getCountriesVB = () => {
    try {
      let x = JSON.parse(localStorage.getItem("countriesVB")).countries;
      if (!x || x.length === 0) {
        let vbJSON = cvb;
        vbJSON.countries.sort(compare);
        return vbJSON.countries;
      }

      return x;
    } catch (error) {
      let vbJSON = cvb;
      vbJSON.countries.sort(compare);
      return vbJSON.countries;
    }
  };

  const [vbData, setVBData] = useState("");

  useEffect(() => {
    setVBData(() => {
      return getCountriesVB().filter((c) => c.representations.length !== 0);
    });
  }, []);

  if (!vbData) {
    return <Loader />;
  }

  return (
    <>
      <LoggedContext.Provider value={{ isLogged, setIsLogged }}>
        <HeaderContext.Provider value={{ header, setHeader }}>
          <UserContext.Provider value={{ user, setUser, offline, setOffline }}>
            <OfflineManager />
            <TravelContext.Provider value={{ travel, setTravel }}>
              <SuccessContext.Provider value={{ successBox, setSuccessBox }}>
                <PushContext.Provider value={{ pushNotifcations, setPushNotifications }}>
                  <ActionInformation
                    text={successBox.text}
                    hidden={successBox.hidden}
                    onClickClose={() =>
                      setSuccessBox((curr) => {
                        return { ...curr, hidden: true, text: "", trigger: "" };
                      })
                    }
                    set={setSuccessBox}
                    trigger={successBox.trigger}
                    alert={successBox.alert ? successBox.alert : false}
                  />
                  <Header
                    value={value}
                    setValue={setValue}
                    hidden={hidden}
                    setHidden={setHidden}
                    valueC={valueC}
                    setValueC={setValueC}
                    hiddenC={hiddenC}
                    setHiddenC={setHiddenC}
                    vb={vbData}
                  />
                  <BodyContainer className={BrowserRouter}>
                    <Switch>
                      <Route
                        exact
                        path="/"
                        render={(props) => (
                          <LoginSignInPage clearSearchInput={resetInputsCountryMapSearch} {...props} />
                        )}
                      />
                      <Route exact path={ApplicationRoute.register} component={LoginSignInPage} />
                      <Route path={ApplicationRoute.registerCitizenship} component={AustrianCitizenshipPage} />
                      <Route path={ApplicationRoute.registerName} component={RegistrationNamePage} />
                      <Route path={ApplicationRoute.registerGender} component={GenderPage} />
                      <Route path={ApplicationRoute.registerForgottenPassword} component={ForgottenPassword} />
                      <Route path={ApplicationRoute.registerMobilePhone} component={MobilePhonePage} />
                      <Route path={ApplicationRoute.registerBirthday} component={DatePage} />
                      <Route path={ApplicationRoute.registerEmail} component={EmailAdressPage} />
                      <Route path={ApplicationRoute.registerSentEmail} component={SentEmailPage} />
                      <Route path={ApplicationRoute.registerVerifiedEmail} component={EmailVerifiedPage} />
                      <Route path={ApplicationRoute.registerPassword} component={PasswordPage} />
                      <Route path={ApplicationRoute.registerNationality} component={NationalityPage} />
                      {/* <Route path={ApplicationRoute.registerForeignCountry} component={AuslandPage} /> */}
                      <Route path={ApplicationRoute.registerLivingIn} component={LivingInPage} />
                      <Route path={ApplicationRoute.registerPassNumber} component={PassNumberPage} />
                      <Route path={ApplicationRoute.registerContactPerson} component={ContactPersonPage} />
                      {/* <Route path={ApplicationRoute.registerUserInfo} component={UserInfoPage} /> */}
                      <Route path={ApplicationRoute.registerConfirmationPage} component={ConfirmationPage} />
                      <Route path={ApplicationRoute.dseConfirmationPage} component={DseConfirmationPage} />
                      <Route path={ApplicationRoute.infoMailPage} component={InfoMailPage} />
                      {areIdaRoutesEnabled() ? (<Route path={ApplicationRoute.loginPage} component={LoginPage} />) : false}
                      {areIdaRoutesEnabled() ? (<Route path={ApplicationRoute.linkAccountExisting} component={IdaLinkExistingPage} />) : false}
                      {areIdaRoutesEnabled() ? (<Route path={ApplicationRoute.linkAccountManual} component={IdaLinkManualPage} />) : false}
                      {areIdaRoutesEnabled() ? (<Route path={ApplicationRoute.linkAccountLogin} component={IdaLinkLoginPage} />) : false}
                      {areIdaRoutesEnabled() ? (<Route path={ApplicationRoute.idaEmailPage} component={IdaEmailPage} />) : false}
                      {areIdaRoutesEnabled() ? (<Route path={ApplicationRoute.idaWelcomePage} component={IdaWelcomePage} />) : false}
                      <Route exact path={ApplicationRoute.TravelDashboardNoPermissions} component={TravelDashboard} />
                      <Route exact path={ApplicationRoute.addTravel} component={AddTravel} />
                      <Route exact path={ApplicationRoute.addTravelTourOperator} component={TourOperator} />
                      {/* <Route exact path={ApplicationRoute.editProfile} component={EditProfilePage} /> */}
                      <Route exact path={ApplicationRoute.travelDashboard} component={TravelDashboard} />
                      <Route exact path={ApplicationRoute.addTravel} component={AddTravel} />
                      <Route exact path={ApplicationRoute.addTravelCompanion} component={AddTravelCompanion} />
                      <Route exact path={ApplicationRoute.addTravelCompanionName} component={AddTravelCompanionName} />
                      <Route
                        exact
                        path={ApplicationRoute.addTravelCompanionDateOfBirth}
                        component={AddTravelCompanionDateOfBirth}
                      />
                      <Route exact path={ApplicationRoute.addTravelGender} component={AddTravelGender} />
                      <Route exact path={ApplicationRoute.addTravelMobilePhone} component={AddTravelMobilePhone} />
                      <Route exact path={ApplicationRoute.addTravelEmail} component={AddTravelEmail} />
                      <Route exact path={ApplicationRoute.addTravelNationality} component={AddTravelNationality} />
                      <Route
                        exact
                        path={ApplicationRoute.addTravelCompanionAddedSuccess}
                        component={AddTravelCompanionAddedSuccess}
                      />
                      <Route exact path={ApplicationRoute.addTravelEdit} component={EditTravel} />
                      <Route
                        exact
                        path={ApplicationRoute.countryList}
                        render={(props) => <CountryList valueC={valueC} {...props} />}
                      />
                      <Route exact path={ApplicationRoute.countryDetailedInfo} component={CountryDetailedInfo} />
                      {/* <Route exact path={ApplicationRoute.editProfile} component={EditProfilePage} /> */}
                      <Route
                        exact
                        path={ApplicationRoute.foreignRegistrationRegionSelection}
                        component={RegionSelection}
                      />
                      <Route exact path={ApplicationRoute.foreignRegistrationAddRelatives} component={AddRelatives} />
                      <Route
                        exact
                        path={ApplicationRoute.foreignRegistrationAddRelativesName}
                        component={AddRelativesName}
                      />
                      <Route
                        exact
                        path={ApplicationRoute.foreignRegistrationAddRelativesBirthdate}
                        component={AddRelativesBirthdate}
                      />
                      <Route
                        exact
                        path={ApplicationRoute.foreignRegistrationAddRelativesGender}
                        component={AddRelativesGender}
                      />
                      <Route
                        exact
                        path={ApplicationRoute.foreignRegistrationAddRelativesEmail}
                        component={AddRelativesEmail}
                      />
                      <Route
                        exact
                        path={ApplicationRoute.foreignRegistrationAddRelativesNumber}
                        component={AddRelativesNumber}
                      />
                      <Route
                        exact
                        path={ApplicationRoute.foreignRegistrationAddRelativesNationality}
                        component={AddRelativesNationality}
                      />
                      <Route
                        exact
                        path={ApplicationRoute.foreignRegistrationAddRelativesSuccess}
                        component={AddRelativesSuccess}
                      />
                      <Route
                        exact
                        path={ApplicationRoute.foreignRegistrationRegistrationName}
                        component={ForeignName}
                      />
                      <Route
                        exact
                        path={ApplicationRoute.foreignRegistrationRegistrationEmail}
                        component={ForeignEmail}
                      />
                      <Route
                        exact
                        path={ApplicationRoute.foreignRegistrationRegistrationBirthdate}
                        component={ForeignBirhtdate}
                      />
                      <Route
                        exact
                        path={ApplicationRoute.foreignRegistrationRegistrationCounty}
                        component={ForeignCounty}
                      />
                      <Route
                        exact
                        path={ApplicationRoute.foreignRegistrationRegistrationGender}
                        component={ForeignGender}
                      />
                      <Route
                        exact
                        path={ApplicationRoute.foreignRegistrationRegistrationNumber}
                        component={ForeignNumber}
                      />
                      <Route
                        exact
                        path={ApplicationRoute.foreignRegistrationRegistrationPassport}
                        component={ForeignPassport}
                      />
                      <Route
                        exact
                        path={ApplicationRoute.foreignRegistrationRegistrationEmergencyContact}
                        component={ForeignEmergencyContact}
                      />
                      <Route exact path={ApplicationRoute.foreignRegistrationEditPage} component={ForeignEditTravel} />
                      <Route
                        exact
                        path={ApplicationRoute.dashboardForeignRegistrationPage}
                        component={DashboardForeignEditTravel}
                      />
                      <Route
                        //exact
                        path={ApplicationRoute.renewPassword}
                        component={NewPassword}
                      />
                      <Route exact path={ApplicationRoute.firstPushStep} component={FirstPushStep} />
                      <Route exact path={ApplicationRoute.secondPushStep} component={SecondPushStep} />
                      <Route exact path={ApplicationRoute.pushEditFromDashboard} component={PushEditFromDashBoard} />
                      <Route exact path={ApplicationRoute.pushLogin} component={PushLogin} />
                      <Route exact path={ApplicationRoute.loginBeforePushNotf} component={PushLoginBeforeNF} />
                      <Route exact path={ApplicationRoute.editProfileSecond} component={EditProfileSecond} />
                      <Route exact path={ApplicationRoute.changePassword} component={ChangePassword} />
                      <Route exact path={ApplicationRoute.editTravel} component={EditTravelFromDashboard} />
                      <Route exact path={ApplicationRoute.addRelative1} component={AddRelative1} />
                      <Route exact path={ApplicationRoute.addRelative2} component={AddRelative2} />
                      <Route exact path={ApplicationRoute.addRelative3} component={AddRelative3} />
                      <Route exact path={ApplicationRoute.addRelative4} component={AddRelative4} />
                      <Route exact path={ApplicationRoute.addRelative5} component={AddRelative5} />
                      <Route exact path={ApplicationRoute.addRelative6} component={AddRelative6} />
                      <Route
                        exact
                        path={ApplicationRoute.forgottenPasswordSecondPage}
                        component={ForgottenPasswordSecondPage}
                      />
                      <Route exact path={ApplicationRoute.errorPage} component={ErrorPage} />
                      <Route exact path={ApplicationRoute.errorPageVerified} component={ErrorPageNotVerified} />
                      <Route exact path={ApplicationRoute.errorPageResetToken} component={ErrorPageResetTokenInvalid} />
                      <Route exact path={ApplicationRoute.dataProtection} component={DataProtection} />
                      <Route
                        exact
                        path={ApplicationRoute.dataProtectionBeforeLogin}
                        component={DataProtectionBeforeLogin}
                      />
                      <Route exact path={ApplicationRoute.botschaftenKonsulate} component={BotschaftenKonsulate} />
                      <Route
                        exact
                        path={ApplicationRoute.botschaftenKonsulateContacts}
                        component={BotschaftenKonsulateContacts}
                      />
                      <Route exact path={ApplicationRoute.notfalltipps} component={Notfalltipps} />
                      <Route exact path={ApplicationRoute.vertretungen} component={Vertretungen} />
                      <Route exact path={ApplicationRoute.dashboardInfos} component={DashboardInfos} />
                      <Route exact path={ApplicationRoute.dashboardInfosBodytext} component={DashboardInfosBodytext} />
                      <Route exact path={ApplicationRoute.bildquellen} component={Bildquellen} />
                      <Route
                        exact
                        path={ApplicationRoute.allgemeineInformationen}
                        component={AllgemeineInformationen}
                      />
                      <Route exact path={ApplicationRoute.infoReiseregistrierung} component={InfoReiseregistrierung} />
                      <Route exact path={ApplicationRoute.infoBarrierefreiheit} component={InfoBarrierefreiheit} />
                      <Route exact path={ApplicationRoute.infoDatenschutz} component={InfoDatenschutz} />
                      <Route exact path={ApplicationRoute.infoPass} component={InfoPass} />
                      <Route exact path={ApplicationRoute.countryInformation} component={CountryInformation} />
                      <Route
                        exact
                        path={ApplicationRoute.registrationEmailAdress}
                        component={EmailAdressRegistration}
                      />
                      <Route exact path={ApplicationRoute.impresumInfos} component={ImpresumInfos} />

                      <Route exact path={ApplicationRoute.redirectPage} component={RedirectPage} />

                      <Route exact path={ApplicationRoute.activatedAccount} component={ActivatedAccountPage} />
                      {isDotEnabled && <Route path={ApplicationRoute.dot} component={DotWebsite} />}

                      <Route path={ApplicationRoute.notfalltipps} component={NotfalltippsRouter} />
                      <Route path={ApplicationRoute.faqs} component={FAQsPage} />

                      <Route path="*" component={PageNotFound} />
                    </Switch>
                  </BodyContainer>
                  <Footer
                    value={value}
                    setValue={setValue}
                    hidden={hidden}
                    setHidden={setHidden}
                    valueC={valueC}
                    setValueC={setValueC}
                    hiddenC={hiddenC}
                    setHiddenC={setHiddenC}
                    color={color}
                    path={location.pathname}
                  />
                </PushContext.Provider>
              </SuccessContext.Provider>
            </TravelContext.Provider>
          </UserContext.Provider>
        </HeaderContext.Provider>
      </LoggedContext.Provider>
    </>
  );
}

export default App;
