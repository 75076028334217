import {useState, useEffect, forwardRef} from "react";
import {
    setText
} from "./listUtils.js";
import SelectListTemplate from "./SelectListTemplate.jsx";

const FilteredSelectList = (
    {
        id = "filteredselectlist-random",
        state,
        options,
        label,
        ariaLabelledBy,
        isDisabled = false,
        onChangeHandler,
        open = false,
        noIcon,
        className,
        optional = false,
        isTravel = false,
        isRegion = false,
        ariaLabelType = "",
    },
    ref
) => {
    const [suggestions, setSuggestions] = useState([
        {
            id: id + "-1",
            value: label,
            selected: true,
        },
    ]);
    const [selected, setSelected] = useState(suggestions[0]);
    const [inputText, setInputText] = useState("");

    useEffect(() => {
        let arr = [
            {
                id: id + "-1",
                value: label,
                selected: false,
            },
        ];

        if (options?.length) {
            options
                .forEach((item, index) => {
                    arr.push({
                        id: id + "-" + (index + 2),
                        value: item,
                        selected: state === item,
                    });
                });
            if (inputText !== setText(selected.value, isRegion, isTravel))
                arr = arr.filter((a) => a.id === id + "-1" || a.value.toLowerCase().indexOf(inputText.toLowerCase()) >= 0)
        }

        if (!options?.length || !state) {
            arr[0].selected = true;
        }

        let sel = arr.filter((item) => item.selected)[0];

        if (sel == null) sel = arr[0];

        setSuggestions(arr);
        setSelected(sel);
    }, [options, state, inputText]);

    return (
        <SelectListTemplate
            filtered={true}
            id={id}
            state={state}
            label={label}
            ariaLabelledBy={ariaLabelledBy}
            ariaLabelType={ariaLabelType}
            open={open}
            noIcon={noIcon}
            className={className}
            optional={optional}
            isTravel={isTravel}
            isRegion={isRegion}
            selected={selected}
            suggestions={suggestions}
            onChangeHandler={onChangeHandler}
            inputText={inputText}
            setInputText={setInputText}
            setSelected={setSelected}
            ref={ref}
        />
    );
};

export default forwardRef(FilteredSelectList);
