import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import apipath from "../apipath";
import MainButton from "../common/MainButton";
import ApplicationRoute from "../config/routes";
import HeaderContext from "../context/HeaderContext";
import SuccessContext from "../context/SuccessContext";
import fetcher from "../services/fetcher";
import { getUIID } from "../utils/helpers";
import MetaDecorator from "../utils/MetaDecorator";

const applicationPageTitles = require("../utils/pageTitles.json");
const disStyle = { color: "rgba(117, 117, 117, 1)" };

const ForgottenPaswordSecondPage = () => {
  const { setHeader } = useContext(HeaderContext);
  const { successBox, setSuccessBox } = useContext(SuccessContext);

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const [timerDissable, setTimerDissable] = useState(false);

  const history = useHistory();

  const submittingNow = () => {
    return submitting && loading;
  };

  const timerDissabling = () => {
    setTimeout(() => {
      setTimerDissable(() => false);
    }, 30000);
  };

  useEffect(() => {
    if (!loading && !error) {
      timerDissabling();
    }
  }, [loading, error]);

  const resetPassword = () => {
    fetcher(
      `${apipath.path}users/passwordreset`,
      "POST",
      setData,
      setError,
      setLoading,
      setSubmitting,
      false,
      "application/json",
      JSON.stringify({ Email: localStorage.getItem("restoreEmail") }),
      false
    );
  };

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: "no",
        title: "Passwort wiederherstellen",
      };
    });
  }, []);

  const onClickReset = () => {
    setTimerDissable(() => true);
    resetPassword();
  };

  useEffect(() => {
    if (!loading && !error) {
      setSuccessBox((curr) => {
        return {
          ...curr,
          hidden: false,
          text: `Eine E-Mail mit Anleitung, wie Sie Ihr Passwort wiederherstellen können, wurde an die angegebene E-Mail-Adresse (${localStorage.getItem(
            "restoreEmail"
          )}) gesendet. `,
          trigger: getUIID(),
        };
      });
    }
  }, [loading, error]);

  const onClickGoHome = () => {
    history.push(ApplicationRoute.home);
  };

  return (
    <div className="forgotten-password-second-container registration-middle-container">
      <MetaDecorator title={applicationPageTitles.forgotenPassword} />
      <h1 className="forgotten-password-second-container__heading">
        Passwort wiederherstellen
      </h1>

      <p className="forgotten-password-second-container__info">
        Eine E-Mail mit Anleitung, wie Sie Ihr Passwort wiederherstellen können,
        wurde an die angegebene E-Mail-Adresse (
        {localStorage.getItem("restoreEmail")}) verschickt. Falls Sie diese
        E-Mail nicht finden, sehen Sie bitte im Spam-Ordner nach.
      </p>

      <MainButton
        path={ApplicationRoute.home}
        text="Zurück zur Anmeldung"
        positioned="static"
        className="forgotten-password-second-container__weiter-button"
        onClick={onClickGoHome}
        disabled={false}
        color="transperant"
      />

      <MainButton
        path={ApplicationRoute.renewPassword}
        text="E-Mail mit Anleitung erneut senden"
        positioned="static"
        className="forgotten-password-second-container__go-home-button"
        onClick={onClickReset}
        disabled={submittingNow() || timerDissable}
      />
    </div>
  );
};

export default ForgottenPaswordSecondPage;
