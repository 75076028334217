import React, {useEffect, useState} from "react";
import UserContext from "../../../context/UserContext";
import {useHistory} from "react-router-dom";
import HeaderContext from "../../../context/HeaderContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import LoggedContext from "../../../context/LoggedContext.js";
import LoginTemplate from "../utils/LoginTemplate.jsx";
import {login, loginUser} from "../utils/loginHelper.js";

const applicationPageTitles = require("../../../utils/pageTitles.json");
const formLabel = "Bitte einloggen.";

const LoginPage = () => {
    const history = useHistory();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [loginData, setLoginData] = useState(null);
    const [loginError, setLoginError] = useState(null);
    const [loginLoading, setLoginLoading] = useState(true);
    const [submittingLogin, setSubmittingLogin] = useState(false);

    const [disableButton, setDisableButton] = useState(false);

    const initLoginCred = {email: false, password: false};
    const [noLoginCred, setNoLoginCred] = useState(initLoginCred);

    const {setUser} = React.useContext(UserContext);
    const {setHeader} = React.useContext(HeaderContext);
    const {setIsLogged} = React.useContext(LoggedContext);

    useEffect(() => {
        setHeader((curr) => {
            return {
                ...curr,
                inUse: "registration",
                progress: parseInt(100),
                title: "Login",
                ariaLabel: "Login",
            };
        });
    }, [setHeader]);

    const onLoginHandler = (e) => {
        e.preventDefault();
        login(email, password, setNoLoginCred, setLoginData, setLoginError, setLoginLoading, setSubmittingLogin);
    };


    useEffect(() => {
        if (!loginLoading) {
            const submittedLogginWithNoErrors = () => {
                return !loginLoading && !loginError;
            };

            if (submittedLogginWithNoErrors()) {
                setDisableButton(true);
                loginUser(loginData, setUser, setIsLogged, history);
            }
        }
    }, [loginData, loginError, loginLoading, history, setIsLogged, setUser]);

    return (
        <div className="registration-middle-container">
            <MetaDecorator title={applicationPageTitles.loginPage}/>


            <LoginTemplate onSubmitHandler={onLoginHandler} noLoginCred={noLoginCred}
                           email={email} setEmail={setEmail}
                           password={password} setPassword={setPassword}
                           error={loginError} loading={loginLoading} submitting={submittingLogin}
                           disableButton={disableButton} formLabel={formLabel}></LoginTemplate>

        </div>
    );
};

export default LoginPage;