import {
    activeKeyDownHandler,
    effectInterval,
    getAlertChoseDestionation,
    getAriaLabel,
    getClassName,
    listClickHandler, optionClassKeyDownHandler,
    setText
} from "./listUtils.js";
import SelectListSvg from "./SelectListSvg.jsx";
import SelectListOptions from "./SelectListOptions.jsx";
import {forwardRef, useEffect, useRef, useState} from "react";
import {getUIID} from "../../../utils/helpers.js";


const SelectListTemplate = (
    {
        id,
        state,
        label,
        ariaLabelledBy,
        ariaLabelType,
        open = false,
        noIcon,
        className,
        optional,
        isTravel = false,
        isRegion = false,
        selected,
        suggestions,
        onChangeHandler,
        inputText,
        setInputText,
        setSelected,
        filtered = false,
    },
    ref) => {
    const [active, setActive] = useState(open);
    const [reverse, setReverse] = useState(false);
    const [inputTextPlaceholder] = useState(setText(selected.value, isRegion, isTravel));
    const [intervalId, setIntervalId] = useState(0);
    const [activeAlert, setActiveAlert] = useState(false);

    const [selector,] = useState(getUIID());

    const inputRef = useRef();
    const highRef = useRef();


    function focusElement(e) {
        for (let el of Array.from(document.getElementById(selector).childNodes)) {
            if (el.innerText.startsWith(String.fromCharCode(e.keyCode))) {
                if (["Land auswählen", "Bitte wählen Sie aus"].includes(el.innerText)) {
                    continue;
                }

                el.focus();
                break;
            }
        }
    }

    function keyDownHandler(e) {
        try {
            if (activeKeyDownHandler(e, active, setActive, setActiveAlert)) {
                return;
            }

            if (e.keyCode === 192) {
                const el = Array.from(document.getElementById(selector).childNodes).filter((elem) => {
                    return elem.innerText === "Österreich";
                });
                el[0].focus();
            } else if (!filtered) {
                focusElement(e);
            }

            if (!optionClassKeyDownHandler(e)) {
                if (e.keyCode === 40) {
                    if (filtered) e.preventDefault();
                    e.target.querySelector(".custom-selectlist__body .custom-selectlist__option:not(.not-selectable)").focus();
                }
            }
        } catch (error) {
        }
    }

    useEffect(() => {
        if (filtered) {
            if (!active && selected.value !== label) {
                setInputText(selected.value);
            } else if (selected.value === label) {
                setInputText(state ? state : "");
            }
        }
        effectInterval(intervalId, setIntervalId, active, setActive, id);
    }, [active]);

    function inputTextHandler(e) {
        setInputText(e.target.value);
    }

    function clickHandler() {
        if (!filtered || (!active && inputRef.current === document.activeElement) || inputRef.current !== document.activeElement) {
            listClickHandler(active, setActive, highRef, setReverse);
        }
    }

    function changeHandler(index, value) {
        if (filtered) {
            setInputText(value);
            setSelected(suggestions[index]);
        }
        if (index > 0) onChangeHandler(value);
        else if (index === 0 && optional) onChangeHandler(null);
    }

    return (
        <div
            className={getClassName(active, reverse, className)}
            ref={highRef}
        >
            <label role="label" id={ariaLabelledBy} className="custom-selectlist__label">
                {label}
            </label>
            <div
                ref={ref}
                role="listbox"
                id={id}
                className="custom-selectlist__listbox"
                tabIndex="0"
                onClick={clickHandler}
                onKeyDown={keyDownHandler}
                aria-activedescendant={selected.id}
                aria-label={getAriaLabel(ariaLabelType, selected.value)}
            >
                {filtered ? <input className="custom-selectlist__header"
                                   value={inputText} onChange={inputTextHandler}
                                   placeholder={inputTextPlaceholder}
                                   ref={inputRef}
                                   style={{
                                       backgroundColor: "transparent",
                                       border: "0",
                                       marginLeft: "16px",
                                       paddingLeft: "0"
                                   }}
                /> : null}
                <p className="custom-selectlist__header" style={filtered ? {width: "40px"} : undefined}>
                    {!filtered ? setText(selected.value) : null}
                    {!noIcon && (
                        <SelectListSvg></SelectListSvg>
                    )}
                </p>
                <div id={selector} className="custom-selectlist__body">
                    <SelectListOptions arr={suggestions} optional={optional} changeHandler={changeHandler}
                                       label={label}></SelectListOptions>
                    {
                        filtered && suggestions.length === 1 ?
                            <div className="custom-selectlist__option">
                                Dazu gibt es keine Ergebnisse
                            </div>
                            : null
                    }
                </div>
            </div>
            {activeAlert && (
                <div aria-relevant="all" role="alert" aria-hidden="false" style={{height: "0px", fontSize: "0px"}}>
                    {getAlertChoseDestionation(selected.value)}
                </div>
            )}
        </div>
    )
}
export default forwardRef(SelectListTemplate);