import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import {
  getEnumFromGender,
  getGenderFromEnum,
  getKeyByValue,
} from "../../../utils/helpers";
import ControledRadioInput from "../../registration/utils/ControledRadioInput";
import Form from "../../registration/utils/Form";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import WeiterButton from "../../registration/utils/WeiterButton";
import PageTitle from "../../travel-registration/utils/PageTitle";
import MetaDecorator from "../../../utils/MetaDecorator";
import HorizontalRule from "../../../common/HorizontalRule";

const applicationPageTitles = require("../../../utils/pageTitles.json");
const formLabel = "Geschlecht eingeben.";

const genders = {
  male: "männlich",
  female: "weiblich",
  other: "divers",
};

const initSelectValue = {
  male: false,
  female: false,
  other: false,
};

const AddRelative3 = ({ text }) => {
  const { setHeader } = React.useContext(HeaderContext);

  useEffect(() => {
    let title = "Mitreisende hinzufügen";

    try {
      title = localStorage.getItem("addTGMTitle");
    } catch (error) {}

    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: title,
        progress: "no",
      };
    });
  }, []);
  const history = useHistory();

  let initgender = {};
  try {
    initgender = JSON.parse(localStorage.getItem("newTGM")).Gender;
    let g = getGenderFromEnum(initgender);
    initgender = { ...initSelectValue, [g]: true };
  } catch (error) {}

  const [selectedValue, setSelectedValue] = useState(
    initgender ? initgender : initSelectValue
  );

  const isValid = () => {
    return getKeyByValue(selectedValue, true);
  };

  const updateTGM = () => {
    let tgm = JSON.parse(localStorage.getItem("newTGM"));
    localStorage.setItem(
      "newTGM",
      JSON.stringify({
        ...tgm,
        Gender: getEnumFromGender(getKeyByValue(selectedValue, true)),
      })
    );
  };

  const onClickHandler = (e) => {
    e.preventDefault();
    if (isValid()) {
      updateTGM();
      history.push(ApplicationRoute.addRelative4);
    }
  };

  const onChangeHandler = (e) => {
    setSelectedValue(() => {
      return { ...initSelectValue, [e.target.id]: true };
    });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateTGM();
      history.push(ApplicationRoute.addRelative4);
    }
  };
  return (
    <div className="add-relative-container registration-middle-container">
      <MetaDecorator title={applicationPageTitles.editTrip} />
      <PageTitle id={formLabel} text="Bitte wählen Sie das Geschlecht aus:" />

      <p className="page-paragraph page-paragraph--small">Mitreisende/r</p>
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form
        id="form"
        onSubmit={onSubmitHandler}
        ariaLabelledBy={formLabel}
        className="max-width-380"
      >
        <ControledRadioInput
          id="male"
          value="100000000"
          name="gender"
          textInfo={genders.male}
          checked={selectedValue.male}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.male}
        />
        <ControledRadioInput
          id="female"
          value="100000001"
          name="gender"
          textInfo={genders.female}
          checked={selectedValue.female}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.female}
        />
        <ControledRadioInput
          id="other"
          value="100000002"
          name="gender"
          textInfo={genders.other}
          checked={selectedValue.other}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.other}
        />

        <HiddenSubmitInput />
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content"> </div>
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.addRelative4}
        onClick={onClickHandler}
        ariaLabel={text}
        className={"floating-weiter-buton"}
      />
    </div>
  );
};

export default AddRelative3;
