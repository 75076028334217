import React, { useEffect, useState } from "react";
import WeiterButton from "../../../registration/utils/WeiterButton";
import UserContext from "../../../../context/UserContext";
import ContentTitle from "../../../registration/utils/ContentTitle";
import ApplicationRoute from "../../../../config/routes";
import { useHistory } from "react-router-dom";
import Form from "../../../registration/utils/Form";
import HiddenSubmitInput from "../../../../common/HiddenSubmitInput";
import HeaderContext from "../../../../context/HeaderContext";
import MetaDecorator from "../../../../utils/MetaDecorator";
import CustomSelectList from "../../utils/CustomSelectList";
import HorizontalRule from "../../../../common/HorizontalRule";
import {
  GetBundeslandIdFromName,

} from "../../../../utils/helpers";

const applicationPageTitles = require("../../../../utils/pageTitles.json");
const countries = [
  "Burgenland",
  "Kärnten",
  "Niederösterreich",
  "Oberösterreich",
  "Salzburg",
  "Steiermark",
  "Tirol",
  "Vorarlberg",
  "Wien",
];

//HINT* NOT REGISTRATION
export function ForeignCounty({ text }) {
  const history = useHistory();
  const { user, setUser } = React.useContext(UserContext);

  const [livingIn, setLivingIn] = useState(user.CountyHomeRegionName);

  const { setHeader } = React.useContext(HeaderContext);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((4 / 19) * 100),
        title: "Als Auslandsösterreicher/in registrieren",
        ariaLabel: "Als Auslandsösterreicher:in registrieren",
      };
    });
  }, []);

  const isValid = () => {
    //return !!livingIn;
    return true;
  };

  const updateUser = () => {
    setUser((curr) => {
      return {
        ...curr,
        CountyHomeRegionName: livingIn,
        CountyHomeRegionId: GetBundeslandIdFromName(livingIn),
      };
    });
  };

  const onChangeHandler = (value) => {
    setLivingIn(value);
  };

  const onClickHandler = () => {
    updateUser();
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateUser();
      history.push(ApplicationRoute.foreignRegistrationRegistrationGender);
    }
  };

  return (
    <section className="foreign-registration registration-middle-container">
      <MetaDecorator title={applicationPageTitles.foreignRegistration} />
      <ContentTitle
        id="contentTitle"
        text={
          "In welchem österreichischen Bundesland hatten Sie zuletzt Ihren Wohnsitz? (optional)"
        }
      />
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form
        id="foreign-registration-form"
        onSubmit={onSubmitHandler}
        ariaLabelledBy="contentTitle"
        className="max-width-380"
      >
        <CustomSelectList
          id="county"
          label="Bundesland auswählen"
          state={livingIn}
          onChangeHandler={onChangeHandler}
          options={countries}
          ariaLabelledBy="contentTitle"
          optional={true}
        />

        <HiddenSubmitInput />
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content"> </div>
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.foreignRegistrationRegistrationGender}
        onClick={onClickHandler}
        ariaLabel={text}
        className={" floating-weiter-buton"}
      />
    </section>
  );
}
