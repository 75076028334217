const InfoContainer = ({ title, date, onClick }) => {
  return (
    <div className="info-container">
      <h2 className="info-container__title"> {title} </h2>
      <div className="info-container__date"> {date}</div>
      <button
        className="info-container__button"
        type="button"
        onClick={onClick}
      >
        Reise löschen
      </button>
    </div>
  );
};

export default InfoContainer;
