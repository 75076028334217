import React, { useEffect } from "react";
import apipath from "../../../apipath";
import HeaderContext from "../../../context/HeaderContext";
import { replaceAll_rgx } from "../../../utils/helpers";
import LoadingPage from "../../travel-registration/LoadingPage";

function ImpresumInfos() {
  const { setHeader } = React.useContext(HeaderContext);
  const [content, setContent] = React.useState("");

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "content",
        title: "Impressum",
      };
    });

    fetch(`${apipath.pathOrig}app-inhalte/impressum-app/type/json/`, {
      method: "GET",
    })
      .then((data) => data.json())
      .then((data) => setContent(data.data));
  }, []);

  if (content.length === 0) {
    return <LoadingPage />;
  }

  return (
    <div className="content-pages-container">
      {content.map((headerBody) => (
        <>
          <div className="content-pages-heading" dangerouslySetInnerHTML={{ __html: headerBody.header }}></div>
          <div
            className="content-pages-paragraph"
            dangerouslySetInnerHTML={{ __html: replaceAll_rgx(headerBody.bodytext) }}
          ></div>
        </>
      ))}
    </div>
  );
}

export default ImpresumInfos;
