const Cross = ({
  className,
  height = "3.7rem",
  width = "3.7rem",
  color = "rgb(158, 5, 41)",
  onClick,
  ariaLabel = "",
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={className}
      style={{ backgroundColor: "transparent", border: "0" }}
      aria-label={ariaLabel}
    >
      <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 30 30"
        style={{
          enableBackground: "new 0 0 30 30",
          height,
          width,
        }}
        xmlSpace="preserve"
      >
        <line
          style={{
            fill: "none",
            stroke: color,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
          }}
          x1="19.17"
          y1="10.83"
          x2="10.83"
          y2="19.17"
        />
        <line
          style={{
            fill: "none",
            stroke: color,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
          }}
          x1="10.83"
          y1="10.83"
          x2="19.17"
          y2="19.17"
        />
      </svg>
    </button>
  );
};

export default Cross;
