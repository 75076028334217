import { Link } from "react-router-dom";

const Separater = ({ text, secondRow, buttonText, buttonClickHandler, linkText, linkTo, isHidden }) => {
  return (
    <div style={{ padding: "28px 0px" }} className="clear-contact-div" hidden={isHidden} aria-hidden={isHidden}>
      <div className="clear-contact-div__separater-title">
        <h2 style={{ fontSize: "1.6rem" }}>
          {text}
          {secondRow ? (
            <>
              <br />
              {secondRow}
            </>
          ) : null}
        </h2>
        {buttonText && buttonClickHandler ? (
          <button className="clear-contact-div__separater-button" type="button" onClick={buttonClickHandler}>
            {buttonText}
          </button>
        ) : null}
        {linkText && linkTo ? (
          <Link className="clear-contact-div__separater-button" to={linkTo} onClick={buttonClickHandler}>
            {linkText}
          </Link>
        ) : null}
      </div>
    </div>
  );
};

export default Separater;
