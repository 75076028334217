import {useContext, useEffect, useState} from "react";
import WeiterButton from "../utils/WeiterButton";
import ContentTitle from "../utils/ContentTitle";
import UserContext from "../../../context/UserContext";
import Input from "../utils/Input";
import ApplicationRoute from "../../../config/routes";
import Info from "../utils/Info";
import {useHistory} from "react-router-dom";
import {
    isPasswordValid,
    arePasswordsValid,
} from "../../../utils/validation";
import Form from "../utils/Form";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import ValidationChecks from "../utils/ValidationChecks.jsx";
import {registrationValidationErrorMessages} from "../../../utils/validationErrorMessages";
import HeaderContext from "../../../context/HeaderContext";
import InputValidation from "../../../common/InputValidaiton";
import MetaDecorator from "../../../utils/MetaDecorator";
import HorizontalRule from "../../../common/HorizontalRule";

const applicationPageTitles = require("../../../utils/pageTitles.json");
//HINT* REGISTRATION
const PasswordPage = ({text}) => {
    const history = useHistory();
    const [passwords, setPasswords] = useState({
        password: "",
        confirmPassword: "",
    });

    const {user, setUser} = useContext(UserContext);
    const {setHeader} = useContext(HeaderContext);

    useEffect(() => {
        setHeader((curr) => {
            return {
                ...curr,
                inUse: "registration",
                progress: parseInt((3 / 6) * 100),
                title: "Registrierung",
                ariaLabel: "Registrierung step 3 of 6",
            };
        });
    }, []);

    const updateUser = () => {
        setUser((curr) => {
            return {...curr, Password: passwords.password};
        });
    };

    const onChangeHandler = (e) => {
        setPasswords((curr) => {
            return {...curr, [e.target.id]: e.target.value};
        });
    };

    const onClickHandler = () => {
        updateUser();
    };

    const onSubmitHandler = (e) => {
        e.preventDefault();

        if (arePasswordsValid(passwords)) {
            updateUser();
            history.push(ApplicationRoute.registerConfirmationPage);
        }
    };

    useEffect(() => {
        setPasswords((curr) => {
            let psw = user.Password ? user.Password : "";
            return {...curr, password: psw, confirmPassword: psw};
        });
    }, [user]);

    return (
        <div className="registration-middle-container">
            <MetaDecorator title={applicationPageTitles.registrationPages}/>
            <ContentTitle style={{marginBottom: "32px"}} text="Bitte erstellen Sie Ihr Passwort:" id="enter-password"/>
            <HorizontalRule className="horizontal-rule-desktop"/>
            <p className="sr-only">
                Ihr Passwort sollte folgende Kriterien erfüllen: mindestens 8 Zeichen lang sein, keine Leerzeichen
                enthalten,
                einen Kleinbuchstaben, einen Großbuchstaben, eine Ziffer und ein Sonderzeichen enthalten
            </p>
            <div className="mobile-hidden half-col " style={{float: "right"}} aria-hidden="true">
                <p
                    style={{
                        marginBottom: "1.6rem",
                        marginTop: "-1.6rem",
                        marginRight: "16rem",
                        wordWrap: "break-word",
                        width: "200px",
                    }}
                >
                    Ihr Passwort sollte folgende Kriterien erfüllen:
                </p>
                <div id="hint" style={{marginBottom: "0"}} aria-relevant="all">
                    <ValidationChecks passwords={passwords}></ValidationChecks>
                </div>
            </div>
            <Form className="half-col max-width-380" id="form" onSubmit={onSubmitHandler}
                  ariaLabelledBy="contentTitleId">
                <Input
                    id="password"
                    value={passwords.password}
                    onChange={onChangeHandler}
                    placeholder="Passwort"
                    type="password"
                    text="Passwort"
                    labelText="Passwort"
                    showValidationBorder={!isPasswordValid(passwords.password)}
                />

                <div className="desktop-hidden">
                    <Info
                        style={{marginTop: "32px", marginBottom: "16px"}}
                        text="Ihr Passwort sollte folgende Kriterien erfüllen:"
                    />

                    <div style={{marginBottom: "32px"}} role="alert" aria-relevant="all">
                        <ValidationChecks passwords={passwords}></ValidationChecks>
                    </div>
                </div>

                <Input
                    id="confirmPassword"
                    type="password"
                    htmlFor="confirmPassword"
                    value={passwords.confirmPassword}
                    onChange={onChangeHandler}
                    placeholder="Passwort wiederholen"
                    ariaLabelledBy="enter-password"
                    ariaDescribedby="hint"
                    labelText="Passwort wiederholen"
                    showValidationBorder={
                        passwords.confirmPassword.length > 0 && passwords.confirmPassword !== passwords.password
                    }
                    error={passwords.confirmPassword.length > 0 && passwords.confirmPassword !== passwords.password}
                />
                <InputValidation
                    alert={true}
                    isHidden={!(passwords.confirmPassword.length > 0 && passwords.confirmPassword !== passwords.password)}
                    text={registrationValidationErrorMessages.passwordAndConfirmationPasswordNotEqual}
                />

                <HiddenSubmitInput/>
            </Form>
            <HorizontalRule className="horizontal-rule-desktop"/>

            <WeiterButton
                disabled={!arePasswordsValid(passwords)}
                path={ApplicationRoute.registerConfirmationPage}
                onClick={onClickHandler}
                ariaLabel={text}
                positioned="fixed"
                className="hide-weiter-on-keyboard"
            />

            <div className="button-space-on-keyboard"></div>
        </div>
    );
};

export default PasswordPage;
