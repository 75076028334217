import React, { useState, useEffect } from "react";
import WeiterButton from "../utils/WeiterButton";
import ContentTitle from "../utils/ContentTitle";
import ControledRadioInput from "../utils/ControledRadioInput";
import ApplicationRoute from "../../../config/routes";
import { useHistory } from "react-router-dom";
import Form from "../utils/Form";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import HeaderContext from "../../../context/HeaderContext";
import Info from "../utils/Info";
import MetaDecorator from "../../../utils/MetaDecorator";

import HorizontalRule from "../../../common/HorizontalRule";
//HINT* REGISTRATION
const applicationPageTitles = require("../../../utils/pageTitles.json");
const initSelectValue = {
  hasCitizenship: false,
  noCitizenship: false,
};

const AustrianCitizenshipPage = ({ text }) => {
  const history = useHistory();
  const [selectedValue, setSelectedValue] = useState({
    ...initSelectValue,
    hasCitizenship: true,
  });

  const { setHeader } = React.useContext(HeaderContext);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((1 / 6) * 100),
        title: "Registrierung",
        ariaLabel: "Registrierung step 1 of 6",
      };
    });
  }, []);
  const isValid = () => {
    return selectedValue.hasCitizenship;
  };

  const onChangeHandler = (e) => {
    setSelectedValue(() => {
      return { ...initSelectValue, [e.target.id]: true };
    });
  };

  const onsubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      history.push(ApplicationRoute.registrationEmailAdress);
    }
  };

  return (
    <div className="registration-middle-container">
      <MetaDecorator title={applicationPageTitles.registrationPages} />
      <ContentTitle id="citizenship-title" text="Besitzen Sie die österreichische Staatsbürgerschaft?" />
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form id="citizenship-form" ariaLabelledBy="citizenship-title" onSubmit={onsubmitHandler}>
        <ControledRadioInput
          id="hasCitizenship"
          value="Ja"
          name="citizenship"
          textInfo="ja"
          checked={selectedValue.hasCitizenship}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.hasCitizenship}
        />
        <ControledRadioInput
          id="noCitizenship"
          value="Nein"
          name="citizenship"
          textInfo="nein"
          checked={selectedValue.noCitizenship}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.noCitizenship}
        />

        <div style={{ height: "20px" }}></div>
        {selectedValue.noCitizenship && (
          <Info
            style={{ marginTop: "0px" }}
            text="Die Auslandsregistrierung ist nur mit österreichischer Staatsbürgerschaft möglich."
          />
        )}

        <HiddenSubmitInput />
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.registrationEmailAdress}
        ariaLabel={text}
        className={"floating-weiter-buton"}
      />
    </div>
  );
};

export default AustrianCitizenshipPage;
