import HorizontalRule from "../../../common/HorizontalRule";
import InputValidation from "../../../common/InputValidaiton";
import {
  hasAtSymbolEmailValidation,
  hasNumber,
  hasTwoCharactersAfterAtSymbolEmailValidation,
  IsDateBiggerThanTodayValidation,
  IsDateSmallerThan_1900_1_1_Validation,
  isDateValidation,
  isEmailValidation,
  isOlderOrIsEighteenYearsOlds,
  isPhoneValidation,
  isValidFirstAndLastName,
} from "../../../utils/validation";
import { registrationValidationErrorMessages } from "../../../utils/validationErrorMessages";
import EditInput from "../../../common/EditInput";
import { getEnumFromGender, getGenderFromEnumGerman, hasMiddleName } from "../../../utils/helpers";

const Relative = ({
  onChange,
  FirstLastName,
  Email,
  PhoneNumber,
  Gender,
  BirthDate,
  Nationality,
  DegreeOfRelationship,
  countries,
  index = "",
}) => {
  Email = Email ?? "";
  FirstLastName = FirstLastName ?? "";
  PhoneNumber = PhoneNumber ?? "";

  return (
    <>
      <EditInput
        id={`id${index}-Name`}
        placeholder={"Vorname Familienname"}
        value={FirstLastName}
        labelText={"Vorname Familienname"}
        ariaRequired={true}
        onChange={onChange}
      />
      <InputValidation
        text={
          hasNumber(FirstLastName)
            ? registrationValidationErrorMessages.nameHasNumber
            : registrationValidationErrorMessages.notValidContactPersonFirstAndLastName
        }
        isHidden={isValidFirstAndLastName(FirstLastName) || hasMiddleName(FirstLastName)}
      />
      <InputValidation
        isHidden={!hasMiddleName(FirstLastName)}
        text={registrationValidationErrorMessages.middleName}
        ariaLabel={registrationValidationErrorMessages.middleName}
      />
      <HorizontalRule />
      <EditInput
        id={`id${index}-MobilePhone`}
        placeholder={"+43"}
        value={PhoneNumber}
        labelText={"Mobiltelefonnummer (inkl. Ländervorwahl)"}
        ariaRequired={true}
        onChange={onChange}
      />
      <InputValidation
        text={registrationValidationErrorMessages.notValidPhoneNumber}
        isHidden={isPhoneValidation(PhoneNumber, true) || PhoneNumber.length === 0}
      />

      <HorizontalRule />

      <EditInput
        id={`id${index}-Gender`}
        placeholder="Geschlecht"
        value={getGenderFromEnumGerman(Gender)}
        labelText="Geschlecht"
        ariaRequired={true}
        onChange={(value) =>
          onChange({
            target: {
              id: `id${index}-Gender`,
              value: getEnumFromGender(value),
            },
          })
        }
        options={["männlich", "weiblich", "divers"]}
      />

      <HorizontalRule />
      <EditInput
        id={`id${index}-Birthdate`}
        placeholder="Geburtsdatum"
        value={BirthDate}
        labelText="Geburtsdatum"
        ariaRequired={true}
        onChange={onChange}
      />
      <InputValidation text={registrationValidationErrorMessages.notValidDate} isHidden={isDateValidation(BirthDate)} />
      <InputValidation
        text={registrationValidationErrorMessages.ageLessThan_18}
        isHidden={
          !IsDateBiggerThanTodayValidation(BirthDate) ||
          !isDateValidation(BirthDate) ||
          isOlderOrIsEighteenYearsOlds(BirthDate)
        }
      />
      <InputValidation
        text={registrationValidationErrorMessages.dateBiggerThanToday}
        isHidden={!isDateValidation(BirthDate) || IsDateBiggerThanTodayValidation(BirthDate)}
      />
      <InputValidation
        text={registrationValidationErrorMessages.dateOfBirthLessThan_01_01_1900}
        isHidden={!isDateValidation(BirthDate) || !IsDateSmallerThan_1900_1_1_Validation(BirthDate)}
      />

      <HorizontalRule />
      <EditInput
        id={`id${index}-Nationality`}
        labelText="Staatsangehörigkeit"
        value={Nationality}
        onChange={(value) =>
          onChange({
            target: { id: `id${index}-Nationality`, value: value },
          })
        }
        options={countries}
        filtered={true}
      />

      <HorizontalRule />
      <EditInput
        id={`id${index}-DegreeOfRelationship`}
        labelText="Verwandtschaftsgrad"
        value={DegreeOfRelationship}
        onChange={(value) =>
          onChange({
            target: { id: `id${index}-DegreeOfRelationship`, value: value },
          })
        }
        options={[
          "Eltern, Großeltern",
          "Schwester, Bruder",
          "Kinder, Enkelkinder",
          "Ehegatte/in, Partner/in​",
          "Sonstige Verwandtschaftsverhältnisse",
        ]}
      />

      <HorizontalRule />
      <EditInput
        id={`id${index}-Email`}
        placeholder={"E-Mail-Adresse"}
        value={Email}
        labelText={"E-Mail-Adresse"}
        ariaRequired={false}
        onChange={onChange}
      />
      <InputValidation
        isHidden={
          Email.length === 0 ||
          !hasAtSymbolEmailValidation(Email) ||
          !hasTwoCharactersAfterAtSymbolEmailValidation(Email) ||
          isEmailValidation(Email)
        }
        text={registrationValidationErrorMessages.notValidEmail}
      />
      <InputValidation
        isHidden={Email.length === 0 || hasAtSymbolEmailValidation(Email)}
        text={registrationValidationErrorMessages.missingAtSymbolEmail}
      />
      <InputValidation
        isHidden={
          Email.length === 0 ||
          !hasAtSymbolEmailValidation(Email) ||
          hasTwoCharactersAfterAtSymbolEmailValidation(Email)
        }
        text={registrationValidationErrorMessages.charactersAfterAtSymbolLessThanTwoEmail}
      />
    </>
  );
};

export default Relative;
