import React, { useEffect } from "react";
import HeaderContext from "../../../context/HeaderContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import NavigationCard from "../utils/NavigationCard";
import ApplicationRoute from "../../../config/routes";
import ArrowRight from "../../../svg/ArrowRight.js";
import { useTranslation } from "react-i18next";
const applicationPageTitles = require("../../../utils/pageTitles.json");

function DashboardInfos() {
  const { setHeader } = React.useContext(HeaderContext);
  const { t } = useTranslation()

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "content",
        title: t("pages.informationen.title"),
      };
    });
  }, []);
  return (
    <div style={{ marginBottom: "56px" }}>
      <MetaDecorator title={t("pages.informationen.title")} />

      <NavigationCard text="Informationen zur Auslandsservice App" to={ApplicationRoute.allgemeineInformationen} />
      <NavigationCard text="Informationen zur Auslandsregistrierung" to={ApplicationRoute.infoReiseregistrierung} />
      <a
        style={{ textDecoration: "none", color: "#000000" }}
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.sozialministerium.at/Services/Neuigkeiten-und-Termine/Archiv-2023/Juni-2023/ende-gruenerpass.html"
        className="navigation-info-link"
      >
        <div className="navigation-info-cards">
          <div className="country__name">Informationen zum grünen Pass</div>
          <ArrowRight />
        </div>
      </a>
      <a
        style={{ textDecoration: "none", color: "#000000" }}
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.bmeia.gv.at/reise-services/auslandsservice/datenschutz/"
        className="navigation-info-link"
      >
        <div className="navigation-info-cards">
          <div className="country__name">Datenschutz</div>
          <ArrowRight />
        </div>
      </a>
      <NavigationCard text="Bildquellen" to={ApplicationRoute.bildquellen} />
      <a
        style={{ textDecoration: "none", color: "#000000" }}
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.bmeia.gv.at/barrierefreiheitserklaerung/"
        className="navigation-info-link"
      >
        <div className="navigation-info-cards">
          <div className="country__name">Barrierefreiheit</div>
          <ArrowRight />
        </div>
      </a>

      <a
        style={{ textDecoration: "none", color: "#000000" }}
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.bmeia.gv.at/app-inhalte/impressum-app/"
        className="navigation-info-link"
      >
        <div className="navigation-info-cards">
          <div className="country__name">Impressum</div>
          <ArrowRight />
        </div>
      </a>
    </div>
  );
}

export default DashboardInfos;
