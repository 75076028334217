import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import HeaderContext from "../../../context/HeaderContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import CountryInfoHeader from "../utils/CountryInfoHeader";
import HorizontalRule from "../../../common/HorizontalRule";
import InfoCard from "../utils/InfoCard";
import {
  getCountryFlagFromCountryVB,
  getCountryNameFromUid,
  getCountryAliasFromUid,
} from "../../travel-registration/utils/countryInfo";
import Separater from "../../../common/Separater";
import EmbassyServiceCheckBox from "../../countryOverview/utils/EmbassyServiceCheckBox";

function BotschaftenKonsulateContacts() {
  const { countryUid, orgaUnitUid } = useParams();
  const { setHeader } = React.useContext(HeaderContext);
  const countries = JSON.parse(localStorage.getItem("countriesVB")).countries;
  const [countryName] = React.useState(getCountryNameFromUid(countryUid));
  const [representation, setRepresentation] = React.useState(null);
  const [countryAlias] = React.useState(getCountryAliasFromUid(countryUid));

  useEffect(() => {
    let data = [];
    if (representation == null) {
      const arr = localStorage.getItem("countriesVB") ? JSON.parse(localStorage.getItem("countriesVB")).countries : [];

      for (let i = 0; i < arr.length; i++) {
        if (arr[i].countryUid == countryUid) {
          data = arr[i].representations.filter((repr) => repr.orgaUnitUid == orgaUnitUid);
          break;
        }
      }

      setRepresentation(data.length > 0 ? data[0] : {});
    }

    setHeader((curr) => {
      return {
        ...curr,
        inUse: "content",
        title: getHeader(data.length > 0 ? data[0] : null),
      };
    });
  }, []);

  const getHeader = (repr) => {
    if (!repr) return " ";
    const type = repr.type ? repr.type : "";
    const city = repr.city ? repr.city : "";
    return type + " " + city;
  };

  return (
    <div>
      <MetaDecorator title={representation ? representation.type : "Botschaften und Konsulate"} />
      <CountryInfoHeader
        flag={getCountryFlagFromCountryVB(countryName)}
        alt=""
        Country={countryAlias.length > 0 ? countryAlias + " (" + countryName + ")" : countryName}
        className="country__flag-no-image"
      />
      <HorizontalRule />
      {representation != null ? (
        <>
          <InfoCard type={representation.type} city={representation.city} />
          <HorizontalRule />
          <InfoCard type="Adresse" city={representation.address} isHidden={representation.address.length === 0} />
          <HorizontalRule isHidden={representation.address.length === 0} />

          <Separater
            text="Kontakt"
            isHidden={
              representation.phone.map((number) => {
                return number.number;
              }).length === 0 &&
              representation.mobile.map((number) => {
                return number.number;
              }).length === 0 &&
              representation.email.map((email) => {
                return email.email;
              }).length === 0 &&
              representation.fax.map((email) => {
                return email.email;
              }).length === 0 &&
              representation.websiteUrls.map((url) => {
                return (
                  <a target="_blank" rel="noopener noreferrer" href={url.url}>
                    {url.url}
                  </a>
                );
              }).length === 0
            }
          />

          <InfoCard
            type="Telefon"
            city={representation.phone.map((number) => {
              return (
                <React.Fragment key={number.number}>
                  <a style={{ textDecoration: "none", color: "#000000" }} href={`tel:${number.number}`}>
                    {number.number}
                  </a>
                  <br />
                </React.Fragment>
              );
            })}
            isHidden={
              representation.phone.map((number) => {
                return number.number;
              }).length === 0
            }
          />
          <HorizontalRule
            isHidden={
              representation.phone.map((number) => {
                return number.number;
              }).length === 0
            }
          />

          {/* If no phone show mobile phone */}
          {representation.phone.map((number) => {
            return number.number;
          }).length === 0 && (
            <>
              <InfoCard
                type="Telefon"
                city={representation.mobile.map((number) => {
                  return (
                    <React.Fragment key={number.number}>
                      <a style={{ textDecoration: "none", color: "#000000" }} href={`tel:${number.number}`}>
                        {number.number}
                      </a>
                      <br />
                    </React.Fragment>
                  );
                })}
                isHidden={
                  representation.mobile.map((number) => {
                    return number.number;
                  }).length === 0
                }
              />
              <HorizontalRule
                isHidden={
                  representation.mobile.map((number) => {
                    return number.number;
                  }).length === 0
                }
              />
            </>
          )}

          <InfoCard
            type="Fax"
            city={representation.fax.map((number) => {
              return (
                <React.Fragment key={number.number}>
                  <a style={{ textDecoration: "none", color: "#000000" }} href={`fax:${number.number}`}>
                    {number.number}
                  </a>
                  <br />
                </React.Fragment>
              );
            })}
            isHidden={
              representation.fax.map((number) => {
                return number.number;
              }).length === 0
            }
          />
          <HorizontalRule
            isHidden={
              representation.fax.map((number) => {
                return number.number;
              }).length === 0
            }
          />

          <InfoCard
            type="E-Mail"
            city={representation.email.map((email) => {
              return (
                <React.Fragment key={email.email}>
                  <a style={{ textDecoration: "none", color: "#000000" }} href={`mailto:${email.email}`}>
                    {email.email}
                  </a>
                  <br />
                </React.Fragment>
              );
            })}
            isHidden={
              representation.email.map((email) => {
                return email.email;
              }).length === 0
            }
          />
          <HorizontalRule
            isHidden={
              representation.email.map((email) => {
                return email.email;
              }).length === 0
            }
          />

          <InfoCard
            style={{ wordWrap: "break-word", width: "250px" }}
            type="Web"
            city={representation.websiteUrls.map((url) => {
              return (
                <React.Fragment key={url.url}>
                  <a
                    style={{ color: "#000000" }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`http://${url.url.replace("https://", "")}`}
                  >
                    {url.url}
                  </a>
                  <br />
                </React.Fragment>
              );
            })}
            isHidden={
              representation.websiteUrls.map((url) => {
                return (
                  <a target="_blank" rel="noopener noreferrer" href={`//${url.url}`}>
                    {url.url}
                  </a>
                );
              }).length === 0
            }
          />
          <Separater
            text="Öffnungszeiten"
            isHidden={representation.opening.length === 0 && representation.info == ""}
          />
          <div
            style={{
              margin: representation.opening.length ? "24px 16px" : "0 16px 24px 16px",
            }}
          >
            {representation.opening.length ? (
              <ul style={{ fontSize: "17px", lineHeight: "1" }}>
                {representation.opening.split(",").map((op) => {
                  return (
                    <li key={op} style={{ display: "block", margin: "8px 0" }}>
                      {op}
                      <br />
                    </li>
                  );
                })}
              </ul>
            ) : null}
            <p style={{ marginTop: "24px", fontSize: "17px", lineHeight: "1.1" }}>{representation.info}</p>
          </div>
          {/* <InfoCard
            type={
              representation.opening.slice(0, 2) +
              "-" +
              representation.opening.slice(-14, -11)
            }
            city={representation.opening.slice(2, 14)}
            isHidden={representation.opening.length === 0}
          /> */}
          <HorizontalRule />
          <Separater text="Informationen" />
          <div className="embassy-services--flex">
            <InfoCard city="Antragsstelle für alle Reisepässe und Personalausweise" />
            <EmbassyServiceCheckBox
                id="passportAuthority"
                style={{ marginBottom: "0" }}
                active={representation.passportAuthority === true}
            />
          </div>
          <HorizontalRule />
          <div className="embassy-services--flex">
            <InfoCard
              city="Antragsstelle nur für Kinderpässe,
                      Notpässe und Kinderpersonalausweise"
            />
            <EmbassyServiceCheckBox
              id="passportAuthorityLimited"
              style={{ marginBottom: "0" }}
              active={representation.passportAuthorityLimited == true}
            />
          </div>
          <HorizontalRule />
          <div className="embassy-services--flex">
            <InfoCard city="Notpassausstellung vor Ort" />
            <EmbassyServiceCheckBox
              id="passportAuthorityEmergency"
              style={{ marginBottom: "0" }}
              active={representation.passportAuthorityEmergency === true}
            />
          </div>
          <HorizontalRule />
          <div className="embassy-services--flex">
            <InfoCard city="Visabefugnis" />
            <EmbassyServiceCheckBox
              id="visaAuthority"
              style={{ marginBottom: "0" }}
              active={representation.visaAuthority === true}
            />
          </div>
          <HorizontalRule />
          <div className="embassy-services--flex">
            <InfoCard city="Beglaubigungsbefugnis" />
            <EmbassyServiceCheckBox
              id="legalizationAuthority"
              style={{ marginBottom: "0" }}
              active={representation.legalizationAuthority === true}
            />
          </div>
          <HorizontalRule />
          <InfoCard
            type={`Amtsbereich: ${representation.officeArea}`}
            isHidden={representation.officeArea.length === 0}
          />
          <p style={{ paddingBottom: "16px" }}></p>
        </>
      ) : null}
    </div>
  );
}

export default BotschaftenKonsulateContacts;
