const alertTriangle = ({ className }) => {
  return (
    <div className={className}>
      <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 30 30"
        style={{
          enableBackground: "new 0 0 30 30",
          height: "4.8rem",
          width: "5.2rem",
        }}
        xmlSpace="preserve"
      >
        <polygon
          style={{
            fill: "rgb(201, 44, 12)",
            stroke: "rgb(201, 44, 12)",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
          }}
          points="27.92,27 2.08,27 15,3 "
        />
        <line
          style={{
            fill: "none",
            stroke: "white",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
          }}
          x1="15"
          y1="9.93"
          x2="15"
          y2="17.77"
        />
        <line
          style={{
            fill: "none",
            stroke: "white",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
          }}
          x1="15"
          y1="21.46"
          x2="15"
          y2="23.31"
        />
      </svg>
    </div>
  );
};

export default alertTriangle;
