import {useCallback, useContext, useEffect, useState} from "react";
import ApplicationRoute from "../../../config/routes";
import {useHistory} from "react-router-dom";
import HeaderContext from "../../../context/HeaderContext";
import MetaDecorator from "../../../utils/MetaDecorator.jsx";
import fetcher from "../../../services/fetcher.js";
import apipath from "../../../apipath.js";
import LoginTemplate from "../utils/LoginTemplate.jsx";

const applicationPageTitles = require("../../../utils/pageTitles.json");
//HINT* REGISTRATION
const IdaLinkLoginPage = () => {
    const history = useHistory();

    const {setHeader} = useContext(HeaderContext);

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    const initLoginCred = {email: false, password: false};
    const [noLoginCred, setNoLoginCred] = useState(initLoginCred);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        setHeader((curr) => {
            return {
                ...curr,
                inUse: "registration",
                progress: (3 / 5) * 100,
                title: applicationPageTitles.linkAccountPage,
                ariaLabel: "Login zum Account verknüpfen",
            };
        });
    }, [setHeader]);

    const confirmUser = () => {
        if (!email) {
            setNoLoginCred(() => {
                return {...initLoginCred, email: true};
            });

            return;
        } else if (!password) {
            setNoLoginCred(() => {
                return {...initLoginCred, password: true};
            });

            return;
        }
        fetcher(
            `${apipath.path}login`,
            "POST",
            setData,
            setError,
            setLoading,
            setSubmitting,
            false,
            "application/x-www-form-urlencoded;charset=UTF-8",
            new URLSearchParams({
                username: email.trim(),
                password: password
            }),
            false,
            true
        );
    };

    const submittedWithNoErrors = useCallback(() => {
        return !loading && !error;
    }, [loading, error]);

    //After submit
    useEffect(() => {
        if (submittedWithNoErrors()) {
            history.push(ApplicationRoute.TravelDashboardNoPermissions);
        }
    }, [loading, error, data, submittedWithNoErrors, history]);

    const onSubmitHandler = (e) => {
        e.preventDefault();

        confirmUser();
    };

    return (<div className="registration-middle-container">
        <MetaDecorator title={applicationPageTitles.linkAccountPage}/>
        <LoginTemplate onSubmitHandler={onSubmitHandler} noLoginCred={noLoginCred}
                       email={email} setEmail={setEmail}
                       password={password} setPassword={setPassword}
                       error={error} loading={loading} submitting={submitting}></LoginTemplate>

        <p className="centered-link__container centered-link__container__bottom">
            <a
                href="https://www.bmeia.gv.at/reise-aufenthalt/auslandsservice/datenschutz"
                target="_blank"
                style={{textDecoration: "none", display: "block"}}
                aria-label="Datenschutzinformation."
                rel="noopener noreferrer"
            >
                <span className="centered-link__container__link centered-link__container__link--red">
                    Datenschutzinformation
                </span>
            </a>
        </p>
    </div>);
};

export default IdaLinkLoginPage;