import React, { useEffect, useState } from "react";
import ApplicationRoute from "../../../../config/routes";
import { useHistory } from "react-router-dom";
import Form from "../../../travel-registration/utils/Form";
import HiddenSubmitInput from "../../../../common/HiddenSubmitInput";
import { isPhoneValidation } from "../../../../utils/validation";
import HeaderContext from "../../../../context/HeaderContext";
import { registrationValidationErrorMessages } from "../../../../utils/validationErrorMessages";
import InputValidation from "../../../../common/InputValidaiton";
import { getTravelGroupMemberLastIndex_Array, phoneFormatter } from "../../../../utils/helpers";
import Input from "../../../registration/utils/Input";
import TravelContext from "../../../../context/TravelContext";
import WeiterButton from "../../../registration/utils/WeiterButton";
import PageTitle from "../../../travel-registration/utils/PageTitle";
import MetaDecorator from "../../../../utils/MetaDecorator";
import HorizontalRule from "../../../../common/HorizontalRule";

const applicationPageTitles = require("../../../../utils/pageTitles.json");
export function AddRelativesNumber({ text }) {
  const history = useHistory();

  const { setHeader } = React.useContext(HeaderContext);
  const { travel, setTravel } = React.useContext(TravelContext);

  const groupMemberIndex = getTravelGroupMemberLastIndex_Array(travel);
  let initMobilePhone = travel?.TravelGroupMember[groupMemberIndex]?.MobilePhone;

  const [phoneNumber, setPhoneNumber] = useState(initMobilePhone ? initMobilePhone : "");

  const activate = () => {
    setActive(() => true);
  };

  const [active, setActive] = useState(false);

  useEffect(() => {
    document.getElementsByTagName("HTML")[0].addEventListener("click", activate);

    return () => document.getElementsByTagName("HTML")[0].removeEventListener("click", activate);
  }, []);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: "Als Auslandsösterreicher/in registrieren",
        ariaLabel: "Als Auslandsösterreicher:in registrieren",
        progress: parseInt((14 / 17) * 100),
      };
    });
  }, []);

  const isValid = () => {
    return isPhoneValidation(phoneNumber, true);
  };

  const updateTravel = () => {
    const groupMemberIndex = travel.TravelGroupMember.length > 0 ? travel.TravelGroupMember.length - 1 : 0;
    let newArray = [...travel.TravelGroupMember];
    newArray[groupMemberIndex] = {
      ...newArray[groupMemberIndex],
      MobilePhone: phoneNumber.trim(),
    };

    setTravel((curr) => {
      return {
        ...curr,
        TravelGroupMember: newArray,
      };
    });
  };

  const onChangeHandler = (e) => {
    let phone = phoneFormatter(phoneNumber, e.target.value);

    setPhoneNumber(phone);
  };

  const onClickHandler = () => {
    updateTravel();

    history.push(ApplicationRoute.foreignRegistrationAddRelativesNationality);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateTravel();
      history.push(ApplicationRoute.foreignRegistrationAddRelativesNationality);
    }
  };

  return (
    <section className="foreign-registration registration-middle-container">
      <MetaDecorator title={applicationPageTitles.foreignRegistration} />
      <PageTitle text="Bitte geben Sie die Telefonnummer ein (optional):" />
      <p className="page-paragraph page-paragraph--small">Angehörige/r</p>

      <HorizontalRule className="horizontal-rule-desktop" />
      <Form id="form" onSubmit={onSubmitHandler} className="max-width-380">
        <Input
          id="phoneNumber"
          value={phoneNumber}
          onChange={onChangeHandler}
          placeholder="Mobiltelefonnummer"
          type="tel"
          labelText="Mobiltelefonnummer (inkl. Ländervorwahl)"
          labelStyle={{ display: "inline-block" }}
        />

        <InputValidation
          isHidden={!active || isValid()}
          text={registrationValidationErrorMessages.notValidPhoneNumber}
        />

        <HiddenSubmitInput />
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content"> </div>
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.foreignRegistrationAddRelativesNationality}
        onClick={onClickHandler}
        ariaLabel={text}
        className="hide-weiter-on-keyboard floating-weiter-buton"
      />
    </section>
  );
}
