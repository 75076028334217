import React, { useContext, useEffect } from "react";
import HeaderContext from "../context/HeaderContext";
import WeiterButton from "../components/registration/utils/WeiterButton";
import ApplicationRoute from "../config/routes";
import SadFace from "./sadFace";
import MetaDecorator from "../utils/MetaDecorator";
import { getIsLogged } from "../utils/helpers";
import { useHistory } from "react-router";

const PageNotFound = () => {
  const { setHeader } = useContext(HeaderContext);
  const root = document.getElementsByTagName("HTML")[0];
  root.classList.add("error-page-background");

  const history = useHistory();

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "main",
        title: "Bundesministerium",
        description: "Europaische and internationale Angelegenheiten",
      };
    });
  }, []);

  const navigate = (e) => {
    e.preventDefault();
    history.push(getIsLogged() ? ApplicationRoute.TravelDashboardNoPermissions : ApplicationRoute.home);
  };

  return (
    <div className="page-not-found registration-middle-container">
      <MetaDecorator title="404 – Seite nicht gefunden" />
      <div className="page-not-found__content-wrapper">
        <SadFace />
        <h1 className="page-not-found__header">404 – Seite nicht gefunden</h1>
        <p className="page-not-found__p">Tut uns Leid, die von Ihnen aufgerufene Seite existiert nicht.</p>
      </div>

      <WeiterButton
        onClick={navigate}
        disabled={false}
        path={ApplicationRoute.TravelDashboardNoPermissions}
        ariaLabel={`Senden und gehen Sie zu ${ApplicationRoute.TravelDashboardNoPermissions}`}
        text="Zurück zum Dashboard"
        style={{ marginBottom: "0.6rem" }}
        positioned="fixed"
      />
    </div>
  );
};

export default PageNotFound;
