import Cross from "../svg/Cross";

const MapInput = ({ hidden, value, setValue, onCrossClick, id = "mapInputId", labelText = "Suchen" }) => {
  const isHidden = hidden ? "none" : "block";
  const isHiddenLabel = value ? "block" : "none";

  return (
    <div style={{ display: isHidden }} className="search-input">
      <div className="search-input__input-container">
        <label style={{ display: isHiddenLabel }} className="search-input__label" htmlFor={id}>
          {labelText}
        </label>
        <input
          id={id}
          onChange={(e) => setValue(e.target.value)}
          value={value}
          className="search-input__input"
          placeholder="Suchen"
          autoComplete="off"
        />
        <Cross onClick={onCrossClick} className="search-input__cross" color="black" ariaLabel="Suchformular schließen" />
      </div>
    </div>
  );
};

export default MapInput;
