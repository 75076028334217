export function getClassName(active, reverse, className) {
    return "custom-selectlist" +
        (active ? " custom-selectlist--active" : "") +
        (reverse ? " reverse" : "") +
        (className ? " " + className : "")
}

export function effectInterval(intervalId, setIntervalId, active, setActive, id) {
    clearInterval(intervalId);
    setIntervalId(
        setInterval(() => {
            try {
                if (document.querySelector(`.custom-selectlist--active #${id}`)?.parentElement) {
                    const arr = [...document.querySelector(`.custom-selectlist--active #${id}`).parentElement.querySelectorAll("[tabindex], input")].filter((a) => a === document.activeElement);
                    if (active && (document.querySelector(`.custom-selectlist--active #${id}`) == null || arr.length === 0)) {
                        setActive(false);
                    }
                }
            } catch (e) {
                clearInterval(intervalId);
            }
        }, 200)
    );
}

export function listClickHandler(active, setActive, highRef, setReverse) {
    if (!active) {
        document.querySelectorAll(".custom-selectlist--active").forEach((el) => {
            el.classList.remove("custom-selectlist--active");
        });

        if (
            highRef.current.offsetTop + highRef.current.offsetHeight + 200 >
            document.getElementsByTagName("html")[0].offsetHeight
        ) {
            setReverse(true);
        }
    } else setReverse(false);

    setActive(!active);
}

export function activeKeyDownHandler(e, active, setActive, setActiveAlert) {
    if (!active && e.keyCode === 13) {
        setActive(() => true);
        setActiveAlert(() => true);
    }
    if (active && e.keyCode === 13 && e.target.classList.contains("custom-selectlist__option")) {
        e.target.click();
    }
    if (!active && e.keyCode === 40) {
        e.preventDefault();
        setActiveAlert(() => true);
        e.target.click();
        return true;
    }
    if (active && e.keyCode === 9) {
        setActive(() => false);
    }

    return false;
}

export function optionClassKeyDownHandler(e) {
    if (e.target.classList.contains("custom-selectlist__option")) {
        if (e.keyCode === 38) {
            let el = e.target.previousSibling;
            if (!el.classList.contains("not-selectable")) el.focus();
            else e.target.parentElement.parentElement.focus();
        } else if (e.keyCode === 40) {
            let el = e.target.nextSibling;
            if (el) el.focus();
        }

        return true;
    }

    return false;
}

export function getAriaLabel(type, value) {
    if (type === "countries") {
        return value === "Bitte wählen Sie aus" ? setText(value) : `Aktuell ausgewählte Destination ${value}`;
    }

    if (
        value === "Bitte wählen Sie aus" ||
        value === "Bundesland auswählen" ||
        value === "Region auswählen" ||
        value === "Land oder Gebiet auswählen" ||
        value === "Destination auswählen"
    ) {
        return value;
    } else {
        return `Aktuell ausgewählte Destination ${value}`;
    }
}


export function getAlertChoseDestionation(value) {
    if (value === "Bitte wählen Sie aus" || value === "Land oder Gebiet auswählen") return "";

    return "Gewählt " + value;
}

export function setText(value, isRegion, isTravel) {
    if (!value) return value;

    if (value === "Bitte wählen Sie aus") {
        if (isRegion) return "Region auswählen";
        if (!isTravel) {
            return "Land oder Gebiet auswählen";
        } else {
            return "Destination auswählen";
        }
    }

    return value;
}
