import React, { useEffect, useState } from "react";
import WeiterButton from "../../registration/utils/WeiterButton";
import ApplicationRoute from "../../../config/routes";
import { useHistory } from "react-router-dom";
import { hasNumber, isValidFirstAndLastName } from "../../../utils/validation";
import Form from "../utils/Form";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import PageTitle from "../utils/PageTitle";
import HeaderContext from "../../../context/HeaderContext";
import { registrationValidationErrorMessages } from "../../../utils/validationErrorMessages";
import InputValidation from "../../../common/InputValidaiton";
import Input from "../../registration/utils/Input";
import TravelContext from "../../../context/TravelContext";
import {
  getKeyByValue,
  getTravelGroupMemberLastIndex_Array,
  hasMiddleName,
} from "../../../utils/helpers";
import MetaDecorator from "../../../utils/MetaDecorator";
import CustomSelectList from "../../foreign-registration/utils/CustomSelectList";
import HorizontalRule from "../../../common/HorizontalRule";

const applicationPageTitles = require("../../../utils/pageTitles.json");

const formLabel = "Geben Sie den Vor- und Familiennamen ein";

const relationships = [
  "Eltern, Großeltern",
  "Schwester, Bruder",
  "Kinder, Enkelkinder",
  "Ehegatte/in, Partner/in​",
  "Sonstige Verwandtschaftsverhältnisse",
];

const relationshipEnums = {
  "Eltern, Großeltern": 100000000,
  "Schwester, Bruder": 100000001,
  "Kinder, Enkelkinder": 100000002,
  "Ehegatte/in, Partner/in​": 100000003,
  "Sonstige Verwandtschaftsverhältnisse": 100000004,
};

const AddTravelCompanionName = ({ text }) => {
  const { setHeader } = React.useContext(HeaderContext);

  const [active, setActive] = useState(false);

  const { travel, setTravel } = React.useContext(TravelContext);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: "Neue Reise anlegen",
        progress: parseInt((12 / 19) * 100),
      };
    });
  }, []);

  const history = useHistory();

  const groupMemberIndex = getTravelGroupMemberLastIndex_Array(travel);

  const initName = travel?.TravelGroupMember[groupMemberIndex]?.FirstLastName;

  const initRelationship = getKeyByValue(
    relationshipEnums,
    travel?.TravelGroupMember[groupMemberIndex]?.DegreeOfRelationship
  );

  const [relationship, setRelationship] = useState(initRelationship ? initRelationship : "");

  const [fullName, setFullName] = useState(initName ? initName : "");

  const isValidRelationShip = () => {
    return relationships.includes(relationship);
  };

  const isValid = () => {
    return (
      fullName.length > 0 && isValidFirstAndLastName(fullName) && isValidRelationShip() && !hasMiddleName(fullName)
    );
  };

  const updateTravel = () => {
    const names = fullName.trim().split(" ");

    const groupMemberIndex = travel.TravelGroupMember.length > 0 ? travel.TravelGroupMember.length - 1 : 0;
    let newArray = [...travel.TravelGroupMember];
    newArray[groupMemberIndex] = {
      ...newArray[groupMemberIndex],

      FirstName: names[0],
      LastName: names[1],
      FirstLastName: fullName,
      DegreeOfRelationship: relationshipEnums[relationship],
    };

    setTravel((curr) => {
      return {
        ...curr,
        TravelGroupMember: newArray,
      };
    });
  };

  const onChangeHandler = (e) => {
    setActive(true);
    setFullName(e.target.value);
  };

  const onChangeHandlerCountries = (value) => {
    setRelationship(value);
  };

  const onClickHandler = () => {
    updateTravel();
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateTravel();
      history.push(ApplicationRoute.addTravelCompanionDateOfBirth);
    }
  };

  return (
    <section className="add-travel registration-middle-container">
      <MetaDecorator title={applicationPageTitles.createTripPages} />
      <PageTitle id={formLabel} text="Wie lauten die Namen der Mitreisenden?" />

      {/* <p className="page-paragraph page-paragraph--small">Mitreisende/r</p> */}
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form ariaLabelledBy={formLabel} className="max-width-380" id="form" onSubmit={onSubmitHandler}>
        <Input
          labelText="Vorname Familienname"
          id="firstName"
          name="firstName"
          type="text"
          htmlFor="firstName"
          placeholder="Vorname Familienname"
          value={fullName}
          onChange={onChangeHandler}
        />

        <InputValidation
          style={{ marginBottom: "1.5rem" }}
          isHidden={!active || isValidFirstAndLastName(fullName) || hasMiddleName(fullName)}
          text={
            hasNumber(fullName)
              ? registrationValidationErrorMessages.nameHasNumber
              : registrationValidationErrorMessages.notValidContactPersonFirstAndLastName
          }
          ariaLabel={registrationValidationErrorMessages.notValidContactPersonFirstAndLastName}
        />
        <InputValidation
          isHidden={!hasMiddleName(fullName)}
          text={registrationValidationErrorMessages.middleName}
          ariaLabel={registrationValidationErrorMessages.middleName}
        />

        <CustomSelectList
          id="relationship"
          label="Verwandtschaftsgrad"
          state={relationship}
          onChangeHandler={onChangeHandlerCountries}
          options={relationships}
        />

        <HiddenSubmitInput />
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />

      <div className="hide-weiter-on-keyboard-content"> </div>
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.addTravelCompanionDateOfBirth}
        onClick={onClickHandler}
        ariaLabel={text}
        className="hide-weiter-on-keyboard floating-weiter-buton"
      />
    </section>
  );
};

export default AddTravelCompanionName;
