export default function SadFace() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="88"
      height="88"
      viewBox="0 0 88 88"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g transform="translate(-144 -205) translate(25 197)">
            <g transform="translate(111)">
              <path
                fill="#ccd9de"
                d="M52 8c11.753 0 22.802 4.577 31.113 12.887C91.423 29.197 96 40.247 96 52s-4.577 22.802-12.887 31.113C74.803 91.423 63.753 96 52 96s-22.802-4.577-31.113-12.887C12.577 74.803 8 63.753 8 52s4.577-22.802 12.887-31.113C29.197 12.577 40.247 8 52 8zm0 7c-20.402 0-37 16.598-37 37s16.598 37 37 37 37-16.598 37-37-16.598-37-37-37zm.917 44.333c11.255 0 18.405 6.176 20.471 8.229l.167.168c.257.262.406.43.445.475l-5.534 3.949.012.013c-.235-.264-5.849-6.454-15.561-6.454-9.833 0-15.494 6.376-15.55 6.44l-5.534-3.948c.316-.362 7.87-8.872 21.084-8.872zM37.333 33.667c3.038 0 5.5 2.462 5.5 5.5 0 3.037-2.462 5.5-5.5 5.5-3.037 0-5.5-2.463-5.5-5.5 0-3.038 2.463-5.5 5.5-5.5zm29.334 0c3.037 0 5.5 2.462 5.5 5.5 0 3.037-2.463 5.5-5.5 5.5-3.038 0-5.5-2.463-5.5-5.5 0-3.038 2.462-5.5 5.5-5.5z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
