import React, { useEffect} from "react";
import { useHistory } from "react-router-dom";
import ApplicationRoute from "../../../../config/routes";
import HeaderContext from "../../../../context/HeaderContext";
import TravelContext from "../../../../context/TravelContext";
import ControledRadioInput from "../../../travel-registration/utils/ControledRadioInput";
import Form from "../../../travel-registration/utils/Form";
import HiddenSubmitInput from "../../../../common/HiddenSubmitInput";
import PageTitle from "../../../travel-registration/utils/PageTitle";
import WeiterButton from "../../../travel-registration/utils/WeiterButton";
import MetaDecorator from "../../../../utils/MetaDecorator";
import HorizontalRule from "../../../../common/HorizontalRule";

const applicationPageTitles = require("../../../../utils/pageTitles.json");
const formLabel = "Angehörigen im Ausland hinzufügen";
const pathValues = {
  yes: ApplicationRoute.foreignRegistrationAddRelativesName,
  no: ApplicationRoute.foreignRegistrationEditPage,
};

const defValue = {
  Birthdate: "",
  DegreeOfRelationship: "",
  Email: "",
  FirstLastName: "",
  Gender: "",
  Id: "",
  MobilePhone: "",
  Name: "",
  Nationality: "",
};

export default function AddRelatives({ text }) {
  const history = useHistory();
  const { travel, setTravel } = React.useContext(TravelContext);

  const { setHeader } = React.useContext(HeaderContext);

  const [addRelative, setAddRelative] = React.useState(null);
  const [path, setPath] = React.useState(pathValues.no);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: "Als Auslandsösterreicher/in registrieren",
        ariaLabel: "Als Auslandsösterreicher:in registrieren",
        progress: parseInt((10 / 19) * 100),
      };
    });
  }, []);

  const onClickHandler = (e) => {
    e.preventDefault();
    if (path === pathValues.yes && latestTravelGroupMemberIsNotEmpty()) {
      let travelCopy = Object.assign({}, travel);
      travelCopy.TravelGroupMember.push(Object.assign({}, defValue));
      setTravel(travelCopy);
    }

    if (path === pathValues.no) {
      setTravel((curr) => {
        return { ...curr, TravelGroupMember: [] };
      });
    }

    history.push(path);
  };

  const latestTravelGroupMemberIsNotEmpty = () => {
    let el = travel.TravelGroupMember[travel.TravelGroupMember.length - 1];

    if (el) return el.FirstLastName && el.Gender && el.Nationality;
    else return true;
  };

  const changeRadioHandler = (bool) => {
    setAddRelative(bool);
    setPath(bool ? pathValues.yes : pathValues.no);
  };

  return (
    <>
      <section className="foreign-registration registration-middle-container">
        <MetaDecorator title={applicationPageTitles.foreignRegistration} />
        <PageTitle text="Möchten Sie Angehörige im Ausland hinzufügen?" />
        <HorizontalRule className="horizontal-rule-desktop" />
        <Form
          id="form"
          ariaLabel={formLabel}
          onSubmit={onClickHandler}
          className="m-t-l max-width-380"
        >
          <ControledRadioInput
            id="yes"
            value="Yes"
            //name="tour-operator"
            textInfo="Ja"
            checked={addRelative === true}
            onChange={() => changeRadioHandler(true)}
            ariaChecked={addRelative === true}
          />
          <ControledRadioInput
            id="no"
            value="No"
            //name="tour-operator"
            textInfo="Nein"
            checked={addRelative === false}
            onChange={() => changeRadioHandler(false)}
            ariaChecked={addRelative === false}
          />

          <HiddenSubmitInput />
        </Form>
        <HorizontalRule className="horizontal-rule-desktop" />
        <div className="hide-weiter-on-keyboard-content"> </div>
        <WeiterButton
          disabled={addRelative === null}
          path={path}
          onClick={onClickHandler}
          ariaLabel={text}
          className={"floating-weiter-buton"}
        />
      </section>
    </>
  );
}
