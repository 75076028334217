const SelectListOptions = (
    {
        arr, optional, changeHandler, label
    }) => {
    return (
        <>
            {arr.map((option, index) => {
                if (index > 0 || optional)
                    return (
                        <div
                            role="option"
                            key={option.id}
                            id={option.id}
                            className={
                                "custom-selectlist__option" +
                                (option.selected ? " selected" : "") +
                                (index === 0 ? " not-selectable" : "")
                            }
                            aria-selected={option.selected}
                            onClick={() => changeHandler(index, option.value)}
                            tabIndex="0"
                        >
                            {!optional || (optional && option.value !== label) ? option.value : "Optional"}
                        </div>
                    );
                else
                    return <></>;
            })}
        </>
    );
}

export default SelectListOptions;