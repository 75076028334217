import React, { useEffect, useState } from "react";
import WeiterButton from "../../../registration/utils/WeiterButton";
import UserContext from "../../../../context/UserContext";
import ContentTitle from "../../../registration/utils/ContentTitle";
import Input from "../../../registration/utils/Input";
import ApplicationRoute from "../../../../config/routes";
import { useHistory } from "react-router-dom";
import Form from "../../../registration/utils/Form";
import HiddenSubmitInput from "../../../../common/HiddenSubmitInput";
import HeaderContext from "../../../../context/HeaderContext";
import InputValidation from "../../../../common/InputValidaiton";
import { registrationValidationErrorMessages } from "../../../../utils/validationErrorMessages";
import { phoneFormatter, prefilledUserData } from "../../../../utils/helpers";
import { isPhoneValidation } from "../../../../utils/validation";
import MetaDecorator from "../../../../utils/MetaDecorator";
import HorizontalRule from "../../../../common/HorizontalRule";

const applicationPageTitles = require("../../../../utils/pageTitles.json");
//HINT* NOT REGISTRATION
export function ForeignNumber({ text }) {
  const history = useHistory();
  const [phoneNumber, setPhoneNumber] = useState("");

  const { user, setUser } = React.useContext(UserContext);
  const { setHeader } = React.useContext(HeaderContext);

  const activate = () => {
    setActive(() => true);
  };

  const [active, setActive] = useState(false);

  useEffect(() => {
    document.getElementsByTagName("HTML")[0].addEventListener("click", activate);

    return () => document.getElementsByTagName("HTML")[0].removeEventListener("click", activate);
  }, []);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((6 / 19) * 100),
        title: "Als Auslandsösterreicher/in registrieren",
      };
    });
  }, []);

  const isValid = () => {
    return isPhoneValidation(phoneNumber);
  };

  const updateUser = () => {
    setUser((curr) => {
      return { ...curr, PhoneNumber: phoneNumber };
    });
  };

  const onChangeHandler = (e) => {
    let phone = phoneFormatter(phoneNumber, e.target.value);
    // phone = e.target.value;

    setPhoneNumber(phone);
  };

  const onClickHandler = () => {
    updateUser();
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateUser();
      history.push(ApplicationRoute.foreignRegistrationRegistrationEmail);
    }
  };

  useEffect(() => {
    setPhoneNumber(user.PhoneNumber ? user.PhoneNumber : "");
  }, [user]);

  return (
    <section className="foreign-registration registration-middle-container">
      <MetaDecorator title={applicationPageTitles.foreignRegistration} />
      <ContentTitle
        id="foreign-registration-phone"
        text={prefilledUserData("Bitte geben Sie Ihre Mobiltelefonnummer ein:")}
      />
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form id="form" onSubmit={onSubmitHandler} ariaLabelledBy="foreign-registration-phone" className="max-width-380">
        <Input
          id="phoneNumber"
          htmlFor="phoneNumber"
          name="phoneNumber"
          value={phoneNumber}
          labelText="Mobiltelefonnummer (inkl. Ländervorwahl)"
          onChange={onChangeHandler}
          placeholder="Mobiltelefonnummer"
          type="tel"
          labelStyle={{ display: "inline-block" }}
        />

        <InputValidation
          isHidden={!active || isValid()}
          text={registrationValidationErrorMessages.notValidPhoneNumber}
        />

        <HiddenSubmitInput />
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content"> </div>
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.foreignRegistrationRegistrationEmail}
        onClick={onClickHandler}
        ariaLabel={text}
        className="hide-weiter-on-keyboard floating-weiter-buton"
      />
    </section>
  );
}
