import MainHeaderDesktop from "./MainHeaderDesktop";
import { isMobile } from "../../utils/helpers";
import RegistrationSubHeader from "./RegistrationSubHeader.jsx";

const RegistrationHeader = ({ title, currentProgress, onBackButtonClickHandler, ariaLabel }) => {

  return <div id="registration-header-id" className="mobile-sticky">
      {!isMobile() ?
          <MainHeaderDesktop className="mobile-hidden"/>
          : <></>
      }
      <RegistrationSubHeader title={title} currentProgress={currentProgress} onBackButtonClickHandler={onBackButtonClickHandler} ariaLabel={ariaLabel}></RegistrationSubHeader>
    </div>
};

export default RegistrationHeader;
