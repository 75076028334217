import { Link } from "react-router-dom";
import MainHeaderDesktop from "./MainHeaderDesktop";
import { isMobile } from "../../utils/helpers";

const EditAOHeader = ({ title, currentProgress, onBackButtonClickHandler, ariaLabel }) => {

  const onClick = (e) => {
    e.preventDefault();
    onBackButtonClickHandler();
  };

  let newTitle = title?.includes("%noarrow%") ? title.replace("%noarrow%", "") : title;

  let hasArrow = !title?.includes("%noarrow%");

  return !isMobile() ? (
    <div className="mobile-sticky">
      <MainHeaderDesktop className="mobile-hidden" />
      <header className="register-header" role="navigation">
        <a
          className="sr-only"
          href="#main"
          onClick={(ev) => {
            ev.preventDefault();
            document.getElementById("main").scrollIntoView();
          }}
        >
          Zum Inhalt springen
        </a>
        {hasArrow && (
          <Link className="register-header__back-button" to="./" onClick={onClick} aria-label="Zurück" role="button">
            <img className="register-header__back-button__img" src="./images/common/headerback.png" alt="" />
          </Link>
        )}

        <h1 className="register-header__title" aria-label={ariaLabel} tabIndex="0">
          {newTitle}
        </h1>
      </header>
      {currentProgress.includes("no") ? (
        <> </>
      ) : (
        <div className="progress-bar">
          <div className="current-progress" style={{width: currentProgress}}/>
        </div>
      )}
    </div>
  ) : (
    <div className="mobile-sticky">
      <header className="register-header" role="navigation">
        <a
          className="sr-only"
          href="#main"
          onClick={(ev) => {
            ev.preventDefault();
            document.getElementById("main").scrollIntoView();
          }}
        >
          Zum Inhalt springen
        </a>
        {hasArrow && (
          <Link className="register-header__back-button" to="./" onClick={onClick} aria-label="Zurück" role="button">
            <img className="register-header__back-button__img" src="./images/common/headerback.png" alt="" />
          </Link>
        )}

        <h1 className="register-header__title" aria-label={ariaLabel} tabIndex="0">
          {newTitle}
        </h1>
      </header>
      {currentProgress.includes("no") ? (
        <> </>
      ) : (
        <div className="progress-bar">
          <div className="current-progress" style={{width: currentProgress}}/>
        </div>
      )}
    </div>
  );
};

export default EditAOHeader;
