import React, { useEffect, useState } from "react";
import TravelContext from "../../../context/TravelContext";
import ApplicationRoute from "../../../config/routes";
import {
  getEnumFromGender,
  getGenderFromEnum,
  getKeyByValue,
  getTravelGroupMemberLastIndex_Array,
} from "../../../utils/helpers";
import { useHistory } from "react-router-dom";
import Form from "../utils/Form";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import PageTitle from "../utils/PageTitle";
import HeaderContext from "../../../context/HeaderContext";
import WeiterButton from "../../registration/utils/WeiterButton";
import ControledRadioInput from "../../registration/utils/ControledRadioInput";
import MetaDecorator from "../../../utils/MetaDecorator";
import HorizontalRule from "../../../common/HorizontalRule";

const applicationPageTitles = require("../../../utils/pageTitles.json");
const formLabel = "Geschlecht eingeben.";

const genders = {
  male: "männlich",
  female: "weiblich",
  other: "divers",
};

const initSelectValue = {
  male: false,
  female: false,
  other: false,
};

const AddTravelGender = ({ text }) => {
  const { setHeader } = React.useContext(HeaderContext);
  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: "Neue Reise anlegen",
        progress: parseInt((14 / 19) * 100),
      };
    });
  }, []);

  const history = useHistory();
  const { travel, setTravel } = React.useContext(TravelContext);

  const groupMemberIndex = getTravelGroupMemberLastIndex_Array(travel);
  let initGender = travel?.TravelGroupMember[groupMemberIndex]?.Gender
    ? getGenderFromEnum(travel?.TravelGroupMember[groupMemberIndex]?.Gender)
    : "";

  const [selectedValue, setSelectedValue] = useState({
    ...initSelectValue,
    [initGender]: true,
  });

  const isValid = () => {
    return getKeyByValue(selectedValue, true);
  };

  const updateTravel = () => {
    const groupMemberIndex =
      travel.TravelGroupMember.length > 0
        ? travel.TravelGroupMember.length - 1
        : 0;
    let newArray = [...travel.TravelGroupMember];
    newArray[groupMemberIndex] = {
      ...newArray[groupMemberIndex],
      Gender: getEnumFromGender(getKeyByValue(selectedValue, true)),
    };

    setTravel((curr) => {
      return {
        ...curr,
        TravelGroupMember: newArray,
      };
    });
  };

  const onClickHandler = () => {
    updateTravel();
  };

  const onChangeHandler = (e) => {
    setSelectedValue(() => {
      return { ...initSelectValue, [e.target.id]: true };
    });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateTravel();
      history.push(ApplicationRoute.addTravelMobilePhone);
    }
  };

  return (
    <div className="registration-middle-container">
      <MetaDecorator title={applicationPageTitles.createTripPages} />
      <PageTitle id={formLabel} text="Bitte wählen Sie das Geschlecht aus:" />
      <p
        className="page-paragraph page-paragraph--small"
        aria-label="Mitreisende:r"
      >
        Mitreisende/r
      </p>
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form
        className="max-width-380"
        id="form"
        onSubmit={onSubmitHandler}
        ariaLabelledBy={formLabel}
      >
        <ControledRadioInput
          id="male"
          value="100000000"
          name="gender"
          textInfo={genders.male}
          checked={selectedValue.male}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.male}
        />
        <ControledRadioInput
          id="female"
          value="100000001"
          name="gender"
          textInfo={genders.female}
          checked={selectedValue.female}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.female}
        />
        <ControledRadioInput
          id="other"
          value="100000002"
          name="gender"
          textInfo={genders.other}
          checked={selectedValue.other}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.other}
        />

        <HiddenSubmitInput />
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.addTravelMobilePhone}
        onClick={onClickHandler}
        ariaLabel={text}
        className={"floating-weiter-buton"}
      />
    </div>
  );
};

export default AddTravelGender;
