import React, { useState, useEffect, useRef } from "react";
import MainButton from "../../../common/MainButton";
import HeaderContext from "../../../context/HeaderContext";
import ContentTitle from "../../registration/utils/ContentTitle";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import Cross from "../../../svg/Cross";
import FilteredSelectList from "../../foreign-registration/utils/FilteredSelectList";
import Input from "../../registration/utils/Input";
import InputCalendar from "../../travel-registration/utils/InputCalendar";
import { pluck } from "../../../utils/helpers";

const inputStyle = {
  backgroundColor: "rgb(240, 244, 245)",
};

const labelStyle = {
  backgroundColor: "rgb(240, 244, 245)",
};

const inputValidationContainerStyle = {
  marginBottom: "22px",
};

const AddRelative = ({ hidden, setHidden, onSubmit }) => {
  const { setHeader } = React.useContext(HeaderContext);

  const [atCity, setAtCity] = useState("");
  const [country, setCountry] = useState("");
  const [date, setDate] = useState(Object.assign({}, { stopStart: "", stopEnd: "" }));

  const [countries, setCountries] = useState([]);

  let startRef = useRef();

  useEffect(() => {
    setCountries(() => {
      try {
        return pluck(JSON.parse(localStorage.getItem("countries")), "Name").filter((c) => {
          if (c === "Österreich" || c === "Andere") return false;
          return true;
        });
      } catch (error) {
        return [];
      }
    });
  }, []);

  const update = () => {
    if (isValid()) {
      let newTravel = {
        Name: country,
        StopEnd: date.stopEnd,
        StopStart: date.stopStart,
        Comment: atCity,
      };

      onSubmit(newTravel);

      setHidden(true);
    }
  };
  const onClickHandler = () => {
    update();
  };

  useEffect(() => {
    if (hidden) {
      setAtCity("");
      setCountry("");
      setDate(() => {
        return { stopStart: "", stopEnd: "" };
      });
    } else {
      startRef?.current.focus();
    }
  }, [hidden]);

  const isValid = () => {
    return !!country && date.stopEnd && date.stopStart;
  };

  return (
    <div
      onKeyDown={(e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          if (isValid()) {
            onClickHandler();
          }
        }
      }}
      style={{ display: hidden ? "none" : "block" }}
      className="add-person__container registration-middle-container"
    >
      <Cross onClick={() => setHidden(true)} className="add-person__container__cross" color="black" />

      <div className="add-person__container__inputs-container">
        <ContentTitle style={{ marginTop: "76px", marginBottom: "41px" }} text="Destination hinzufügen" />
        <FilteredSelectList
          id={"AT-destination-country"}
          label="Bitte wählen Sie aus"
          state={country}
          onChangeHandler={setCountry}
          options={countries}
          className="grey-background"
          ref={startRef}
          isTravel={true}
        />
        <div>
          <Input
            labelText="Stadt"
            id={"ATCity"}
            onChange={(e) => {
              setAtCity(e.target.value);
            }}
            placeholder="Stadt eintragen (optional)"
            value={atCity}
            labelStyle={labelStyle}
          />
        </div>
        <div>
          <InputCalendar
            labelText={`Reisezeitraum  
               Destination`}
            id={"AT-reisezeitram"}
            placeholder="Reisezeitraum"
            startDate={date.stopStart}
            endDate={date.stopEnd}
            cLabelClass={"add-person__container__label-style"}
            noToggle={true}
            onChange={(startDate, endDate) =>
              setDate((curr) => {
                return { ...curr, stopStart: startDate, stopEnd: endDate };
              })
            }
          />
        </div>
        <div></div>
        <HiddenSubmitInput />
      </div>

      <MainButton
        type="button"
        ariaLabel={"einreichen"}
        disabled={!isValid()}
        onClick={onClickHandler}
        text="Speichern & Schließen"
        positioned="fixed"
        style={{ bottom: "5.6rem" }}
      />
    </div>
  );
};

export default AddRelative;
