import React, { useState, useEffect } from "react";
import apipath from "../../../apipath";
import HeaderContext from "../../../context/HeaderContext";
import MetaDecorator from "../../../utils/MetaDecorator";

function Bildquellen() {
  const { setHeader } = React.useContext(HeaderContext);
  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "content",
        title: "Bildquellen",
      };
    });
  }, []);
  const data = JSON.parse(localStorage.getItem("countries"));
  useEffect(() => {
    fetchData();
  }, []);
  const [bildquellen, setBildquellen] = useState([]);
  const fetchData = async () => {
    const response = await fetch(
      `${apipath.pathOrig}app-inhalte/impressum/verzeichnis-bildquellen-laenderfotos/type/json/`,
      {
        method: "GET",
      }
    );
    let bildquellen = await response.json();
    setBildquellen(bildquellen.data);
  };
  return (
    <div className="content-pages-container">
      <MetaDecorator title="Bildquellen" />

      {bildquellen.map((list, index) => {
        return (
          <div
            key={index}
            dangerouslySetInnerHTML={{ __html: list.bodytext }}
            className="content-pages-paragraph"
          ></div>
        );
      })}
    </div>
  );
}

export default Bildquellen;
