import React, { useEffect, useState } from "react";
import WeiterButton from "../utils/WeiterButton";
import ContentTitle from "../utils/ContentTitle";
import UserContext from "../../../context/UserContext";
import ApplicationRoute from "../../../config/routes";
import { useHistory } from "react-router-dom";
import Form from "../utils/Form";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import HeaderContext from "../../../context/HeaderContext";
import CustomSelectList from "../../foreign-registration/utils/CustomSelectList";
import MetaDecorator from "../../../utils/MetaDecorator";
import HorizontalRule from "../../../common/HorizontalRule";
import { GetBundeslandIdFromName} from "../../../utils/helpers";

const applicationPageTitles = require("../../../utils/pageTitles.json");

const countries = [
  "Burgenland",
  "Kärnten",
  "Niederösterreich",
  "Oberösterreich",
  "Salzburg",
  "Steiermark",
  "Tirol",
  "Vorarlberg",
  "Wien",
];

//HINT* NOT REGISTRATION
const LivingInPage = ({ text }) => {
  const history = useHistory();

  let init = "";
  try {
    const ls = JSON.parse(localStorage.getItem("registerUser"));
    init = ls.CountyHomeRegionName;
  } catch (error) {}

  const [livingIn, setLivingIn] = useState(init);

  const { user, setUser } = React.useContext(UserContext);
  const { setHeader } = React.useContext(HeaderContext);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((4 / 19) * 100),
        title: "Neue Reise anlegen",
      };
    });
  }, []);

  const isValid = () => {
    //return !!livingIn;
    return true;
  };

  const updateUser = () => {
    setUser((curr) => {
      return {
        ...curr,
        CountyHomeRegionName: livingIn,
        CountyHomeRegionId: GetBundeslandIdFromName(livingIn),
      };
    });
  };

  const onChangeHandler = (value) => {
    setLivingIn(value);
  };

  const onClickHandler = () => {
    updateUser();
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateUser();
      history.push(ApplicationRoute.registerGender);
    }
  };

  return (
    <>
      <MetaDecorator title={applicationPageTitles.createTripPages} />
      <div className="registration-middle-container">
        <ContentTitle id="contentTitle" text={"In welchem Bundesland haben Sie Ihren Wohnsitz? (optional)"} />
        <p id="helptext-bundesland">
          Falls Sie Auslandsösterreicher/in sind, bitte geben Sie das österreichische Bundesland ein, in dem Sie zuletzt
          Ihren Wohnsitz hatten.
        </p>
        <HorizontalRule className="horizontal-rule-desktop" />
        <Form className="max-width-380" id="form" onSubmit={onSubmitHandler} ariaLabelledBy="contentTitleId">
          <CustomSelectList
            id="selectListId"
            label="Bundesland auswählen"
            state={livingIn}
            onChangeHandler={onChangeHandler}
            options={countries}
            ariaLabelledBy="contentTitle"
            optional={true}
          />

          <HiddenSubmitInput />
        </Form>
        <HorizontalRule className="horizontal-rule-desktop" />
        <WeiterButton
          disabled={!isValid()}
          path={ApplicationRoute.registerGender}
          onClick={onClickHandler}
          ariaLabel={text}
          className={"floating-weiter-buton"}
        />
      </div>
    </>
  );
};

export default LivingInPage;
