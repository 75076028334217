const StopsSeparater = ({ id = "stopsSeparaterClear", text, onClick }) => {
  return (
    <div className="stops-separater-container">
      <h2>
        <div className="stops-separater-container__title">{text}</div>
      </h2>
      <button id={id} type="button" className="stops-separater-container__clear-link" onClick={onClick}>
        löschen
      </button>
    </div>
  );
};

export default StopsSeparater;
