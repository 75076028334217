import React, { useEffect } from "react";

const passwordIcons = {
  showed: "👁",
  hiden: "@",
};

const Input = ({
  id,
  value,
  onChange,
  onFocus,
  onBlur,
  placeholder,
  type = "text",
  labelText,
  ariaRequired = true,
  className,
}, ref) => {
  useEffect(() => {
    if (type === "password") {
      try {
        const input = document.getElementById(id);

        const showPasswordEl = document.getElementById(id + "-passwordToggle");
        showPasswordEl.addEventListener("click", () => {
          if (input.type === "text") {
            input.type = "password";
            showPasswordEl.innerText = passwordIcons.hiden;
          } else {
            input.type = "text";
            showPasswordEl.innerText = passwordIcons.showed;
          }
        });
      } catch {}
    }
  }, [id]);

  return (
    <div className={"custom-input__container" + " " + className}>
      <label
        className="custom-input__container__custom-label"
        htmlFor={id}
        style={{ display: value ? "inline-block" : "none" }}
      >
        {labelText}
      </label>
      <input
        id={id}
        className="custom-input__container__custom-input"
        value={value}
        type={type}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        ref={ref}
      />
      {type === "password" && (
        <i
          className="custom-input__container__show-password-ico"
          id={`${id}-passwordToggle`}
        >
          {passwordIcons.hiden}
        </i>
      )}
    </div>
  );
};

export default React.forwardRef(Input);
