import ApplicationRoute from "../../../config/routes";
import {Link} from "react-router-dom";
import WeiterButton from "../utils/WeiterButton.jsx";
import Input from "../utils/Input.jsx";
import Form from "../utils/Form.jsx";
import ApiRequestErrorMessage from "../../../common/ApiRequestErrorMessage.jsx";
import {apiRequestErrors} from "../../../utils/validationErrorMessages.js";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput.jsx";
import { useTranslation } from "react-i18next";

//HINT* REGISTRATION
const LoginTemplate = (
    {
        onSubmitHandler,
        email,
        setEmail,
        password,
        setPassword,
        noLoginCred,
        error,
        loading,
        submitting,
        background,
        formLabel,
        disableButton
    }
) => {
    const { t } = useTranslation()
    const onEmailChangeHandler = (e) => {
        setEmail(e.target.value);
    };

    const onPasswordChangeHandler = (e) => {
        setPassword(e.target.value);
    };

    const submittingNow = () => {
        return submitting && loading;
    };


    return (<>
        <Form id="form" ariaLabel={formLabel} ariaLabelledBy="contentTitle" onSubmit={onSubmitHandler}>
            <Input
                id="email"
                htmlFor="email"
                placeholder={t("terms.email_adresse")}
                label={t("terms.email_adresse")}
                type="text"
                labelText={t("terms.email_adresse")}
                onChange={onEmailChangeHandler}
                value={email}
                error={noLoginCred.email || error?.status === 401}
                labelStyle={!!background ? { backgroundColor: background } : null}
            />
            {(noLoginCred.email || noLoginCred.password || error?.status === 401) && (
                <ApiRequestErrorMessage
                    style={{marginTop: "-1.3rem", marginBottom: "1.6rem"}}
                    text={apiRequestErrors["401-login"]}
                />
            )}
            <Input
                id="password"
                htmlFor="password"
                placeholder={t("terms.passwort")}
                label={t("terms.passwort")}
                type="password"
                labelText={t("terms.passwort")}
                onChange={onPasswordChangeHandler}
                value={password}
                error={noLoginCred.password || error?.status === 401}
                labelStyle={!!background ? { backgroundColor: background } : null}
            />
            <HiddenSubmitInput />
            <WeiterButton
                disabled={disableButton || submittingNow()}
                onClick={onSubmitHandler}
                path={ApplicationRoute.TravelDashboardNoPermissions}
                positioned="static"
                linkId="loginLinkId"
                style={{marginBottom: "0px"}}
            />
        </Form>

        <Link
            to={ApplicationRoute.registerForgottenPassword}
            aria-label="Passwort wiederherstellen."
            style={{display: "block"}}
        >
            <div className="centered-link__container">
                <div className="centered-link__container__link">{t("text.passwort_vergessen")}</div>
            </div>
        </Link>
    </>);
};

export default LoginTemplate;