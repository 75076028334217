import { NavLink, useHistory, useParams } from "react-router-dom";
import MemoIconsTrip from "../svg/IconsTrip.jsx";
import IconsLNder from "../svg/IconsLNder.jsx";
import MemoIconsNotfalltipps from "../svg/IconsNotfalltipps.jsx";
import MemoIconsVertretungen from "../svg/IconsVertretungen.jsx";
import MemoInfoIcon from "../svg/IconInfoLogo.jsx";
import ApplicationRoute from "../config/routes.js";
import DesktopFooter from "../common/Footers/DesktopFooter";
import { getIsLogged, isMobileEnv } from "../utils/helpers";
import { ReactComponent as UrkundenIcon } from '../assets/icons/icon-bcms_urkundenservice.svg';

function Footer({ value, setValue, hidden, setHidden, valueC, setValueC, hiddenC, setHiddenC, color, path }) {
  const { id } = useParams();
  const history = useHistory();
  const isDotEnabled = process.env.REACT_APP_DOT_ENABLED === 'true';

  const navigate = (e) => {
    e.preventDefault();
    if (getIsLogged()) {
      history.push(ApplicationRoute.TravelDashboardNoPermissions);
    } else {
      if (history.location.pathname === "/") {
        window.scrollTo(0, document.body.scrollHeight);
      } else {
        history.push(ApplicationRoute.home);
      }
    }
  };

  const resetInputMapSearch = () => {
    setValue("");
    setHidden(true);
    setValueC("");
    setHiddenC(true);
  };

  const nav2 = (e) => {
    e.preventDefault();
    history.push(ApplicationRoute.countryList);
    resetInputMapSearch();
  };

  const nav3 = (e) => {
    e.preventDefault();
    history.push(ApplicationRoute.notfalltipps);
    resetInputMapSearch();
  };

  const nav4 = (e) => {
    e.preventDefault();
    history.push(ApplicationRoute.vertretungen);
    resetInputMapSearch();
  };

  const nav5 = (e) => {
    e.preventDefault();
    history.push(ApplicationRoute.dashboardInfos);
    resetInputMapSearch();
  };

  const nav6 = (e) => {
    e.preventDefault();
    history.push(ApplicationRoute.dot);
    resetInputMapSearch();
  };

  return isMobileEnv() ? (
    // history.location.pathname !== "/" &&
    <footer className="mobile__navbar app-footer" role="navigation" aria-label="App">
      <NavLink
        onClick={navigate}
        to={getIsLogged() ? ApplicationRoute.TravelDashboardNoPermissions : ApplicationRoute.home}
        exact
        activeClassName="active"
        aria-label="Öffne Auslandsregistrierung"
      >
        <MemoIconsTrip color={color[1]} className="navbar__icon" />
      </NavLink>
      <NavLink
        onClick={nav2}
        to={ApplicationRoute.countryList}
        exact
        activeClassName="active"
        aria-label="Öffne Länderinformationen"
      >
        <IconsLNder color={color[2]} className="navbar__icon" />
      </NavLink>
      <NavLink
        onClick={nav4}
        to={ApplicationRoute.vertretungen}
        exact
        activeClassName="active"
        aria-label="Öffne Karte mit Vertretungen"
      >
        <MemoIconsVertretungen color={color[4]} className="navbar__icon" />
      </NavLink>
      <NavLink
        onClick={nav3}
        to={ApplicationRoute.notfalltipps}
        exact
        activeClassName="active"
        aria-label="Öffne Notfalltipps"
      >
        <MemoIconsNotfalltipps color={color[3]} className="navbar__icon" />
      </NavLink>
      <NavLink
        onClick={nav5}
        to={ApplicationRoute.dashboardInfos}
        exact
        activeClassName="active"
        aria-label="Öffne Informationen"
      >
        <MemoInfoIcon color={color[5]} className="navbar__icon" />
      </NavLink>
      { isDotEnabled && <NavLink
        onClick={nav6}
        to={ApplicationRoute.dot}
        exact
        activeClassName="active"
        aria-label="Öffne Urkundenbestellung"
      >
        <UrkundenIcon color={color[6]} className="navbar__icon" />
      </NavLink> }
    </footer>
  ) : (
    <>
      {/* {isMobile() && <div style={{ height: "64px" }} />} */}
      <div className="space-fix" />

      <DesktopFooter path={path} style={{ marginTop: "200px" }} />
    </>
  );
}

export default Footer;
