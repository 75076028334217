import React from "react";
import ArrowRight from "../../../svg/ArrowRight.js";

function NavigationPattern({ id, text, to }) {
  return (
    <div>
      <div className="navigation-info-cards" key={id}>
        <div className="country__name">{text}</div>

        <ArrowRight to={to} />
      </div>
    </div>
  );
}

export default NavigationPattern;
