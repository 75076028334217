import React, { useEffect, useState } from "react";
import ApplicationRoute from "../../../config/routes";
import { useHistory } from "react-router-dom";
import Form from "../utils/Form";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import { isPhoneValidation } from "../../../utils/validation";
import HeaderContext from "../../../context/HeaderContext";
import { registrationValidationErrorMessages } from "../../../utils/validationErrorMessages";
import InputValidation from "../../../common/InputValidaiton";
import { getTravelGroupMemberLastIndex_Array, phoneFormatter } from "../../../utils/helpers";
import Input from "../../registration/utils/Input";
import TravelContext from "../../../context/TravelContext";
import WeiterButton from "../../registration/utils/WeiterButton";
import PageTitle from "../utils/PageTitle";
import PageDescription from "../utils/Description";
import MetaDecorator from "../../../utils/MetaDecorator";
import HorizontalRule from "../../../common/HorizontalRule";

const applicationPageTitles = require("../../../utils/pageTitles.json");
const AddTravelMobilePhone = ({ text }) => {
  const history = useHistory();

  const { setHeader } = React.useContext(HeaderContext);
  const { travel, setTravel } = React.useContext(TravelContext);

  const groupMemberIndex = getTravelGroupMemberLastIndex_Array(travel);
  let initMobilePhone = travel?.TravelGroupMember[groupMemberIndex]?.MobilePhone;

  const [phoneNumber, setPhoneNumber] = useState(initMobilePhone ? initMobilePhone : "");

  const activate = () => {
    setActive(() => true);
  };

  const [active, setActive] = useState(false);

  useEffect(() => {
    document.getElementsByTagName("HTML")[0].addEventListener("click", activate);

    return () => document.getElementsByTagName("HTML")[0].removeEventListener("click", activate);
  }, []);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: "Neue Reise anlegen",
        progress: parseInt((15 / 19) * 100),
      };
    });
  }, []);

  const isValid = () => {
    return isPhoneValidation(phoneNumber, true);
  };

  const updateTravel = () => {
    const groupMemberIndex = travel.TravelGroupMember.length > 0 ? travel.TravelGroupMember.length - 1 : 0;
    let newArray = [...travel.TravelGroupMember];
    newArray[groupMemberIndex] = {
      ...newArray[groupMemberIndex],
      MobilePhone: phoneNumber,
    };

    setTravel((curr) => {
      return {
        ...curr,
        TravelGroupMember: newArray,
      };
    });
  };

  const onChangeHandler = (e) => {
    let phone = phoneFormatter(phoneNumber, e.target.value);

    setPhoneNumber(phone);
  };

  const onClickHandler = () => {
    updateTravel();

    history.push(ApplicationRoute.addTravelEmail);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateTravel();
      history.push(ApplicationRoute.addTravelEmail);
    }
  };

  return (
    <div className="registration-middle-container">
      <MetaDecorator title={applicationPageTitles.createTripPages} />
      <PageTitle id="contactperson-phone" text="Bitte geben Sie die Telefonnummer ein:" />

      <p className="page-paragraph page-paragraph--small">Mitreisende/r</p>
      <PageDescription text="Bitte geben Sie entweder die Mobiltelefonnummer oder die E-Mail-Adresse der mitreisenden Person an – falls vorhanden. Es ist wichtig, dass wir die Person im Notfall kontaktieren können." />
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form className="max-width-380" id="form" onSubmit={onSubmitHandler} ariaLabelledBy="contactperson-phone">
        <Input
          id="phoneNumber"
          name="phoneNumber"
          htmlFor="phoneNumber"
          value={phoneNumber}
          onChange={onChangeHandler}
          placeholder="Mobiltelefonnummer"
          type="tel"
          labelText="Mobiltelefonnummer (inkl. Ländervorwahl)"
          labelStyle={{ display: "inline-block" }}
          showValidationBorder={active && !isValid()}
        />

        <InputValidation
          isHidden={!active || isValid()}
          text={registrationValidationErrorMessages.notValidPhoneNumber}
        />

        <HiddenSubmitInput />
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />

      <div className="hide-weiter-on-keyboard-content"> </div>
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.addTravelEmail}
        onClick={onClickHandler}
        ariaLabel={text}
        className="hide-weiter-on-keyboard floating-weiter-buton"
      />
    </div>
  );
};

export default AddTravelMobilePhone;
