import { useEffect, useState } from "react/cjs/react.development";
import apipath from "../apipath";
import ApplicationRoute from "../config/routes";
import fetcher from "../services/fetcher";
import { setIsLoggedFalse} from "../utils/helpers";
import WeiterButton from "./registration/utils/WeiterButton";
import Loader from "./travel-registration/utils/Loader";

const RedirectPage = () => {
  const [logingout, setLogingout] = useState(null);
  const [logingoutError, setLogingoutError] = useState(null);
  const [logingoutLoading, setLogingoutLoading] = useState(true);
  const [submttingLogingout, setSubmttingLogingout] = useState(false);
  const logOut = () => {
    fetcher(
      `${apipath.path}logout`,
      "POST",
      setLogingout,
      setLogingoutError,
      setLogingoutLoading,
      setSubmttingLogingout,
      false,
      "application/json",
      "",
      false,
      true
    );
  };

  useEffect(() => {
    setIsLoggedFalse();
    logOut();
  }, []);

  if (logingoutLoading && setSubmttingLogingout) return <Loader />;

  return (
    <>
      {" "}
      <h1> You have been auto logged out</h1>
      <WeiterButton
        path={ApplicationRoute.home}
        ariaLabel={`Senden und gehen Sie zu ${ApplicationRoute.home}`}
        positioned="fixed"
        text="Zurück zum Login"
        style={{ marginTop: "4rem" }}
      />{" "}
    </>
  );
};

export default RedirectPage;
