import React from "react";
import DayPicker from "react-day-picker";
import dateAndTime from "date-and-time";
import "react-day-picker/lib/style.css";

const MONTHS = [
  "Jänner",
  "Februar",
  "März",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Dezember",
];
const WEEKDAYS_LONG = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];
const WEEKDAYS_SHORT = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];

export default class DateModalDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: props.startDate,
      endDate: props.endDate,
      dayClick: 0,
      selectedStartDate: props.startDate,
      selectedEndDate: props.endDate,
      startRef: React.createRef(),
    };
    this.close = () => {
      this.props.close();
    };

    this.dayClickHandler = (day, modifiers, e) => {
      if (!modifiers.disabled) {
        if (this.state.dayClick === 0) {
          this.setState({
            dayClick: ++this.state.dayClick,
            selectedStartDate: day,
            selectedEndDate: null,
          });
        } else if (this.state.dayClick === 1) {
          let stateObj = {
            dayClick: 0,
            selectedEndDate: day,
          };

          if (day < this.state.selectedStartDate) {
            stateObj.selectedEndDate = this.state.selectedStartDate;
            stateObj.selectedStartDate = day;
          }

          this.setState(stateObj);
        }
      }
    };
  }

  componentDidMount() {
    this.state.startRef.current.focus();
  }

  render() {
    let modifiers = {
      start: this.state.selectedStartDate,
      end: this.state.selectedEndDate,
      inBetween: [],
    };

    if (
      this.state.selectedEndDate &&
      dateAndTime.format(this.state.selectedStartDate, "DD.MM.YYYY") ==
        dateAndTime.format(this.state.selectedEndDate, "DD.MM.YYYY")
    ) {
      modifiers.start = null;
      modifiers.end = null;
      modifiers.noHighlighting = this.state.selectedStartDate;
    }

    if (this.state.selectedEndDate) {
      let startDay = this.state.selectedStartDate;

      let daysCount = Math.abs(dateAndTime.subtract(this.state.selectedStartDate, this.state.selectedEndDate).toDays());

      for (let i = 0; i < daysCount - 1; i++) {
        startDay = dateAndTime.addDays(startDay, 1);
        modifiers.inBetween.push(startDay);
      }
    }

    let heading = " ";
    if (this.state.selectedStartDate)
      heading = `${dateAndTime.format(this.state.selectedStartDate, "DD.MM.")} - ${
        this.state.selectedEndDate ? dateAndTime.format(this.state.selectedEndDate, "DD.MM.") : ""
      }`;

    let disabledDays = {
      // before: new Date(),
    };

    // if (this.props.range) {
    //   if (this.props.range.startDate)
    //     disabledDays.before = this.props.range.startDate;
    //   if (this.props.range.endDate)
    //     disabledDays.after = this.props.range.endDate;
    // }

    return (
      <section id="date-modal" className="date-modal">
        <header className="date-modal__header">
          <button className="date-modal__close-button" onClick={this.close} type="button" ref={this.state.startRef}>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
              <g fill="none" fillRule="evenodd">
                <g fill="#FFF">
                  <g>
                    <path
                      d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                      transform="translate(-24 -21) translate(19 16)"
                    />
                  </g>
                </g>
              </g>
            </svg>
            {/* <img className="cls-button-img" src="./images/common/closepng.png" alt="" /> */}
          </button>
          <button
            aria-label="Speichern"
            className="date-modal__save-button"
            type="button"
            onClick={() =>
              this.props.sendData(
                this.state.selectedStartDate,
                this.state.selectedEndDate == null ? this.state.selectedStartDate : this.state.selectedEndDate
              )
            }
          >
            Speichern
          </button>
          <div className="date-modal__header-title-wrapper">
            <p className="date-modal__label">Reisezeitraum</p>
            <h3 className="date-modal__heading">{heading}</h3>
          </div>
        </header>
        <article className="date-modal__body">
          <DayPicker
            locale="de"
            months={MONTHS}
            weekdaysLong={WEEKDAYS_LONG}
            weekdaysShort={WEEKDAYS_SHORT}
            firstDayOfWeek={0}
            numberOfMonths={2}
            selectedDays={[this.state.selectedStartDate, this.state.selectedEndDate]}
            modifiers={modifiers}
            disabledDays={disabledDays}
            onDayClick={this.dayClickHandler}
          />
        </article>
      </section>
    );
  }
}
