import React, { useEffect, useState} from "react";
import WeiterButton from "../registration/utils/WeiterButton";
import { useHistory } from "react-router-dom";
import ApplicationRoute from "../../config/routes";
import PageTitle from "./utils/PageTitle";
import PageParagraph from "./utils/Paragraph";
import AddStopButton from "./utils/AddStopButton";
import HeaderContext from "../../context/HeaderContext";
import TravelContext from "../../context/TravelContext";
import Input from "./utils/Input";
import { convertAppDateToMiddlewareDateFormat, getIsLogged, getUIID, isMobile, pluck } from "../../utils/helpers";
import { TravelStops } from "../../config/defaultTravelValues";
import InputCalendar from "./utils/InputCalendar";
import dateAndTime from "date-and-time";
import getCountries from "./utils/GetCountries";
import FilteredSelectList from "../foreign-registration/utils/FilteredSelectList";
import MetaDecorator from "../../utils/MetaDecorator";
import HorizontalRule from "../../common/HorizontalRule";
import InputValidation from "../../common/InputValidaiton";
import { registrationValidationErrorMessages } from "../../utils/validationErrorMessages";
import SuccessContext from "../../context/SuccessContext";

const applicationPageTitles = require("../../utils/pageTitles.json");

const numbering = [
  "Erste",
  "Zweite",
  "Dritte",
  "Vierte",
  "Fünfte",
  "Sechste",
  "Siebte",
  "Achte",
  "Neunte",
  "Zehnte",
  "Elfte",
  "Zwölfte",
];

const AddTravel = ({ text }) => {
  const { travel, setTravel } = React.useContext(TravelContext);
  const { setHeader } = React.useContext(HeaderContext);
  const { setSuccessBox } = React.useContext(SuccessContext);

  let [destinations, setDestinations] = useState([]);
  const [countriesFetched, setCountriesFetched] = useState(false);
  const [countries, setCountries] = useState([]);

  const [updateRef, setUpdateRef] = useState(false);
  let ref = React.useRef();

  useEffect(() => {
    setDestinations(() => {
      return travel.TravelStops[0].StopStart
        ? travel.TravelStops.map((el) => {
            el.StopStart = new Date(el.StopStart);
            el.StopEnd = new Date(el.StopEnd);
            return el;
          })
        : travel.TravelStops;
    });
  }, []);
  if (!countriesFetched)
    getCountries().then((data) => {
      setCountriesFetched(true);
      setCountries(
        pluck(data, "Name").filter((c) => {
          if (c === "Österreich" || c === "Andere") return false;
          return true;
        })
      );
    });

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: "Neue Reise anlegen",
        progress: parseInt((1 / 19) * 100),
      };
    });
  }, []);

  const history = useHistory();
  if (!getIsLogged()) {
    history.push(ApplicationRoute.home);
  }

  function alertMessage(hidden, text) {
    setSuccessBox((curr) => {
      return {
        ...curr,
        hidden,
        text,
        trigger: getUIID(),
        alert: true,
      };
    });
  }

  // Add new Destination
  const addDestination = () => {
    setUpdateRef(true);
    setDestinations([...destinations, Object.assign({}, TravelStops)]);
  };

  React.useEffect(() => {
    if (updateRef) {
      setUpdateRef(false);
      ref.current.focus();
    }
  });

  const deleteDestination = (targetIndex) => {
    if (targetIndex > 0) {
      destinations = destinations.filter((destination, index) => {
        return index !== targetIndex;
      });
      setDestinations([...destinations]);
    }
  };

  const destinationCountryChangeHandler = (index, value) => {
    destinations[index].CountryId = value;
    destinations[index].Country = value;
    setDestinations([...destinations]);
  };

  const destionationCityChangeHandler = (index, e) => {
    destinations[index].Comment = e.target.value;
    setDestinations([...destinations]);
  };

  const saveTravelData = (e) => {
    // e.preventDefault();
    history.push(ApplicationRoute.registerName);

    //Set addTravel in localStorage

    let travelCopy = Object.assign({}, travel);

    travelCopy.StartDate = convertAppDateToMiddlewareDateFormat(
      dateAndTime.format(destinations[0].StopStart, "DD.MM.YYYY")
    );
    travelCopy.EndDate = convertAppDateToMiddlewareDateFormat(
      dateAndTime.format(destinations[destinations.length - 1].StopEnd, "DD.MM.YYYY")
    );

    travelCopy.TravelStops = [];

    destinations.forEach((destination, index) => {
      let travelStopsCopy = Object.assign({}, TravelStops);

      travelStopsCopy.CountryId = destination.CountryId;
      travelStopsCopy.Country = destination.Country;
      travelStopsCopy.Name = destination.Country;
      travelStopsCopy.StopStart = convertAppDateToMiddlewareDateFormat(
        dateAndTime.format(destination.StopStart, "DD.MM.YYYY")
      );
      travelStopsCopy.StopEnd = convertAppDateToMiddlewareDateFormat(
        dateAndTime.format(destination.StopEnd, "DD.MM.YYYY")
      );
      travelStopsCopy.Comment = destination.Comment;

      travelCopy.TravelStops.push(travelStopsCopy);
    });
    setTravel(travelCopy);
  };

  const validator = () => {
    let bool = true;
    if (destinations.length && bool) {
      for (let i = 0; i < destinations.length; i++) {
        if (
          destinations[i].CountryId === "" ||
          destinations[i].StopStart === null ||
          !destinations[i].CountryId ||
          !destinations[i].StopStart
        ) {
          bool = false;
          break;
        }
      }
    }

    return bool;
  };

  function destinationsOverlapsing(destinationsArr) {
    destinationsArr.forEach((element) => (element.overlapsing = false));

    let showAlertMessage = false;

    for (let i = 0; i < destinationsArr.length; i++) {
      for (let j = i + 1; j < destinationsArr.length; j++) {
        let start1 = new Date(destinationsArr[i].StopStart);
        let start2 = new Date(destinationsArr[j].StopStart);
        let end1 = new Date(destinationsArr[i].StopEnd);
        let end2 = new Date(destinationsArr[j].StopEnd);

        // if (
        //   (end1 > start2 && end1 < end2) ||
        //   (end2 > start1 && end2 < end1) ||
        //   (start1 > start2 && start1 < end2 && end1 > end2) ||
        //   (start2 > start1 && start2 < end1 && end2 > end1)
        // ) {
        //   destinationsArr[i].overlapsing = true;
        //   destinationsArr[j].overlapsing = true;

        //   showAlertMessage = true;
        // }

        if (end1 > start2 && ((start1 > start2 && start1 < end2) || (end1 > start2 && end1 < end2))) {
          destinationsArr[i].overlapsing = true;
          destinationsArr[j].overlapsing = true;

          showAlertMessage = true;
        } else if (end2 > start1 && ((start2 > start1 && start2 < end1) || (end2 > start1 && end2 < end1))) {
          destinationsArr[i].overlapsing = true;
          destinationsArr[j].overlapsing = true;

          showAlertMessage = true;
        } else if (
          start1 < end1 &&
          start2 < end2 &&
          start1.toString() === start2.toString() &&
          end1.toString() === end2.toString()
        ) {
          destinationsArr[i].overlapsing = true;
          destinationsArr[j].overlapsing = true;

          showAlertMessage = true;
        }
      }
    }

    if (showAlertMessage) {
      alertMessage(false, "Bitte passen Sie Ihren Reisezeitraum an, um Überschneidungen zu vermeiden.%alert%");
    } else {
      // setSuccessBox((curr) => {
      //   return {
      //     ...curr,
      //     hidden: false,
      //     text: "Destination wurde hinzugefügt",
      //     trigger: getUIID(),
      //   };
      // });
    }

    return destinationsArr;
  }

  const receiveDataForDestination = (index, StopStart, StopEnd) => {
    StopStart.setHours(0, 0, 0);
    StopEnd.setHours(0, 0, 0);

    let destinationsArr = [...destinations];
    destinationsArr[index].StopStart = StopStart;
    destinationsArr[index].StopEnd = StopEnd;

    setDestinations(destinationsOverlapsing(destinationsArr));
  };

  function formattingForHeading (index){
    return (index < 12 ? numbering[index] : index + 1 + ".") + " Destination";
  }

  return (
    <>
      <section role="group" className="registration-middle-container add-travel" aria-describedby="add-travel">
        <MetaDecorator title={applicationPageTitles.createTripPages} />
        <PageTitle id="add-travel" text="Wie lauten Ihre Reisedaten?" />
        <PageParagraph text="Falls Sie durch mehrere Länder oder Gebiete reisen, fügen Sie bitte auch die weiteren Destinationen hinzu." />
        <HorizontalRule className="horizontal-rule-desktop" />
        <form className="max-width-380">
          <div className="destination-container">
            {destinations.length > 0
              ? destinations.map((destination, index) => {
                  return (
                    <div className="destination-group" id={index + 1 + "-destination"} key={index + 1 + "-destination"}>
                      <h3 className="destination-group__heading">
                        {formattingForHeading(index)}
                      </h3>
                      {index == 0 ? null : (
                        <button
                          className="destination-group__delete-btn"
                          type="button"
                          onClick={(e) => deleteDestination(index)}
                        >
                          Destination löschen
                        </button>
                      )}
                      <FilteredSelectList
                        id={"destination-country-" + (index + 1)}
                        ariaLabelledBy="add-travel"
                        label="Bitte wählen Sie aus"
                        state={destination.Country}
                        onChangeHandler={(value) => destinationCountryChangeHandler(index, value)}
                        options={countries}
                        className={isMobile() ? "grey-background" : ""}
                        ref={ref}
                        isTravel={true}
                        ariaLabelType="countries"
                      />

                      <Input
                        labelText="Stadt"
                        id={index + 1 + "-stadt"}
                        onChange={(e) => destionationCityChangeHandler(index, e)}
                        placeholder="Stadt eintragen (optional)"
                        value={destination.Comment}
                      />
                      <InputCalendar
                        labelText={`Reisezeitraum ${
                          index < 12 ? numbering[index].toLowerCase() : index + 1 + "."
                        } Destination`}
                        id={index + 1 + "-reisezeitram"}
                        placeholder="Reisezeitraum"
                        startDate={destination.StopStart}
                        endDate={destination.StopEnd}
                        onChange={(startDate, endDate) => receiveDataForDestination(index, startDate, endDate)}
                        error={destination.overlapsing}
                      />
                      <InputValidation
                        text={registrationValidationErrorMessages.datesAreOverlapsing}
                        isHidden={!destination.overlapsing}
                      />
                    </div>
                  );
                })
              : null}
          </div>

          <AddStopButton text="Weitere Destination" target={addDestination} />
        </form>
        <HorizontalRule className="horizontal-rule-desktop" />
        <WeiterButton
          disabled={!validator()}
          path={ApplicationRoute.registerName}
          onClick={saveTravelData}
          positioned="static"
          ariaLabel={text}
          style={{ marginTop: "5rem" }}
          className="floating-weiter-buton"
          classFix="yes"
        />
      </section>
    </>
  );
};

export default AddTravel;
