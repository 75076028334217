// Options to set for the button
//
//positioned => fixed/static
//color => red/transperant => setting the background and color of the button

const MainButton = ({
  disabled,
  ariaLabel,
  onClick,
  buttonId = "buttonId",
  text = "Weiter",
  ariaLabelledBy,
  positioned = "fixed",
  color = "red",
  style,
  img,
  className,
  type,
  dissClass = "",
}) => {
  const onKeyPressHandler = (e) => {
    if (disabled) {
      e.preventDefault();
    }
  };

  return (
    <div
      style={style}
      className={`weiter-button-wrapper ${
        positioned === "fixed" ? "weiter-button-wrapper--fixed" : "weiter-button-wrapper--static"
      } ${className ?? className}`}
      onKeyDown={onKeyPressHandler}
    >
      <div className={`weiter-button-container`}>
        <button
          type={type}
          id={buttonId}
          onClick={onClick}
          style={disabled ? { pointerEvents: "none" } : { pointerEvents: "auto" }}
          className={`weiter-button  ${disabled ? dissClass : " "}  ${
            color === "transperant"
              ? "weiter-button--transperant"
              : disabled
              ? "weiter-button--red--disabled"
              : "weiter-button--red--enabled"
          }`}
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledBy}
          aria-disabled={disabled}
        >
          {img ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="16"
              viewBox="0 0 17 16"
              className="weiter-button-dynamic-img"
            >
              <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g>
                  <g transform="translate(-112 -347) translate(112.5 347)">
                    <g>
                      <path
                        stroke="#9e0529"
                        d="M15.902 14.769L0 14.769 7.951 0zM7.951 4.265L7.951 9.089M7.951 11.36L7.951 12.498"
                        transform="translate(0 .615)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          ) : (
            <> </>
          )}{" "}
          {text}
        </button>
      </div>
    </div>
  );
};

export default MainButton;
