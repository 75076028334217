import React, {useState, useEffect, useCallback} from "react";
import ApplicationRoute from "../../../config/routes";
import {useHistory} from "react-router-dom";
import HeaderContext from "../../../context/HeaderContext";
import fetcher from "../../../services/fetcher";
import apipath from "../../../apipath";
import {setApiUserObject} from "../../../utils/helpers";
import UserContext from "../../../context/UserContext";
import DataConfirmationForm from "../utils/DataConfirmationForm";

const applicationPageTitles = require("../../../utils/pageTitles.json");
//HINT* REGISTRATION
const ConfirmationPage = ({text}) => {
  const history = useHistory();
  const [checked, setChecked] = useState(false);

  const {user} = React.useContext(UserContext);

  const {setHeader} = React.useContext(HeaderContext);

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const registerUser = () => {
    fetcher(
        `${apipath.path}users/register`,
        "POST",
        setData,
        setError,
        setLoading,
        setSubmitting,
        false,
        "application/json",
        JSON.stringify(setApiUserObject(user))
    );
  };

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((4 / 6) * 100),
        title: "Registrierung",
        ariaLabel: "Registrierung step 4 of 6",
      };
    });
  }, [setHeader]);


  const submittedWithNoErrors = useCallback(
      () => {
        return !loading && !error;
      }, [loading, error]);

  //After submit
  useEffect(() => {
    if (submittedWithNoErrors()) {
      history.push(ApplicationRoute.registerSentEmail);
    }
  }, [loading, error, data, history, submittedWithNoErrors]);

  //After submit and changes user state
  useEffect(() => {
    if (submittedWithNoErrors()) {
      history.push(ApplicationRoute.registerSentEmail);
    }
  }, [user, history, submittedWithNoErrors]);

  const submittingNow = () => {
    return submitting && loading;
  };

  const onChangeHandler = () => {
    setChecked(!checked);
  };

  const onClickHandler = (e) => {
    e.preventDefault();

    registerUser();
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    registerUser();
  };

  return (
      <DataConfirmationForm text={text} onChangeHandler={onChangeHandler} onClickHandler={onClickHandler}
                            submittingNow={submittingNow} checked={checked} onSubmitHandler={onSubmitHandler}
                            pageTitle={applicationPageTitles.registrationPages}
                            route={ApplicationRoute.registerVerifiedEmail}/>
  );
};

export default ConfirmationPage;
