import React, {useEffect} from "react";
import ApplicationRoute from "../../../config/routes";
import {useHistory} from "react-router-dom";
import HeaderContext from "../../../context/HeaderContext";
import MetaDecorator from "../../../utils/MetaDecorator.jsx";
import WeiterButton from "../utils/WeiterButton.jsx";
import HorizontalRule from "../../../common/HorizontalRule.jsx";

const applicationPageTitles = require("../../../utils/pageTitles.json");
//HINT* REGISTRATION
const IdaLinkManualPage = () => {
    const history = useHistory();

    const {setHeader} = React.useContext(HeaderContext);

    useEffect(() => {
        setHeader((curr) => {
            return {
                ...curr,
                inUse: "registration",
                progress: (2 / 5) * 100,
                title: applicationPageTitles.linkAccountPage + "%noarrow%",
                ariaLabel: "Bestehenden Account manuell verknüpfen",
            };
        });
    }, [setHeader]);

    const onSkipHandler = (e) => {
        e.preventDefault();

        history.push(ApplicationRoute.idaEmailPage)
    };

    const onLinkHandler = (e) => {
        e.preventDefault();

        history.push(ApplicationRoute.linkAccountLogin)
    };

    return (
        <div className="registration-middle-container">
            <MetaDecorator title={applicationPageTitles.linkAccountPage}/>
            <p className="content-title" style={{marginBottom: "3.2rem"}}>
                Sie können ihr Konto manuell mit einem
                bestehenden Account verknüpfen.
            </p>
            <HorizontalRule className="horizontal-rule-desktop"/>
            <div style={{marginBottom: "1.6rem"}}>
                <p>Dazu müssen Sie sich nachfolgend mit dem bestehenden Account einloggen.
                </p>
            </div>
            <div style={{marginBottom: "1.6rem"}}>
                <p>
                    Wenn Sie diesen Punkt überspringen kann nachträglich kein Account mehr verknüpft werden.
                </p>
            </div>
            <HorizontalRule className="horizontal-rule-desktop"/>
            <WeiterButton
                path={ApplicationRoute.idaEmailPage}
                text="Verknüpfung überspringen"
                className="second-button"
                onClick={onSkipHandler}
                color="transperant"
            />
            <WeiterButton
                text="Account jetzt verknüpfen"
                ariaLabel="Account jetzt verknüpfen"
                onClick={onLinkHandler}
                path={ApplicationRoute.linkAccountLogin}
                className=""
            />
        </div>
    );
};

export default IdaLinkManualPage;