import {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router";
import apipath from "../../apipath";
import InputValidation from "../../common/InputValidaiton";
import ApplicationRoute from "../../config/routes";
import HeaderContext from "../../context/HeaderContext";
import UserContext from "../../context/UserContext";
import fetcher from "../../services/fetcher";
import {
    isPasswordValid,
    arePasswordsValid,
} from "../../utils/validation";
import {registrationValidationErrorMessages} from "../../utils/validationErrorMessages";
import ContentTitle from "../registration/utils/ContentTitle";
import Form from "../registration/utils/Form";
import HiddenSubmitInput from "../../common/HiddenSubmitInput";
import Info from "../registration/utils/Info";
import Input from "../registration/utils/Input";
import ValidationChecks from "../registration/utils/ValidationChecks.jsx";
import ValidationRow from "../registration/utils/ValidationRow";
import WeiterButton from "../registration/utils/WeiterButton";
import MetaDecorator from "../../utils/MetaDecorator";
import SuccessContext from "../../context/SuccessContext";
import {getUIID} from "../../utils/helpers";
import HorizontalRule from "../../common/HorizontalRule";

const applicationPageTitles = require("../../utils/pageTitles.json");
//HINT* REGISTRATION
const ChangePassword = () => {
    const history = useHistory();
    const [currentPassword, setCurrentPassword] = useState("");
    const {setSuccessBox} = useContext(SuccessContext);

    const [passwords, setPasswords] = useState({
        password: "",
        confirmPassword: "",
    });

    const {user} = useContext(UserContext);
    const {setHeader} = useContext(HeaderContext);

    const [updateProfileError, setUpdateProfileError] = useState(null);
    const [updateProfileLoading, setUpdateProfileLoading] = useState(true);

    const updatePassword = () => {
        fetcher(
            `${apipath.path}users/CURRENT/updatepasswordvialogin `,
            "POST",
            () => {},
            setUpdateProfileError,
            setUpdateProfileLoading,
            () => {},
            false,
            "application/json",
            JSON.stringify({
                OldPassword: currentPassword,
                NewPassword: passwords.password,
                Email: user.Email,
            }),
            false
        );
    };

    useEffect(() => {
        setHeader((curr) => {
            return {
                ...curr,
                inUse: "registration",
                progress: "no",
                title: "Passwort ändern",
            };
        });
    }, []);

    const isValid = () => {
        return (
            isPasswordValid(currentPassword) &&
            arePasswordsValid(passwords)
        );
    };

    const onChangeHandler = (e) => {
        setPasswords((curr) => {
            return {...curr, [e.target.id]: e.target.value};
        });
    };

    const onCurrentPasswordChange = (e) => {
        setCurrentPassword(e.target.value);
    };

    const onClickHandler = (e) => {
        e.preventDefault();
        updatePassword();
    };

    const onSubmitHandler = (e) => {
        e.preventDefault();

        if (isValid()) {
            updatePassword();
        }
    };

    useEffect(() => {
        if (!updateProfileLoading && !updateProfileError) {
            setSuccessBox((curr) => {
                return {
                    ...curr,
                    hidden: false,
                    text: "Ihr Passwort wurde geändert.",
                    trigger: getUIID(),
                };
            });

            history.push(ApplicationRoute.editProfileSecond);
        }
    }, [updateProfileLoading, updateProfileError]);

    useEffect(() => {
        setPasswords((curr) => {
            let psw = user.Password ? user.Password : "";
            return {...curr, password: psw, confirmPassword: psw};
        });
    }, [user]);

    return (
        <section className="registration-middle-container">
            <MetaDecorator title={applicationPageTitles.forgotenPassword}/>
            <ContentTitle id="change-password" style={{marginBottom: "32px"}} text="Passwort ändern"/>
            <HorizontalRule className="horizontal-rule-desktop"/>
            <div className="mobile-hidden half-col " style={{float: "right"}}>
                <Info
                    style={{marginBottom: "16px", marginRight: "0"}}
                    text="Ihr neues Passwort sollte diese Vorgaben erfüllen:"
                />

                <div id="hint" style={{marginBottom: "32px"}} role="alert" aria-relevant="all">
                    <ValidationChecks passwords={passwords}></ValidationChecks>

                    <ValidationRow
                        isValid={passwords.password.length > 0 && passwords.confirmPassword === passwords.password}
                        validationText={registrationValidationErrorMessages.theNewPasswordWasEnterIdenticallyTwice}
                    />
                </div>
            </div>
            <Form id="form" onSubmit={onSubmitHandler} ariaLabelledBy="change-password" className="max-width-380">
                <Input
                    id="currentPassword"
                    value={currentPassword}
                    onChange={onCurrentPasswordChange}
                    placeholder="Ihr derzeitiges Passwort"
                    type="password"
                    labelText="Ihr derzeitiges Passwort"
                    showValidationBorder={!isPasswordValid(currentPassword)}
                />

                <InputValidation text="Falsches Passwort" isHidden={!updateProfileError}/>

                <Input
                    id="password"
                    value={passwords.password}
                    onChange={onChangeHandler}
                    placeholder="Ihr neues Passwort"
                    type="password"
                    text="Passwort"
                    labelText="Ihr neues Passwort"
                    ariaDescribedBy="hint"
                    showValidationBorder={!isPasswordValid(passwords.password)}
                />

                <Input
                    id="confirmPassword"
                    value={passwords.confirmPassword}
                    onChange={onChangeHandler}
                    placeholder="Ihr neues Passwort erneut eingeben"
                    type="password"
                    labelText="Ihr neues Passwort erneut eingeben"
                    ariaDescribedBy="hint"
                    showValidationBorder={
                        passwords.confirmPassword.length > 0 && passwords.confirmPassword !== passwords.password
                    }
                    error={passwords.confirmPassword.length > 0 && passwords.confirmPassword !== passwords.password}
                />

                <div className="desktop-hidden">
                    <Info
                        style={{marginTop: "32px", marginBottom: "16px"}}
                        text="Ihr neues Passwort sollte diese Vorgaben erfüllen:"
                    />
                    <div style={{marginBottom: "21px"}} id="hint" role="alert" aria-relevant="all">
                        <ValidationChecks passwords={passwords}></ValidationChecks>
                    </div>
                    <ValidationRow
                        isValid={passwords.password.length > 0 && passwords.confirmPassword === passwords.password}
                        validationText={registrationValidationErrorMessages.theNewPasswordWasEnterIdenticallyTwice}
                    />
                </div>

                <HiddenSubmitInput/>
            </Form>
            <HorizontalRule className="horizontal-rule-desktop"/>
            <div className="hide-weiter-on-keyboard-content"></div>
            <WeiterButton
                disabled={!isValid()}
                path={ApplicationRoute.editProfileSecond}
                onClick={onClickHandler}
                ariaLabel={`Senden und gehen Sie zu ${ApplicationRoute.editProfileSecond}`}
                positioned="static"
                style={{marginTop: "4rem"}}
            />
        </section>
    );
};

export default ChangePassword;
