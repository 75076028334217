import React, { useEffect, useState } from "react";
import { getIsLogged, setLocalStorage } from "../../utils/helpers";
import PushContext from "../../context/PushContext";
import ApplicationRoute from "../../config/routes";
import { useHistory, useLocation, useParams } from "react-router-dom";
import HeaderContext from "../../context/HeaderContext";
import apipath from "../../apipath";
import useFetch from "../../services/useFetch";
import LoggedContext from "../../context/LoggedContext";
import MetaDecorator from "../../utils/MetaDecorator";
import Loader from "../travel-registration/utils/Loader";
import FirstPushStepComponentReceivingPush from "./utils/1-push-step-component-receiving-push";

const applicationPageTitles = require("../../utils/pageTitles.json");
const initChoices = { needHelp: false, noHelp: false };

const initServerChoices = { needHelp: null, noHelp: null, shareLocation: null };

const FirstPushStep = () => {
  const [checked, setChecked] = useState(false);
  const [choices, setChoices] = useState(initChoices);
  const [serverChoices, setServerChoices] = useState(initServerChoices);
  const location = useLocation();

  const [cityReason, setCityReason] = useState({ city: "", reason: "" });

  const { id, longitude, latitude } = useParams();

  const history = useHistory();

  const { setHeader } = React.useContext(HeaderContext);
  const { pushNotifcations, setPushNotifications } = React.useContext(PushContext);

  const { isLogged, setIsLogged } = React.useContext(LoggedContext);
  try {
    let loggedLS = getIsLogged();

    if (!(loggedLS && loggedLS.toString().toLowerCase() === "true")) {
      setLocalStorage("linkToGo", location.pathname);
      history.push(ApplicationRoute.loginBeforePushNotf);
    }
  } catch (error) {}

  const [updatePush, setUpdatePush] = useState(null);
  const [updatePushError, setUpdatePushError] = useState(null);
  const [updatePushLoading, setUpdatePushLoading] = useState(true);
  const [submittingIpdatePush, submittingSetUpdatePush] = useState(false);

  // const updateCrisis = () => {
  //   fetcher(
  //     `${apipath.path}users/CURRENT/crisisstatus`,
  //     "POST",
  //     setUpdatePush,
  //     setUpdatePushError,
  //     setUpdatePushLoading,
  //     submittingSetUpdatePush,
  //     false,
  //     "application/json",
  //     JSON.stringify({
  //       CurrentLocation: checked ? `${latitude}, ${longitude}` : "-",
  //       IsActionNeeded: choices.needHelp,
  //       CrisisId: id,
  //     }),
  //     false
  //   );
  // };

  const { data, error, loading } = useFetch(`${apipath.path}crisis/${id}`, "GET", null, false, true);

  useEffect(() => {
    if (updatePushError && updatePushError.status !== 401 && updatePushError.status !== 403) {
      history.push(ApplicationRoute.errorPage);
    }
  }, [updatePushError]);

  useEffect(() => {
    if (error && error.status !== 401 && error.status !== 403) {
      history.push(ApplicationRoute.errorPage);
    }
  }, [error]);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "noHeader",
      };
    });
  }, []);

  const onChoiceHandler = (e) => {
    setChoices(() => {
      return { ...initChoices, [e.target.id]: true };
    });
  };

  const onCheckedHandler = () => {
    setChecked((curr) => !curr);
  };

  const isValid = () => {
    return choices.needHelp || choices.noHelp;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (isValid()) {
      const newPushObject = {
        needHelp: choices.needHelp,
        noHelp: choices.noHelp,
        shareLocation: checked,
        crisisId: id,
        longitude: longitude,
        latitude: latitude,
      };

      setPushNotifications(() => {
        return newPushObject;
      });

      //updateCrisis();
      history.push(ApplicationRoute.pushLogin);
    }
  };

  // useEffect(() => {
  //   if (!updatePushLoading && !updatePushError) {
  //     history.push(ApplicationRoute.pushLogin);
  //   }
  // }, [updatePushLoading]);

  useEffect(() => {
    if (!loading) {
      setCityReason((curr) => {
        try {
          return {
            ...curr,
            reason: data.CrisisDescription,
            city: data.CrisisAreas[0].CountryName,
          };
        } catch {
          return {
            ...curr,
            reason: "",
            city: "",
          };
        }
      });
    }
  }, [loading]);

  if (loading) return <Loader />;
  if (updatePushLoading && submittingIpdatePush) return <Loader />;

  return (
    <>
      <MetaDecorator title={applicationPageTitles.crisisWarningPage} />
      <FirstPushStepComponentReceivingPush
        onSubmit={onSubmit}
        onChoiceHandler={onChoiceHandler}
        choices={choices}
        onCheckedHandler={onCheckedHandler}
        checked={checked}
        isValid={isValid}
        weiterText="Weiter"
        showTopPart={true}
        city={cityReason.city}
        reason={cityReason.reason}
      />
    </>
  );
};

export default FirstPushStep;
