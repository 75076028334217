import React, { useEffect } from "react";
import HeaderContext from "../../../context/HeaderContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import informationLists from "../utils/informationLists.js";
import { useParams } from "react-router-dom";

function AllgemeineInformationen() {
  const { text } = useParams();
  const { setHeader } = React.useContext(HeaderContext);
  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "content",
        title: text,
      };
    });
  }, []);
  return (
    <div className="content-pages-container">
      <MetaDecorator title={text} />
      {informationLists
        .filter((list) => {
          return list.text === text;
        })
        .map((list, index) => {
          return (
            <p className="content-pages-paragraph" id={index}>
              {list.body}
            </p>
          );
        })}
    </div>
  );
}

export default AllgemeineInformationen;
