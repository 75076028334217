import React from "react";

import HorizontalRule from "../../common/HorizontalRule";
import ContentTitle from "../registration/utils/ContentTitle.jsx";

const QuestionAnswerTemplate = ({question, answer, anchor}) => {

    return (
        <>
            <ContentTitle
                id={anchor}
                text={question}
            />
            <HorizontalRule className="horizontal-rule-desktop" />
            <div className="answer-container">
                {answer}
            </div>
            <HorizontalRule className="horizontal-rule-desktop"/>
        </>
    );
};

export default QuestionAnswerTemplate;
