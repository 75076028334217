import React, { useEffect, useState } from "react";
import WeiterButton from "../utils/WeiterButton";
import TravelContext from "../../../context/TravelContext";
import ApplicationRoute from "../../../config/routes";
import { useHistory } from "react-router-dom";
import Form from "../utils/Form";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import PageTitle from "../utils/PageTitle";
import HeaderContext from "../../../context/HeaderContext";
import Input from "../utils/Input";
import ControledRadioInput from "../../registration/utils/ControledRadioInput";
import MetaDecorator from "../../../utils/MetaDecorator";
import HorizontalRule from "../../../common/HorizontalRule";

const applicationPageTitles = require("../../../utils/pageTitles.json");
const formLabel = "Reisegruppe eingeben.";
let nextStep = "";
const compaion = {
  yes: "Ich reise alleine",
  no: "Es gibt Mitreisende",
};

const TourOperator = ({ text }) => {
  const history = useHistory();
  const { travel, setTravel } = React.useContext(TravelContext);

  const { setHeader } = React.useContext(HeaderContext);

  const [isTourGroup, setIsTourGroup] = useState(travel.IsTourGroup);
  const [tourOperator, setTourOperator] = useState(travel.TourOperator);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: "Neue Reise anlegen",
        progress: parseInt((10 / 19) * 100),
      };
    });
  }, []);

  const onClickHandler = (e) => {
    e.preventDefault();

    history.push(ApplicationRoute.addTravelCompanion);

    let travelCopy = Object.assign({}, travel);
    travelCopy.IsTourGroup = isTourGroup;

    if (travelCopy.IsTourGroup) travelCopy.TourOperator = tourOperator;
    else travelCopy.TourOperator = "";

    setTravel(travelCopy);
  };

  const changeRadioHandler = (bool) => {
    setIsTourGroup(bool);
  };

  const changeTourOperator = (e) => {
    setTourOperator(e.target.value);
  };

  return (
    <div className="registration-middle-container">
      <MetaDecorator title={applicationPageTitles.createTripPages} />
      <section className="add-travel ">
        <PageTitle
          id={formLabel}
          text="Reisen Sie mit einer Gruppe im Rahmen einer organisierten Reiseveranstaltung?"
        />
        <HorizontalRule className="horizontal-rule-desktop" />
        <Form
          id="form"
          ariaDescribedBy={formLabel}
          className="m-t-l max-width-380"
          onSubmit={onClickHandler}
        >
          <ControledRadioInput
            id="yes"
            value="Yes"
            name="tour-operator"
            textInfo="Ja"
            checked={isTourGroup === true}
            onChange={() => changeRadioHandler(true)}
            ariaChecked={isTourGroup > 0}
          />
          <ControledRadioInput
            id="no"
            value="No"
            name="tour-operator"
            textInfo="Nein"
            checked={isTourGroup === false}
            onChange={() => changeRadioHandler(false)}
            ariaChecked={isTourGroup == 0}
          />

          <HiddenSubmitInput />

          {isTourGroup === true ? (
            <Input
              labelText="Reiseveranstalter"
              id="tour-operator-name"
              onChange={changeTourOperator}
              placeholder="Reiseveranstalter (optional)"
              value={tourOperator}
              className="m-t-l"
            />
          ) : null}
        </Form>
        <HorizontalRule className="horizontal-rule-desktop" />

        <div className="hide-weiter-on-keyboard-content"> </div>
        <WeiterButton
          disabled={isTourGroup === null || typeof isTourGroup === "undefined"}
          path={ApplicationRoute.addTravelCompanion}
          onClick={onClickHandler}
          ariaLabel={text}
          className="hide-weiter-on-keyboard floating-weiter-buton"
        />
      </section>
    </div>
  );
};

export default TourOperator;
