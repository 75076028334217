import React from "react";
import EditInput from "../../../common/EditInput";
import HorizontalRule from "../../../common/HorizontalRule";
import ClearContactDiv from "../../../common/ClearContactDiv";
import InputValidation from "../../../common/InputValidaiton";
import { registrationValidationErrorMessages } from "../../../utils/validationErrorMessages";
import {
  isEmailValidation,
  isPhoneValidation,
  hasMinLettersValidation,
  isPassnumberValidation,
  hasAtSymbolEmailValidation,
  hasTwoCharactersAfterAtSymbolEmailValidation,
  isDateValidation,
  isOlderOrIsEighteenYearsOlds,
  IsDateBiggerThanTodayValidation,
  IsDateSmallerThan_1900_1_1_Validation,
  isPersonalNumberValidation,
  isValidFirstAndLastName,
  hasNumber,
} from "../../../utils/validation";
import { getEnumFromGender, getGenderFromEnumGerman, hasMiddleName } from "../../../utils/helpers";

const ProfileComponentStateless = ({
  userInfoState,
  onUserInfoChangeHandler,
  contactPersonInfoState,
  onContactPersonInfoChangeHandler,
}) => {
  return (
    <div>
      <EditInput
        id="FirstName"
        placeholder="Vorname"
        value={userInfoState.FirstName}
        labelText="Vorname"
        ariaRequired={true}
        onChange={onUserInfoChangeHandler}
      />
      <InputValidation
        text={
          hasNumber(userInfoState.FirstName)
            ? registrationValidationErrorMessages.nameHasNumber
            : registrationValidationErrorMessages.firstNameLessCharacters
        }
        isHidden={hasMinLettersValidation(userInfoState.FirstName)}
      />

      <HorizontalRule />
      <EditInput
        id="LastName"
        placeholder="Familienname"
        value={userInfoState.LastName}
        labelText="Familienname"
        ariaRequired={true}
        onChange={onUserInfoChangeHandler}
      />
      <InputValidation
        text={
          hasNumber(userInfoState.LastName)
            ? registrationValidationErrorMessages.nameHasNumber
            : registrationValidationErrorMessages.lastNameLessCharacters
        }
        isHidden={hasMinLettersValidation(userInfoState.LastName)}
      />
      <HorizontalRule />
      <EditInput
        id="Gender"
        placeholder="Geschlecht"
        value={getGenderFromEnumGerman(userInfoState.Gender)}
        labelText="Geschlecht"
        ariaRequired={true}
        onChange={(value) =>
          onUserInfoChangeHandler({
            target: { id: "Gender", value: getEnumFromGender(value) },
          })
        }
        options={["männlich", "weiblich", "divers"]}
      />

      <HorizontalRule />
      <EditInput
        id="BirthDate"
        placeholder="Geburtsdatum"
        value={userInfoState.BirthDate}
        labelText="Geburtsdatum"
        ariaRequired={true}
        onChange={onUserInfoChangeHandler}
      />

      <InputValidation
        text={registrationValidationErrorMessages.notValidDate}
        isHidden={isDateValidation(userInfoState.BirthDate)}
      />
      <InputValidation
        text={registrationValidationErrorMessages.ageLessThan_18}
        isHidden={
          !IsDateBiggerThanTodayValidation(userInfoState.BirthDate) ||
          !isDateValidation(userInfoState.BirthDate) ||
          isOlderOrIsEighteenYearsOlds(userInfoState.BirthDate)
        }
      />

      <InputValidation
        text={registrationValidationErrorMessages.dateBiggerThanToday}
        isHidden={
          !isDateValidation(userInfoState.BirthDate) || IsDateBiggerThanTodayValidation(userInfoState.BirthDate)
        }
      />
      <InputValidation
        text={registrationValidationErrorMessages.dateOfBirthLessThan_01_01_1900}
        isHidden={
          !isDateValidation(userInfoState.BirthDate) || !IsDateSmallerThan_1900_1_1_Validation(userInfoState.BirthDate)
        }
      />

      <HorizontalRule />

      <EditInput
        id="CountyHomeRegionName"
        placeholder="Bundesland"
        value={userInfoState.CountyHomeRegionName}
        labelText="Bundesland"
        ariaRequired={true}
        onChange={(value) =>
          onUserInfoChangeHandler({
            target: { id: "CountyHomeRegionName", value: value },
          })
        }
        options={[
          "Burgenland",
          "Kärnten",
          "Niederösterreich",
          "Oberösterreich",
          "Salzburg",
          "Steiermark",
          "Tirol",
          "Vorarlberg",
          "Wien",
        ]}
        optional={true}
      />

      <HorizontalRule />
      <EditInput
        id="PhoneNumber"
        placeholder="Mobiltelefonnummer"
        value={userInfoState.PhoneNumber}
        labelText="Mobiltelefonnummer (inkl. Ländervorwahl)"
        ariaRequired={true}
        onChange={onUserInfoChangeHandler}
      />

      <InputValidation
        text={registrationValidationErrorMessages.notValidPhoneNumber}
        isHidden={isPhoneValidation(userInfoState.PhoneNumber)}
      />

      <HorizontalRule />
      <EditInput
        id="Email"
        placeholder="E-Mail-Adresse"
        value={userInfoState.Email}
        labelText="E-Mail-Adresse"
        ariaRequired={true}
        onChange={onUserInfoChangeHandler}
      />
      <InputValidation
        isHidden={
          !hasAtSymbolEmailValidation(userInfoState.Email) ||
          !hasTwoCharactersAfterAtSymbolEmailValidation(userInfoState.Email) ||
          isEmailValidation(userInfoState.Email)
        }
        text={registrationValidationErrorMessages.notValidEmail}
      />
      <InputValidation
        isHidden={hasAtSymbolEmailValidation(userInfoState.Email)}
        text={registrationValidationErrorMessages.missingAtSymbolEmail}
      />
      <InputValidation
        isHidden={
          !hasAtSymbolEmailValidation(userInfoState.Email) ||
          hasTwoCharactersAfterAtSymbolEmailValidation(userInfoState.Email)
        }
        text={registrationValidationErrorMessages.charactersAfterAtSymbolLessThanTwoEmail}
      />

      <HorizontalRule />
      <EditInput
        id="PassNumber"
        placeholder="Reisepassnummer"
        value={userInfoState.PassNumber}
        labelText="Reisepassnummer"
        ariaRequired={false}
        onChange={onUserInfoChangeHandler}
      />

      <InputValidation
        text={registrationValidationErrorMessages.notValidAustrianPassportOrIdentityCardNumberFormat}
        isHidden={isPassnumberValidation(userInfoState.PassNumber)}
      />

      <HorizontalRule />
      <EditInput
        id="IdNumber"
        value={userInfoState.IdNumber}
        onChange={onUserInfoChangeHandler}
        placeholder="Personalausweisnummer"
        ariaRequired={false}
        labelText="Personalausweisnummer"
        showValidationBorder={!isPersonalNumberValidation(userInfoState.IdNumber)}
      />

      <InputValidation
        isHidden={isPersonalNumberValidation(userInfoState.IdNumber)}
        text={registrationValidationErrorMessages.notValidPersonalNumber}
      />

      <ClearContactDiv
        text={`Notfallkontakt (${
          contactPersonInfoState.FirstLastName ? contactPersonInfoState.FirstLastName : "Keine Angabe"
        })`}
      />
      {/* <HorizontalRule /> */}
      <EditInput
        id={"NotfalkontaktFirstLastName"}
        placeholder={"Vorname Familienname"}
        value={contactPersonInfoState.FirstLastName}
        labelText={"Vorname Familienname"}
        ariaRequired={true}
        onChange={onContactPersonInfoChangeHandler}
      />
      <InputValidation
        text={
          hasNumber(contactPersonInfoState.FirstLastName)
            ? registrationValidationErrorMessages.nameHasNumber
            : registrationValidationErrorMessages.notValidContactPersonFirstAndLastName
        }
        isHidden={
          isValidFirstAndLastName(contactPersonInfoState.FirstLastName) ||
          hasMiddleName(contactPersonInfoState.FirstLastName)
        }
      />
      <InputValidation
        isHidden={!hasMiddleName(contactPersonInfoState.FirstLastName)}
        text={registrationValidationErrorMessages.middleName}
        ariaLabel={registrationValidationErrorMessages.middleName}
      />
      <HorizontalRule />
      <EditInput
        id={"NotfalkontaktPhoneNumber"}
        placeholder={"+43"}
        value={contactPersonInfoState.PhoneNumber}
        labelText={"Mobiltelefonnummer (inkl. Ländervorwahl)"}
        ariaRequired={true}
        onChange={onContactPersonInfoChangeHandler}
      />
      <InputValidation
        text={registrationValidationErrorMessages.notValidPhoneNumber}
        isHidden={
          isPhoneValidation(contactPersonInfoState.PhoneNumber, true) || contactPersonInfoState.PhoneNumber.length === 0
        }
      />
      <HorizontalRule />
      <EditInput
        id={"NotfalkontaktEmail"}
        placeholder={"E-Mail-Adresse"}
        value={contactPersonInfoState.Email}
        labelText={"E-Mail-Adresse"}
        ariaRequired={false}
        onChange={onContactPersonInfoChangeHandler}
      />
      <InputValidation
        isHidden={
          contactPersonInfoState.Email.length === 0 ||
          !hasAtSymbolEmailValidation(contactPersonInfoState.Email) ||
          !hasTwoCharactersAfterAtSymbolEmailValidation(contactPersonInfoState.Email) ||
          isEmailValidation(contactPersonInfoState.Email)
        }
        text={registrationValidationErrorMessages.notValidEmail}
      />
      <InputValidation
        isHidden={contactPersonInfoState.Email.length === 0 || hasAtSymbolEmailValidation(contactPersonInfoState.Email)}
        text={registrationValidationErrorMessages.missingAtSymbolEmail}
      />
      <InputValidation
        isHidden={
          contactPersonInfoState.Email.length === 0 ||
          !hasAtSymbolEmailValidation(contactPersonInfoState.Email) ||
          hasTwoCharactersAfterAtSymbolEmailValidation(contactPersonInfoState.Email)
        }
        text={registrationValidationErrorMessages.charactersAfterAtSymbolLessThanTwoEmail}
      />
    </div>
  );
};

export default ProfileComponentStateless;
