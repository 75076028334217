// use =>
//
//  const [alertBox, setAlertBox] = useState({ level: "", hidden: true });
//
// setAlertBox((curr) => {
//   return { ...curr, level: "attention", hidden: false };
// });
//
// level  => set the level of the desired alert box
// levels => information/attention
//
//
// change title, message, leftButtonText, rightButtonText for custom text
import React from "react";
import AlertTriangle from "../svg/AlertTriangle";
import InfoCircle from "../svg/InfoCircle";
import { FocusScope } from "@react-aria/focus";

const levels = {
  information: "information",
  attention: "attention",
};

const AlertBox = ({
  id,
  level,
  hidden,
  title,
  message,
  leftButtonText,
  rightButtonText,
  onClickLeftButton,
  onClickRightButton,
  twoRows = false,
}) => {
  let startRef = React.useRef();
  React.useEffect(() => {
    if (!hidden) {
      startRef.current.focus();
    }
  }, [hidden]);

  const onKeyDownHandler = (e) => {
    if (e.keyCode === 27) {
      onClickLeftButton();
    }
  };

  return (
    <div
      style={{ display: hidden ? "none" : "block" }}
      id={id}
      className="alert-box-background"
      aria-hidden={hidden}
      aria-atomic="true"
      aria-live="assertive"
      role="dialog"
      aria-label="Wollen Sie sich wirklich abmelden?"
      aria-describedby="abdescription"
      aria-modal="true"
      onKeyDown={onKeyDownHandler}
    >
      {/* <div tabindex="0"></div> */}
      <FocusScope contain autoFocus>
        <div
          className="alert-box"
          role="alertdialog"
          aria-modal="true"
          // aria-labelledby="alert-box__title"
          // aria-describedby="alert-box__message"
        >
          <div className={`alert-box__icon `}>
            {level === levels.information ? (
              <InfoCircle className="alert-box__icon__information" />
            ) : (
              <AlertTriangle className="alert-box__icon__attention" />
            )}
          </div>
          {title ? (
            <div
              //aria-label="Titel"
              id="alert-box__title"
              className="alert-box__title"
            >
              {title}
            </div>
          ) : (
            <div
              //aria-label="Titel"
              id="alert-box__title"
              className="alert-box__title"
            >
              {level === levels.information
                ? "Wollen Sie sich wirklich abmelden?"
                : "Ihr Profil und Ihre Auslandsaufenthalte werden unwiderruflich gelöscht."}
            </div>
          )}
          {message ? (
            <div id="abdescription" aria-label="Botschaft" className="alert-box__message">
              {message}
            </div>
          ) : (
            <div id="abdescription" aria-label="Botschaft" className="alert-box__message">
              {level === levels.information
                ? "Im abgemeldeten Zustand können Sie Ihre Reisen nicht einsehen."
                : "Sie möchten Ihr Profil unwiderruflich löschen? Bitte denken Sie daran, dass für Reiseregistrierungen ein Profil notwendig ist."}
            </div>
          )}

          <div>
            <div className={"alert-box__buttons" + (twoRows ? " alert-box__buttons--two-rows" : "")}>
              <div>
                <button
                  type="button"
                  id="leftButton"
                  className="alert-box__buttons__button alert-box__buttons__button--left"
                  onClick={onClickLeftButton}
                  ref={startRef}
                >
                  {leftButtonText ? leftButtonText : "Abbrechen"}
                </button>
              </div>
              <div>
                <button
                  type="button"
                  id="rightButton"
                  className=" alert-box__buttons__button alert-box__buttons__button--right"
                  onClick={onClickRightButton}
                >
                  {rightButtonText ? rightButtonText : level === levels.information ? "Abmelden" : "Profil löschen"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </FocusScope>
      {/* <div tabindex="0"></div> */}
    </div>
  );
};

export default AlertBox;
