import React from "react";

const Pin = ({ text, id, countryObject, representation, tag, infoText, setState, lat, lng, onClick }) => {
  return (
    <div>
      <div className={"react-pin-text"} id={tag} style={{ display: "none" }}></div>
      <div
        onClick={() => {
          onClick(tag, countryObject, representation, infoText, lat, lng);
          setState();
        }}
      >
        {text}
      </div>
    </div>
  );
};

export const MemoPin = React.memo(Pin);
