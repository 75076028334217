import { useContext, useEffect } from "react";
import HeaderContext from "../../context/HeaderContext";
import MetaDecorator from "../../utils/MetaDecorator";

const DataProtectionBeforeLogin = () => {
  const { setHeader } = useContext(HeaderContext);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: "Datenschutzerklärung",
        progress: "no",
      };
    });
  }, []);

  return (
    <>
      <MetaDecorator title="Datenschutzerklärung" />
      <p className="context-text-dp-mt-24 ">
        Ziel dieser Datenverarbeitung ist die bessere Unterstützung in einem
        größeren Krisen- und Notfall oder bei Gefahr für Leben und Gesundheit.
        Zu diesem Zweck darf aufgrund der angegebenen personenbezogenen Daten in
        Situationen, in denen mein Leben oder meine Gesundheit gefährdet ist
        oder die Ausübung konsularischen Schutzes erforderlich wird, durch die
        jeweils zuständige österreichische Konsularbehörde an mich und
        angegebene Kontaktpersonen herangetreten werden.
      </p>
    </>
  );
};

export default DataProtectionBeforeLogin;
