import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import apipath from "../../apipath";
import AlertBox from "../../common/AlertBox";
import HorizontalRule from "../../common/HorizontalRule";
import MainButton from "../../common/MainButton";
import defaultUserValue from "../../config/defaultUserValues";
import ApplicationRoute from "../../config/routes";
import HeaderContext from "../../context/HeaderContext";
import LoggedContext from "../../context/LoggedContext";
import SuccessContext from "../../context/SuccessContext";
import fetcher from "../../services/fetcher";
import Cross from "../../svg/Cross";
import { getUIID, removeIsLoggedStorage, setUserValuesOnLogin } from "../../utils/helpers";
import MetaDecorator from "../../utils/MetaDecorator";
import Loader from "../travel-registration/utils/Loader";
import {isLoginRedirected} from "../registration/utils/loginHelper.js";
import UserContext from "../../context/UserContext.js";

const applicationPageTitles = require("../../utils/pageTitles.json");
const EditProfileSecond = () => {
  const { setHeader } = React.useContext(HeaderContext);
  const { setSuccessBox } = React.useContext(SuccessContext);
  const { isLogged, setIsLogged } = React.useContext(LoggedContext);
  const { user } = React.useContext(UserContext);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "noHeader",
      };
    });
  }, []);

  const history = useHistory();

  const [alertBox, setAlertBox] = useState({ level: "", hidden: true });

  const [logingout, setLogingout] = useState(null);
  const [logingoutError, setLogingoutError] = useState(null);
  const [logingoutLoading, setLogingoutLoading] = useState(true);
  const [submttingLogingout, setSubmttingLogingout] = useState(false);

  const [deleteProfile, setDeleteProfile] = useState(null);
  const [deleteProfileError, setDeleteProfileError] = useState(null);
  const [deleteProfileLoading, setDeleteProfileLoading] = useState(true);
  const [submttingDeleteProfile, setSubmttingDeleteProfile] = useState(false);

  const onClickLogoutHandler = () => {
    setAlertBox((curr) => {
      return { ...curr, level: "information", hidden: false };
    });
  };

  const onClickCloseHandler = (e) => {
    e.preventDefault();
    history.push(ApplicationRoute.TravelDashboardNoPermissions);
  };

  //The left button of the alert box
  const onClickLeftButtonHandler = () => {
    return alertBox.level === "information"
      ? onClickAlertBoxLeftButtonInformationLevel()
      : onClickAlertBoxLeftButtonAttentionLevel();
  };

  const logOut = () => {
    fetcher(
      `${apipath.path}logout`,
      "POST",
      setLogingout,
      setLogingoutError,
      setLogingoutLoading,
      setSubmttingLogingout,
      false,
      "application/json",
      "",
      false
    );
  };

  const clearUser = () => {
    localStorage.removeItem("registerUser");
    removeIsLoggedStorage();

    setUserValuesOnLogin(() => {
      return defaultUserValue;
    });

    setIsLogged(false);
  };

  useEffect(() => {
    if (!logingoutLoading && !isLogged) {
      removeIsLoggedStorage();

      setSuccessBox((curr) => {
        return {
          ...curr,
          hidden: false,
          text: "Sie wurden abgemeldet.",
          trigger: getUIID(),
        };
      });

      history.push(ApplicationRoute.home);

      //TODO: loggout middleware (API CALL)
    }
  }, [isLogged, logingoutLoading, logingout, logingoutError]);

  //After loggin out update or delete profile
  useEffect(() => {
    ///in case of delete
    if (!isLogged && !deleteProfileLoading) {
      setSuccessBox((curr) => {
        return {
          ...curr,
          hidden: false,
          text: "Ihr Profil wurde gelöscht.",
          trigger: getUIID(),
        };
      });

      history.push(history.push(ApplicationRoute.home));
    }
  }, [isLogged, deleteProfile, deleteProfileError, deleteProfileLoading]);

  //Information level left button of the alert box == sign out
  const onClickAlertBoxLeftButtonInformationLevel = () => {
    clearUser();
    logOut();
  };

  const onClickDeleteProfileHandler = () => {
    setAlertBox((curr) => {
      return { ...curr, level: "attention", hidden: false };
    });
  };

  const deleteProfileMethod = () => {
    fetcher(
      `${apipath.path}users/CURRENT`,
      "DELETE",
      setDeleteProfile,
      setDeleteProfileError,
      setDeleteProfileLoading,
      setSubmttingDeleteProfile,
      false,
      "application/json"
    );
  };

  //Attention level left button of the alert box = on delete profile
  const onClickAlertBoxLeftButtonAttentionLevel = () => {
    deleteProfileMethod();
  };

  const onClickChangePasswordHandler = () => {
    history.push(ApplicationRoute.changePassword);
  };

  useEffect(() => {
    if (!deleteProfileLoading && !deleteProfileError) {
      clearUser();

      //TODO: after delete
    }
  }, [deleteProfile, deleteProfileError, deleteProfileLoading]);

  //on delete profile
  const onClickAlertBoxRightButton = () => {
    setAlertBox((curr) => {
      return { ...curr, hidden: true };
    });
  };

  if (logingoutLoading && submttingLogingout) return <Loader />;
  if (deleteProfile && submttingDeleteProfile) return <Loader />;

  return (
    <div className="edit-profile-second-container content-container max-width-780">
      <MetaDecorator title={applicationPageTitles.crisisWarningPage} />
      <header className="edit-profile-header-second">
        <div role="navigation" className="edit-profile-header-second__nav">
          <Link
            to="./"
            className="edit-profile-header-second__nav__close"
            aria-label="Schließen"
            onClick={onClickCloseHandler}
          >
            <Cross className="edit-profile-header-second__nav__close-svg" />
          </Link>
          <button onClick={onClickLogoutHandler} className="edit-profile-header-second__nav__logout">
            Abmelden
          </button>
        </div>
      </header>

      <div className="max-width-380">
        <h1 className="edit-profile-second-container__title"> Einstellungen</h1>

        <AlertBox
          id="alertBox"
          level={alertBox.level}
          hidden={alertBox.hidden}
          onClickLeftButton={onClickAlertBoxRightButton}
          onClickRightButton={onClickLeftButtonHandler}
        />

        <MainButton
          buttonId="changePassword"
          text="Passwort ändern"
          disabled={isLoginRedirected(user)}
          positioned="static"
          color="transperant"
          onClick={onClickChangePasswordHandler}
          style={{ marginTop: "2.2rem" }}
          dissClass="weiter-button--transperant--disabled"
        />

        <HorizontalRule className="edit-profile-second-container__hr" />

        <p className="edit-profile-second-container__text">
          Sie möchten Ihr Konto unwiderruflich löschen? <br /> Dies kann nicht rückgängig gemacht werden.
        </p>

        <MainButton
          buttonId="deleteProfile"
          text="Konto löschen"
          ariaLabelledBy="informTextDelete"
          disabled={false}
          positioned="static"
          color="transperant"
          onClick={onClickDeleteProfileHandler}
          img="./images/common/smallalert.png"
        />
      </div>
    </div>
  );
};

export default EditProfileSecond;
