import { Link } from "react-router-dom";
import { isMobileEnv } from "../../utils/helpers";

const ContentHeader = ({ title, onBackButtonClickHandler }) => {
  return (
    <div className="mobile-sticky">
      <header className="register-header" role="navigation">
        <a
          className="sr-only"
          href="#main"
          onClick={(ev) => {
            ev.preventDefault();
            document.getElementById("main").scrollIntoView();
          }}
        >
          Zum Inhalt springen
        </a>
        {
          isMobileEnv() && <Link className="register-header__back-button" to="./" onClick={onBackButtonClickHandler} aria-label="Zurück">
            <img className="register-header__back-button__img" src="./images/common/headerback.png" alt="" />
          </Link>
        }
        <h1 className="register-header__title">{title ? title : <>&nbsp;</>}</h1>
      </header>
    </div>
  );
};

export default ContentHeader;
