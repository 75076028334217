import {forwardRef} from "react";
import SelectListTemplate from "./SelectListTemplate.jsx";

const CustomSelectList = (
    {
        id = "customselectlist-random",
        state,
        options,
        label,
        ariaLabelledBy,
        isDisabled = false,
        onChangeHandler,
        open = false,
        noIcon,
        className,
        optional = false,
        isTravel = false,
        isRegion = false,
        ariaLabelType = "",
    },
    ref
) => {

    let arr = [
        {
            id: id + "-1",
            value: label,
            selected: false,
        },
    ];

    if (options?.length) {
        options.forEach((item, index) => {
            arr.push({
                id: id + "-" + (index + 2),
                value: item,
                selected: state === item,
            });
        });
    }
    if (!options?.length || !state) {
        arr[0].selected = true;
    }

    let selected = arr.filter((item) => item.selected)[0];

    if (selected == null) selected = arr[0];

    return (
        <SelectListTemplate
            id={id}
            state={state}
            label={label}
            ariaLabelledBy={ariaLabelledBy}
            ariaLabelType={ariaLabelType}
            open={open}
            noIcon={noIcon}
            className={className}
            optional={optional}
            isTravel={isTravel}
            isRegion={isRegion}
            selected={selected}
            suggestions={arr}
            onChangeHandler={onChangeHandler}
            ref={ref}
        />
    );
};
export default forwardRef(CustomSelectList);
