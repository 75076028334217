import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ApiRequestErrorMessage from "../../common/ApiRequestErrorMessage";
import LoggedContext from "../../context/LoggedContext";
import UserContext from "../../context/UserContext";
import { apiRequestErrors } from "../../utils/validationErrorMessages";
import Form from "../registration/utils/Form";
import Input from "../registration/utils/Input";
import WeiterButton from "../registration/utils/WeiterButton";
import { Link } from "react-router-dom";
import Cross from "../../svg/Cross";
import ApplicationRoute from "../../config/routes";
import MetaDecorator from "../../utils/MetaDecorator";
import Loader from "../travel-registration/utils/Loader";
import { getCountries__with__loader, getVB__with__loader } from "../travel-registration/utils/GetCountries";
import {login, loginUser} from "../registration/utils/loginHelper.js";

const applicationPageTitles = require("../../utils/pageTitles.json");
const PushLoginBeforeNF = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();

  const onPasswordChangeHandler = (e) => {
    setPassword(e.target.value);
  };

  const onEmailChangeHandler = (e) => {
    setEmail(e.target.value);
  };

  const { setUser } = React.useContext(UserContext);
  const { setIsLogged } = React.useContext(LoggedContext);

  const [loginData, setLoginData] = useState(null);
  const [loginError, setLogingError] = useState(null);
  const [logingLoading, setLogintLoading] = useState(true);
  const [submittingLogin, setSubmittingLogin] = useState(false);

  const [disableButton, setDisableButton] = useState(false);

  const [vbLoader, setVBLoader] = useState(false);
  const [countryDataLoaded, setCountryDataLoaded] = React.useState(false);

  const initLoginCred = { email: false, password: false };
  const [noLoginCred, setNoLoginCred] = useState(initLoginCred);

  const submittedLogginWithNoErrors = () => {
    return !logingLoading && !loginError;
  };

  const submittingLoggin = () => {
    return submittingLogin && logingLoading;
  };

  const onLoginHandler = (e) => {
    e.preventDefault();
    login(email, password, setNoLoginCred, setLoginData, setLogingError, setLogintLoading, setSubmittingLogin);
  };

  useEffect(() => {
    if (!logingLoading && !loginError) {
      const path = JSON.parse(localStorage.getItem("linkToGo"));
      history.push(path);
    }
  }, [logingLoading, loginError]);

  useEffect(() => {
    if (!logingLoading) {
      if (submittedLogginWithNoErrors()) {
        setDisableButton(true);

        loginUser(loginData, setUser, setIsLogged, history);
      }
    }
  }, [loginData, loginError, logingLoading]);

  const onCrossClickHandler = () => {
    history.push(ApplicationRoute.home);
  };

  useEffect(() => {
    getCountries__with__loader(setCountryDataLoaded);
    getVB__with__loader(setVBLoader);
  }, []);

  if (!vbLoader) return <Loader />;
  if (!countryDataLoaded) return <Loader />;

  return (
    <div className="push-login-before-container">
      <MetaDecorator title={applicationPageTitles.crisisWarningPage} />
      <Cross
        className="push-login-before-container__cross"
        height="3.7rem"
        width="3.7rem"
        color="black"
        onClick={onCrossClickHandler}
      />
      <div>
        <div className="push-login-before-container__top--container">
          <img
            src="./images/common/redcrisis.png"
            className="push-login-before-container__top--container__alert-triangle"
            alt=""
          />
        </div>
        <p className="push-login-before-container__message">
          Bitte melden Sie sich an, damit wir Sie unterstützen können.
        </p>
      </div>
      <div className="push-login-before-container_login-text">Login</div>

      <Form id="form">
        <Input
          id="email"
          placeholder="E-Mail-Adresse"
          type="text"
          labelText="E-Mail-Adresse"
          onChange={onEmailChangeHandler}
          value={email}
          inputStyle={{ backgroundColor: "rgb(240, 244, 245)" }}
          labelStyle={{ backgroundColor: "rgb(240, 244, 245)" }}
          error={noLoginCred.email || loginError?.status === 401}
        />

        {(noLoginCred.email || noLoginCred.password || loginError?.status === 401) && (
          <ApiRequestErrorMessage
            style={{ marginTop: "-1.3rem", marginBottom: "1.6rem" }}
            text={apiRequestErrors["401-login"]}
          />
        )}

        <Input
          id="password"
          placeholder="Passwort"
          type="password"
          labelText="Passwort"
          onChange={onPasswordChangeHandler}
          value={password}
          inputStyle={{ backgroundColor: "rgb(240, 244, 245)" }}
          labelStyle={{ backgroundColor: "rgb(240, 244, 245)" }}
          error={noLoginCred.password || loginError?.status === 401}
        />

        <WeiterButton
          disabled={submittingLoggin() || disableButton}
          path="./"
          ariaLabel="Einloggen."
          text="Anmelden"
          positioned="static"
          linkId="loginLinkId"
          onClick={onLoginHandler}
          style={{ marginBottom: "0px" }}
        />
      </Form>

      <Link
        to={ApplicationRoute.registerForgottenPassword}
        aria-label="Passwort wiederherstellen."
        style={{ display: "block" }}
      >
        <div className="centered-link__container">
          <div className="centered-link__container__link">Passwort vergessen oder nicht bekannt?</div>
        </div>
      </Link>
    </div>
  );
};

export default PushLoginBeforeNF;
