import {useEffect, useContext, useState} from "react";
import {useHistory} from "react-router-dom";
import Separater from "../../../common/Separater";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import TravelContext from "../../../context/TravelContext";
import CheckBox from "../../registration/utils/CheckBox";
import ContentTitle from "../../registration/utils/ContentTitle";
import WeiterButton from "../../registration/utils/WeiterButton";
import EditInput from "../../../common/EditInput";
import HorizontalRule from "../../../common/HorizontalRule";
import {
    convertAppDateToMiddlewareDateFormat,
    convertMiddlewareDateToAppDateFormat,
    getEnumFromGender,
    getSecondAfterSpace,
    getUIID,
    isValidContactPerson,
    pluck,
    postalCodeFormatter,
    setApiObjectAddTravel,
    setApiUserObjectUpdate2,
    setUserValuesOnLogin,
} from "../../../utils/helpers";
import Form from "../../registration/utils/Form";
import fetcher from "../../../services/fetcher";
import apipath from "../../../apipath";
import UserContext from "../../../context/UserContext";

import ProfileEditComponent from "../../travel-registration/steps/ProfileEditComponent";
import MetaDecorator from "../../../utils/MetaDecorator";
import {travelGroupMemberValidation} from "../../../utils/validation";
import InputValidation from "../../../common/InputValidaiton";
import {TravelGroupMember} from "../../../config/defaultTravelValues";
import RelativeModal from "../../../common/RelativeModal";
import SuccessContext from "../../../context/SuccessContext";
import AlertBox from "../../../common/AlertBox";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import {getRegionsByCountryName} from "../../travel-registration/utils/countryInfo.js";
import {
    editOnChangeGroupMembers,
    effectOptionals,
    optionalDefault,
} from "../../../utils/editUtils.js";
import TravelGroup from "../../travel-registration/utils/TravelGroup.jsx";
import InfoMailForm from "../utils/InfoMailForm.jsx";

const applicationPageTitles = require("../../../utils/pageTitles.json");

export default function ForeignEditTravel() {
    const {travel, setTravel} = useContext(TravelContext);
    const {setHeader} = useContext(HeaderContext);
    const {user, setUser} = useContext(UserContext);
    const {setSuccessBox} = useContext(SuccessContext);

    const [userState, setUserState] = useState(user);

    const history = useHistory();

    const [error,] = useState(null);
    const [loading,] = useState(true);

    const [updateProfile, setUpdateProfile] = useState(null);
    const [updateProfileError, setUpdateProfileError] = useState(null);
    const [updateProfileLoading, setUpdateProfileLoading] = useState(true);
    const [, setSubmttingUpdateProfile] = useState(false);
    const [relativeModal, setRelativeModal] = useState(false);

    const [GDPRDataRetention,] = useState(null);
    const [GDPRForeignAustrian, setGDPRForeignAustrian] = useState(false);
    const [GDPRForeignAustrianOfficialInfo, setGDPRForeignAustrianOfficialInfo] = useState(null);
    const [GDPRForeignAustrianInofficialInfo, setGDPRForeignAustrianInofficialInfo] = useState(null);
    const [deleteRelativeIndex, setDeleteRelativeIndex] = useState(-1);

    const [regions] = useState(travel.Country ? pluck(getRegionsByCountryName(travel.CountryName), "Name") : []);
    const [groupMembers, setGroupMembers] = useState(
        travel.TravelGroupMember.map((tg) => {
            return {
                ...tg,
                Birthdate: tg.Birthdate ? convertMiddlewareDateToAppDateFormat(tg.Birthdate) : "",
            };
        }).filter((tg) => {
            return !!tg.Nationality;
        })
    );

    const [validPersonalInfo, setValidPersonalInfo] = useState(true);
    const [validGroupMembers, setValidGroupMembers] = useState(true);
    const [optionals, setOptionals] = useState(optionalDefault);

    useEffect(() => {
        effectOptionals(setOptionals, userState.ContactPerson);
    }, [userState.ContactPerson]);

    useEffect(() => {
        setValidGroupMembers(true);
        if (groupMembers.length === 0) {
            setValidGroupMembers(true);
        } else {
            let valid = true;
            groupMembers.forEach((tgm) => {
                if (!travelGroupMemberValidation(tgm)) valid = false;
            });

            setValidGroupMembers(valid);
        }
    }, [groupMembers]);

    function successBox(hidden, text) {
        setSuccessBox((curr) => {
            return {
                ...curr,
                hidden,
                text,
                trigger: getUIID(),
            };
        });
    }

    const updateUserMiddleware = () => {
        let userCopy = Object.assign({}, userState);
        userCopy.TravelGroupMember = groupMembers;

        userCopy.TravelGroupMember.forEach((item) => {
            item.Birthdate = convertAppDateToMiddlewareDateFormat(item.Birthdate);
        });

        let userobj = setApiUserObjectUpdate2(userCopy, travel);

        if ("FamilyGroupMember" in userobj && !!userobj.FamilyGroupMember && userobj.FamilyGroupMember.length > 0) {
            userobj.FamilyGroupMember = userobj.FamilyGroupMember.map((fgm) => {
                return {
                    ...fgm,
                    MobilePhone: fgm.MobilePhone ? fgm.MobilePhone : null,
                    Email: fgm.Email ? fgm.Email : null,
                    Gender: getEnumFromGender(fgm.Gender),
                    LastName: getSecondAfterSpace(fgm.FirstLastName.trim()).trim(),
                };
            }).filter((tg) => {
                return !!tg.Nationality;
            });
        }

        fetcher(
            `${apipath.path}users/CURRENT`,
            "PUT",
            setUpdateProfile,
            setUpdateProfileError,
            setUpdateProfileLoading,
            setSubmttingUpdateProfile,
            false,
            "application/json",
            JSON.stringify(userobj)
        );
    };

    const getDestinationDateInit = () => {
        let first = travel.StartDate ? convertMiddlewareDateToAppDateFormat(travel.StartDate) : "";

        let second = travel.EndDate ? convertMiddlewareDateToAppDateFormat(travel.EndDate) : "";

        return first + "-" + second;
    };

    const [destination,] = useState({
        Reisezeitraum: getDestinationDateInit(),
        OrganisierteReiseveranstaltung: travel.IsTourGroup ? "Ja" : "Nein",
        Reiseveranstalter: travel.TourOperator,
    });

    useEffect(() => {
        setHeader((curr) => {
            return {
                ...curr,
                inUse: "registration",
                title: "Als Auslandsösterreicher/in registrieren",
                ariaLabel: "Als Auslandsösterreicher:in registrieren",
                progress: parseInt(100),
            };
        });
    }, [setHeader]);

    const isValid = () => {
        return (
            !!GDPRForeignAustrian &&
            validGroupMembers &&
            validPersonalInfo &&
            // GDPRDataRetention !== null &&
            GDPRForeignAustrianOfficialInfo !== null &&
            GDPRForeignAustrianInofficialInfo !== null &&
            isValidContactPerson(optionals) &&
            travel.Street &&
            travel.PostalCode &&
            travel.City
        );
    };

    const updateTravel = () => {
        let newGroupMembers = groupMembers.map((gm) => {
            return {
                ...gm,
                Birthdate: convertAppDateToMiddlewareDateFormat(gm.Birthdate),
            };
        });

        setTravel((curr) => {
            return {
                ...curr,
                IsTourGroup: destination.OrganisierteReiseveranstaltung === "Ja",
                TourOperator: destination.Reiseveranstalter,
                TravelGroupMember: newGroupMembers,
            };
        });
    };

    const submit = (e) => {
        e.preventDefault();

        if (isValid()) {
            updateTravel();

            updateUserMiddleware();
        }
    };

    //HINT* POST TRAVELL
    useEffect(() => {
        if (!loading && !error) {
            updateUserMiddleware();
        }
    }, [loading]);

    useEffect(() => {
        if (!updateProfileLoading && !updateProfileError) {
            let userCopy = Object.assign({}, userState);
            userCopy.TravelGroupMember = groupMembers;

            userCopy.TravelGroupMember.forEach((item) => {
                item.Birthdate = convertAppDateToMiddlewareDateFormat(item.Birthdate);
            });

            userCopy = setApiUserObjectUpdate2(userCopy, travel);
            userCopy.Email = userState.Email;
            setUser(setUserValuesOnLogin(userCopy));

            setSuccessBox((curr) => {
                return {
                    ...curr,
                    hidden: false,
                    text: "Sie wurden erfolgreich als Auslandsösterreicher/in registriert!",
                    trigger: getUIID(),
                };
            });

            history.push(ApplicationRoute.TravelDashboardNoPermissions);
        }
    }, [updateProfile, updateProfileError, updateProfileLoading]);

    const onClickHandler = (e) => {
        e.preventDefault();
        if (isValid()) {
            submit(e);
        }
    };

    const onChangeGroupMembers = (e) => {
        setGroupMembers((curr) => {
            return editOnChangeGroupMembers(e, curr);
        });
    };

    const updateUser = (userInfoState, contactPersonInfoState) => {
        let userObj = {
            ...userInfoState,
            ContactPerson: {...contactPersonInfoState},
            TempDeletedContact: [],
            TempPerson: "",
            HasPersonInit: "",
            TempDeletedRelatives: [],
            TempRelatives: [],
            SaveChanges: true,
        };

        setUserState(() => {
            return userObj;
        });
    };

    function changeTravel(id, value) {
        let travelCopy = Object.assign({}, travel);

        if (id === "PostalCode") travelCopy[id] = postalCodeFormatter(travelCopy.PostalCode, value);
        else travelCopy[id] = value;

        setTravel(travelCopy);
    }

    function addRelativeModal() {
        setRelativeModal(!relativeModal);

        if (document.getElementsByTagName("body").item(0).classList.contains("overflow-hidden")) {
            document.getElementsByTagName("body").item(0).classList.remove("overflow-hidden");
        } else {
            document.getElementsByTagName("body").item(0).classList.add("overflow-hidden");
        }
    }

    useEffect(() => {
        setUserState((curr) => {
            return {
                ...curr,
                GDPRDataRetention: GDPRDataRetention,
                GDPRForeignAustrian: GDPRForeignAustrian,
                GDPRForeignAustrianOfficialInfo: GDPRForeignAustrianOfficialInfo,
                GDPRForeignAustrianInofficialInfo: GDPRForeignAustrianInofficialInfo,
            };
        });
    }, [GDPRDataRetention, GDPRForeignAustrian, GDPRForeignAustrianOfficialInfo, GDPRForeignAustrianInofficialInfo]);

    function addRelative(FirstLastName, DegreeOfRelationship, Gender, Nationality, Birthdate, Email, MobilePhone) {
        addRelativeModal();
        let groupMembersCopy = travel.IsAlone ? [] : Object.assign([], groupMembers);
        let travelGroupMemberCopy = Object.assign({}, TravelGroupMember);

        travelGroupMemberCopy.FirstLastName = FirstLastName;
        travelGroupMemberCopy.DegreeOfRelationship = DegreeOfRelationship;
        travelGroupMemberCopy.Gender = Gender;
        travelGroupMemberCopy.Birthdate = Birthdate;
        travelGroupMemberCopy.Nationality = Nationality;
        travelGroupMemberCopy.Email = Email;
        travelGroupMemberCopy.MobilePhone = MobilePhone;

        groupMembersCopy.push(travelGroupMemberCopy);

        setGroupMembers(groupMembersCopy);
        setTravel((curr) => {
            return {
                ...curr,
                IsAlone: false,
            };
        });
    }

    function deleteRelative(index) {
        let groupMembersCopy = Object.assign([], groupMembers);
        groupMembersCopy = groupMembersCopy.filter((element, i) => i !== index);

        setGroupMembers(groupMembersCopy);
        let travelCopy = Object.assign({}, travel);
        travelCopy.TravelGroupMember = groupMembersCopy;
        if (groupMembersCopy.length === 0) travelCopy.IsAlone = true;

        setDeleteRelativeIndex(-1);

        successBox(false, "Angehörige Person wurde gelöscht");

        setTravel(travelCopy);
    }

    function openDeleteRelativeModal(index) {
        setDeleteRelativeIndex(index);
    }

    function setRegion(value) {
        let travelCopy = Object.assign({}, travel);
        travelCopy.RegionName = value;
        let regionsArr = getRegionsByCountryName(travel.CountryName);
        for (const regionItem of regionsArr) {
            if (regionItem.Name === value) {
                travelCopy.Region = regionItem.Id;
            }
        }
        setTravel(travelCopy);
    }

    return (
        <>
            <MetaDecorator title={applicationPageTitles.permanentOtsideCountry}/>
            {error && <h1> {JSON.stringify(setApiObjectAddTravel(travel), null, 2)} </h1>}
            <AlertBox
                id="alertBox"
                level="attention"
                hidden={deleteRelativeIndex === -1}
                onClickLeftButton={() => setDeleteRelativeIndex(-1)}
                onClickRightButton={() => deleteRelative(deleteRelativeIndex)}
                title={
                    deleteRelativeIndex >= 0
                        ? `${groupMembers[deleteRelativeIndex].FirstLastName} wird als Angehörige/r im Ausland gelöscht.`
                        : ""
                }
                message=" "
                leftButtonText="Abbrechen"
                rightButtonText="Löschen"
            />
            <Form
                ariaLbeledBy="foreign-registration-edit"
                onSubmit={onClickHandler}
                className="content-container max-width-780"
            >
                <div className="specialAlertContainer">
                    <ContentTitle
                        id="foreign-registration-edit"
                        text="Gleich geschafft! Bitte überprüfen Sie abschließend Ihre Daten:"
                    />
                    {/* Change to Country not Id */}
                    <Separater text={"Permanenter Auslandsaufenthalt"} secondRow={travel.CountryName}/>
                    <EditInput
                        id="Region"
                        placeholder="Region"
                        value={travel.RegionName}
                        labelText="Region"
                        ariaRequired={true}
                        options={regions}
                        onChange={(value) => setRegion(value)}
                        filtered={true}
                    />

                    <HorizontalRule/>
                    <EditInput
                        id="street"
                        placeholder="Straße"
                        value={travel.Street}
                        labelText="Straße"
                        ariaRequired={false}
                        onChange={(e) => changeTravel("Street", e.target.value)}
                    />
                    <InputValidation text="Bitte geben Sie Ihre Adresse an." isHidden={travel.Street !== ""}/>

                    <HorizontalRule/>
                    <EditInput
                        id="postalCode"
                        placeholder="PLZ"
                        value={travel.PostalCode}
                        labelText="PLZ"
                        ariaRequired={false}
                        onChange={(e) => changeTravel("PostalCode", e.target.value)}
                    />
                    <InputValidation text="Bitte geben Sie Ihre Adresse an." isHidden={travel.PostalCode !== ""}/>

                    <HorizontalRule/>
                    <EditInput
                        id="city"
                        placeholder="Ort"
                        value={travel.City}
                        labelText="Ort"
                        ariaRequired={false}
                        onChange={(e) => changeTravel("City", e.target.value)}
                    />
                    <InputValidation text="Bitte geben Sie Ihre Adresse an." isHidden={travel.City !== ""}/>
                    <HorizontalRule/>

                    <ProfileEditComponent
                        user={userState}
                        updateUser={updateUser}
                        noInitialHorizontalLine={true}
                        setValid={setValidPersonalInfo}
                    />

                    {/* Change to Country not Id */}
                    {!travel.IsAlone && groupMembers.length ? (
                        <>
                            <TravelGroup
                                groupMembers={groupMembers}
                                onChange={onChangeGroupMembers}
                                foreign={true}
                                deleteHandler={openDeleteRelativeModal}
                            />
                            <button
                                className="add-relative-link add-relative-link--align-right"
                                type="button"
                                aria-label="Angehörige Person hinzufügen"
                                onClick={addRelativeModal}

                            >
                                + Angehörige Person hinzufügen
                            </button>
                        </>
                    ) : (
                        <Separater
                            text={"Angehörige/r im Ausland (Keine Angabe)"}
                            buttonText="Angehörige Person im Ausland hinzufügen"
                            buttonClickHandler={addRelativeModal}
                        />
                    )}

                    {relativeModal ? <RelativeModal close={addRelativeModal} save={addRelative}/> : null}

                    <div style={{margin: "2.4rem 1.6rem"}}>
                        <CheckBox
                            id="GDPRForeignAustrian_checkbox"
                            htmlFor="GDPRForeignAustrian_checkbox"
                            state={GDPRForeignAustrian}
                            onChangeHandler={(e) => setGDPRForeignAustrian(e.target.checked)}
                            text="Ich stimme zu, dass meine Daten vom BMEIA zur Erleichterung der Gewährung konsularischen Schutzes im Krisen- oder Notfall verarbeitet werden. Ich bestätige, dass meine Angaben den Tatsachen entsprechen und ich von den angeführten Angehörigen zur Angabe ihrer Daten bevollmächtigt bin."
                        />
                    </div>

                    <InfoMailForm
                        GDPRForeignAustrianOfficialInfo={GDPRForeignAustrianOfficialInfo}
                        setGDPRForeignAustrianOfficialInfo={setGDPRForeignAustrianOfficialInfo}
                        GDPRForeignAustrianInofficialInfo={GDPRForeignAustrianInofficialInfo}
                        setGDPRForeignAustrianInofficialInfo={setGDPRForeignAustrianInofficialInfo}
                    />

                    <HiddenSubmitInput/>

                    <WeiterButton
                        disabled={!isValid()}
                        onClick={onClickHandler}
                        ariaLabel="Als Auslandsösterreicher:in registrieren"
                        path={ApplicationRoute.registerUserInfo}
                        positioned="static"
                        text="Als Auslandsösterreicher/in registrieren"
                        style={{marginTop: "3.2rem", marginBottom: "5.6rem"}}
                    />
                </div>
            </Form>
        </>
    );
}
