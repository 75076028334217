import React from "react";
import FilteredSelectList from "../components/foreign-registration/utils/FilteredSelectList";
import {getCountryNameFromId, getRegionsByCountryName} from "../components/travel-registration/utils/countryInfo";
import Input from "../components/travel-registration/utils/Input";
import WeiterButton from "../components/travel-registration/utils/WeiterButton";
import {compareGermanWords, pluck, postalCodeFormatter} from "../utils/helpers";

export default function CountryModal({country, county, close, save}) {

    const [countries] = React.useState(JSON.parse(localStorage.getItem("countries")));
    const [countryNames] = React.useState(
        pluck(countries, "Name").filter((c) => {
            return !(c === "Österreich" || c === "Andere");
        })
    );

    const [selectedCountry, setSelectedCountry] = React.useState(country ? getCountryNameFromId(country) : "");
    const [regions, setRegions] = React.useState([]);
    const [selectedRegion, setSelectedRegion] = React.useState("");

    const [street, setStreet] = React.useState("");
    const [postalCode, setPostalCode] = React.useState("");
    const [city, setCity] = React.useState("");

    const [update, setUpdate] = React.useState(0);

    let startRef = React.useRef();

    const onCountryChange = (value) => {
        setSelectedRegion(() => "");
        setSelectedCountry(value);
        let regionsArr = pluck(getRegionsByCountryName(value), "Name");
        if (regionsArr.length === 1) {
            setSelectedRegion(() => regionsArr[0]);
        }
        setRegions(regionsArr.sort(compareGermanWords));
    };
    const onRegionChange = (value) => {
        setSelectedRegion(() => value);
    };

    const validation = () => {
        return !(selectedCountry && selectedRegion && street && city && postalCode.toString().length >= 1);
    };

    const countryOnClickHandler = (e) => {
        e.preventDefault();

        let countryId;
        let regionId;

        for (let country of countries) {
            if (country.Name === selectedCountry) {
                countryId = country.Id;

              for (let region of country.Regions) {
                    if (region.Name === selectedRegion) {
                        regionId = region.Id;
                        break;
                    }
                }
                break;
            }
        }
        save(countryId, regionId, street, postalCode, city);
    };
    React.useEffect(() => {
        if (selectedCountry) {
            let regionsArr = getRegionsByCountryName(selectedCountry);
            setRegions(pluck(regionsArr, "Name"));

            if (county) {
                setSelectedRegion(() => regionsArr.find((element) => element.Id === county)?.Name);
            }
        }
    }, []);
    React.useEffect(() => {
        if (update <= 1) {
            setUpdate(update + 1);
            startRef.current.focus();
        }
    });

    return (
        <div
            className="modal"
            onKeyDown={(e) => {
                if (e.keyCode === 13) {
                    if (!validation()) {
                        countryOnClickHandler(e);
                    } else {
                        e.preventDefault();
                    }
                }
            }}
        >
            <button className="modal__close-button" onClick={close}>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                    <g fill="none" fillRule="evenodd">
                        <g fill="#000">
                            <g>
                                <path
                                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                                    transform="translate(-24 -21) translate(19 16)"
                                />
                            </g>
                        </g>
                    </g>
                </svg>
            </button>
            <h2 className="modal__heading">Permanenten Auslandsaufenthalt ändern</h2>
            <div className="modal__form">
                <FilteredSelectList
                    id="countryModal"
                    label="Bitte wählen Sie aus"
                    state={selectedCountry}
                    options={countryNames}
                    onChangeHandler={onCountryChange}
                    className="grey-background"
                    ref={startRef}
                />
                <FilteredSelectList
                    id="regionModal"
                    options={regions}
                    label="Bitte wählen Sie aus"
                    state={selectedRegion}
                    onChangeHandler={onRegionChange}
                    className="grey-background"
                    isRegion={true}
                />
                <Input
                    labelText="Straße"
                    id="street"
                    placeholder="Straße"
                    type="text"
                    value={street}
                    onChange={(e) => {
                        setStreet(e.target.value);
                    }}
                />
                <div className="plz-city-flex">
                    <Input
                        labelText="PLZ"
                        id="plz"
                        placeholder="PLZ"
                        type="text"
                        value={postalCode}
                        onChange={(e) => {
                            setPostalCode(postalCodeFormatter(postalCode, e.target.value));
                        }}
                    />
                    <Input
                        labelText="Ort"
                        id="city"
                        placeholder="Ort"
                        type="text"
                        value={city}
                        onChange={(e) => {
                            setCity(e.target.value);
                        }}
                    />
                </div>
                <WeiterButton
                    disabled={validation()}
                    path={""}
                    text="Speichern & Schließen"
                    onClick={countryOnClickHandler}
                    ariaLabel={`Senden und gehen Sie zu ${""}`}
                />
            </div>
        </div>
    );
}
