import React, { useEffect, useState } from "react";
import WeiterButton from "../../../registration/utils/WeiterButton";
import ContentTitle from "../../../registration/utils/ContentTitle";
import ApplicationRoute from "../../../../config/routes";
import { useHistory } from "react-router-dom";
import Form from "../../../registration/utils/Form";
import HiddenSubmitInput from "../../../../common/HiddenSubmitInput";
import HeaderContext from "../../../../context/HeaderContext";
import ControledRadioInput from "../../../registration/utils/ControledRadioInput";
import { getKeyByValue, prefilledUserData } from "../../../../utils/helpers";
import {
  getEnumFromGender,
  getGenderFromEnum,
} from "../../../../utils/helpers";
import MetaDecorator from "../../../../utils/MetaDecorator";
import UserContext from "../../../../context/UserContext";
import HorizontalRule from "../../../../common/HorizontalRule";

const applicationPageTitles = require("../../../../utils/pageTitles.json");
const genders = {
  male: "männlich",
  female: "weiblich",
  other: "divers",
};

const initSelectValue = {
  male: false,
  female: false,
  other: false,
};

//HINT* NOT REGISTRATION
export function ForeignGender({ text }) {
  const history = useHistory();
  const { user, setUser } = React.useContext(UserContext);
  const { setHeader } = React.useContext(HeaderContext);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((5 / 19) * 100),
        title: "Als Auslandsösterreicher/in registrieren",
        ariaLabel: "Als Auslandsösterreicher:in registrieren",
      };
    });
  }, []);

  const [selectedValue, setSelectedValue] = useState(initSelectValue);

  const isValid = () => {
    return getKeyByValue(selectedValue, true);
  };

  const updateUser = () => {
    setUser((curr) => {
      return {
        ...curr,
        Gender: getEnumFromGender(getKeyByValue(selectedValue, true)),
      };
    });
  };

  const onClickHandler = () => {
    updateUser();
  };

  const onChangeHandler = (e) => {
    setSelectedValue(() => {
      return { ...initSelectValue, [e.target.id]: true };
    });
  };

  const onsubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateUser();
      history.push(ApplicationRoute.foreignRegistrationRegistrationNumber);
    }
  };

  useEffect(() => {
    if (user && user.Gender) {
      setSelectedValue(() => {
        return { ...initSelectValue, [getGenderFromEnum(user.Gender)]: true };
      });
    }
  }, [user]);

  return (
    <section className="foreign-registration registration-middle-container">
      <MetaDecorator title={applicationPageTitles.foreignRegistration} />
      <ContentTitle
        id="foreign-registration-gender"
        text={prefilledUserData("Bitte wählen Sie Ihr Geschlecht aus:")}
      />
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form
        id="form"
        onSubmit={onsubmitHandler}
        ariaLabelledBy="foreign-registration-gender"
        className="max-width-380"
      >
        <ControledRadioInput
          role="radio"
          id="male"
          value="100000000"
          name="gender"
          textInfo={genders.male}
          ariaLabel={genders.male}
          checked={selectedValue.male}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.male}
        />
        <ControledRadioInput
          role="radio"
          id="female"
          value="100000001"
          name="gender"
          textInfo={genders.female}
          ariaLabel={genders.female}
          checked={selectedValue.female}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.female}
        />
        <ControledRadioInput
          role="radio"
          id="other"
          value="100000002"
          name="gender"
          textInfo={genders.other}
          ariaLabel={genders.other}
          checked={selectedValue.other}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.other}
        />

        <HiddenSubmitInput />
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content"> </div>
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.foreignRegistrationRegistrationNumber}
        onClick={onClickHandler}
        ariaLabel={text}
        className={" floating-weiter-buton"}
      />
    </section>
  );
}
