import {Link} from "react-router-dom";
import {isMobile} from "../../utils/helpers";

const RegistrationSubHeader = ({title, currentProgress, onBackButtonClickHandler, ariaLabel}) => {
    let newTitle = title?.includes("%noarrow%") ? title.replace("%noarrow%", "") : title;

    let hasArrow = !title?.includes("%noarrow%");

    return <>

        <header className="register-header" role="navigation">
            {!isMobile() ?
                <a
                    className="sr-only"
                    href="#main"
                    onClick={(ev) => {
                        ev.preventDefault();
                        document.getElementById("main").scrollIntoView();
                    }}
                >
                    Zum Inhalt springen
                </a>
                : <></>
            }
            {hasArrow && (
                <Link
                    className="register-header__back-button"
                    to="./"
                    onClick={onBackButtonClickHandler}
                    aria-label="Zurück"
                    role="button"
                >
                    <img className="register-header__back-button__img" src="./images/common/headerback.png" alt=""/>
                </Link>
            )}

            <h1 className="register-header__title" aria-label={ariaLabel} tabIndex="0">
                {newTitle}
            </h1>
        </header>
        {currentProgress.includes("no") ? (
            <> </>
        ) : (
            <div className="progress-bar">
                <div className="current-progress" style={{width: currentProgress}}></div>
            </div>
        )}
    </>
};

export default RegistrationSubHeader;
