import React, { useEffect, useState } from "react";
import WeiterButton from "../../../registration/utils/WeiterButton";
import TravelContext from "../../../../context/TravelContext";
import ApplicationRoute from "../../../../config/routes";
import { useHistory } from "react-router-dom";
import Form from "../../../travel-registration/utils/Form";
import HiddenSubmitInput from "../../../../common/HiddenSubmitInput";
import PageTitle from "../../../travel-registration/utils/PageTitle";
import {
  hasAtSymbolEmailValidation,
  hasTwoCharactersAfterAtSymbolEmailValidation,
  isEmailValidation,
} from "../../../../utils/validation";
import HeaderContext from "../../../../context/HeaderContext";
import InputValidation from "../../../../common/InputValidaiton";
import Input from "../../../registration/utils/Input";
import { registrationValidationErrorMessages } from "../../../../utils/validationErrorMessages";
import { getTravelGroupMemberLastIndex_Array } from "../../../../utils/helpers";
import MetaDecorator from "../../../../utils/MetaDecorator";
import HorizontalRule from "../../../../common/HorizontalRule";

const applicationPageTitles = require("../../../../utils/pageTitles.json");

export function AddRelativesEmail({ text }) {
  const history = useHistory();

  const { travel, setTravel } = React.useContext(TravelContext);
  const { setHeader } = React.useContext(HeaderContext);

  const activate = () => {
    setActive(() => true);
  };

  const [active, setActive] = useState(false);

  useEffect(() => {
    document.getElementsByTagName("HTML")[0].addEventListener("click", activate);

    return () => document.getElementsByTagName("HTML")[0].removeEventListener("click", activate);
  }, []);

  const groupMemberIndex = getTravelGroupMemberLastIndex_Array(travel);
  let initEmail = travel?.TravelGroupMember[groupMemberIndex]?.Email;

  const [email, setEmail] = useState(initEmail ? initEmail : "");

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: "Als Auslandsösterreicher/in registrieren",
        ariaLabel: "Als Auslandsösterreicher:in registrieren",
        progress: parseInt((13 / 17) * 100),
      };
    });
  }, []);

  const isValid = () => {
    return (isEmailValidation(email) && hasTwoCharactersAfterAtSymbolEmailValidation(email)) || email.length === 0;
  };

  const updateTravel = () => {
    const groupMemberIndex = travel.TravelGroupMember.length > 0 ? travel.TravelGroupMember.length - 1 : 0;
    let newArray = [...travel.TravelGroupMember];
    newArray[groupMemberIndex] = {
      ...newArray[groupMemberIndex],
      Email: email,
    };

    setTravel((curr) => {
      return {
        ...curr,
        TravelGroupMember: newArray,
      };
    });
  };

  const onChangeHandler = (e) => {
    setEmail(e.target.value);
  };

  const onClickHandler = () => {
    updateTravel();

    history.push(ApplicationRoute.foreignRegistrationAddRelativesNumber);
  };

  const onsubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateTravel();
      history.push(ApplicationRoute.foreignRegistrationAddRelativesNumber);
    }
  };

  return (
    <section className="foreign-registration registration-middle-container">
      <MetaDecorator title={applicationPageTitles.foreignRegistration} />
      <PageTitle text="Bitte geben Sie die E-Mail-Adresse ein (optional):" />
      <p className="page-paragraph page-paragraph--small">Angehörige/r</p>
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form id="form" onSubmit={onsubmitHandler} ariaLabelledBy="contentTitleId" className="max-width-380">
        <Input
          id="email"
          value={email}
          onChange={onChangeHandler}
          placeholder="E-Mail-Adresse"
          type="email"
          labelText="E-Mail-Adresse"
          showValidationBorder={
            email.length > 0 && (!isEmailValidation(email) || !hasTwoCharactersAfterAtSymbolEmailValidation(email))
          }
        />

        <InputValidation
          isHidden={
            !active ||
            !email.length > 0 ||
            !hasAtSymbolEmailValidation(email) ||
            !hasTwoCharactersAfterAtSymbolEmailValidation(email) ||
            isValid()
          }
          text={registrationValidationErrorMessages.notValidEmail}
        />

        <InputValidation
          isHidden={!active || !email.length > 0 || hasAtSymbolEmailValidation(email)}
          text={registrationValidationErrorMessages.missingAtSymbolEmail}
        />

        <InputValidation
          isHidden={
            !active ||
            !email.length > 0 ||
            !hasAtSymbolEmailValidation(email) ||
            hasTwoCharactersAfterAtSymbolEmailValidation(email)
          }
          text={registrationValidationErrorMessages.charactersAfterAtSymbolLessThanTwoEmail}
        />

        <HiddenSubmitInput />
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content"> </div>
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.foreignRegistrationAddRelativesNumber}
        onClick={onClickHandler}
        ariaLabel={text}
        className="hide-weiter-on-keyboard floating-weiter-buton"
      />
    </section>
  );
}
