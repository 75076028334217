import React, {useCallback, useEffect, useState} from "react";
import ApplicationRoute from "../../../config/routes";
import {useHistory} from "react-router-dom";
import HeaderContext from "../../../context/HeaderContext";
import MetaDecorator from "../../../utils/MetaDecorator.jsx";
import HorizontalRule from "../../../common/HorizontalRule.jsx";
import WeiterButton from "../utils/WeiterButton.jsx";
import Input from "../utils/Input.jsx";
import fetcher from "../../../services/fetcher.js";
import Form from "../utils/Form.jsx";
import ContentTitle from "../utils/ContentTitle.jsx";
import {hasTwoCharactersAfterAtSymbolEmailValidation, isEmailValidation} from "../../../utils/validation.js";
import apipath from "../../../apipath.js";

const applicationPageTitles = require("../../../utils/pageTitles.json");
//HINT* REGISTRATION
const IdaEmailPage = () => {
    const history = useHistory();

    const {setHeader} = React.useContext(HeaderContext);

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    const [email, setEmail] = useState("");

    const onEmailChangeHandler = (e) => {
        setEmail(e.target.value);
    };

    useEffect(() => {
        setHeader((curr) => {
            return {
                ...curr,
                inUse: "registration",
                progress: (3 / 5) * 100,
                title: applicationPageTitles.completeAccount + "%noarrow%",
                ariaLabel: "E-Mail ergänzen",
            };
        });
    }, [setHeader]);

    const confirmMail = () => {
        fetcher(
            // Change api call when backend/middleware are modified
            `${apipath.path}users/add/mail`,
            "POST",
            setData,
            setError,
            setLoading,
            setSubmitting,
            false,
            "application/json",
            JSON.stringify(email) // add content
        );
    };

    const submittedWithNoErrors = useCallback(
        () => {
            // Change: uncomment when API is modified
            return !loading; //&& !error;
        }, [loading, error]);

    //After submit
    useEffect(() => {
        if (submittedWithNoErrors()) {
            history.push(ApplicationRoute.idaWelcomePage);
        }
    }, [loading, error, data, submittedWithNoErrors, history]);

    const isValid = () => {
        return isEmailValidation(email) && hasTwoCharactersAfterAtSymbolEmailValidation(email);
    };

    const submittingNow = () => {
        return submitting && loading;
    };

    const onSubmitHandler = (e) => {
        e.preventDefault();

        confirmMail();
    };

    return (
        <div className="registration-middle-container">
            <MetaDecorator title={applicationPageTitles.completeAccount}/>
            <ContentTitle
                id="contentTitle"
                text="Bitte geben Sie Ihre E-Mail Adresse ein:"
            />
            <HorizontalRule className="horizontal-rule-desktop"/>
            <Form id="form" ariaLabelledBy="contentTitle" onSubmit={onSubmitHandler}>
                <Input
                    id="email"
                    htmlFor="email"
                    placeholder="E-Mail-Adresse"
                    label="E-Mail-Adresse"
                    type="text"
                    ariaLabelledBy="contentTitle"
                    labelText="E-Mail-Adresse"
                    onChange={onEmailChangeHandler}
                    value={email}
                />
                <HorizontalRule className="horizontal-rule-desktop"/>
                <WeiterButton
                    text="Weiter"
                    disabled={!isValid() || submittingNow()}
                    onClick={onSubmitHandler}
                    path={ApplicationRoute.TravelDashboardNoPermissions}
                />
            </Form>
        </div>
    );
};

export default IdaEmailPage;