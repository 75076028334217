import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import InputValidation from "../../../common/InputValidaiton";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import { hasMiddleName } from "../../../utils/helpers";
import { hasNumber, isValidFirstAndLastName } from "../../../utils/validation";
import { registrationValidationErrorMessages } from "../../../utils/validationErrorMessages";
import CustomSelectList from "../../foreign-registration/utils/CustomSelectList";
import Form from "../../registration/utils/Form";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import Input from "../../registration/utils/Input";
import WeiterButton from "../../registration/utils/WeiterButton";
import PageTitle from "../../travel-registration/utils/PageTitle";
import MetaDecorator from "../../../utils/MetaDecorator";
import HorizontalRule from "../../../common/HorizontalRule";

const applicationPageTitles = require("../../../utils/pageTitles.json");
const formLabel = "Geben Sie den Vor- und Familiennamen ein";

const relationships = [
  "Eltern, Großeltern",
  "Schwester, Bruder",
  "Kinder, Enkelkinder",
  "Ehegatte/in, Partner/in​",
  "Sonstige Verwandtschaftsverhältnisse",
];

const relationshipEnums = {
  "Eltern, Großeltern": 100000000,
  "Schwester, Bruder": 100000001,
  "Kinder, Enkelkinder": 100000002,
  "Ehegatte/in, Partner/in​": 100000003,
  "Sonstige Verwandtschaftsverhältnisse": 100000004,
};

const AddRelative1 = ({ text }) => {
  const { setHeader } = React.useContext(HeaderContext);

  const [activated, setActivated] = useState(false);

  useEffect(() => {
    let title = "Mitreisende hinzufügen";

    try {
      title = localStorage.getItem("addTGMTitle");
    } catch (error) {}

    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: title,
        progress: "no",
      };
    });
  }, []);

  let initname = "";
  let initRelationship = "";
  try {
    initname = JSON.parse(localStorage.getItem("newTGM")).Name ?? "";
    initRelationship = JSON.parse(localStorage.getItem("newTGM")).DegreeOfRelationship ?? "";
  } catch (error) {}

  const history = useHistory();

  const [relationship, setRelationship] = useState(initRelationship);

  const [fullName, setFullName] = useState(initname);

  const isValidRelationShip = () => {
    return relationships.includes(relationship);
  };

  const isValid = () => {
    return (
      fullName.length > 0 && isValidFirstAndLastName(fullName) && isValidRelationShip() && !hasMiddleName(fullName)
    );
  };

  const updateTGM = () => {
    localStorage.setItem(
      "newTGM",
      JSON.stringify({
        Name: fullName.trim(),
        FirstName: fullName.split(" ")[0],
        LastName: fullName.split(" ")[1],
        DegreeOfRelationship: relationship,
      })
    );
  };

  const onChangeHandler = (e) => {
    setActivated(() => true);
    setFullName(e.target.value);
  };

  const onChangeHandlerCountries = (value) => {
    setRelationship(value);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateTGM();
      history.push(ApplicationRoute.addRelative2);
    }
  };

  const onClickHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateTGM();
      history.push(ApplicationRoute.addRelative2);
    }
  };

  return (
    <section className="add-travel add-relative-container registration-middle-container">
      <MetaDecorator title={applicationPageTitles.editTrip} />
      <PageTitle id={formLabel} text="Bitte geben Sie den Namen ein:" />

      <p className="page-paragraph page-paragraph--small">Mitreisende/r</p>
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form id="form" onSubmit={onSubmitHandler} ariaLabelledBy={formLabel} className="max-width-380">
        <Input
          labelText="Vorname Familienname"
          id="firstName"
          placeholder="Vorname Familienname"
          type="text"
          value={fullName}
          onChange={onChangeHandler}
        />

        <InputValidation
          style={{ marginBottom: "1.5rem" }}
          isHidden={isValidFirstAndLastName(fullName) || !activated || hasMiddleName(fullName)}
          text={
            hasNumber(fullName)
              ? registrationValidationErrorMessages.nameHasNumber
              : registrationValidationErrorMessages.notValidContactPersonFirstAndLastName
          }
        />
        <InputValidation
          isHidden={!hasMiddleName(fullName)}
          text={registrationValidationErrorMessages.middleName}
          ariaLabel={registrationValidationErrorMessages.middleName}
        />

        <CustomSelectList
          label="Verwandtschaftsgrad"
          state={relationship}
          onChangeHandler={onChangeHandlerCountries}
          options={relationships}
        />

        <HiddenSubmitInput />
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content"> </div>
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.addRelative2}
        ariaLabel={text}
        onClick={onClickHandler}
        className="hide-weiter-on-keyboard floating-weiter-buton"
      />
    </section>
  );
};

export default AddRelative1;
