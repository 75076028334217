import React from "react";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import TravelContext from "../../../context/TravelContext";
import {
    compareGermanWords,
    pluck,
    postalCodeFormatter,
} from "../../../utils/helpers";
import Form from "../../travel-registration/utils/Form";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import Input from "../../travel-registration/utils/Input";
import PageTitle from "../../travel-registration/utils/PageTitle";
import WeiterButton from "../../travel-registration/utils/WeiterButton";
import FilteredSelectList from "../utils/FilteredSelectList";
import MetaDecorator from "../../../utils/MetaDecorator";
import HorizontalRule from "../../../common/HorizontalRule";
import {useHistory} from "react-router";
import {getRegionsByCountryName} from "../../travel-registration/utils/countryInfo.js";

const applicationPageTitles = require("../../../utils/pageTitles.json");

export default function RegionSelection({text}) {
    const {travel, setTravel} = React.useContext(TravelContext);
    const {setHeader} = React.useContext(HeaderContext);

    const history = useHistory();

    const [countries] = React.useState(JSON.parse(localStorage.getItem("countries")));
    const [countryNames] = React.useState(
        pluck(countries, "Name").filter((c) => {
            return !(c === "Österreich" || c === "Andere");
        })
    );
    const [regions, setRegions] = React.useState([]);

    const [selectedCountry, setSelectedCountry] = React.useState(travel.CountryName ? travel.CountryName : "");
    const [selectedRegion, setSelectedRegion] = React.useState(travel.RegionName ? travel.RegionName : "");

    const [street, setStreet] = React.useState(travel.Street ? travel.Street : "");
    const [postalCode, setPostalCode] = React.useState(travel.PostalCode ? travel.PostalCode : "");
    const [city, setCity] = React.useState(travel.City ? travel.City : "");

    React.useEffect(() => {
        setHeader((curr) => {
            return {
                ...curr,
                inUse: "registration",
                title: "Als Auslandsösterreicher/in registrieren",
                ariaLabel: "Als Auslandsösterreicher:in registrieren",
                progress: parseInt((1 / 19) * 100),
            };
        });

        if (selectedCountry) {
            setRegions(pluck(getRegionsByCountryName(selectedCountry), "Name"));
        }
    }, []);

    const onClickHandler = (e) => {
        e.preventDefault();
        // Add Coutry and Region to the data

        if (!validation()) {
            let countryId,
                countryName = selectedCountry;
            let regionId,
                regionName = selectedRegion;

            for (let i = 0; i < countries.length; i++) {
                if (countries[i].Name === selectedCountry) {
                    countryId = countries[i].Id;

                    for (let j = 0; j < countries[i].Regions.length; j++) {
                        if (countries[i].Regions[j].Name === selectedRegion) {
                            regionId = countries[i].Regions[j].Id;
                            break;
                        }
                    }
                    break;
                }
            }

            let travelCopy = Object.assign({}, travel);
            travelCopy.Country = countryId;
            travelCopy.Region = regionId;
            travelCopy.RegionName = regionName;
            travelCopy.CountryName = countryName;
            travelCopy.RegionName = regionName;
            travelCopy.Street = street;
            travelCopy.PostalCode = postalCode;
            travelCopy.City = city;

            // travelCopy.StartDate = dateAndTime.format(new Date(), "DD.MM.YYYY");
            setTravel(travelCopy);

            history.push(ApplicationRoute.foreignRegistrationRegistrationName);
        }
    };
    const onCountryChange = (value) => {
        setSelectedCountry(value);
        setSelectedRegion("");
        let regionsArr = pluck(getRegionsByCountryName(value), "Name");
        if (regionsArr.length === 1) {
            setSelectedRegion(regionsArr[0]);
        } else if (regionsArr) {
            regionsArr.sort(compareGermanWords);
        }

        setRegions(regionsArr);
    };
    const onRegionChange = (value) => {
        setSelectedRegion(value);
    };

    const validation = () => {
        return !(selectedCountry && selectedRegion && street && city && postalCode.toString().length >= 1);
    };

    return (
        <section className="foreign-registration registration-middle-container">
            <MetaDecorator title={applicationPageTitles.foreignRegistration}/>
            <PageTitle id="region-selection" text="Wo wohnen Sie?" ariaLabel="Wo wohnen Sie?"/>
            <HorizontalRule className="horizontal-rule-desktop"/>
            <Form id="form" className="form m-t-l max-width-380" ariaLabelledBy="region-selection"
                  onSubmit={onClickHandler}>
                <div className="m-b-s">
                    <FilteredSelectList
                        id="country-select"
                        options={countryNames}
                        state={selectedCountry}
                        label="Bitte wählen Sie aus"
                        onChangeHandler={onCountryChange}
                    />
                </div>
                <FilteredSelectList
                    id="region-select"
                    options={regions}
                    label="Bitte wählen Sie aus"
                    state={selectedRegion}
                    onChangeHandler={onRegionChange}
                    isRegion={true}
                />
                <Input
                    labelText="Straße"
                    id="street"
                    name="street"
                    type="text"
                    htmlFor="street"
                    placeholder="Straße"
                    value={street}
                    onChange={(e) => {
                        setStreet(e.target.value);
                    }}
                />
                <div className="plz-city-flex">
                    <Input
                        labelText="PLZ"
                        id="plz"
                        name="plz"
                        type="text"
                        htmlFor="plz"
                        placeholder="PLZ"
                        value={postalCode}
                        onChange={(e) => {
                            setPostalCode(postalCodeFormatter(postalCode, e.target.value));
                        }}
                    />
                    <Input
                        labelText="Ort"
                        id="city"
                        name="city"
                        htmlFor="city"
                        placeholder="Ort"
                        type="text"
                        value={city}
                        onChange={(e) => {
                            setCity(e.target.value);
                        }}
                    />
                </div>
                <HiddenSubmitInput/>
            </Form>
            <HorizontalRule className="horizontal-rule-desktop"/>
            <div className="hide-weiter-on-keyboard-content"></div>
            <WeiterButton
                disabled={validation()}
                path={ApplicationRoute.foreignRegistrationRegistrationName}
                onClick={onClickHandler}
                ariaLabel={text}
                className="hide-weiter-on-keyboard floating-weiter-buton"
            />
        </section>
    );
}
