import HorizontalRule from "../../../common/HorizontalRule";
import InputValidation from "../../../common/InputValidaiton";
import Separater from "../../../common/Separater";
import EditInput from "../../../common/EditInput";
import {
  hasAtSymbolEmailValidation,
  hasNumber,
  hasTwoCharactersAfterAtSymbolEmailValidation,
  IsDateBiggerThanTodayValidation,
  IsDateSmallerThan_1900_1_1_Validation,
  isDateValidation,
  isEmailValidation,
  isPhoneValidation,
  isValidFirstAndLastName,
} from "../../../utils/validation";
import { registrationValidationErrorMessages } from "../../../utils/validationErrorMessages";
import { getCountryNameFromId } from "./countryInfo";
import { hasMiddleName, isIn, pluck } from "../../../utils/helpers";

const relationshipEnums = {
  "Eltern, Großeltern": 100000000,
  "Schwester, Bruder": 100000001,
  "Kinder, Enkelkinder": 100000002,
  "Ehegatte/in, Partner/in​": 100000003,
  "Sonstige Verwandtschaftsverhältnisse": 100000004,
};
const relationshipInvertEnums = {
  100000000: "Eltern, Großeltern",
  100000001: "Schwester, Bruder",
  100000002: "Kinder, Enkelkinder",
  100000003: "Ehegatte/in, Partner/in​",
  100000004: "Sonstige Verwandtschaftsverhältnisse",
};

const TravelMember = ({
  FirstLastName,
  Birthdate,
  MobilePhone,
  Email,
  Gender,
  Nationality,
  DegreeOfRelationship,
  onChange,
  id = "",
  foreign = false,
  buttonText,
  buttonClickHandler,
  linkText,
  linkTo,
}) => {
  let text = `Mitreisende/r (${FirstLastName})`;

  if (foreign) {
    text = `Angehörige/r (${FirstLastName})`;
  }

  const countriesRaw = JSON.parse(localStorage.getItem("countries"));
  let countries = countriesRaw ? pluck(countriesRaw, "Name") : [];
  countries = countries ? countries.filter((n) => !isIn(n)) : countries;

  return (
    <div>
      <Separater
        text={text}
        buttonText={buttonText}
        buttonClickHandler={buttonClickHandler}
        linkText={linkText}
        linkTo={linkTo}
      />
      <EditInput
        id={"FirstLastName" + id}
        placeholder={"Vorname Familienname"}
        value={FirstLastName}
        labelText={"Vorname Familienname"}
        ariaRequired={true}
        onChange={(e) =>
          onChange({
            target: { id: id + "FirstLastName", value: e.target.value },
          })
        }
      />
      <InputValidation
        text={
          hasNumber(FirstLastName)
            ? registrationValidationErrorMessages.nameHasNumber
            : registrationValidationErrorMessages.notValidContactPersonFirstAndLastName
        }
        isHidden={isValidFirstAndLastName(FirstLastName) || hasMiddleName(FirstLastName)}
      />
      <InputValidation
        isHidden={!hasMiddleName(FirstLastName)}
        text={registrationValidationErrorMessages.middleName}
        ariaLabel={registrationValidationErrorMessages.middleName}
      />

      <HorizontalRule />
      <EditInput
        id={"Birthdate" + id}
        value={Birthdate}
        onChange={(e) => onChange({ target: { id: id + "Birthdate", value: e.target.value } })}
        placeholder="Geburtsdatum"
        labelText="Geburtsdatum"
        showValidationBorder={
          !isDateValidation(Birthdate) ||
          !IsDateSmallerThan_1900_1_1_Validation(Birthdate) ||
          IsDateBiggerThanTodayValidation(Birthdate)
        }
      />
      <InputValidation isHidden={isDateValidation(Birthdate)} text={registrationValidationErrorMessages.notValidDate} />

      <InputValidation
        isHidden={!IsDateSmallerThan_1900_1_1_Validation(Birthdate) || !isDateValidation(Birthdate)}
        text={registrationValidationErrorMessages.dateOfBirthLessThan_01_01_1900}
      />

      <InputValidation
        isHidden={IsDateBiggerThanTodayValidation(Birthdate) || !isDateValidation(Birthdate)}
        text={registrationValidationErrorMessages.dateBiggerThanToday}
      />

      <HorizontalRule />
      <EditInput
        id={"MobilePhone" + id}
        placeholder={"+43"}
        value={MobilePhone}
        labelText={"Mobiltelefonnummer (inkl. Ländervorwahl)"}
        ariaRequired={true}
        onChange={(e) =>
          onChange({
            target: { id: id + "MobilePhone", value: e.target.value },
          })
        }
      />
      <InputValidation
        text={registrationValidationErrorMessages.notValidPhoneNumber}
        isHidden={isPhoneValidation(MobilePhone, true) || MobilePhone.length === 0 || MobilePhone === ""}
      />
      <HorizontalRule />
      <EditInput
        id={"Email" + id}
        placeholder={"E-Mail-Adresse"}
        value={Email}
        labelText={"E-Mail-Adresse"}
        ariaRequired={false}
        onChange={(e) => onChange({ target: { id: id + "Email", value: e.target.value } })}
      />
      <InputValidation
        isHidden={
          Email.length === 0 ||
          !hasAtSymbolEmailValidation(Email) ||
          !hasTwoCharactersAfterAtSymbolEmailValidation(Email) ||
          isEmailValidation(Email)
        }
        text={registrationValidationErrorMessages.notValidEmail}
      />
      <InputValidation
        isHidden={Email.length === 0 || hasAtSymbolEmailValidation(Email)}
        text={registrationValidationErrorMessages.missingAtSymbolEmail}
      />
      <InputValidation
        isHidden={
          Email.length === 0 ||
          !hasAtSymbolEmailValidation(Email) ||
          hasTwoCharactersAfterAtSymbolEmailValidation(Email)
        }
        text={registrationValidationErrorMessages.charactersAfterAtSymbolLessThanTwoEmail}
      />
      <HorizontalRule />
      <EditInput
        id={"Gender" + id}
        placeholder="Geschlecht"
        value={Gender}
        labelText="Geschlecht"
        ariaRequired={true}
        onChange={(value) => onChange({ target: { id: id + "Gender", value: value } })}
        options={["männlich", "weiblich", "divers"]}
      />
      <HorizontalRule />
      <EditInput
        id={"Nationality" + id}
        placeholder="Staatsangehörigkeit"
        value={getCountryNameFromId(Nationality)}
        labelText="Staatsangehörigkeit"
        ariaRequired={true}
        onChange={(value) =>
          onChange({
            target: {
              id: id + "Nationality",
              value: countriesRaw.find((el) => {
                return el.Name === value;
              })["Id"],
            },
          })
        }
        filtered={true}
        options={countries}
      />
      <HorizontalRule />
      <EditInput
        id={"DegreeOfRelationship" + id}
        placeholder="Verwandtschaftsgrad"
        value={relationshipInvertEnums[DegreeOfRelationship]}
        labelText="Verwandtschaftsgrad"
        ariaRequired={true}
        onChange={(value) =>
          onChange({
            target: {
              id: id + "DegreeOfRelationship",
              value: relationshipEnums[value],
            },
          })
        }
        options={[
          "Eltern, Großeltern",
          "Schwester, Bruder",
          "Kinder, Enkelkinder",
          "Ehegatte/in, Partner/in​",
          "Sonstige Verwandtschaftsverhältnisse",
        ]}
      />
    </div>
  );
};

export default TravelMember;
