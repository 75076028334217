import React, { FC, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HashRouter } from 'react-router-dom';
import App from './App';
import './Style/global.scss'
import HeaderContext from '../../context/HeaderContext';
import { isMobileEnv } from '../../utils/helpers';
import MetaDecorator from '../../utils/MetaDecorator';

const queryClient = new QueryClient()

const DotWebsite: FC = () => {
    const ctx = React.useContext(HeaderContext) as any;

    useEffect(() => {
        ctx.setHeader((curr: any) => {
            return {
                ...curr,
                inUse: isMobileEnv() ? "content" : "main",
                title: "Urkunden-Bestellung",
            };
        });
    }, []);

    return (
        <>
            <MetaDecorator title="Urkunden-Bestellung" description={undefined} />
            <HashRouter basename={process.env.REACT_APP_ROUTER_BASENAME}>
                <QueryClientProvider client={queryClient}>
                    <div className="dot-website">
                        <App />
                    </div>
                </QueryClientProvider>
            </HashRouter>
        </>
    )
}

export default DotWebsite