import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import apipath from "../../../apipath";
import HeaderContext from "../../../context/HeaderContext";
import fetcher from "../../../services/fetcher";
import Loader from "../../travel-registration/utils/Loader";
import Map from "../utils/Map";
import { useTranslation } from "react-i18next";

function Vertretungen() {
  const { setHeader } = React.useContext(HeaderContext);
  const { t } = useTranslation();

  //lat and lng are the center of Austria
  const [center, setCenter] = useState({ lat: 46.21, lng: 13.5 });

  //user location
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const [showLoader, setShowLoader] = useState(true);

  const [location, setLocation] = useState({ long: "", lat: "" });

  const getMiddlewareLocationObject = () => {
    fetcher(
      `${apipath.path}geocodes/${location.lat}/${location.long}`,
      "GET",
      setData,
      setError,
      setLoading,
      setSubmitting,
      true
    );
  };

  useEffect(() => {
    //get location from getLocation
    enableLocation();
  }, []);

  useEffect(() => {
    //load middleware object
    if ((!!location.long || location.long < 0) && (!!location.lat || location.lat < 0)) {
      getMiddlewareLocationObject();
    }
  }, [location]);

  function enableLocation() {
    if (navigator.geolocation) navigator.geolocation.getCurrentPosition(handleGetCurrentPosition, onError);
  }

  function handleGetCurrentPosition(location) {
    setLocation((curr) => {
      return {
        ...curr,
        long: location.coords.longitude,
        lat: location.coords.latitude,
      };
    });
  }
  useEffect(() => {
    //use location object from middleware
    if (!loading && !error) {
      setCenter((curr) => {
        return { ...curr, lat: data.latitude, lng: data.longitude };
      });
    }
  }, [loading]);

  function onError() {
    setCenter((curr) => {
      return { ...curr, lat: 46.21, lng: 13.5 };
    });

    setShowLoader(() => false);
  }

  ///

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "map",
        title: t("pages.vertretungen.title"),
        progress: "no",
        setMapCenter: setCenter,
      };
    });
  }, []);

  if (showLoader && loading) return <Loader />;

  return (
    <div>
      <Helmet>
        <title>{t("pages.vertretungen.title")}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1.0, maximum-scale=1.0" />
      </Helmet>
      <Map center={center} />
    </div>
  );
}

export default Vertretungen;
