import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import { getGenderFromEnumGerman, getUIID, isIn, pluck } from "../../../utils/helpers";
import FilteredSelectList from "../../foreign-registration/utils/FilteredSelectList";
import Form from "../../registration/utils/Form";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import WeiterButton from "../../registration/utils/WeiterButton";
import PageTitle from "../../travel-registration/utils/PageTitle";
import MetaDecorator from "../../../utils/MetaDecorator";
import SuccessContext from "../../../context/SuccessContext";

const applicationPageTitles = require("../../../utils/pageTitles.json");
const AddRelative6 = ({ text }) => {
  const data = JSON.parse(localStorage.getItem("countries"));
  let countries = pluck(data, "Name");
  countries = countries ? countries.filter((n) => !isIn(n)) : countries;

  const history = useHistory();

  const { setHeader } = React.useContext(HeaderContext);
  const { successBox, setSuccessBox } = React.useContext(SuccessContext);

  const [path, setPath] = useState(ApplicationRoute.TravelDashboardNoPermissions);

  const [livingIn, setLivingIn] = useState("");

  useEffect(() => {
    let title = "Mitreisende hinzufügen";

    try {
      title = localStorage.getItem("addTGMTitle");
    } catch (error) {}

    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: title,
        progress: "no",
      };
    });

    try {
      setPath(localStorage.getItem("addTGMURL"));
    } catch (error) {}
  }, []);

  const isValid = () => {
    return !!livingIn;
  };

  // const getCountryId = () => {
  //   if (!livingIn) {
  //     return null;
  //   } else {
  //     const { Id } = data.find((el) => {
  //       return el.Name === livingIn;
  //     });

  //     return Id;
  //   }
  // };

  const updateTGM = () => {
    let tgm = JSON.parse(localStorage.getItem("newTGM"));
    localStorage.setItem(
      "newTGM",
      JSON.stringify({
        ...tgm,
        Nationality: livingIn,
        Gender: getGenderFromEnumGerman(tgm.Gender),
      })
    );
  };

  const onClickHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateTGM();

      setSuccessBox((curr) => {
        return {
          ...curr,
          hidden: false,
          text: "Mitreisende/r wurde hinzugefügt!",
          ariaLabel: "Mitreisende/r wurde hinzugefügt!",
          trigger: getUIID(),
        };
      });

      history.push(path);
    }
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateTGM();
      history.push(path);
    }
  };

  const onChangeHandlerCountries = (value) => {
    setLivingIn(value);
  };
  return (
    <div className="add-relative-container registration-middle-container">
      <MetaDecorator title={applicationPageTitles.editTrip} />
      <PageTitle id="add-county" text="Bitte geben Sie die Staatsangehörigkeit ein:" />

      <p className="page-paragraph page-paragraph--small">Mitreisende/r</p>

      <Form id="form" onSubmit={onSubmitHandler} ariaLabelledBy="add-county" className="max-width-380">
        <FilteredSelectList
          label="Staatsangehörigkeit auswählen"
          state={livingIn}
          onChangeHandler={onChangeHandlerCountries}
          options={countries}
        />

        <HiddenSubmitInput />

        <WeiterButton
          disabled={!isValid()}
          path={path}
          onClick={onClickHandler}
          ariaLabel={text}
          text="Mitreisende Person hinzufügen"
          className={"floating-weiter-buton"}
        />
      </Form>
    </div>
  );
};

export default AddRelative6;
