import dateAndTime from "date-and-time";
import {hasMinLettersValidation__normal, hasOnlyDigitsValidation, isDateValidation} from "./validation";
import defaultUserValues from "../config/defaultUserValues";
import apipath from "../apipath";

export const areArraysEqual = (first, second) => {
    return (
        Array.isArray(first) &&
        Array.isArray(second) &&
        first.length === second.length &&
        first.every((val, index) => val === second[index])
    );
};

export const setLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const setSessionStorage = (key, value) => {
    sessionStorage.setItem(key, JSON.stringify(value));
};

////
////

const key = "9e4cfa4b0e964717d3834219421eb40fbaa600fbfc859753156944f5d28a567b";
const getIsLocalStorage = () => {
    let is;

    try {
        is = localStorage.getItem("ls").toString() === key;
    } catch (error) {
        is = false;
    }

    return is;
};

export const setIsLoggedTrue = () => {
    if (getIsLocalStorage()) {
        localStorage.setItem("isLogged", true);
    } else {
        sessionStorage.setItem("isLogged", true);
    }
};

export const setIsLoggedFalse = () => {
    if (getIsLocalStorage()) {
        localStorage.setItem("isLogged", false);
    } else {
        sessionStorage.setItem("isLogged", false);
    }
};

export const getIsLoggedStorage = () => {
    if (getIsLocalStorage()) {
        return localStorage.getItem("isLogged");
    } else {
        return sessionStorage.getItem("isLogged");
    }
};

export const removeIsLoggedStorage = () => {
    if (getIsLocalStorage()) {
        localStorage.setItem("isLogged", "");
    } else {
        sessionStorage.setItem("isLogged", "");
    }
};

export const getIsLogged = () => {
    try {
        let isLogged = getIsLoggedStorage();
        return isLogged.toString().toLowerCase() === "true";
    } catch (error) {
        return false;
    }
};


export const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
};

//Return array of only one property from array of objects
export const pluck = (array, key) => {
    return array.map((item) => item[key]);
};

//IN USE
export const getEnumFromGender = (gender) => {
    switch (gender) {
        case "männlich":
            return "100000000";
        case "weiblich":
            return "100000001";
        case "divers":
            return "100000002";
        case "male":
            return "100000000";
        case "female":
            return "100000001";
        case "other":
            return "100000002";
        default:
            return gender;
    }
};

export const getGenderFromEnum = (genderEnum) => {
    switch (genderEnum.toString()) {
        case "100000000":
            return "male";
        case "100000001":
            return "female";
        case "100000002":
            return "other";
        default:
            return genderEnum;
    }
};

export const getGenderFromEnumGerman = (genderEnum) => {
    switch (genderEnum.toString()) {
        case "100000000":
            return "männlich";
        case "100000001":
            return "weiblich";
        case "100000002":
            return "divers";
        default:
            return genderEnum;
    }
};

export const convertMiddlewareDateToAppDateFormat = (mwDate) => {
    return dateAndTime.transform(mwDate, "YYYY-MM-DD HH:mm:ss", "DD.MM.YYYY");
};

export const convertAppDateToMiddlewareDateFormat = (appDate) => {
    return dateAndTime.transform(appDate, "DD.MM.YYYY", "YYYY-MM-DD") + "T23:59:59";
};

export const setOptionalPhoneStringToNull = (phone) => {
    if (phone.length <= 3) {
        return null;
    }

    return phone;
};

export const setZeroUIIDtoNull = (uiid) => {
    if (uiid === "00000000-0000-0000-0000-000000000000") {
        return null;
    }

    return uiid;
};

//UPDATE
//Object to send to Middleware
//
//map the APP user object to the middleware JSON object

export const setApiUserObjectUpdate = (user) => {
    let county = user.County;
    let homeregion = user.CountyHomeRegionId;
    return {
        IdNumber: user.IdNumber ? user.IdNumber : null,
        AdditionalTravelDokuments: null,
        BirthDate: convertAppDateToMiddlewareDateFormat(user.BirthDate),
        City: user.City ? user.City : null,
        Country: user.Country ? setZeroUIIDtoNull(user.Country) : null,
        County: setZeroUIIDtoNull(county),
        Email: user.Email,
        EmergencyContactEmail: user.ContactPerson.Email ? user.ContactPerson.Email : null,
        EmergencyContactName: user.ContactPerson.FirstLastName ? user.ContactPerson.FirstLastName : null,
        EmergencyContactPhoneNumber: user.ContactPerson.PhoneNumber
            ? setOptionalPhoneStringToNull(user.ContactPerson.PhoneNumber.replace(/\s/g, ""))
            : null,
        FamilyStatusCode: null,
        FirstName: user.FirstName,
        Gender: user.Gender ? getEnumFromGender(user.Gender) : null,
        HomeRegion: setZeroUIIDtoNull(homeregion),
        IsLivingAbroad: user.IsLivingAbroad,
        JobTitle: null,
        LastName: user.LastName,
        MobilePhone: user.PhoneNumber.replace(/\s/g, ""),
        Nationality: user.Nationality ? user.Nationality : null,
        Passport: user.PassNumber ? user.PassNumber : null,
        PostalCode: user.PostalCode ? user.PostalCode : null,
        PreviousNames: null,
        Street: user.Street ? user.Street : null,
        //GDPR*
        ...GDPRUserObject(user),
        FamilyGroupMember: user.FamilyGroupMember
            ? user.FamilyGroupMember.map((fg) => {
                return relativeObj(fg);
            })
            : [],
        Relatives: user.Relatives
            ? user.Relatives.map((fg) => {
                return relativeObj(fg);
            })
            : [],
    };
};
export const setApiUserObjectUpdate2 = (user, travel) => {
    return {
        CountyHomeRegionName: user.CountyHomeRegionName,
        CountyHomeRegionId: user.CountyHomeRegionId,
        IdNumber: user.IdNumber ? user.IdNumber : null,
        AdditionalTravelDokuments: null,
        BirthDate: convertAppDateToMiddlewareDateFormat(user.BirthDate),
        City: travel.City ? travel.City : null,
        Country: travel.Country ? travel.Country : null,
        County: travel.Region ? travel.Region : null,
        HomeRegion: user.CountyHomeRegionId,
        Email: user.Email,
        EmergencyContactEmail: user.ContactPerson.Email ? user.ContactPerson.Email : null,
        EmergencyContactName: user.ContactPerson.FirstLastName ? user.ContactPerson.FirstLastName : null,
        EmergencyContactPhoneNumber: user.ContactPerson.PhoneNumber
            ? setOptionalPhoneStringToNull(user.ContactPerson.PhoneNumber.replace(/\s/g, ""))
            : null,
        FirstName: user.FirstName,
        Gender: getEnumFromGender(user.Gender),
        IsLivingAbroad: true,
        JobTitle: null,
        LastName: user.LastName,
        MobilePhone: user.PhoneNumber.replace(/\s/g, ""),
        Nationality: user.Nationality ? user.Nationality : null,
        Passport: user.PassNumber ? user.PassNumber : null,
        PostalCode: travel.PostalCode ? travel.PostalCode : null,
        PreviousNames: null,
        Street: travel.Street ? travel.Street : null,
        FamilyStatusCode: travel.TravelGroupMember.length ? travel.TravelGroupMember.length : null,
        FamilyGroupMember: travel.TravelGroupMember.length
            ? travel.TravelGroupMember.map((element) => {
                element.FirstName = element.FirstLastName.split(" ")[0];
                element.LastName = element.FirstLastName ? getSecondAfterSpace(element.FirstLastName.trim()).trim() : null;
                element.MobilePhone = element.MobilePhone.replace(/\s/g, "");
                return element;
            })
            : null,
        ...GDPRUserObject(user)
    };
};
export const setApiUserObjectUpdate3 = (user) => {
    return {
        CountyHomeRegionName: user.CountyHomeRegionName,
        CountyHomeRegionId: user.CountyHomeRegionId,
        IdNumber: user.IdNumber ? user.IdNumber : null,
        AdditionalTravelDokuments: null,
        BirthDate: convertAppDateToMiddlewareDateFormat(user.BirthDate),
        City: user.City ? user.City : null,
        Country: user.Country ? user.Country : null,
        County: user.County ? user.County : null,
        HomeRegion: user.CountyHomeRegionId,
        Email: user.Email,
        EmergencyContactEmail: user.ContactPerson.Email ? user.ContactPerson.Email : null,
        EmergencyContactName: user.ContactPerson.FirstLastName ? user.ContactPerson.FirstLastName : null,
        EmergencyContactPhoneNumber: user.ContactPerson.PhoneNumber
            ? setOptionalPhoneStringToNull(user.ContactPerson.PhoneNumber.replace(/\s/g, ""))
            : null,
        FirstName: user.FirstName,
        Gender: getEnumFromGender(user.Gender),
        IsLivingAbroad: user.IsLivingAbroad,
        JobTitle: null,
        LastName: user.LastName,
        MobilePhone: user.PhoneNumber.replace(/\s/g, ""),
        Nationality: user.Nationality ? user.Nationality : null,
        Passport: user.PassNumber ? user.PassNumber : null,
        PostalCode: user.PostalCode ? user.PostalCode : null,
        PreviousNames: null,
        Street: user.Street ? user.Street : null,
        FamilyStatusCode: null,
        FamilyGroupMember: user.Relatives.length
            ? user.Relatives.map((element) => {
                element.FirstName = element.FirstLastName ? element.FirstLastName.split(" ")[0] : "";
                element.LastName = element.FirstLastName ? getSecondAfterSpace(element.FirstLastName.trim()).trim() : null;
                element.MobilePhone = element.MobilePhone ? element.MobilePhone.replace(/\s/g, "") : "";
                // element.Birthdate = element.Birthdate
                //   ? convertAppDateToMiddlewareDateFormat(element.Birthdate)
                //   : "";
                return element;
            })
            : null,
        ...GDPRUserObject(user)
    };
};

//REGISTRATION
//Object to send to Middleware
//
//map the APP user object to the middleware JSON object
export const setApiUserObject = (user) => {
    return {
        // AdditionalTravelDokuments: null,
        // BirthDate: user.BirthDate
        //   ? convertAppDateToMiddlewareDateFormat(user.BirthDate)
        //   : null,
        // City: user.City ? user.City : null,
        // Country: user.LivingInUIID ? setZeroUIIDtoNull(user.LivingInUIID) : null,
        // County: user.CountyUIID ? setZeroUIIDtoNull(user.CountyUIID) : null,
        Email: user.Email,
        Password: user.Password,
        // EmergencyContactEmail: user.ContactPerson.Email
        //   ? user.ContactPerson.Email
        //   : null,
        // EmergencyContactName: user.ContactPerson.FirstLastName
        //   ? user.ContactPerson.FirstLastName
        //   : null,
        // EmergencyContactPhoneNumber: user.ContactPerson.PhoneNumber
        //   ? setOptionalPhoneStringToNull(
        //       user.ContactPerson.PhoneNumber.replace(/\s/g, ''),
        //     )
        //   : null,
        // FamilyStatusCode: null,
        // FirstName: user.FirstName ? user.FirstName : null,
        // Gender: user.Gender ? getEnumFromGender(user.Gender) : null,
        // HomeRegion: user.LivingInUIID ? setZeroUIIDtoNull(user.LivingInUIID) : null,
        // IsLivingAbroad: false,
        // JobTitle: null,
        // LastName: user.LastName ? user.LastName : null,
        // MobilePhone: user.PhoneNumber ? user.PhoneNumber.replace(/\s/g, '') : null,
        // Nationality: user.Nationality ? user.Nationality : null,
        // Passport: user.PassNumber ? user.PassNumber : null,
        // PostalCode: null,
        // PreviousNames: null,
        // Street: null,
    };
};

//
//Object comming from Middleware
//
//map the middleware JSON object to the APP user object
export const setUserValuesOnLogin = (loginResponseJson) => {
    return {
        IdNumber: loginResponseJson.IdNumber ? loginResponseJson.IdNumber : "",
        CountyHomeRegionName: loginResponseJson.CountyHomeRegionName,
        CountyHomeRegionId: loginResponseJson.CountyHomeRegionId,
        UsedId: loginResponseJson.UserId ? loginResponseJson.UserId : "",
        State: "",
        Nationality: "",
        NationalityUIID: loginResponseJson.Nationality ? loginResponseJson.Nationality : "",
        LivingIn: "",
        LivingInUIID: loginResponseJson.HomeRegion ? loginResponseJson.HomeRegion : "",
        Country: loginResponseJson.Country ? loginResponseJson.Country : "",
        CountryUIID: loginResponseJson.Country ? loginResponseJson.Country : "",
        County: loginResponseJson.County ? loginResponseJson.County : "",
        HomeRegion: loginResponseJson.HomeRegion ? loginResponseJson.HomeRegion : "",
        CountyUIID: loginResponseJson.County ? loginResponseJson.County : "",
        FirstName: loginResponseJson.FirstName ? loginResponseJson.FirstName : "",
        LastName: loginResponseJson.LastName ? loginResponseJson.LastName : "",
        City: loginResponseJson.City ? loginResponseJson.City : "",
        CityUIID: "",
        Street: loginResponseJson.Street ? loginResponseJson.Street : "",
        PostalCode: loginResponseJson.PostalCode ? loginResponseJson.PostalCode : "",
        Email: loginResponseJson.Email ? loginResponseJson.Email : "",
        Password: "",
        SocialSecurityNumber: "",
        PassNumber: loginResponseJson.Passport ? loginResponseJson.Passport : "",
        BirthDate: loginResponseJson.BirthDate ? convertMiddlewareDateToAppDateFormat(loginResponseJson.BirthDate) : "",
        Gender: loginResponseJson.Gender ? loginResponseJson.Gender : "",
        PhoneNumber: loginResponseJson.MobilePhone ? FormatPhone(loginResponseJson.MobilePhone) : "",
        IsLivingAbroad: loginResponseJson.IsLivingAbroad ? loginResponseJson.IsLivingAbroad : "false",
        ContactPerson: {
            FirstLastName: loginResponseJson.EmergencyContactName ? loginResponseJson.EmergencyContactName : "",
            PhoneNumber: loginResponseJson.EmergencyContactPhoneNumber
                ? FormatPhone(loginResponseJson.EmergencyContactPhoneNumber)
                : "",
            Email: loginResponseJson.EmergencyContactEmail ? loginResponseJson.EmergencyContactEmail : "",
        },
        FamilyGroupMember: loginResponseJson.FamilyGroupMember ? loginResponseJson.FamilyGroupMember : [],
        Relatives: loginResponseJson.FamilyGroupMember
            ? loginResponseJson.FamilyGroupMember.map((element) => {
                element.FirstLastName = element.FirstName + " " + element.LastName;
                return element;
            })
            : [],
        TempDeletedContact: "",
        TempDeletedRelatives: [],
        HasPersonInit: "",
        HasRelativesInit: "",
        TempPerson: "",
        TempRelatives: [],
        SaveChanges: false,
        ...GDPRUserObject(loginResponseJson)
    };
};

const GDPRUserObject = (user) => {
    let isLastVersion = (user.GDPRAccountLatestVersion
        ? user.GDPRAccountLatestVersion
        : false
    )
    
    return {
        GDPRAccount: true,
        GDPRDataRetention: user.GDPRDataRetention ? user.GDPRDataRetention : false,
        GDPRForeignAustrian: user.GDPRForeignAustrian ? user.GDPRForeignAustrian : false,
        GDPRForeignAustrianOfficialInfo: user.GDPRForeignAustrianOfficialInfo
            ? user.GDPRForeignAustrianOfficialInfo
            : false,
        GDPRForeignAustrianInofficialInfo: user.GDPRForeignAustrianInofficialInfo
            ? user.GDPRForeignAustrianInofficialInfo
            : false,
        GDPRAccountLatestVersion: user.GDPRAccountLatestVersion === null || user.GDPRAccountLatestVersion === undefined
            ? true
            : isLastVersion,
        GDPRForeignAustrianOfficialInfoViewflag: user.GDPRForeignAustrianOfficialInfoViewflag
            ? user.GDPRForeignAustrianOfficialInfoViewflag
            : false,
        GDPRForeignAustrianInofficialInfoViewflag: user.GDPRForeignAustrianInofficialInfoViewflag
            ? user.GDPRForeignAustrianInofficialInfoViewflag
            : false,
    };
};

//If names entered either Email or Phonenumber has to be also entered
export const isValidContactPerson = ({FirstLastName, PhoneNumber, Email}) => {
    if (FirstLastName === false && PhoneNumber === false && Email === false) {
        return true;
    }

    return FirstLastName === true && (PhoneNumber === true || Email === true);
};

export const dateFormatter = (prevDate, currDate) => {
    let newDate = currDate;

    if (currDate.length < prevDate.length) {
        return newDate;
    }

    let isNewEntryNum = hasOnlyDigitsValidation(currDate.replace(prevDate, ""));
    if (isDateValidation(currDate)) {
        return currDate;
    }

    if (!isNewEntryNum) {
        return prevDate;
    }

    if (currDate.length > 10) {
        return prevDate;
    }

    if (prevDate.length === 2 || prevDate.length === 5) {
        let lastChar = currDate[currDate.length - 1];
        newDate = currDate.substr(0, currDate.length - 1) + "." + lastChar;

        return newDate;
    }

    if (currDate.length === 2 || currDate.length === 5) {
        newDate += ".";
        return newDate;
    }

    return currDate;
};

export const removeAllSlashes = (text) => {
    return text.replace(/\//g, "");
};

export const replaceAll_rgx = (text) => {
    return text.replace(/\(at\)/g, "@");
};

export const phoneFormatter = (prev, curr) => {
    // if (!curr.startsWith("+")) {
    //   return prev;
    // }

    if (curr.length > 1 && curr[curr.length - 1] === "+") {
        return prev;
    }

    if (curr.length === 1 && curr === "+") {
        return curr;
    }

    if (curr.length > 0 && !hasOnlyDigitsValidation(curr.replace("+", "").replace(/\s/g, ""))) {
        return prev;
    }

    if (curr.replace("+", "").replace(/\s/g, "").length === 18) {
        return prev.trim();
    }

    if (curr === "" || curr.length === 0) {
        // return "+";
        return "";
    }

    if (curr.length < prev.length) {
        return curr;
    }

    if (curr.length === 3) {
        return curr + " ";
    }

    if (curr.length > 2 && curr.substring(3).length % 4 === 0) {
        return curr + " ";
    }

    if (curr.length === 4) {
        const lastChar = curr[curr.length - 1];

        return prev + " " + lastChar;
    }

    return curr;
};

export const FormatPhone = (text) => {
    let phArray = text.match(/.{1,3}/g);
    if (!phArray) {
        return text;
    }
    return phArray.join(" ");
};

export const ClearTempAddUsers = (user) => {
    return {
        ...user,
        TempDeletedContact: [],
        TempPerson: "",
        HasPersonInit: "",
        TempDeletedRelatives: [],
        TempRelatives: [],
    };
};

const getCountryIdFromLocalStorage = (countryName) => {
    const countries = JSON.parse(localStorage.getItem("countries"));

    let obj = countries.filter((c) => {
        return c.Name === countryName;
    });

    return obj[0].Id;
};

const travelGroupMemmberObjectApi = (obj) => {
    return {
        Id: obj.Id ? obj.Id : null,
        Name: obj.Name ? obj.Name : null,
        FirstName: obj.FirstName ? obj.FirstName : null,
        LastName: obj.LastName ? getSecondAfterSpace(obj.FirstLastName.trim()).trim() : null,
        Birthdate: obj.Birthdate ? obj.Birthdate : null,
        MobilePhone: obj.MobilePhone ? setOptionalPhoneStringToNull(obj.MobilePhone.replace(/\s/g, "")) : null,
        Gender: obj.Gender ? getEnumFromGender(obj.Gender) : null,
        Email: obj.Email ? obj.Email : null,
        Nationality: obj.Nationality ? obj.Nationality : null,
        DegreeOfRelationship: obj.DegreeOfRelationship ? obj.DegreeOfRelationship : null,
    };
};

const relativeObj = (obj) => {
    return {
        Id: obj.Id ? obj.Id : null,
        Birthdate: obj.Birthdate ? obj.Birthdate : null,
        FirstLastName: obj.FirstLastName ? obj.FirstLastName : null,
        FirstName: obj.FirstName ? obj.FirstName : null,
        LastName: obj.LastName ? getSecondAfterSpace(obj.FirstLastName.trim()).trim() : null,
        MobilePhone: obj.MobilePhone ? setOptionalPhoneStringToNull(obj.MobilePhone.replace(/\s/g, "")) : null,
        Gender: obj.Gender ? getEnumFromGender(obj.Gender) : null,
        Nationality: obj.Nationality ? obj.Nationality : null,
        DegreeOfRelationship: obj.DegreeOfRelationship ? obj.DegreeOfRelationship : null,
    };
};

const travelStopObjectApi = (obj) => {
    return {
        Id: obj.Id ? obj.Id : null,
        Name: obj.Name ? obj.Name : null,
        StopStart: obj.StopStart ? obj.StopStart : null,
        StopEnd: obj.StopEnd ? obj.StopEnd : null,
        CountryId: obj.CountryId ? getCountryIdFromLocalStorage(obj.CountryId) : null,
        Comment: obj.Comment ? obj.Comment : null,
    };
};

//HINT* ADD TRAVEL
export const setApiObjectAddTravel = (obj) => {
    return {
        Id: obj.Id ? obj.Id : null,
        Name: obj.Name ? obj.Name : null,
        StartDate: obj.StartDate ? obj.StartDate : null,
        EndDate: obj.EndDate ? obj.EndDate : null,
        CountryId: obj.CountryId ? obj.CountryId : null,
        Comment: obj.TourOperator ? obj.TourOperator : null,
        IsTourGroup: obj.IsTourGroup ?? null,

        TravelGroupMember: obj.TravelGroupMember.map((tg) => {
            return travelGroupMemmberObjectApi(tg);
        }),
        TravelStops: obj.TravelStops.map((ts) => {
            return travelStopObjectApi(ts);
        }),
    };
};

//HINT* ADD TRAVEL
export const setApiObjectForeign = (obj) => {
    return {
        FirstName: obj.FirstName ? obj.FirstName : null,
        LastName: obj.LastName ? obj.LastName : null,
        StartDate: obj.StartDate ? obj.StartDate : null,
        IsLivingAbroad: true,
        CountryId: obj.Country ? obj.Country : null,
        RegionId: obj.Region ? obj.Region : null,
        CountyId: obj.County ? obj.County : null,

        FamilyGroupMember: obj.FamilyGroupMember.map((tg) => {
            return travelGroupMemmberObjectApi(tg);
        }),
    };
};

export const getTravelGroupMemberLastIndex_Array = (arr) => {
    return arr.TravelGroupMember.length > 0 ? arr.TravelGroupMember.length - 1 : 0;
};

export function getCountryNameFromId__countries_from_arg(id, countries) {
    let name = "";

    for (let i = 0; i < countries.length; i++) {
        if (countries[i].Id === id) {
            name = countries[i].Name;
            break;
        }
    }

    return name;
}

export function getIsoCodeFromName(name) {
    let countries = localStorage.getItem("countries");
    let isoCode = "";

    if (countries) {
        countries = JSON.parse(countries);
        for (let i = 0; i < countries.length; i++) {
            if (countries[i].Name === name) {
                isoCode = countries[i].IsoCode;
                break;
            }
        }
    }

    return isoCode;
}

export function getCountryUidFromName(name, countriesVB) {
    let isoCode = getIsoCodeFromName(name);

    let countryUid = "";

    for (let i = 0; i < countriesVB.length; i++) {
        if (countriesVB[i].countryIsoCode === isoCode) {
            countryUid = countriesVB[i].countryUid;
            break;
        }
    }

    return countryUid;
}

export function getCountryPicture(name, countriesVB) {
    return `${apipath.pathBMEIAgvat_API}country/${getCountryUidFromName(name, countriesVB)}.jpg`;
}

export const getDistance = (lat1, lon1, lat2, lon2, index) => {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
        dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    return {distance: dist * 1.609344, arrayIndex: index};
};

export const isEmptyObject = (obj) => {
    for (let prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            return false;
        }
    }

    return JSON.stringify(obj) === JSON.stringify({});
};

//ok with -> 2021-04-04T23:59:59
export const getYMDfromMiddleware = (date) => {
    if (!date) {
        return date;
    }

    const year = parseInt(date.substring(0, 4));
    const month = parseInt(date.substring(5, 7)) - 1;
    const day = parseInt(date.substring(8, 10));

    return {year, month, day};
};

//ok with -> 2021-04-04T23:59:59
export const getYMDfromMiddleware__FOR__APP__USE = (date) => {
    date = date.toString();

    const year = parseInt(date.substring(0, 4));
    const month = parseInt(date.substring(5, 7));
    const day = parseInt(date.substring(8, 10));

    return addZeroToDate(day) + "." + addZeroToDate(month) + "." + year;
};

export const getYMDfromMiddlewarePlusOneDay = (date) => {
    if (!date) {
        return date;
    }

    const year = parseInt(date.substring(0, 4));
    const month = parseInt(date.substring(5, 7)) - 1;
    const day = parseInt(date.substring(8, 10)) + 1;

    return {year, month, day};
};

export const getYMDfromDMY = (date) => {
    const year = parseInt(date.substring(6, 10));
    const month = parseInt(date.substring(3, 5)) - 1;
    const day = parseInt(date.substring(0, 2)) + 1;

    return {year, month, day};
};

export function postalCodeFormatter(oldValue, newValue) {
    return newValue;
}

export const GetBundeslandNameFromId = (key) => {
    switch (key) {
        case "c166d9bb-3862-eb11-a829-000d3a46d73b":
            return "Burgenland";
        case "c266d9bb-3862-eb11-a829-000d3a46d73b":
            return "Kärnten";
        case "3275fbd4-1c6d-eb11-a838-000d3a46d73b":
            return "Niederösterreich";
        case "3475fbd4-1c6d-eb11-a838-000d3a46d73b":
            return "Oberösterreich";
        case "3675fbd4-1c6d-eb11-a838-000d3a46d73b":
            return "Salzburg";
        case "3875fbd4-1c6d-eb11-a838-000d3a46d73b":
            return "Steiermark";
        case "3a75fbd4-1c6d-eb11-a838-000d3a46d73b":
            return "Tirol";
        case "3c75fbd4-1c6d-eb11-a838-000d3a46d73b":
            return "Vorarlberg";
        case "3e75fbd4-1c6d-eb11-a838-000d3a46d73b":
            return "Wien";

        default:
            return "";
    }
};

export const GetBundeslandIdFromName = (key) => {
    switch (key) {
        case "Burgenland":
            return "c166d9bb-3862-eb11-a829-000d3a46d73b";
        case "Kärnten":
            return "c266d9bb-3862-eb11-a829-000d3a46d73b";
        case "Niederösterreich":
            return "3275fbd4-1c6d-eb11-a838-000d3a46d73b";
        case "Oberösterreich":
            return "3475fbd4-1c6d-eb11-a838-000d3a46d73b";
        case "Salzburg":
            return "3675fbd4-1c6d-eb11-a838-000d3a46d73b";
        case "Steiermark":
            return "3875fbd4-1c6d-eb11-a838-000d3a46d73b";
        case "Tirol":
            return "3a75fbd4-1c6d-eb11-a838-000d3a46d73b";
        case "Vorarlberg":
            return "3c75fbd4-1c6d-eb11-a838-000d3a46d73b";
        case "Wien":
            return "3e75fbd4-1c6d-eb11-a838-000d3a46d73b";

        default:
            return "00000000-0000-0000-0000-000000000000";
    }
};

export const addZeroToDate = (date) => {
    date = date.toString();
    if (date.length === 1) {
        return "0" + date;
    }
    return date;
};

export const getYearMonthDateFromNewDate = (date) => {
    let month = date.getMonth() + 1;
    date = date.toString();
    let day = date.substring(8, 10);
    let year = date.substring(11, 15);

    return addZeroToDate(day) + "." + addZeroToDate(month) + "." + year;
};

export const getYearMonthDateFromNewDateMiddlewareFormat = (date) => {
    let month = date.getMonth() + 1;
    date = date.toString();
    let day = date.substring(8, 10);
    let year = date.substring(11, 15);

    return year + "-" + addZeroToDate(month) + "-" + addZeroToDate(day) + "T23:59:59";
};

const defTravel = {
    Id: "",
    Name: "",
    StopStart: "",
    StopEnd: "",
    Country: "",
    CountryId: "",
    Comment: "",
    TravelGroupMember: [],
    TravelStops: [
        {
            Id: "",
            Name: "",
            StopStart: "",
            StopEnd: "",
            Country: "",
            CountryId: "",
            Comment: "",
        },
    ],
};

export const clearLocalStorageOnLogOut = () => {
    if (!getIsLogged()) {
        localStorage.setItem("registerUser", JSON.stringify(defaultUserValues));
        localStorage.setItem("addTGMURL", "");
        localStorage.setItem("addTravel", JSON.stringify(defTravel));
        localStorage.setItem("linkToGo", "");
        localStorage.setItem("tempData", "");
        localStorage.setItem("addTGMTitle", "");
        localStorage.setItem("tempDate", "");
        localStorage.setItem("addedTGM", "");
        localStorage.setItem("pushNotifcations", "");
        localStorage.setItem("travelTourInfo", "");
        localStorage.setItem("userInfoState", "");
        removeIsLoggedStorage();
        localStorage.setItem("addTravel", "");
        localStorage.setItem("travelTourInfo", "");
        localStorage.setItem("newTGM", "");
        localStorage.setItem("restoreEmail", "");
        localStorage.setItem("travelInfo", "");
        localStorage.setItem("tempUser", "");
        localStorage.setItem("tempTravel", "");
    }
};

export function isMobile() {
    return window.innerWidth < 1180;
}

//Shortens the Name of travel Destinations to fit nicely on screen
export function lengthForTravelName(travelDestinationName) {
    if (isMobile()) {
        return travelDestinationName.length > 40 ? travelDestinationName.substring(0, 36) + " ..." : travelDestinationName;
    }
    return travelDestinationName.length > 63 ? travelDestinationName.substring(0, 59) + " ..." : travelDestinationName;
}

export function getUIID() {
    const crypto = window.crypto || window.msCrypto;
    const array = new Uint8Array(1);

  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        let r = (crypto.getRandomValues(array) / Math.pow(2, 8) * 16) | 0,
            v = c === "x" ? r : r & (0x3 | 0x8);
        return v.toString(16);
    });
}

export function prefilledUserData(stringIfNotPrefilled) {
    try {
        if (JSON.parse(localStorage.getItem("registerUser"))?.ContactPerson?.FirstLastName) {
            return "Bitte überprüfen Sie Ihre Daten:";
        } else {
            return stringIfNotPrefilled;
        }
    } catch (e) {
        return stringIfNotPrefilled;
    }
}

export function logMessage(message, color) {
    color = color || "black";

    switch (color) {
        case "success":
            color = "Green";
            break;
        case "info":
            color = "DodgerBlue";
            break;
        case "error":
            color = "Red";
            break;
        case "warning":
            color = "Orange";
            break;
        default:
            break;
    }

    console.log("%c" + message, "color:" + color);
}

export function hasMiddleName(name) {
    const namesArr = name.split(" ").filter((s) => !!s);

    if (namesArr.length <= 2) {
        return false;
    }

    let bool = false; //false => its okay

    namesArr.forEach((n) => {
        if (!hasMinLettersValidation__normal(n, 2)) {
            bool = true;
        }
    });

    return bool;
}

export const getSecondAfterSpace = (str) => {
    return str.substr(str.indexOf(" ") + 1);
};

export const getFirstPartBeforeSpace = (str) => {
    return str.substr(0, str.indexOf(" "));
};

const replaceGermanLetters = (str) => {
    if (str) {
        str = str
            .replace("ö", "o")
            .replace("Ö", "O")
            .replace("ü", "u")
            .replace("Ü", "U")
            .replace("ä", "a")
            .replace("Ä", "A")
            .replace("Å", "A")
            .replace("å", "a");
    }

    return str;
};

export const compareGermanWords = (a, b) => {
    let first = replaceGermanLetters(a);
    let second = replaceGermanLetters(b);

    if (first < second) {
        return -1;
    }
    if (first > second) {
        return 1;
    }
    return 0;
};

export const compare = (a, b) => {
    let aHasAlias = a.countryAlias.length > 0;
    let bHasAlias = b.countryAlias.length > 0;

    let first = replaceGermanLetters(aHasAlias ? `${a.countryAlias[0]} (${a.country})` : a.country);
    let second = replaceGermanLetters(bHasAlias ? `${b.countryAlias[0]} (${b.country})` : b.country);
    if (first < second) {
        return -1;
    }
    if (first > second) {
        return 1;
    }
    return 0;
};

export const compareMiddlewareStringDate = (a, b) => {
    const first = a.StartDate;
    const second = b.StartDate;
    if (first < second) {
        return -1;
    }
    if (first > second) {
        return 1;
    }
    return 0;
};

export const sortCountriesVB = (vb) => {
    vb.countries.sort(compare);
};

let removedCountries = [
    "Amerikanische Jungferninseln",
    "Amerikanisch-Samoa",
    "Anguilla",
    "Aruba",
    "Bermuda",
    "Bonaire, Sint Eustatius und Saba",
    "Britische Jungferninseln",
    "Cayman Islands",
    "Cookinseln",
    "Curaçao",
    "Falkland-Inseln",
    "Färöer-Inseln",
    "Französisch-Guayana",
    "Französisch-Polynesien",
    "Gibraltar",
    "Grönland",
    "Guernsey",
    "Guadeloupe",
    "Guam",
    "Hongkong",
    "Isle of Man",
    "Jersey",
    "Macao",
    "Martinique",
    "Mayotte",
    "Montserrat",
    "Neukaledonien",
    "Nördliche Marianen",
    "Palästina",
    "Puerto Rico",
    "La Réunion",
    "Réunion",
    "Saint-Barthélemy",
    "Saint-Pierre und Miquelon",
    "Sint Maarten",
    "St. Helena",
    "St. Martin",
    "Taiwan (Chinesisches Taipei)",
    "Turks- und Caicosinseln",
    "United States Minor Outlying Islands",
    "Wallis und Futuna",
    "Westsahara",
];

export const isIn = (str) => {
    return removedCountries.includes(str);
};

const crisisRepresentations = [
    "Botschaft",
    "Botschaft beim Heiligen Stuhl",
    "Österreich Büro (Taiwan)",
    "Generalkonsulat",
];

export const toShowRepresentation = (r) => {
    return crisisRepresentations.includes(r);
};

const blf = ["37e7e990-e35f-eb11-a825-000d3a46d73b", "Palästina"];

export const isBlockedFlag = (f) => {
    return blf.includes(f);
};

export const isMobileEnv = () => {
    return process.env.REACT_APP_ENVIRONMENT === "mobile";
};

export const areIdaRoutesEnabled = () => {
    return process.env.REACT_APP_IDA_ROUTES === "true";
};
