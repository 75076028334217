const loggedIn = {
  registerName: "/registrierung/name",
  registerGender: "/registrierung/geschlecht",
  registerMobilePhone: "/registrierung/mobiltelefon",
  registerBirthday: "/registrierung/geburtstag",
  registerEmail: "/registrierung/emailadresse",
  registerLivingIn: "/registrierung/wohnhaft",
  registerPassNumber: "/registrierung/passnummer",
  registerContactPerson: "/registrierung/kontaktperson",
  registerUserInfo: "/registrierung/userinfo",
  dseConfirmationPage: "/dse/confirmation",
  infoMailPage: "/info/confirmation",
  travelDashboard: "/travel-dashboard/",
  addTravel: "/add-travel",
  addTravelTourOperator: "/add-travel-tour-operator",
  addTravelCompanion: "/add-travel-step-1",
  addTravelCompanionName: "/add-travel-step-2",
  addTravelCompanionDateOfBirth: "/add-travel-step-3",
  addTravelGender: "/add-travel-step-4",
  addTravelMobilePhone: "/add-travel-step-5",
  addTravelEmail: "/add-travel-step-6",
  addTravelNationality: "/add-travel-step-7",
  addTravelCompanionAddedSuccess: "/add-travel-step-8",
  addTravelEdit: "/add-travel-step-9",
  lastStep: "/last-step",
  editProfile: "/edit-profile",
  foreignRegistrationRegionSelection: "/region-selection",
  foreignRegistrationRegistrationName: "/auslandsregistrierung/name",
  foreignRegistrationRegistrationBirthdate: "/auslandsregistrierung/birthdate",
  foreignRegistrationRegistrationCounty: "/auslandsregistrierung/county",
  foreignRegistrationRegistrationGender: "/auslandsregistrierung/gender",
  foreignRegistrationRegistrationNumber: "/auslandsregistrierung/number",
  foreignRegistrationRegistrationEmail: "/auslandsregistrierung/email",
  foreignRegistrationRegistrationPassport: "/auslandsregistrierung/passport",
  foreignRegistrationRegistrationEmergencyContact: "/auslandsregistrierung/emergency-contact",
  foreignRegistrationAddRelatives: "/add-relatives",
  foreignRegistrationAddRelativesName: "/add-relatives/name",
  foreignRegistrationAddRelativesBirthdate: "/add-relatives/birthdate",
  foreignRegistrationAddRelativesGender: "/add-relatives/gender",
  foreignRegistrationAddRelativesEmail: "/add-relatives/email",
  foreignRegistrationAddRelativesNumber: "/add-relatives/number",
  foreignRegistrationAddRelativesNationality: "/add-relatives/nationality",
  foreignRegistrationAddRelativesSuccess: "/add-relatives/success",
  foreignRegistrationEditPage: "/auslandsregistrierung/edit",
  dashboardForeignRegistrationPage: "/foreign-edit-page",
  pushEditFromDashboard: "/pushnotification-edit",
  pushLogin: "/pushlogin-cred",
  editProfileSecond: "/editprofile",
  changePassword: "/changepassword",
  editTravel: "/edit-travel/",
  addRelative1: "/edittravel/addRelative1",
  addRelative2: "/edittravel/addRelative2",
  addRelative3: "/edittravel/addRelative3",
  addRelative4: "/edittravel/addRelative4",
  addRelative5: "/edittravel/addRelative5",
  addRelative6: "/edittravel/addRelative6",
};

const loggedOut = {
  registerForgottenPassword: "/forgottenPassword",
  forgottenPasswordSecondPage: "/forgottenPasswordinfo",
  registerSentEmail: "/registrierung/sentemail",
  registerVerifiedEmail: "/registrierung/verifiedemail/:token",
  registerPassword: "/registrierung/passwort",
  registerCitizenship: "/registrierung/staatsbürgerschaft",
  registrationEmailAdress: "/registrierung/registerEmail",
};

const mobileOnly = {
  countryOverview: "country-overview",
  tips: "notfalltipps",
  map: "vertretungen",
  info: "dashboard-infos",
  pushNotfSumScreen: "pushnotification-conf",
  editUserPushNotf: "pushlogin-cred",
  pushNotification: "pushnotification",
  secondLogin_fromPushNotf: "pushlogin-bf",
};

const loggedInNotRedirected = {
  dseConfirmationPage: "/dse/confirmation",
  infoMailPage: "/info/confirmation",
  editProfileSecond: "/editprofile",
};

export const loggedInRoutes = { ...loggedIn };
export const loggedOutRoutes = { ...loggedOut };
export const mobileOnlyRoutes = { ...mobileOnly };
export const loggedInNotRedirectedRoutes = { ...loggedInNotRedirected };
