import React, { useEffect} from "react";

import ContentTitle from "../utils/ContentTitle";
import HeaderContext from "../../../context/HeaderContext";
import WeiterButton from "../utils/WeiterButton";
import ApplicationRoute from "../../../config/routes";
import Mail from "../../../common/Unions/Mail";
import HorizontalRule from "../../../common/HorizontalRule";
import MetaDecorator from "../../../utils/MetaDecorator";
const applicationPageTitles = require("../../../utils/pageTitles.json");
//HINT* REGISTRATION

const ActivatedAccountPage = () => {
  const { setHeader } = React.useContext(HeaderContext);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: 100,
        title: "Registrierung",
      };
    });
  }, []);

  return (
    <div className="registration-middle-container registration-middle-container--se">
      <MetaDecorator title={applicationPageTitles.registrationPages} />
      <ContentTitle
        id="emaild-verified"
        style={{ marginTop: "0.6rem", marginBottom: "1.6rem" }}
        text="Ihre E-Mail-Adresse wurde erfolgreich bestätigt."
      />
      <HorizontalRule className="horizontal-rule-desktop" />
      <Mail type="success" className="global-flex-center" style={{ marginTop: "4rem" }} />
      <HorizontalRule className="horizontal-rule-desktop" />
      <WeiterButton className="register-verify-email" text="Zur Anmeldung" path={ApplicationRoute.home} />
    </div>
  );
};

export default ActivatedAccountPage;
