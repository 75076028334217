import React from "react";
import { useHistory } from "react-router-dom";
import InputValidation from "../../../../common/InputValidaiton";
import ApplicationRoute from "../../../../config/routes";
import HeaderContext from "../../../../context/HeaderContext";
import Form from "../../../travel-registration/utils/Form";
import HiddenSubmitInput from "../../../../common/HiddenSubmitInput";
import Input from "../../../travel-registration/utils/Input";
import PageTitle from "../../../travel-registration/utils/PageTitle";
import WeiterButton from "../../../travel-registration/utils/WeiterButton";
import { registrationValidationErrorMessages } from "../../../../utils/validationErrorMessages";
import { hasNumber, isValidFirstAndLastName } from "../../../../utils/validation";
import TravelContext from "../../../../context/TravelContext";
import CustomSelectList from "../../utils/CustomSelectList";
import MetaDecorator from "../../../../utils/MetaDecorator";
import HorizontalRule from "../../../../common/HorizontalRule";
import { hasMiddleName } from "../../../../utils/helpers";

const applicationPageTitles = require("../../../../utils/pageTitles.json");

const formLabel = "Geben Sie den Vor- und Familiennamen ein";
const relationships = [
  "Eltern, Großeltern",
  "Schwester, Bruder",
  "Kinder, Enkelkinder",
  "Ehegatte/in, Partner/in​",
  "Sonstige Verwandtschaftsverhältnisse",
];

const relationshipEnums = {
  "Eltern, Großeltern": 100000000,
  "Schwester, Bruder": 100000001,
  "Kinder, Enkelkinder": 100000002,
  "Ehegatte/in, Partner/in​": 100000003,
  "Sonstige Verwandtschaftsverhältnisse": 100000004,
};

const relationshipInvertEnums = {
  100000000: "Eltern, Großeltern",
  100000001: "Schwester, Bruder",
  100000002: "Kinder, Enkelkinder",
  100000003: "Ehegatte/in, Partner/in​",
  100000004: "Sonstige Verwandtschaftsverhältnisse",
};

export function AddRelativesName({ text }) {
  const history = useHistory();
  const { setHeader } = React.useContext(HeaderContext);
  const { travel, setTravel } = React.useContext(TravelContext);
  const [active, setActive] = React.useState(false);

  let index = travel.TravelGroupMember.length - 1;

  const [fullName, setFullName] = React.useState(
    travel.TravelGroupMember[index].FirstLastName ? travel.TravelGroupMember[index].FirstLastName : ""
  );
  const [relationship, setRelationship] = React.useState(
    travel.TravelGroupMember[index].DegreeOfRelationship
      ? relationshipInvertEnums[travel.TravelGroupMember[index].DegreeOfRelationship]
      : ""
  );

  React.useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: "Als Auslandsösterreicher/in registrieren",
        ariaLabel: "Als Auslandsösterreicher:in registrieren",
        progress: parseInt((10 / 17) * 100),
      };
    });
  }, []);

  const changeNameHandler = (e) => {
    setActive(true);
    setFullName(e.target.value);
  };
  const onChangeHandlerRelationship = (value) => {
    setRelationship(value);
  };
  const isValidRelationShip = () => {
    return relationships.includes(relationship);
  };
  const isValid = () => {
    return (
      fullName.length > 0 && isValidFirstAndLastName(fullName) && isValidRelationShip() && !hasMiddleName(fullName)
    );
  };
  const saveData = (e) => {
    e.preventDefault();
    if (isValid()) {
      let travelCopy = Object.assign({}, travel);

      travelCopy.TravelGroupMember[index].FirstLastName = fullName;
      travelCopy.TravelGroupMember[index].DegreeOfRelationship = relationshipEnums[relationship];

      setTravel(travelCopy);

      history.push(ApplicationRoute.foreignRegistrationAddRelativesBirthdate);
    }
  };

  return (
    <section className="foreign-registration registration-middle-container">
      <MetaDecorator title={applicationPageTitles.foreignRegistration} />
      <PageTitle text="Bitte geben Sie den Namen ein:" />
      <p className="page-paragraph page-paragraph--small">Angehörige/r</p>
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form id="form" ariaLabel={formLabel} className="max-width-380" onSubmit={saveData}>
        <Input
          labelText="Vorname Familienname"
          id="firstName"
          placeholder="Vorname Familienname"
          type="text"
          value={fullName}
          onChange={changeNameHandler}
        />

        <InputValidation
          style={{ marginBottom: "1.5rem" }}
          isHidden={isValidFirstAndLastName(fullName) || !active || hasMiddleName(fullName)}
          text={
            hasNumber(fullName)
              ? registrationValidationErrorMessages.nameHasNumber
              : registrationValidationErrorMessages.notValidContactPersonFirstAndLastName
          }
        />
        <InputValidation
          isHidden={!hasMiddleName(fullName)}
          text={registrationValidationErrorMessages.middleName}
          ariaLabel={registrationValidationErrorMessages.middleName}
        />
        <CustomSelectList
          id="verwandschaftsgrad"
          label="Verwandtschaftsgrad"
          state={relationship}
          options={relationships}
          onChangeHandler={onChangeHandlerRelationship}
        />

        <HiddenSubmitInput />
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content"> </div>
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.foreignRegistrationAddRelativesBirthdate}
        onClick={saveData}
        ariaLabel={text}
        className="hide-weiter-on-keyboard floating-weiter-buton"
      />
    </section>
  );
}
