import React, { useEffect, useState } from "react";
import SuccessContext from "../../../context/SuccessContext";
import EditInput from "../../../common/EditInput";
import HorizontalRule from "../../../common/HorizontalRule";
import ClearContactDiv from "../../../common/ClearContactDiv";
import InputValidation from "../../../common/InputValidaiton";
import { registrationValidationErrorMessages } from "../../../utils/validationErrorMessages";
import {
  isEmailValidation,
  isPhoneValidation,
  hasMinLettersValidation,
  isPassnumberValidation,
  hasAtSymbolEmailValidation,
  hasTwoCharactersAfterAtSymbolEmailValidation,
  isDateValidation,
  isOlderOrIsEighteenYearsOlds,
  IsDateBiggerThanTodayValidation,
  IsDateSmallerThan_1900_1_1_Validation,
  isPersonalNumberValidation,
  isValidFirstAndLastName,
  hasNumber,
} from "../../../utils/validation";
import {
  dateFormatter,
  GetBundeslandIdFromName,
  getEnumFromGender,
  getGenderFromEnumGerman,
  hasMiddleName,
  phoneFormatter,


} from "../../../utils/helpers";

const applicationPageTitles = require("../../../utils/pageTitles.json");

const optional = {
  FirstLastName: false,
  PhoneNumber: false,
  Email: false,
};

const defaultAddPersonState = {
  type: "",
  hidden: true,
};

const countriesId = {
  Optional: null,
  Burgenland: "c166d9bb-3862-eb11-a829-000d3a46d73b",
  Kärnten: "c266d9bb-3862-eb11-a829-000d3a46d73b",
  Niederösterreich: "3275fbd4-1c6d-eb11-a838-000d3a46d73b",
  Oberösterreich: "3475fbd4-1c6d-eb11-a838-000d3a46d73b",
  Salzburg: "3675fbd4-1c6d-eb11-a838-000d3a46d73b",
  Steiermark: "3875fbd4-1c6d-eb11-a838-000d3a46d73b",
  Tirol: "3a75fbd4-1c6d-eb11-a838-000d3a46d73b",
  Vorarlberg: "3c75fbd4-1c6d-eb11-a838-000d3a46d73b",
  Wien: "3e75fbd4-1c6d-eb11-a838-000d3a46d73b",
};
const countriesIdInverse = {
  null: "Optional",
  "c166d9bb-3862-eb11-a829-000d3a46d73b": "Burgenland",
  "c266d9bb-3862-eb11-a829-000d3a46d73b": "Kärnten",
  "3275fbd4-1c6d-eb11-a838-000d3a46d73b": "Niederösterreich",
  "3475fbd4-1c6d-eb11-a838-000d3a46d73b": "Oberösterreich",
  "3675fbd4-1c6d-eb11-a838-000d3a46d73b": "Salzburg",
  "3875fbd4-1c6d-eb11-a838-000d3a46d73b": "Steiermark",
  "3a75fbd4-1c6d-eb11-a838-000d3a46d73b": "Tirol",
  "3c75fbd4-1c6d-eb11-a838-000d3a46d73b": "Vorarlberg",
  "3e75fbd4-1c6d-eb11-a838-000d3a46d73b": "Wien",
};

export default function EditProfileComponent({ user, updateUser, noInitialHorizontalLine, setValid }) {
  const { setSuccessBox } = React.useContext(SuccessContext);
  let { ContactPerson: contactPersonInfo, ...userInfo } = user;

  const [userInfoState, setUserInfoState] = useState(userInfo);
  const [contactPersonInfoState, setContactPersonInfoState] = useState({
    ...contactPersonInfo,
  });

  const [hasContactPerson, setHasContactPerson] = useState(false);
  const [hasRelative, setHasRelative] = useState(false);

  const [optionals, setOptionals] = useState(optional);
  const [clearContactDivAlertBoxHidden, setClearContactDivAlertBoxHidden] = useState(true);

  const clearContactPersonHandler = () => {
    setClearContactDivAlertBoxHidden(false);
  };

  useEffect(() => {
    setValid(isValid());
  }, [user]);

  const isValid = () => {
    try {
      const validDate1 = IsDateBiggerThanTodayValidation(userInfoState.BirthDate);
      const validDate2 = !IsDateSmallerThan_1900_1_1_Validation(userInfoState.BirthDate);
      const validDate3 = isDateValidation(userInfoState.BirthDate);

      const validDate = validDate3 && validDate2 && validDate1;

      return (
        validDate &&
        (hasTwoCharactersAfterAtSymbolEmailValidation(contactPersonInfoState.Email) ||
          contactPersonInfoState.Email.length === 0) &&
        hasTwoCharactersAfterAtSymbolEmailValidation(userInfoState.Email) &&
        // isValidContactPerson(optionals) &&
        hasMinLettersValidation(userInfoState.FirstName) &&
        hasMinLettersValidation(userInfoState.LastName) &&
        isPassnumberValidation(userInfoState.PassNumber) &&
        isPersonalNumberValidation(userInfoState.IdNumber) &&
        isEmailValidation(userInfoState.Email) &&
        isPhoneValidation(userInfoState.PhoneNumber) &&
        isValidFirstAndLastName(contactPersonInfoState.FirstLastName) &&
        (isPhoneValidation(contactPersonInfoState.PhoneNumber, true) ||
          contactPersonInfoState.PhoneNumber.length === 0) &&
        (isEmailValidation(contactPersonInfoState.Email) || contactPersonInfoState.Email.length === 0)
      );
    } catch (error) {
      return false;
    }
  };

  const onUserInfoChangeHandler = (e) => {
    let userInfoCopy = Object.assign({}, userInfoState);
    setUserInfoState(userInfoCopy);
    if (e.target.id === "BirthDate") {
      userInfoCopy.BirthDate = dateFormatter(userInfoState.BirthDate, e.target.value);
    } else if (e.target.id === "PhoneNumber") {
      userInfoCopy.PhoneNumber = phoneFormatter(userInfoState.PhoneNumber, e.target.value);
    } else if (e.target.id === "CountyHomeRegionName") {
      userInfoCopy.CountyHomeRegionName = e.target.value;
      userInfoCopy.CountyHomeRegionId = GetBundeslandIdFromName(e.target.value);
    } else {
      userInfoCopy[e.target.id] = e.target.value;
    }

    setUserInfoState(userInfoCopy);
    updateUser(userInfoCopy, contactPersonInfoState);
  };

  const onContactPersonInfoChangeHandler = (e) => {
    let contactPersonInfoStateCopy = Object.assign({}, contactPersonInfoState);
    let key = e.target.id.replace("Notfalkontakt", "");

    if (key === "PhoneNumber") {
      contactPersonInfoStateCopy[key] = phoneFormatter(contactPersonInfoState.PhoneNumber, e.target.value);
    } else {
      contactPersonInfoStateCopy[key] = e.target.value;
    }
    setContactPersonInfoState(contactPersonInfoStateCopy);
    updateUser(userInfoState, contactPersonInfoStateCopy);
  };

  useEffect(() => {}, [hasContactPerson]);

  useEffect(() => {
    const { ContactPerson: cpInfo, ...uInfo } = user;

    if (cpInfo.FirstLastName.length > 0 && user.TempDeletedContact !== true) {
      setHasContactPerson(true);
    }

    if (cpInfo.PhoneNumber.length === 0) {
      cpInfo.PhoneNumber = "";
    }

    setUserInfoState(() => {
      return uInfo;
    });

    setContactPersonInfoState(() => {
      return { ...cpInfo };
    });
  }, [user]);

  const setOptionalPhoneToTrue = (phone, email) => {
    if (email) {
      return true;
    }
    return isPhoneValidation(phone);
  };

  useEffect(() => {
    setOptionals((curr) => {
      return {
        ...curr,
        FirstLastName: !!contactPersonInfoState.FirstLastName,
        PhoneNumber: setOptionalPhoneToTrue(contactPersonInfoState.PhoneNumber, contactPersonInfoState.Email),
        Email: !!contactPersonInfoState.Email,
      };
    });
  }, [contactPersonInfoState]);

  return (
    <>
      {noInitialHorizontalLine ? null : <HorizontalRule />}

      <EditInput
        id="FirstName"
        placeholder="Vorname"
        value={userInfoState.FirstName}
        labelText="Vorname"
        ariaRequired={true}
        onChange={onUserInfoChangeHandler}
      />
      <InputValidation
        text={
          hasNumber(userInfoState.FirstName)
            ? registrationValidationErrorMessages.nameHasNumber
            : registrationValidationErrorMessages.firstNameLessCharacters
        }
        isHidden={hasMinLettersValidation(userInfoState.FirstName)}
      />

      <HorizontalRule />
      <EditInput
        id="LastName"
        placeholder="Familienname"
        value={userInfoState.LastName}
        labelText="Familienname"
        ariaRequired={true}
        onChange={onUserInfoChangeHandler}
      />
      <InputValidation
        text={
          hasNumber(userInfoState.LastName)
            ? registrationValidationErrorMessages.nameHasNumber
            : registrationValidationErrorMessages.lastNameLessCharacters
        }
        isHidden={hasMinLettersValidation(userInfoState.LastName)}
      />
      <HorizontalRule />
      <EditInput
        id="Gender"
        placeholder="Geschlecht"
        value={getGenderFromEnumGerman(userInfoState.Gender)}
        labelText="Geschlecht"
        ariaRequired={true}
        onChange={(value) =>
          onUserInfoChangeHandler({
            target: { id: "Gender", value: getEnumFromGender(value) },
          })
        }
        options={["männlich", "weiblich", "divers"]}
      />

      <HorizontalRule />
      <EditInput
        id="BirthDate"
        placeholder="Geburtsdatum"
        value={userInfoState.BirthDate}
        labelText="Geburtsdatum"
        ariaRequired={true}
        onChange={onUserInfoChangeHandler}
      />

      <InputValidation
        text={registrationValidationErrorMessages.notValidDate}
        isHidden={isDateValidation(userInfoState.BirthDate)}
      />
      <InputValidation
        text={registrationValidationErrorMessages.ageLessThan_18}
        isHidden={
          !IsDateBiggerThanTodayValidation(userInfoState.BirthDate) ||
          !isDateValidation(userInfoState.BirthDate) ||
          isOlderOrIsEighteenYearsOlds(userInfoState.BirthDate)
        }
      />

      <InputValidation
        text={registrationValidationErrorMessages.dateBiggerThanToday}
        isHidden={
          !isDateValidation(userInfoState.BirthDate) || IsDateBiggerThanTodayValidation(userInfoState.BirthDate)
        }
      />
      <InputValidation
        text={registrationValidationErrorMessages.dateOfBirthLessThan_01_01_1900}
        isHidden={
          !isDateValidation(userInfoState.BirthDate) || !IsDateSmallerThan_1900_1_1_Validation(userInfoState.BirthDate)
        }
      />

      <HorizontalRule />

      <EditInput
        id="CountyHomeRegionName"
        placeholder="Bundesland"
        value={userInfoState.CountyHomeRegionName}
        labelText="Bundesland"
        ariaRequired={true}
        onChange={(value) =>
          onUserInfoChangeHandler({
            target: { id: "CountyHomeRegionName", value: value },
          })
        }
        options={[
          "Burgenland",
          "Kärnten",
          "Niederösterreich",
          "Oberösterreich",
          "Salzburg",
          "Steiermark",
          "Tirol",
          "Vorarlberg",
          "Wien",
        ]}
        optional={true}
      />

      <HorizontalRule />
      <EditInput
        id="PhoneNumber"
        placeholder="Mobiltelefonnummer"
        value={userInfoState.PhoneNumber}
        labelText="Mobiltelefonnummer (inkl. Ländervorwahl)"
        ariaRequired={true}
        onChange={onUserInfoChangeHandler}
      />

      <InputValidation
        text={registrationValidationErrorMessages.notValidPhoneNumber}
        isHidden={isPhoneValidation(userInfoState.PhoneNumber)}
      />

      <HorizontalRule />
      <EditInput
        id="Email"
        placeholder="E-Mail-Adresse"
        value={userInfoState.Email}
        labelText="E-Mail-Adresse"
        ariaRequired={true}
        onChange={onUserInfoChangeHandler}
      />
      <InputValidation
        isHidden={
          !hasAtSymbolEmailValidation(userInfoState.Email) ||
          !hasTwoCharactersAfterAtSymbolEmailValidation(userInfoState.Email) ||
          isEmailValidation(userInfoState.Email)
        }
        text={registrationValidationErrorMessages.notValidEmail}
      />
      <InputValidation
        isHidden={hasAtSymbolEmailValidation(userInfoState.Email)}
        text={registrationValidationErrorMessages.missingAtSymbolEmail}
      />
      <InputValidation
        isHidden={
          !hasAtSymbolEmailValidation(userInfoState.Email) ||
          hasTwoCharactersAfterAtSymbolEmailValidation(userInfoState.Email)
        }
        text={registrationValidationErrorMessages.charactersAfterAtSymbolLessThanTwoEmail}
      />

      <HorizontalRule />
      <EditInput
        id="PassNumber"
        placeholder="Reisepassnummer"
        value={userInfoState.PassNumber}
        labelText="Reisepassnummer"
        ariaRequired={false}
        onChange={onUserInfoChangeHandler}
      />

      <InputValidation
        text={registrationValidationErrorMessages.notValidAustrianPassportOrIdentityCardNumberFormat}
        isHidden={isPassnumberValidation(userInfoState.PassNumber)}
      />

      <HorizontalRule />
      <EditInput
        id="IdNumber"
        value={userInfoState.IdNumber}
        onChange={onUserInfoChangeHandler}
        placeholder="Personalausweisnummer"
        ariaRequired={false}
        labelText="Personalausweisnummer"
        showValidationBorder={!isPersonalNumberValidation(userInfoState.IdNumber)}
      />

      <InputValidation
        isHidden={isPersonalNumberValidation(userInfoState.IdNumber)}
        text={registrationValidationErrorMessages.notValidPersonalNumber}
      />
      {/* {user.TempDeletedContact.length > 0 && !user.TempPerson && (
          <div style={{marginBottom: "32px"}}>
            <ClearContactDiv
              text={`Notfallkontakt (Keine Angabe) `}
              onClick={addContactPersonHandler}
              description="+ Notfallkontakt hinzufügen"
            />
          </div>
        )} */}

      {/* {(user.TempDeletedContact.length > 0 && !user.TempPerson) === false &&
          (user.TempPerson ||
            (!user.TempDeletedContact.length > 0 &&
              user.ContactPerson.FirstLastName.length > 0)) === false && (
            <div style={{marginBottom: "32px"}}>
              <ClearContactDiv
                text={`Notfallkontakt (Keine Angabe) `}
                onClick={addContactPersonHandler}
                description="+ Notfallkontakt hinzufügen"
              />
            </div>
          )} */}
      <>
        <ClearContactDiv
          text={`Notfallkontakt (${
            contactPersonInfoState.FirstLastName ? contactPersonInfoState.FirstLastName : "Keine Angabe"
          })`}
          onClick={clearContactPersonHandler}
        />
        {/* <HorizontalRule /> */}
        <EditInput
          id={"NotfalkontaktFirstLastName"}
          placeholder={"Vorname Familienname"}
          value={contactPersonInfoState.FirstLastName}
          labelText={"Vorname Familienname"}
          ariaRequired={true}
          onChange={onContactPersonInfoChangeHandler}
        />
        <InputValidation
          text={
            hasNumber(contactPersonInfoState.FirstLastName)
              ? registrationValidationErrorMessages.nameHasNumber
              : registrationValidationErrorMessages.notValidContactPersonFirstAndLastName
          }
          isHidden={
            isValidFirstAndLastName(contactPersonInfoState.FirstLastName) ||
            hasMiddleName(contactPersonInfoState.FirstLastName)
          }
        />
        <InputValidation
          isHidden={!hasMiddleName(contactPersonInfoState.FirstLastName)}
          text={registrationValidationErrorMessages.middleName}
          ariaLabel={registrationValidationErrorMessages.middleName}
        />
        <HorizontalRule />
        <EditInput
          id={"NotfalkontaktPhoneNumber"}
          placeholder={"+43"}
          value={contactPersonInfoState.PhoneNumber}
          labelText={"Mobiltelefonnummer (inkl. Ländervorwahl)"}
          ariaRequired={true}
          onChange={onContactPersonInfoChangeHandler}
        />
        <InputValidation
          text={registrationValidationErrorMessages.notValidPhoneNumber}
          isHidden={
            isPhoneValidation(contactPersonInfoState.PhoneNumber, true) ||
            contactPersonInfoState.PhoneNumber.length === 0
          }
        />
        <HorizontalRule />
        <EditInput
          id={"NotfalkontaktEmail"}
          placeholder={"E-Mail-Adresse"}
          value={contactPersonInfoState.Email}
          labelText={"E-Mail-Adresse"}
          ariaRequired={false}
          onChange={onContactPersonInfoChangeHandler}
        />
        <InputValidation
          isHidden={
            contactPersonInfoState.Email.length === 0 ||
            !hasAtSymbolEmailValidation(contactPersonInfoState.Email) ||
            !hasTwoCharactersAfterAtSymbolEmailValidation(contactPersonInfoState.Email) ||
            isEmailValidation(contactPersonInfoState.Email)
          }
          text={registrationValidationErrorMessages.notValidEmail}
        />
        <InputValidation
          isHidden={
            contactPersonInfoState.Email.length === 0 || hasAtSymbolEmailValidation(contactPersonInfoState.Email)
          }
          text={registrationValidationErrorMessages.missingAtSymbolEmail}
        />
        <InputValidation
          isHidden={
            contactPersonInfoState.Email.length === 0 ||
            !hasAtSymbolEmailValidation(contactPersonInfoState.Email) ||
            hasTwoCharactersAfterAtSymbolEmailValidation(contactPersonInfoState.Email)
          }
          text={registrationValidationErrorMessages.charactersAfterAtSymbolLessThanTwoEmail}
        />
      </>
    </>
  );
}
