import React from "react";
const AddStopButton = ({ text, target, reference, iconClassName }) => {
  return (
    <button
      type="button"
      className="travel__add-stop"
      id="add-stop-btn"
      onClick={target}
    >
      <span className="travel__add-stop--text">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="14"
          viewBox="0 0 15 14"
        >
          <g fill="none" fillRule="evenodd">
            <g fill="#9E0529" fillRule="nonzero">
              <g>
                <g>
                  <g>
                    <path
                      d="M6 7.667c.442 0 .866-.176 1.179-.488.312-.313.488-.737.488-1.179 0-.442-.176-.866-.488-1.179-.313-.312-.737-.488-1.179-.488-.92 0-1.667.747-1.667 1.667S5.08 7.667 6 7.667M10.667 6C10.667 9.5 6 14.667 6 14.667S1.333 9.5 1.333 6c0-2.577 2.09-4.667 4.667-4.667 2.573 0 4.667 2.087 4.667 4.667zM10 11.333h2v-2h1.333v2h2v1.334h-2v2H12v-2h-2v-1.334z"
                      transform="translate(-65 -539) translate(24 526) translate(40 9) translate(0 3)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>

        {text}
      </span>
    </button>
  );
};

export default AddStopButton;
