export default function GlobeIcon() {
  return (
    <svg aria-hidden={true} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 380 473">
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g strokeWidth="1.5">
          <g>
            <path
              stroke="#757575"
              d="M181.808 110.813L181.808 473M322.768 176.287c-40.856 28.648-91.925 42.971-140.952 40.925-51.07 2.046-100.097-12.277-140.952-40.925M.002 290.879L363.617 290.879"
              transform="translate(-229 -303) translate(229.07 303)"
            />
            <path
              stroke="#757575"
              d="M181.808 108.765c58.668 0 106.224 81.522 106.224 182.116 0 100.574-47.556 182.117-106.224 182.117-58.67 0-106.225-81.543-106.225-182.117 0-100.594 47.556-182.116 106.225-182.116z"
              transform="translate(-229 -303) translate(229.07 303)"
            />
            <path
              stroke="#757575"
              d="M322.768 405.461c-40.856-28.627-91.925-42.971-140.952-40.925-51.07-2.046-100.097 12.298-140.952 40.925"
              transform="translate(-229 -303) translate(229.07 303)"
            />
            <path
              stroke="#757575"
              d="M181.808 108.765c100.402 0 181.807 81.522 181.807 182.116 0 100.574-81.405 182.117-181.807 182.117C81.384 472.998 0 391.455 0 290.881c0-100.594 81.384-182.116 181.808-182.116z"
              transform="translate(-229 -303) translate(229.07 303)"
            />
            <g stroke="#9E0529">
              <path
                fill="#F0F4F5"
                d="M179.586 92.678C179.586 41.482 139.396 0 89.793 0 40.2 0 0 41.482 0 92.678c0 64.044 89.793 170.38 89.793 170.38s89.793-106.336 89.793-170.38z"
                transform="translate(-229 -303) translate(229.07 303) translate(199.484)"
              />
              <path
                d="M143.634 92.678c0 30.757-24.14 55.647-53.836 55.647-29.796 0-53.835-24.89-53.835-55.647 0-30.758 24.14-55.647 53.835-55.647 29.797 0 53.836 24.99 53.836 55.647z"
                transform="translate(-229 -303) translate(229.07 303) translate(199.484)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
