import {useContext, useEffect} from "react";
import HeaderContext from "../../../context/HeaderContext";
import {useParams} from "react-router-dom";
import {
    getCountryNameFromUid,
    getCountryFlagFromCountryVB,
} from "../../travel-registration/utils/countryInfo";
import MetaDecorator from "../../../utils/MetaDecorator";
import CountryInfoHeader from "../utils/CountryInfoHeader";

function CountryInformation() {
    const {countryUid, title} = useParams();

    const {setHeader} = useContext(HeaderContext);
    const countryName = getCountryNameFromUid(countryUid);
    let travelInfo = localStorage.getItem("travelInfo") != null ? JSON.parse(localStorage.getItem("travelInfo")) : {};

    useEffect(() => {
        setHeader((curr) => {
            return {
                ...curr,
                inUse: "content",
                title: title,
            };
        });
    }, []);

    return (
        <div className="content-pages-container detail-countries">
            <MetaDecorator title={title}/>
            <CountryInfoHeader
                flag={getCountryFlagFromCountryVB(countryName)}
                Country={countryName}
                className="country__flag-no-image"
            />
            {travelInfo &&
                travelInfo.data.map((info) => {
                    if (info.header === title) {
                        return <div key={title} dangerouslySetInnerHTML={{__html: info.bodytext}}/>;
                    }
                    return null
                })}
        </div>
    );
}

export default CountryInformation;
