import {useEffect} from "react";

//set %alert% in text field to set success box to create it purple

const ActionInformation = ({ hidden, text, trigger, alert = false }) => {
  if (text && text.includes("%alert%")) {
    alert = true;
    text = text.replace("%alert%", "");
  }

  useEffect(() => {
    if (!hidden && trigger) {
      const div = document.getElementById("dynamic-success-box");
      div.innerHTML = ` <div
      id="${trigger}"
      class="action-information__container"
      role="alert"
      aria-relevant="all"   
      aria-hidden=${hidden}
    >
      <div class="action-information ${alert ? "action-information--alert" : ""}">
        <div> 

          <div class="action-information__cross" onclick='a("${trigger}")'>
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
                <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                    <g stroke="#000">
                        <g>
                            <path d="M8.34 0L0 8.34M0 0L8.34 8.34" transform="translate(-341 -23) translate(342 24)"/>
                        </g>
                    </g>
                </g>
            </svg>
          </div>

       

          <div class="action-information__success">
          ${
          alert
              ? `
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                <defs>
                  <filter id="5mmxyk0yoa">
                    <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.619608 0 0 0 0 0.019608 0 0 0 0 0.160784 0 0 0 1.000000 0"/>
                  </filter>
                </defs>
                <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                  <g>
                    <g filter="url(#5mmxyk0yoa)" transform="translate(-15 -28) translate(15 28)">
                      <g>
                        <path stroke="#000" d="M25.84 24L0 24 12.92 0zM12.92 6.93L12.92 14.769M12.92 18.46L12.92 20.31" transform="translate(0 1)"/>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            `
              : `
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                <defs>
                    <filter id="n43grqe76a">
                        <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.215686 0 0 0 0 0.470588 0 0 0 0 0.243137 0 0 0 1.000000 0"/>
                    </filter>
                </defs>
                <g fill="none" fill-rule="evenodd">
                    <g filter="url(#n43grqe76a)" transform="translate(-14 -14)">
                        <g stroke="#000">
                            <path d="M26 13c0 7.18-5.82 13-13 13S0 20.18 0 13 5.82 0 13 0s13 5.82 13 13z" transform="translate(15 15)"/>
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.21 9.85L11.47 17.58 8.07 14.19" transform="translate(15 15)"/>
                        </g>
                    </g>
                </g>
            </svg>`
      }

          </div>

          <span class="action-information__text"> ${text}</span>
        </div>
      </div>
    </div>`;

      setTimeout(() => {
        try {
          document.getElementById(trigger).remove();
        } catch (error) {}
      }, 10000);
    }
  }, [trigger]);

  text = text + " ";
  return (
    <>
      <div id="dynamic-success-box"/>
    </>
  );
};

export default ActionInformation;
