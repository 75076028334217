import React, {useEffect, useState} from "react";
import HeaderContext from "../../context/HeaderContext";
import MetaDecorator from "../../utils/MetaDecorator";
import QuestionAnswerTemplate from "./QuestionAnswerTemplate.jsx";
import AnswerDeleteAccount from "./AnswerDeleteAccount.jsx";
import AnswerDeleteInfos from "./AnswerDeleteInfos.jsx";
import {useLocation} from "react-router-dom";

const applicationPageTitles = require("../../utils/pageTitles.json");

const FAQsPage = () => {
    const {setHeader} = React.useContext(HeaderContext);

    const [scrolledRef, setScrolledRef] = useState(false);
    const {hash} = useLocation();

    useEffect(() => {
        const messageTimeout = setTimeout(()=>{
            if (hash && !scrolledRef) {
                const element = document.getElementById(hash.replace('#', ''));
                if (element) {
                    element.scrollIntoView({behavior: 'smooth'});
                    setScrolledRef(true);
                }
            }
        }, 50);
        return () => clearTimeout(messageTimeout);
    }, []);

    useEffect(() => {
        setHeader((curr) => {
            return {
                ...curr,
                inUse: "registration",
                progress: "no",
                title: applicationPageTitles.faqs,
            };
        });
    }, [setHeader]);

    return (
        <div className="registration-middle-container registration-middle-container--se faq-container">
            <MetaDecorator title={applicationPageTitles.faqs}/>
            <QuestionAnswerTemplate
                question="Ich möchte mein Konto Löschen."
                answer={<AnswerDeleteAccount/>}
                anchor="delete-account"
            />
            <QuestionAnswerTemplate
                question="Ich möchte meine personenbezogenen Daten löschen."
                answer={<AnswerDeleteInfos/>}
                anchor="delete-data"
            />
        </div>
    );
};

export default FAQsPage;
