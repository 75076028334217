import HeaderContext from "../../context/HeaderContext";
import UserContext from "../../context/UserContext";
import TravelContext from "../../context/TravelContext.js";
import React, { useEffect } from "react";
import RedButton from "./utils/RedButton";
import WhiteButton from "./utils/WhiteButton";
import ApplicationRoute from "../../config/routes";
import { TravelData, TravelForeignData } from "../../config/defaultTravelValues.js";
import {
  getCountryFlag,
  getCountryPicture,
  getCountryNameFromId,
  getCountryFlagFromName,
  getCountryUidFromName,
} from "./utils/countryInfo";
import { Link, useHistory, useParams } from "react-router-dom";
import Loader from "./utils/Loader";
import LoadingPage from "./LoadingPage";

import apipath from "../../apipath";
import PermissionCard from "../push-notifications/utils/PermissionCard";
import DashBoardCrisisCard from "../push-notifications/utils/DashboardCrisisCard";
import useFetchAll from "../../services/useFetchAll";
import useFetch from "../../services/useFetch";
import GlobeIcon from "./utils/GlobeIcon";
import MetaDecorator from "../../utils/MetaDecorator";
import {
  compareMiddlewareStringDate,
  getYMDfromMiddleware__FOR__APP__USE,
  isMobile,
  isMobileEnv, lengthForTravelName,
} from "../../utils/helpers";
import { ReactComponent as StempelIcon } from '../../assets/icons/icon-bcms_offiziel.svg';

const applicationPageTitles = require("../../utils/pageTitles.json");

const TravelDashboard = () => {
  const { setHeader } = React.useContext(HeaderContext);
  const { setTravel } = React.useContext(TravelContext);
  const isDotEnabled = process.env.REACT_APP_DOT_ENABLED === 'true';

  const { user } = React.useContext(UserContext);

  const history = useHistory();

  const { hasPermissions } = useParams();

  /// crisis card
  const { data, loading } = useFetch(`${apipath.path}users/CURRENT/crisisstatus`, "GET");

  let urls = data?.map((d) => `${apipath.path}crisis/${d.CrisisId}`);

  const { data: dataAll, loading: loadingAll } = useFetchAll(urls ?? [], isMobileEnv());

  const { data: travelList, loading: travelListLoading } = useFetch(`${apipath.path}users/CURRENT/travels`, "GET");

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "main",
        title: "Bundesministerium",
        description: "Europaische and internationale Angelegenheiten",
      };
    });
  }, [setHeader]);

  const resetTravelLocalStorage = (e, bool = true) => {
    //bool = true -> it sets the defaultValues for a new trip, bool = false -> it sets the defaultValues for Auslandsregistrierung
    if (bool) {
      setTravel(Object.assign({}, TravelData));
    } else {
      let td = { ...TravelForeignData, TravelGroupMember: [] };
      setTravel(Object.assign({}, td));
    }
  };

  const defTravel = {
    Id: "",
    Name: "",
    StopStart: "",
    StopEnd: "",
    Country: "",
    CountryId: "",
    Comment: "",
    TravelGroupMember: [],
    TravelStops: [
      {
        Id: "",
        Name: "",
        StopStart: "",
        StopEnd: "",
        Country: "",
        CountryId: "",
        Comment: "",
      },
    ],
  };

  const onClickNewTravel = (e) => {
    e.preventDefault();
    setTravel(() => defTravel);
    localStorage.setItem("addTravel", defTravel);
    history.push(ApplicationRoute.addTravel);
  };

  if (travelListLoading) return <LoadingPage />;

  let isEmpty = !(travelList.length > 0 || user.IsLivingAbroad.toString() === "true");

  return (
    <section
      className={
        "content-container travel-dashboard" +
        (!isEmpty ? " registration-middle-container" : " travel-dashboard--empty")
      }
    >
      <MetaDecorator title={applicationPageTitles.dashboard} />
      {isMobile() ? (
        <h1
          className={
            "travel-dashboard__title" +
            (travelList.length || user.IsLivingAbroad ? "" : " travel-dashboard__title--red")
          }
        >
          Willkommen bei der Auslandsregistrierung!
        </h1>
      ) : isEmpty ? (
        <div className="half-col mobile-hidden">
          <div className="loginsignin-dashboard__globe-icon">
            <GlobeIcon />
          </div>
        </div>
      ) : null}

      <div className={"half-col " + (isEmpty ? "max-width-380" : "max-width-780")}>
        {!isMobile() ? (
          <h1
            className={
              "travel-dashboard__title" +
              (travelList.length || user.IsLivingAbroad ? "" : " travel-dashboard__title--red")
            }
          >
            {user.FirstName && user.LastName
              ? `Willkommen bei der Auslandsregistrierung, ${user?.FirstName ? user.FirstName.trim() : user.FirstName
              } ${user?.LastName ? user.LastName.trim() : user.LastName}!`
              : "Willkommen bei der Auslandsregistrierung!"}
          </h1>
        ) : null}
        {isEmpty ? (
          <h2 className="travel-dashboard__subtitle travel-dashboard__subtitle--no-travel-item mobile-hidden ">
            Legen Sie Ihren Auslandsaufenthalt an, um im Notfall für uns erreichbar zu sein.
          </h2>
        ) : null}

        {isMobileEnv() && (
          <PermissionCard
            title="Warnungen sind deaktiviert"
            content="Damit wir Sie im Krisenfall schnell und bestmöglich informieren können, erlauben Sie bitte der App, Ihnen Nachrichten senden und Ihren Standort immer verwenden zu dürfen. Die App benötigt diese Berechtigungen auch, wenn sie nicht geöffnet ist, damit die für Sie relevanten Nachrichten anhand Ihres Standortes zugeordnet und verschickt werden können."
            show={hasPermissions === "false"}
            className="dashboard-permissioncard-container"
          />
        )}

        {isMobileEnv() &&
          hasPermissions === "true" &&
          data &&
          dataAll &&
          !loadingAll &&
          !loading &&
          data?.map((crStatus) => {
            let currentCrisis = dataAll.filter((dc) => dc.Id === crStatus.CrisisId)?.[0];

            return (
              <DashBoardCrisisCard
                key={crStatus.Id}
                editId={crStatus.Id}
                crisisId={crStatus.CrisisId}
                country={currentCrisis?.CrisisAreas?.[0].CountryName}
                countryId={currentCrisis?.CrisisAreas?.[0].CountryId}
                needHelp={crStatus.IsActionNeeded}
                reason={currentCrisis?.CrisisDescription}
                latitude={currentCrisis?.CrisisAreas?.[0].Latitude}
                longitude={currentCrisis?.CrisisAreas?.[0].Longitude}
                shareLocation={crStatus.CurrentLocation}
                className="dashboard-crisis-card-container"
              />
            );
          })}

        {user.IsLivingAbroad && !!user.IsLivingAbroad && user.IsLivingAbroad !== "false" ? (
          <>
            <h2 className="travel-dashboard__subtitle">
              Sie sind als Auslandsösterreicher/in registriert in:
            </h2>
            <div
              role="article"
              className="travel-dashboard__travel-item travel-dashboard__travel-item--abroad"
              key={user.id}
            >
              <div className="travel-dashboard__info-container">
                <div className="travel-dashboard__article-row">
                  <div>
                    <p className="travel-dashboard__country-name">{getCountryNameFromId(user.Country)}</p>
                  </div>
                  <div className="travel-dashboard__image-container">
                    <img
                      src={getCountryFlag(user.Country)}
                      alt=""
                      title=""
                      onError={(ev) => ev.currentTarget.remove()}
                    />
                  </div>
                </div>
                <div
                  className={
                    "travel-dashboard__travel-details" +
                    (user.Relatives?.length === 0 ? " travel-dashboard__travel-details--no-margin-top" : "")
                  }
                >
                  {user.Relatives?.length > 0 ? (
                    <span>
                      {user.Relatives.length} {user.Relatives.length > 1 ? "Angehörige" : "Angehöriger"}
                    </span>
                  ) : (
                    "Keine Angehörigen hinzugefügt"
                  )}
                  <Link
                    role="button"
                    id={"edit-" + user.id}
                    to={ApplicationRoute.dashboardForeignRegistrationPage}
                    onClick={null}
                    className="travel-dashboard__travel-details__edit-button"
                    aria-label={"Auslandsösterreicherdaten bearbeiten"}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22">
                      <defs>
                        <filter id="ksp1ylrtqa">
                          <feColorMatrix
                            in="SourceGraphic"
                            values="0 0 0 0 0.619608 0 0 0 0 0.019608 0 0 0 0 0.160784 0 0 0 1.000000 0"
                          />
                        </filter>
                      </defs>
                      <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                        <g>
                          <g>
                            <g
                              filter="url(#ksp1ylrtqa)"
                              transform="translate(-302 -476) translate(23 216) translate(279.62 260.714)"
                            >
                              <g stroke="#000">
                                <path d="M19.573 2.66c0 .73-.2 1.355-.699 1.876L6.591 17.26 0 20.388l2.996-6.883L15.179.782c.999-1.043 2.597-1.043 3.595 0 .5.522.8 1.147.8 1.877z" />
                                <path d="M13.681 2.347s.999-.209 2.397 1.251c1.399 1.46 1.2 2.503 1.2 2.503" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </>
        ) : null}

        {travelList.length > 0 ? (
          <>
            <h1 className="travel-dashboard__subtitle">Ihre Reisen</h1>

            {localStorage.getItem("countries") && localStorage.getItem("countriesVB") ? (
              <ul
              //role="list"
              >
                {travelList.sort(compareMiddlewareStringDate).map((item) => {
                  let countryNames = "";

                  if (item.TravelStops) {
                    countryNames += item.TravelStops[0].Name;
                    if (item.TravelStops.length) {
                      item.TravelStops.forEach((el, index) => {
                        if (index > 0) countryNames += ", " + el.Name;
                      });
                    }
                  } else {
                    return null;
                  }

                  countryNames = lengthForTravelName(countryNames);

                  return (
                    <li id={item.Id} key={item.Id} role="article" className="travel-dashboard__travel-item">
                      {getCountryUidFromName(item.TravelStops[0].Name) && (
                        <div className="travel-dashboard__travel-picture">
                          <img
                            alt={`Bild von ${item.TravelStops[0].Name}`}
                            title=""
                            src={item.TravelStops ? getCountryPicture(item.TravelStops[0].Name) : ""}
                            onError={(ev) => ev.currentTarget.parentNode.remove()}
                          />
                        </div>
                      )}

                      <div className="travel-dashboard__info-container">
                        <div className="travel-dashboard__article-row">
                          <div>
                            <p className="travel-dashboard__country-name">{countryNames}</p>
                            <p
                              className="travel-dashboard__travel-duration"
                              aria-label={`Zeitraum ${getYMDfromMiddleware__FOR__APP__USE(
                                item.StartDate
                              )} - ${getYMDfromMiddleware__FOR__APP__USE(item.EndDate)}`}
                            >
                              {getYMDfromMiddleware__FOR__APP__USE(item.StartDate) +
                                "-" +
                                getYMDfromMiddleware__FOR__APP__USE(item.EndDate)}
                            </p>
                          </div>
                          <div className="travel-dashboard__image-container">
                            <img
                              src={item.TravelStops ? getCountryFlagFromName(item.TravelStops[0].Name) : ""}
                              alt={`Flagge von ${item.TravelStops[0].Name}`}
                              title=""
                              onError={(ev) => ev.currentTarget.remove()}
                            />
                          </div>
                        </div>
                        <div className="travel-dashboard__travel-details">
                          {item.TravelStops && item.TravelStops.length > 0 ? (
                            <span>
                              {item.TravelStops.length} {item.TravelStops.length > 1 ? "Destinationen" : "Destination"}
                            </span>
                          ) : null}
                          {item.TravelGroupMember && item.TravelGroupMember.length > 0 ? (
                            <span>
                              {item.TravelStops && item.TravelStops.length > 0 ? "," : ""}{" "}
                              {item.TravelGroupMember.length}{" "}
                              {item.TravelGroupMember.length > 1 ? "Mitreisende" : "Mitreisender"}
                            </span>
                          ) : null}
                          <button
                            id={"edit-" + item.id}
                            className="travel-dashboard__travel-details__edit-button"
                            aria-label="Reise bearbeiten"
                            onClick={() => {
                              history.push(ApplicationRoute.editTravel.replace(":id", item.Id));
                            }}
                          // aria-labelledby={""}
                          // aria-disabled={""}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22">
                              <defs>
                                <filter id="ksp1ylrtqa">
                                  <feColorMatrix
                                    in="SourceGraphic"
                                    values="0 0 0 0 0.619608 0 0 0 0 0.019608 0 0 0 0 0.160784 0 0 0 1.000000 0"
                                  />
                                </filter>
                              </defs>
                              <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                                <g>
                                  <g>
                                    <g
                                      filter="url(#ksp1ylrtqa)"
                                      transform="translate(-302 -476) translate(23 216) translate(279.62 260.714)"
                                    >
                                      <g stroke="#000">
                                        <path d="M19.573 2.66c0 .73-.2 1.355-.699 1.876L6.591 17.26 0 20.388l2.996-6.883L15.179.782c.999-1.043 2.597-1.043 3.595 0 .5.522.8 1.147.8 1.877z" />
                                        <path d="M13.681 2.347s.999-.209 2.397 1.251c1.399 1.46 1.2 2.503 1.2 2.503" />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <Loader />
            )}
          </>
        ) : null}

        {isEmpty ? (
          <div className="travel-dashboard__no-travel-item">
            <div role="img" className="travel-dashboard__globe-icon desktop-hidden">
              <GlobeIcon />
            </div>
            <h2
              className="travel-dashboard__subtitle travel-dashboard__subtitle--no-travel-item desktop-hidden"
            >
              Legen Sie hier Ihren Auslandsaufenthalt an, um im Notfall für uns erreichbar zu sein.
            </h2>
          </div>
        ) : null}
        <div className={travelList.length > 0 ? "travel-buttons-flex" : "travel-buttons-grid"}>
          <RedButton
            text="Neue Reise anlegen"
            path={ApplicationRoute.addTravel}
            onClick={onClickNewTravel}
            style={{ marginTop: "4.5rem" }}
          />
          {isDotEnabled && <RedButton
            text="Urkunde bestellen"
            path={ApplicationRoute.dot}
            style={{ marginTop: "2rem" }}
            icon={<StempelIcon width="26" color="#ffffff" />}
          />}
          {user.IsLivingAbroad && !!user.IsLivingAbroad && user.IsLivingAbroad !== "false" ? null : (
            <WhiteButton
              text="Als Auslandsösterreicher/in registrieren"
              ariaLabel="Als Auslandsösterreicher:in registrieren"
              path={ApplicationRoute.foreignRegistrationRegionSelection}
              icon="pin"
              onClick={(e) => resetTravelLocalStorage(e, false)}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default TravelDashboard;
