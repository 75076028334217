import { Link } from "react-router-dom";
import MainHeaderDesktop from "./MainHeaderDesktop";
import { isMobile } from "../../utils/helpers";
import MapInput from "../MapInput";
import MapInputResultComponent from "../MapInputResultComponent";

const checkForRepresentationBType = (country, value) => {
  let bool = false;
  if (!country.representations || country.representations.length === 0) {
    return bool;
  }

  country.representations.forEach((r) => {
    if (r.type && r.type.toLowerCase().startsWith(value)) {
      bool = true;
    }
  });

  return bool;
};

const checkForRepresentation = (representations, value) => {
  let bool = false;
  if (representations.length === 0) {
    return bool;
  }
  representations.forEach((r) => {
    if (r.city && r.city.toLowerCase().startsWith(value)) {
      bool = true;
    }
  });

  return bool;
};

const getFullName = (country, aliasArr) => {
  let a = aliasArr.length > 0 ? aliasArr[0] + " " : "";
  let b = country;

  if (aliasArr.length > 0) {
    b = "(" + b + ")";
  }
  let x = a + b;

  return x;
};

const MapHeader = ({
  title,
  currentProgress,
  onBackButtonClickHandler,
  ariaLabel,
  setMapCenter,
  data,
  value,
  setValue,
  hidden,
  setHidden,
  appHeight,
}) => {
  let newTitle = title?.includes("%noarrow%") ? title.replace("%noarrow%", "") : title;

  let hasArrow = !title?.includes("%noarrow%");

  const setMapCenterHandler = () => {
    if (!hidden) {
      setValue("");
    }
    setHidden((curr) => !curr);
  };

  return (
    <>
      {" "}
      {!isMobile() ? (
        <div id="registration-header-id" className="mobile-sticky">
          <MainHeaderDesktop className="mobile-hidden" />
          <header className="register-header fixed-height-header" role="navigation">
            <a
              className="sr-only"
              href="#main"
              onClick={(ev) => {
                ev.preventDefault();
                document.getElementById("main").scrollIntoView();
              }}
            >
              Zum Inhalt springen
            </a>
            {hasArrow && (
              <Link
                className="register-header__back-button"
                to="./"
                onClick={onBackButtonClickHandler}
                aria-label="Zurück"
                role="button"
              >
                <img className="register-header__back-button__img" src="./images/common/headerback.png" alt="" />
              </Link>
            )}

            <h1 className="register-header__title" aria-label={ariaLabel}>
              {newTitle}
            </h1>

            <button
              role="search"
              onClick={setMapCenterHandler}
              className="register-header__search button-abs"
              aria-label="Suche"
            >
              <img
                className="register-header__search-img"
                src="./images/common/iconsSearch.png"
                alt="Suche"
                aria-label="Suche"
              />
            </button>
          </header>
          {currentProgress.includes("no") ? (
            <> </>
          ) : (
            <div className="progress-bar">
              <div className="current-progress" style={{ width: currentProgress }}></div>
            </div>
          )}
        </div>
      ) : (
        <div id="registration-header-id" className="mobile-sticky">
          <header className="register-header fixed-height-header" role="navigation">
            {hasArrow && (
              <Link
                className="register-header__back-button"
                to="./"
                onClick={onBackButtonClickHandler}
                aria-label="Zurück"
                role="button"
              >
                <img className="register-header__back-button__img" src="./images/common/headerback.png" alt="" />
              </Link>
            )}
            <h1 className="register-header__title" aria-label={ariaLabel}>
              {newTitle}
            </h1>
            <button
              role="search"
              onClick={setMapCenterHandler}
              className="register-header__search button-abs"
              aria-label="Suche"
            >
              <img
                className="register-header__search-img"
                src="./images/common/iconsSearch.png"
                alt="Suche"
                aria-label="Suche"
              />
            </button>
          </header>
          {currentProgress.includes("no") ? (
            <> </>
          ) : (
            <div className="progress-bar">
              <div className="current-progress" style={{ width: currentProgress }}></div>
            </div>
          )}
        </div>
      )}
      <MapInput
        hidden={hidden}
        onCrossClick={() => {
          setValue("");
          setHidden(true);
        }}
        value={value}
        setValue={setValue}
      />
      <div
        style={{ display: hidden ? "none" : "block", maxHeight: appHeight }}
        className="map-input-result-container"
        role="listbox"
      >
        {data
          .filter((c) => {
            let v = value ? value.toLowerCase() : "";
            return (
              getFullName(c.country, c.countryAlias).toLowerCase().startsWith(v) ||
              checkForRepresentationBType(c, v) ||
              checkForRepresentation(c.representations, v)
            );
          })
          .map((country) => {
            return country.representations
              .filter((r) => r.latitude !== null)
              .map((r) => {
                let v = value ? value.toLowerCase() : "";
                if (
                  (r.type && r.type.toLowerCase().startsWith(v)) ||
                  getFullName(country.country, country.countryAlias).toLowerCase().startsWith(v) ||
                  (r.city && r.city.toLowerCase().startsWith(v))
                ) {
                  return (
                    <MapInputResultComponent
                      key={`${r.orgaUnitUid}-${r.locationUid}`}
                      country={
                        country.countryAlias.length > 0
                          ? country.countryAlias + " (" + country.country + ")"
                          : country.country
                      }
                      info={`${r.city ?? "-"}, ${r.type ?? "-"}`}
                      goTo={setMapCenter}
                      long={r.longitude}
                      lat={r.latitude}
                      hide={() => setHidden(() => true)}
                    />
                  );
                } else {
                  return <> </>;
                }
              });
          })}
      </div>
    </>
  );
};

export default MapHeader;
