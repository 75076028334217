import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Input from "../../registration/utils/Input";
import InputValidation from "../../../common/InputValidaiton";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import {
  hasAtSymbolEmailValidation,
  hasTwoCharactersAfterAtSymbolEmailValidation,
  isEmailValidation,


} from "../../../utils/validation";
import { registrationValidationErrorMessages } from "../../../utils/validationErrorMessages";
import Form from "../../registration/utils/Form";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import WeiterButton from "../../registration/utils/WeiterButton";
import PageTitle from "../../travel-registration/utils/PageTitle";
import PageDescription from "../../travel-registration/utils/Description";
import MetaDecorator from "../../../utils/MetaDecorator";
import HorizontalRule from "../../../common/HorizontalRule";

const applicationPageTitles = require("../../../utils/pageTitles.json");
const AddRelative5 = ({ text }) => {
  const history = useHistory();

  const { setHeader } = React.useContext(HeaderContext);

  //
  //
  const activate = () => {
    setActive(() => true);
  };

  const [active, setActive] = useState(false);

  useEffect(() => {
    document.getElementsByTagName("HTML")[0].addEventListener("click", activate);

    return () => document.getElementsByTagName("HTML")[0].removeEventListener("click", activate);
  }, []);
  //
  //

  let initemail = "";
  try {
    initemail = JSON.parse(localStorage.getItem("newTGM")).Email ?? "";
  } catch (error) {}

  const [email, setEmail] = useState(initemail);

  useEffect(() => {
    let title = "Mitreisende hinzufügen";

    try {
      title = localStorage.getItem("addTGMTitle");
    } catch (error) {}

    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: title,
        progress: "no",
      };
    });
  }, []);

  const isValid = () => {
    return (isEmailValidation(email) && hasTwoCharactersAfterAtSymbolEmailValidation(email)) || email.length === 0;
  };

  const onChangeHandler = (e) => {
    setEmail(e.target.value);
  };

  const updateTGM = () => {
    let tgm = JSON.parse(localStorage.getItem("newTGM"));
    localStorage.setItem(
      "newTGM",
      JSON.stringify({
        ...tgm,
        Email: email,
      })
    );
  };

  const onClickHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateTGM();
      history.push(ApplicationRoute.addRelative6);
    }
  };

  const onsubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateTGM();
      history.push(ApplicationRoute.addRelative6);
    }
  };
  return (
    <div className="add-relative-container registration-middle-container">
      <MetaDecorator title={applicationPageTitles.editTrip} />
      <PageTitle id="add-relatives-email" text="Bitte geben Sie die E-Mail-Adresse ein:" />

      <p className="page-paragraph page-paragraph--small">Mitreisende/r</p>

      <PageDescription text="Bitte geben Sie entweder die Mobiltelefonnummer oder die E-Mail-Adresse der mitreisenden Person an – falls vorhanden. Es ist wichtig, dass wir die Person im Notfall kontaktieren können." />

      <HorizontalRule className="horizontal-rule-desktop" />
      <Form id="form" onSubmit={onsubmitHandler} ariaLabelledBy="add-relatives-email" className="max-width-380">
        <Input
          id="email"
          value={email}
          onChange={onChangeHandler}
          placeholder="E-Mail-Adresse"
          type="email"
          labelText="E-Mail-Adresse"
          showValidationBorder={
            email.length > 0 && (!isValid() || !hasTwoCharactersAfterAtSymbolEmailValidation(email))
          }
        />

        <InputValidation
          isHidden={
            !active ||
            !email.length > 0 ||
            !hasAtSymbolEmailValidation(email) ||
            !hasTwoCharactersAfterAtSymbolEmailValidation(email) ||
            isValid()
          }
          text={registrationValidationErrorMessages.notValidEmail}
        />

        <InputValidation
          isHidden={!active || !email.length > 0 || hasAtSymbolEmailValidation(email)}
          text={registrationValidationErrorMessages.missingAtSymbolEmail}
        />

        <InputValidation
          isHidden={
            !active ||
            !email.length > 0 ||
            !hasAtSymbolEmailValidation(email) ||
            hasTwoCharactersAfterAtSymbolEmailValidation(email)
          }
          text={registrationValidationErrorMessages.charactersAfterAtSymbolLessThanTwoEmail}
        />

        <HiddenSubmitInput />
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content"> </div>
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.addRelative6}
        onClick={onClickHandler}
        ariaLabel={text}
        className="hide-weiter-on-keyboard floating-weiter-buton"
      />
    </div>
  );
};

export default AddRelative5;
