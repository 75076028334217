import React from "react";
import CustomSelectList from "../components/foreign-registration/utils/CustomSelectList";
import FilteredSelectList from "../components/foreign-registration/utils/FilteredSelectList";
import CountryModal from "./CountryModal";

const Input = ({
  id,
  value,
  onChange,
  placeholder,
  type = "text",
  labelText,
  ariaRequired = true,
  options,
  optional,
  backGroundClassName,
  readonly = false,
  modal,
  modalData,
  filtered = false
}) => {
  const [edit, setEdit] = React.useState(false);
  const [isDropdown] = React.useState(!!options);
  const [intervalId, setIntervalId] = React.useState(0);
  const inputRef = React.useRef();

  React.useEffect(() => {
    clearInterval(intervalId);
    setIntervalId(
      setInterval(() => {
        if (edit && document.querySelector(`#${id}container .edit`) == null) {
          setEdit(false);
        }
      }, 200)
    );
  }, [edit]);

  function onButtonClick() {
    setEdit(!edit);
    document.querySelectorAll(".edit").forEach((el) => {
      el.classList.remove("edit");
    });
    if (modal && !edit) {
      toggleModal();
    }
  }

  React.useEffect(() => {
    if (edit) {
      inputRef?.current.focus();
    }
  }, [edit]);

  function toggleModal() {
    if (document.getElementsByTagName("body").item(0).classList.contains("overflow-hidden")) {
      document.getElementsByTagName("body").item(0).classList.remove("overflow-hidden");
    } else {
      document.getElementsByTagName("body").item(0).classList.add("overflow-hidden");
    }
  }
  return (
    <div id={`${id}container`} className={"custom-edit" + (backGroundClassName ? " " + backGroundClassName : "")}>
      <label
        className={"custom-edit__label" + (edit ? " edit" : "") + (isDropdown && edit ? " hidden" : "")}
        htmlFor={id}
      >
        {labelText}
      </label>
      {isDropdown && edit ? (
        !filtered ? (
          <CustomSelectList
            id={id}
            label={placeholder}
            options={options}
            state={value}
            onChangeHandler={(value) => {
              setEdit(false);
              onChange(value);
            }}
            open={true}
            noIcon={true}
            ref={inputRef}
            optional={optional ? optional : false}
          />
        ) : (
          <FilteredSelectList
              id={id}
              label={placeholder}
              options={options}
              state={value}
              onChangeHandler={(value) => {
                setEdit(false);
                onChange(value);
              }}
              open={true}
              noIcon={true}
              ref={inputRef}
              optional={optional ? optional : false}
          />
        )
      ) : (
        <input
          id={id}
          className={"custom-edit__input" + (edit ? " edit" : "")}
          value={value}
          type={type}
          onChange={onChange}
          placeholder="-"
          aria-required={ariaRequired}
          disabled={!edit && !readonly}
          readOnly={readonly}
          ref={inputRef}
        />
      )}
      {readonly ? null : (
        <button
          className="custom-edit__button"
          onClick={onButtonClick}
          type="button"
          aria-label={`${labelText} bearbeiten`}
        >
          {edit ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="12" viewBox="0 0 21 12">
              <g
                fill="none"
                fillRule="evenodd"
                fontFamily="SourceSansPro-Bold, Source Sans Pro"
                fontSize="16"
                fontWeight="bold"
              >
                <g fill="#9E0529">
                  <text transform="translate(-1 -7)">
                    <tspan x="1" y="18">
                      OK
                    </tspan>
                  </text>
                </g>
              </g>
            </svg>
          ) : (
            <svg
              width="19.6px"
              height="19.55px"
              viewBox="0 0 19.6 19.55"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Bearbeiten</title>
              <g
                id="Icons-/-edit"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <g>
                  <g transform="translate(1.000000, 1.000000)">
                    <path
                      d="M18,2.34782609 C18,2.99232737 17.8163265,3.54475703 17.3571429,4.00511509 L6.06122449,15.2378517 L0,18 L2.75510204,11.9232737 L13.9591837,0.690537084 C14.877551,-0.230179028 16.3469388,-0.230179028 17.2653061,0.690537084 C17.7244898,1.15089514 18,1.70332481 18,2.34782609 Z"
                      id="Stroke-1"
                      stroke="#9e0529"
                    />
                    <path
                      d="M13,2.00820793 C13,2.00820793 13.8308257,1.84199726 14.9939817,3.00547196 C16.1579686,4.16894665 15.9918034,5 15.9918034,5"
                      id="Stroke-3"
                      stroke="#9e0529"
                    />
                  </g>
                </g>
              </g>
            </svg>
          )}
        </button>
      )}
      {modal && edit ? (
        modal == "country" ? (
          <CountryModal
            country={modalData?.Country}
            county={modalData?.County}
            close={(e) => {
              toggleModal();
              onButtonClick();
            }}
            save={(country, region, street, postalCode, city) => {
              toggleModal();
              onButtonClick();
              onChange(country, region, street, postalCode, city);
            }}
          />
        ) : null
      ) : null}
    </div>
  );
};

export default Input;
