import { Link } from "react-router-dom";
import Cross from "../../svg/Cross";

const EditProfileHeader = ({
  title,
  onClickCloseHandler,
  onClickLogoutHandler,
  buttonId = "buttonId",
  linkId = "linkId",
}) => {
  return (
    <header className="edit-profile-header" role="navigation">
      <a className="sr-only" href="#main" onClick={(ev) => {
        ev.preventDefault();
        document.getElementById("main").scrollIntoView();
      }}>Zum Inhalt springen</a>
      <div className="edit-profile-header__nav">
        <Link
          role="button"
          id={linkId}
          to="./"
          className="edit-profile-header__nav__close"
          ariaLabel="Schließen"
          onClick={onClickCloseHandler}
        >
          <Cross className="edit-profile-header__nav__close-svg" />
        </Link>
        <button
          id={buttonId}
          onClick={onClickLogoutHandler}
          className="edit-profile-header__nav__logout"
          ariaLabel="Ausloggen"
        >
          Abmelden
        </button>
      </div>
      <div role="heading" aria-level="1" className="edit-profile-header__title">
        {title}
      </div>
    </header>
  );
};

export default EditProfileHeader;
