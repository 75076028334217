// Options to set for the button
//
//positioned => fixed/static
//color => red/transperant => setting the background and color of the button
//reg

import React from "react";
import { Link } from "react-router-dom";
import {isMobile, isMobileEnv} from "../../../utils/helpers";
import RegisterIcon from "./RegisterIcon.jsx";
import { useTranslation } from "react-i18next";

const WeiterButton = ({
  id,
  disabled,
  path,
  onClick,
  linkId = "linkId",
  text = "terms.weiter",
  ariaLabelledBy,
  positioned = "fixed",
  color = "red",
  style,
  icon,
  className = "",
  classNameLink = "",
  classFix = "",
}) => {
  const { t } = useTranslation()
  let classState = "weiter-button-wrapper--static";
  if (positioned === "fixed") classState = "weiter-button-wrapper--fixed";
  else if (positioned === "absolute") classState = "weiter-button-wrapper--absolute";

  if (!!classFix && !isMobile()) classState = "weiter-button-wrapper--fixed";

  if (!isMobileEnv() && positioned === "fixed") classState += " weiter-button-wrapper--fixed--browser";

  const onKeyPressHandler = (e) => {
    if (disabled) {
      e.preventDefault();
    }
  };

  return (
    <div
      id={id}
      style={style}
      className={`weiter-button-wrapper ${classState}`}
      onKeyDown={onKeyPressHandler}
      aria-disabled={disabled}
    >
      <div className={`weiter-button-container ${className}`}>
        <Link
          role="button"
          id={linkId}
          to={path}
          onClick={onClick}
          style={disabled ? { pointerEvents: "none" } : { pointerEvents: "auto" }}
          className={`weiter-button ${
            color === "transperant"
              ? "weiter-button--transperant"
              : disabled
              ? "weiter-button--red--disabled"
              : "weiter-button--red--enabled"
          } ${classNameLink}`}
          aria-labelledby={ariaLabelledBy}
          aria-disabled={disabled}
        >
          {icon === "register" ? (
            <RegisterIcon color={color} />
          ) : null}
          {t(text)}
        </Link>
      </div>
      {(positioned === "fixed" || positioned === "absolute") && <div className="button-space-on-keyboard"> </div>}
    </div>
  );
};

export default WeiterButton;
