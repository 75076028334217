import React, { useState, useEffect, useContext } from "react";
import HeaderContext from "../../../context/HeaderContext";
import CountryCard from "../utils/CountryCard";
import Separater from "../../../common/Separater";
import HorizontalRule from "../../../common/HorizontalRule";
import MetaDecorator from "../../../utils/MetaDecorator";
import apipath from "../../../apipath";
import { getVB__with__loader } from "../../travel-registration/utils/GetCountries";
import fetcher from "../../../services/fetcher";
import Loader from "../../travel-registration/utils/Loader";
import { compare } from "../../../utils/helpers";
import { checkIfCached } from "../../../services/caching";
import UserContext from "../../../context/UserContext";
import { CacheKeyTravelInfo } from "../../../utils/const";
import { useTranslation } from "react-i18next";

const applicationPageTitles = require("../../../utils/pageTitles.json");

const getFullName = (country, aliasArr) => {
  let a = aliasArr.length > 0 ? aliasArr[0] + " " : "";
  let b = country;

  if (aliasArr.length > 0) {
    b = "(" + b + ")";
  }
  let x = a + b;

  return x;
};

const CountryList = ({ valueC }) => {
  const { setHeader } = React.useContext(HeaderContext);
  const { t } = useTranslation();

  const [countriesVb, setCountriesVb] = React.useState(null);
  const [cachedCountries, setCachedCountries] = React.useState([]);

  useEffect(() => {
    (async () => {
      if (!countriesVb || !countriesVb.countries) return;
      const result = await Promise.all(
        countriesVb.countries.map(async (x) => {
          const cacheData = await checkIfCached(CacheKeyTravelInfo + x.country);
          return {
            name: x.country,
            ...cacheData
          }
        })
      );
      setCachedCountries(result)
    })()
  }, [countriesVb, countriesVb?.countries, setCachedCountries, checkIfCached])

  const [favCountries, setFavCountries] = useState(
    localStorage.getItem("favoriteCountries") != null ? JSON.parse(localStorage.getItem("favoriteCountries")) : []
  );

  const [locationCountry, setLocationCountry] = useState("");

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const { offline } = useContext(UserContext);

  const getMiddlewareLocationObject = () => {
    fetcher(
      `${apipath.path}geocodes/${location.lat}/${location.long}`,
      "GET",
      setData,
      setError,
      setLoading,
      setSubmitting,
      true,
      "json",
      undefined,
      undefined,
      undefined,
      true
    );
  };

  const [location, setLocation] = useState({ long: "", lat: "" });

  function enableLocation() {
    if (navigator.geolocation) navigator.geolocation.getCurrentPosition(handleGetCurrentPosition, onError);
  }

  function handleGetCurrentPosition(location) {
    setLocation((curr) => {
      return {
        ...curr,
        long: location.coords.longitude,
        lat: location.coords.latitude,
      };
    });
  }

  useEffect(() => {
    //get location from getLocation
    enableLocation();
  }, []);

  useEffect(() => {
    //load middleware object
    if ((!!location.long || location.long < 0) && (!!location.lat || location.lat < 0)) {
      getMiddlewareLocationObject();
    }
  }, [location]);

  useEffect(() => {
    //use location object from middleware
    if (!loading) {
      try {
        let countryVBobject = JSON.parse(localStorage.getItem("countriesVB")).countries.filter((c) => {
          return c.countryIsoCode === data?.country;
        });
        setLocationCountry(countryVBobject[0]);
      } catch (error) { 
        console.error(error)
      }
    }
  }, [loading]);

  function onError() {
    setLocation((curr) => {
      return { ...curr, long: "", lat: "" };
    });
  }

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "countriesList",
        title: t("pages.landerinformationen.title"),
      };
    });
  }, []);

  useEffect(() => {
    localStorage.setItem("favoriteCountries", JSON.stringify(favCountries));
  }, [favCountries]);

  const handleFavClick = (ev, uid) => {
    ev.preventDefault();
    if (favCountries.indexOf(uid) !== -1) {
      let index = favCountries.indexOf(uid);
      let curr = JSON.parse(JSON.stringify(favCountries));
      curr.splice(index, 1);
      setFavCountries(curr);
    } else {
      setFavCountries((curr) => [...curr, uid]);
    }
  };

  const [vbLoader, setVBLoader] = useState(false);

  useEffect(() => {
    try {
      const vbData = JSON.parse(localStorage.getItem("countriesVB"));
      if (vbData) {
        vbData.countries.sort(compare);
        setCountriesVb(vbData);
      } else {
        getVB__with__loader(setVBLoader);
      }
    } catch (error) { 
      console.error(error)
    }
  }, []);

  useEffect(() => {
    if (vbLoader) {
      const vbData = JSON.parse(localStorage.getItem("countriesVB"));

      try {
        let countryVBobject = JSON.parse(localStorage.getItem("countriesVB")).countries.filter((c) => {
          return c.countryIsoCode === data?.country;
        });
        setLocationCountry(countryVBobject[0]);
      } catch (error) { 
        console.error(error)
      }

      setCountriesVb(vbData);
    }
  }, [vbLoader]);

  useEffect(() => {
    if (countriesVb) {
      if (localStorage.getItem("countryOverviewPosition") !== null) {
        setTimeout(() => {
          window.scrollTo(0, parseInt(localStorage.getItem("countryOverviewPosition")));
          localStorage.removeItem("countryOverviewPosition");
        }, 700);
      }
    }
  }, [countriesVb]);

  if (countriesVb === null) return <Loader />;
  if (loading && submitting) return <Loader />;

  // sortCountriesVB(countriesVb);
  return (
    <section className="country-list">
      <MetaDecorator title={t("pages.landerinformationen.title")} />
      {location.long !== "" && location.lat !== "" && !valueC && (
        <section aria-label="Aktueller Standort">
          <Separater text="Aktueller Standort" />

          <CountryCard
            key={locationCountry?.country}
            flag={`${apipath.pathBMEIAgvat_API}flags/${data ? data.country.toLowerCase() : ""}.png`}
            countryName={
              <div>
                {
                  (offline && cachedCountries?.findIndex(x => x.name === locationCountry?.country && x.cached) !== -1) &&
                  <div className="countrylist__offline_available">Offline verfügbar</div>
                }
                {locationCountry?.country}
              </div>
            }
            favouriteToggle={() => { }}
            riskLvl={
              locationCountry?.riskLevel == 6
                ? "Achtung Reisewarnung!"
                : locationCountry?.riskLevel == 5
                  ? "Achtung partielle Reisewarnung!"
                  : ""
            }
            countryUid={locationCountry?.countryUid}
            hasStar={false}
          />
        </section>
      )}

      {favCountries.length ? (
        <div
          key={countriesVb?.countries.map((c) => {
            return c.countryUid;
          })}
        >
          <Separater text="Favoriten" className="country__separater--hidden" />
          <section className="country-list__favourites" role="list" aria-label="Favoriten">
            {countriesVb?.countries
              .filter((c) => {
                let v = valueC ? valueC.toLowerCase() : "";
                return getFullName(c.country, c.countryAlias).toLowerCase().includes(v);
              })
              .map((item) => {
                if (favCountries?.indexOf(item.countryUid) !== -1) {
                  return (
                    <React.Fragment key={item.countryUid}>
                      <CountryCard
                        id={item.countryUid}
                        key={item.countryUid}
                        flag={`${apipath.pathBMEIAgvat_API}flags/${item.countryIsoCode ? item.countryIsoCode.toLowerCase() : "no_iso_code"}.png`}
                        countryName={
                          <div>
                            {
                              (offline && cachedCountries?.findIndex(x => x.name === item?.country && x.cached) !== -1) &&
                              <div className="countrylist__offline_available">Offline verfügbar</div>
                            }
                            {item.countryAlias.length > 0 ? item.countryAlias + " (" + item?.country + ")" : item.country}
                          </div>
                        }
                        riskLvl={
                          item.riskLevel === 6
                            ? "Achtung Reisewarnung!"
                            : item.riskLevel === 5
                              ? "Achtung partielle Reisewarnung!"
                              : ""
                        }
                        favourite={true}
                        favouriteToggle={handleFavClick}
                        countryUid={item.countryUid}
                        role="listitem"
                      />
                      <HorizontalRule />
                    </React.Fragment>
                  );
                } else {
                  return <React.Fragment key={item.countryUid}></React.Fragment>;
                }
              })}
          </section>
        </div>
      ) : null}
      <Separater text={favCountries.length > 0 ? "Andere Länder" : "Alle Länder"}/>
      <section role="list" aria-label="Andere Länder">
        {countriesVb.countries
          .filter((c) => {
            let v = valueC ? valueC.toLowerCase() : "";
            return getFullName(c.country, c.countryAlias).toLowerCase().includes(v);
          })
          .map((item, index) => {
            if (favCountries?.indexOf(item.countryUid) === -1) {
              return (
                <React.Fragment key={item.countryUid}>
                  <CountryCard
                    flag={`${apipath.pathBMEIAgvat_API}flags/${item.countryIsoCode ? item.countryIsoCode.toLowerCase() : "no_iso_code"}.png`}
                    countryName={
                      <div>
                        {
                          (offline && cachedCountries?.findIndex(x => x.name === item?.country && x.cached) !== -1) &&
                          <div className="countrylist__offline_available">Offline verfügbar</div>
                        }
                        {item.countryAlias.length > 0 ? item.countryAlias + " (" + item?.country + ")" : item.country}
                      </div>
                    }
                    id={item.countryUid}
                    riskLvl={
                      item.riskLevel === 6
                        ? "Achtung Reisewarnung!"
                        : item.riskLevel === 5
                          ? "Achtung partielle Reisewarnung!"
                          : ""
                    }
                    favouriteToggle={handleFavClick}
                    countryUid={item.countryUid}
                    role="listitem"
                  />
                  <HorizontalRule />
                  {/* {countriesVb.countries.length == index + 1 ? null : (
                
              )} */}
                </React.Fragment>
              );
            } else {
              return <React.Fragment key={item.countryUid}></React.Fragment>;
            }
          })}
      </section>
    </section>
  );
};
export default CountryList;
