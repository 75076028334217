import React, { useState, useEffect } from "react";
import HeaderContext from "../../../context/HeaderContext";
import {
  getCountryFlagFromCountryVB,
  getCountryPicture,
  getCountryNameFromUid,
  getCountryAliasFromUid,
} from "../../travel-registration/utils/countryInfo";
import MetaDecorator from "../../../utils/MetaDecorator";
import { useParams, Link } from "react-router-dom";
import CountryInfoHeader from "../utils/CountryInfoHeader";
import NavigationPattern from "../utils/NavigationPattern";
import apipath from "../../../apipath";
import Loader from "../../travel-registration/utils/Loader";
import { CacheKeyTravelInfo } from "../../../utils/const";

const CountryDetailedInfo = () => {
  const { countryUid } = useParams();
  const { setHeader } = React.useContext(HeaderContext);
  const [countries] = React.useState(JSON.parse(localStorage.getItem("countriesVB")).countries);

  const [countryName] = React.useState(getCountryNameFromUid(countryUid));
  const [countryAlias] = React.useState(getCountryAliasFromUid(countryUid));
  const [hasRepresentations, setHasRepresentations] = React.useState(null);
  const [alternateRepresentations, setAlternateRepresentations] = React.useState(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "content",
        title: countryAlias.length > 0 ? countryAlias + " (" + countryName + ")" : countryName,
      };
    });
    fetchData();

    if (hasRepresentations == null || alternateRepresentations == null) {
      const arr = countries;
      let data = [];

      for (let i = 0; i < arr.length; i++) {
        if (arr[i].countryUid == countryUid) {
          data = arr[i].representations.length ? true : false;

          if (!data && arr[i].alternativeOrgaUnitUids.length) {
            setAlternateRepresentations(arr[i].alternativeOrgaUnitUids);
          }

          break;
        }
      }
      setHasRepresentations(data);

      if (!data) {
      }
    }
  }, []);

  // get travelInfoUrl from localStorage
  let travelInfoUrl = "";
  try {
    travelInfoUrl = `${apipath.path.replace("/api/v1/", "")}${
      countries.filter((c) => c.country === countryName)[0].middlewareUri
    }`;
  } catch (error) {}

  const [travelInfo, setTravelInfo] = useState({});

  useEffect(() => {
    localStorage.setItem("travelInfo", JSON.stringify(travelInfo));
  }, [travelInfo]);

  const fetchData = async () => {
    setLoading(() => true);

    // Enable cache and autoload with the country name as a key
    const response = await fetch(travelInfoUrl, {
      method: "GET",
    }, true, true, "json", CacheKeyTravelInfo + countryName);
    let travelInfo = await response.json();
    setTravelInfo(travelInfo);
    setLoading(() => false);
  };

  if (loading) return <Loader />;

  return (
    <>
      <MetaDecorator title={countryName} />
      <CountryInfoHeader
        flag={getCountryFlagFromCountryVB(countryName)}
        countryImage={getCountryPicture(countryName)}
        Country={countryAlias.length > 0 ? countryAlias + " (" + countryName + ")" : countryName}
        RiskLvl={
          countries.filter((country) => {
            return country.country === countryName;
          })[0].riskLevel == 5
            ? "Achtung partielle Reisewarnung!"
            : countries.filter((country) => {
                return country.country === countryName;
              })[0].riskLevel == 6
            ? "Achtung Reisewarnung!"
            : ""
        }
        className="country-flag__position-relative"
      />
      {hasRepresentations || (alternateRepresentations && alternateRepresentations.length) ? (
        <Link
          style={{ textDecoration: "none", color: "#000000" }}
          to={`/country/${countryUid}/botschaften-und-konsulate`}
          className="navigation-info-link"
        >
          <NavigationPattern to={`/country/${countryUid}/botschaften-und-konsulate`} text="Botschaften und Konsulate" />
        </Link>
      ) : null}

      {travelInfo.data
        ? travelInfo.data.map((info, index) => {
            if (info.header !== "Stand") {
              return (
                <Link
                  style={{ textDecoration: "none", color: "#000000" }}
                  to={`/countryInfo/${countryUid}/${info.header}`}
                  className="navigation-info-link"
                >
                  <NavigationPattern key={index} text={info.header} />
                </Link>
              );
            }
          })
        : ""}
      <p className="stand-text">
        {travelInfo.data
          ? travelInfo.data.filter((info) => {
              return info.header === "Stand";
            })[0].bodytext
          : ""}
      </p>
    </>
  );
};

export default CountryDetailedInfo;
