import React, {useEffect} from "react";
import ApplicationRoute from "../../../config/routes";
import {useHistory} from "react-router-dom";
import HeaderContext from "../../../context/HeaderContext";
import MetaDecorator from "../../../utils/MetaDecorator.jsx";
import HorizontalRule from "../../../common/HorizontalRule.jsx";
import WeiterButton from "../utils/WeiterButton.jsx";

const applicationPageTitles = require("../../../utils/pageTitles.json");
//HINT* REGISTRATION
const IdaWelcomePage = ({text}) => {
    const history = useHistory();

    const {setHeader} = React.useContext(HeaderContext);

    useEffect(() => {
        setHeader((curr) => {
            return {
                ...curr,
                inUse: "registration",
                progress: 100,
                title: applicationPageTitles.welcomePage + "%noarrow%",
                ariaLabel: "Willkommen",
            };
        });
    }, [setHeader]);

    const onClickHandler = (e) => {
        e.preventDefault();

        history.push(ApplicationRoute.TravelDashboardNoPermissions)
    };

    return (
        <div className="registration-middle-container">
            <MetaDecorator title={applicationPageTitles.welcome}/>
            <p className="content-title" style={{marginBottom: "3.2rem"}}>
                Willkommen bei der Auslandsregistrierung!
            </p>
            <div>
                <p>Bitte prüfen Sie ob Ihre Daten aktuell sind.</p>
            </div>
            <HorizontalRule className="horizontal-rule-desktop"/>
            <WeiterButton
                text="Weiter"
                ariaLabel={text}
                onClick={onClickHandler}
                path={ApplicationRoute.TravelDashboardNoPermissions}
            />
        </div>
    );
};

export default IdaWelcomePage;