import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Input from "../../registration/utils/Input";
import InputValidation from "../../../common/InputValidaiton";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import {
  dateFormatter,
} from "../../../utils/helpers";
import {
  IsDateBiggerThanTodayValidation,
  IsDateSmallerThan_1900_1_1_Validation,
  isDateValidation,

} from "../../../utils/validation";
import { registrationValidationErrorMessages } from "../../../utils/validationErrorMessages";
import Form from "../../registration/utils/Form";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import WeiterButton from "../../registration/utils/WeiterButton";
import PageTitle from "../../travel-registration/utils/PageTitle";
import MetaDecorator from "../../../utils/MetaDecorator";
import HorizontalRule from "../../../common/HorizontalRule";

const applicationPageTitles = require("../../../utils/pageTitles.json");
const formLabel = "Bitte geben Sie das Geburtsdatum ein:";

const AddRelative2 = ({ text }) => {
  const { setHeader } = React.useContext(HeaderContext);

  const history = useHistory();

  let initdate = "";
  try {
    initdate = JSON.parse(localStorage.getItem("newTGM")).Birthdate ?? "";
  } catch (error) {}

  const [date, setDate] = useState(initdate);
  const [placeHolder, setPlaceHolder] = useState("Geburtsdatum");
  const [active, setActive] = useState(false);

  useEffect(() => {
    let title = "Mitreisende hinzufügen";

    try {
      title = localStorage.getItem("addTGMTitle");
    } catch (error) {}

    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: title,
        progress: "no",
      };
    });
  }, []);

  const isValidDate = () => {
    return isDateValidation(date);
  };

  const IsDateSmallerThan1900 = () => {
    return IsDateSmallerThan_1900_1_1_Validation(date);
  };

  const IsDateBiggerThanToday = () => {
    return IsDateBiggerThanTodayValidation(date);
  };

  const isValid = () => {
    return isValidDate() && IsDateBiggerThanToday() && !IsDateSmallerThan1900();
  };

  const onChangeHandler = (e) => {
    let newDate = dateFormatter(date, e.target.value);
    setActive(true);
    setDate(newDate);
  };

  const onClickInputHandler = () => {
    setPlaceHolder("TT.MM.JJJJ");
  };

  const updateTGM = () => {
    let tgm = JSON.parse(localStorage.getItem("newTGM"));
    localStorage.setItem("newTGM", JSON.stringify({ ...tgm, Birthdate: date }));
  };

  const onClickHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateTGM();
      history.push(ApplicationRoute.addRelative3);
    }
  };

  const onsubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateTGM();
      history.push(ApplicationRoute.addRelative3);
    }
  };

  return (
    <div className="add-relative-container registration-middle-container">
      <MetaDecorator title={applicationPageTitles.editTrip} />
      <PageTitle id={formLabel} text="Bitte geben Sie das Geburtsdatum ein:" />

      <p className="page-paragraph page-paragraph--small">Mitreisende/r</p>

      <HorizontalRule className="horizontal-rule-desktop" />
      <Form
        id="form"
        onSubmit={onsubmitHandler}
        ariaLabelledBy={formLabel}
        className="max-width-380"
      >
        <Input
          id="date"
          value={date}
          onChange={onChangeHandler}
          onClick={onClickInputHandler}
          placeholder={placeHolder}
          labelText="Geburtsdatum"
          showValidationBorder={!isValid()}
        />
        <InputValidation
          isHidden={!active || isValidDate()}
          text={registrationValidationErrorMessages.notValidDate}
        />

        <InputValidation
          isHidden={!active || !IsDateSmallerThan1900() || !isValidDate()}
          text={
            registrationValidationErrorMessages.dateOfBirthLessThan_01_01_1900
          }
        />

        <InputValidation
          isHidden={!active || IsDateBiggerThanToday() || !isValidDate()}
          text={registrationValidationErrorMessages.dateBiggerThanToday}
        />

        <HiddenSubmitInput />
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content"> </div>
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.addRelative3}
        onClick={onClickHandler}
        ariaLabel={text}
        className={"floating-weiter-buton"}
      />
    </div>
  );
};

export default AddRelative2;
