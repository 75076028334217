import React from "react";

const AnswerDeleteAccount = () => {

    return (
        <>
            <p className="answer-text">
                Die Löschung des Accounts kann nach Anmeldung unter den Einstellungen mittels Klick auf die Schaltfläche
                „Konto löschen“ durchgeführt werden. Die Löschung muss mit nochmaligem Klick auf „Profil löschen“
                bestätigt werden.
            </p>
            <div className="answer-image-container">
                <div className="answer-image-box">
                    <img className="" src="./images/faqs/delete-account-1.png" alt="" />
                </div>
                <div className="answer-image-box">
                    <img className="" src="./images/faqs/delete-account-2.png" alt="" />
                </div>
                <div className="answer-image-box">
                    <img className="" src="./images/faqs/delete-account-3.png" alt="" />
                </div>
            </div>
        </>
    );
};

export default AnswerDeleteAccount;
