import ValidationRow from "./ValidationRow.jsx";
import {
    hasCapitalLetterValidation, hasDigitValidation,
    hasSmallLetterValidation, hasSpecialSymbolValidation,
    isInLengthRangeValidation
} from "../../../utils/validation.js";
import {registrationValidationErrorMessages} from "../../../utils/validationErrorMessages.js";
import React from "react";

const ValidationChecks = ({passwords}) => {
    return (
        <div>
            <ValidationRow
                isValid={isInLengthRangeValidation(passwords.password, 8, 9999)}
                validationText={registrationValidationErrorMessages.passwordNotInRange}
            />
            <ValidationRow
                isValid={passwords.password && !passwords.password.includes(" ")}
                validationText={registrationValidationErrorMessages.passwordNoSpaces}
            />

            <ValidationRow
                isValid={hasSmallLetterValidation(passwords.password)}
                validationText={registrationValidationErrorMessages.paswwordNoLowerLetter}
            />

            <ValidationRow
                isValid={hasCapitalLetterValidation(passwords.password)}
                validationText={registrationValidationErrorMessages.passwordNoCapitalLetter}
            />
            <ValidationRow
                isValid={hasDigitValidation(passwords.password)}
                validationText={registrationValidationErrorMessages.passwordNoDigit}
            />
            <ValidationRow
                isValid={hasSpecialSymbolValidation(passwords.password)}
                validationText={registrationValidationErrorMessages.passwordNoSpecialLetter}
                tooltipText={registrationValidationErrorMessages.passwordNoSpecialLetterTooltip}
            />
        </div>
    );
};

export default ValidationChecks;
