import React, { useEffect} from "react";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import NavigationCard from "../utils/NavigationCard";
import { useTranslation } from "react-i18next";

function Notfalltipps() {
  const { setHeader } = React.useContext(HeaderContext);
  const { t } = useTranslation();

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "content",
        title: t("pages.notfalltipps.title"),
      };
    });
  }, []);

  return (
    <div className="content-pages-container">
      <MetaDecorator title={t("pages.notfalltipps.title")} />

      <h5 className="heading-16px-bold">Im Notfall 24/7 für Sie erreichbar</h5>
      <div className="content-pages-secondary-button">
        <a role="button" href="tel:+431901154411" id="white-button-2" className="white-button">
          <span className="white-button__text">+43 1 90115 4411</span>
        </a>
      </div>

      <h5 className="heading-16px-bold">Für Reiseinformationen wählen Sie</h5>
      <div className="content-pages-secondary-button">
        <a role="button" href="tel:+431901153775" id="white-button-1" className="white-button">
          <span className="white-button__text">+43 1 90115 3775</span>
        </a>
      </div>

      <NavigationCard text="Erreichbarkeit in Notfällen" to={ApplicationRoute.erreichbarkeitInNotfallen} />
      <NavigationCard text="Unfall, Erkrankung und Sterbefälle" to={ApplicationRoute.unfallErkrankungUndSterbefalle} />
      <NavigationCard text="Passverlust" to={ApplicationRoute.passverlust} />
      <NavigationCard
        text="Verlorene Fahrzeugpapiere / Führerschein"
        to={ApplicationRoute.verloreneFahrzeugpapiereFuhrerschein}
      />
      <NavigationCard text="Finanzielle Notlage" to={ApplicationRoute.finanzielleNotlage} />
      <NavigationCard text="Vermisste Angehörige" to={ApplicationRoute.vermissteAngehorige} />
      <NavigationCard text="Katastrophen und politische Unruhen" to={ApplicationRoute.katastrophenPolitischeUnruhen} />
      <NavigationCard text="Reisewarnungen" to={ApplicationRoute.reisewarnungen} />
      <NavigationCard
        text="Regressforderungen für Schutzmaßnahmen bzw. Hilfeleistungen"
        to={ApplicationRoute.regressforderungenSchutzmassnahmen}
      />
      <NavigationCard
        text="In welchen Fällen das Außenministerium nicht helfen kann"
        to={ApplicationRoute.ministeriumAuswartige}
      />
    </div>
  );
}

export default Notfalltipps;
