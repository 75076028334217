import React, { useEffect } from "react";
import apipath from "../../../apipath";
import HeaderContext from "../../../context/HeaderContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import LoadingPage from "../../travel-registration/LoadingPage";

function AllgemeineInformationen() {
  const { setHeader } = React.useContext(HeaderContext);
  const [content, setContent] = React.useState("");

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "content",
        title: "Informationen zur Auslandsservice App",
      };
    });
    fetch(`${apipath.pathOrig}app-inhalte/informationen-zur-auslandsservice-app/type/json/`, {
      method: "GET",
    })
      .then((data) => data.json())
      .then((data) => setContent(data.data[0].bodytext));
  }, []);

  if (content === "") return <LoadingPage />;

  return (
    <div className="content-pages-container">
      <MetaDecorator title="Informationen zur Auslandsservice App" />
      <div dangerouslySetInnerHTML={{ __html: content }}></div>
    </div>
  );
}

export default AllgemeineInformationen;
