import React from "react";
import CustomSelectList from "../components/foreign-registration/utils/CustomSelectList";
import FilteredSelectList from "../components/foreign-registration/utils/FilteredSelectList";
import HiddenSubmitInput from "../common/HiddenSubmitInput";
import Input from "../components/registration/utils/Input";
import WeiterButton from "../components/travel-registration/utils/WeiterButton";
import SuccessContext from "../context/SuccessContext";
import {
  dateFormatter,
  getEnumFromGender,
  getUIID,
  hasMiddleName,
  isIn,
  phoneFormatter,
  pluck,
} from "../utils/helpers";
import {
  hasAtSymbolEmailValidation,
  hasNumber,
  hasTwoCharactersAfterAtSymbolEmailValidation,
  IsDateBiggerThanTodayValidation,
  IsDateSmallerThan_1900_1_1_Validation,
  isDateValidation,
  isEmailValidation,
  isPhoneValidation,
  isValidFirstAndLastName,
} from "../utils/validation";
import { registrationValidationErrorMessages } from "../utils/validationErrorMessages";
import InputValidation from "./InputValidaiton";

const relationships = [
  "Eltern, Großeltern",
  "Schwester, Bruder",
  "Kinder, Enkelkinder",
  "Ehegatte/in, Partner/in​",
  "Sonstige Verwandtschaftsverhältnisse",
];

const relationshipEnums = {
  "Eltern, Großeltern": 100000000,
  "Schwester, Bruder": 100000001,
  "Kinder, Enkelkinder": 100000002,
  "Ehegatte/in, Partner/in​": 100000003,
  "Sonstige Verwandtschaftsverhältnisse": 100000004,
};

export default function RelativeModal({ close, save }) {
  const [active, setActive] = React.useState(false);
  const [fullName, setFullName] = React.useState("");
  const [relationship, setRelationship] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [birthdate, setBirthdate] = React.useState("");
  const [nationality, setNationaliy] = React.useState("");

  const { setSuccessBox } = React.useContext(SuccessContext);

  let startRef = React.useRef();

  const isValidRelationShip = () => {
    return relationships.includes(relationship);
  };
  const isPhoneNumberValid = () => {
    return isPhoneValidation(phoneNumber, true);
  };

  const isValid = () => {
    return (
      fullName.length > 0 &&
      isValidFirstAndLastName(fullName) &&
      isValidRelationShip() &&
      ((isEmailValidation(email) && hasTwoCharactersAfterAtSymbolEmailValidation(email)) || email.length === 0) &&
      isPhoneNumberValid() &&
      gender !== "" &&
      isDateValidation(birthdate) &&
      IsDateBiggerThanTodayValidation(birthdate) &&
      !IsDateSmallerThan_1900_1_1_Validation(birthdate) &&
      nationality &&
      !hasMiddleName(fullName)
    );
  };

  function onSave(e) {
    e.preventDefault();

    setSuccessBox((curr) => {
      return {
        ...curr,
        hidden: false,
        text: "Angehörige person wurde hinzugefügt",
        trigger: getUIID(),
      };
    });

    const { Id } = JSON.parse(localStorage.getItem("countries")).find((el) => {
      return el.Name === nationality;
    });

    save(fullName, relationshipEnums[relationship], getEnumFromGender(gender), Id, birthdate, email, phoneNumber);
  }

  React.useEffect(() => {
    startRef.current.focus();
  }, []);

  return (
    <div
      className="modal"
      onKeyDown={(e) => {
        if (e.keyCode === 13) {
          e.preventDefault();

          if (isValid()) {
            onSave(e);
          }
        }
      }}
    >
      <button className="modal__close-button" onClick={close} type="button">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
          <g fill="none" fillRule="evenodd">
            <g fill="#000">
              <g>
                <path
                  d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                  transform="translate(-24 -21) translate(19 16)"
                />
              </g>
            </g>
          </g>
        </svg>
      </button>
      <h2 className="modal__heading">Angehörige Person im Ausland hinzufügen</h2>
      <div className="modal__form">
        <Input
          labelText="Vorname Familienname"
          id="relative_firstName"
          placeholder="Vorname Familienname"
          type="text"
          value={fullName}
          onChange={(e) => {
            setActive(true);
            setFullName(e.target.value);
          }}
          ref={startRef}
        />

        <InputValidation
          style={{ marginBottom: "1.5rem" }}
          isHidden={!active || isValidFirstAndLastName(fullName) || hasMiddleName(fullName)}
          text={
            hasNumber(fullName)
              ? registrationValidationErrorMessages.nameHasNumber
              : registrationValidationErrorMessages.notValidContactPersonFirstAndLastName
          }
        />
        <InputValidation
          isHidden={!hasMiddleName(fullName)}
          text={registrationValidationErrorMessages.middleName}
          ariaLabel={registrationValidationErrorMessages.middleName}
        />

        <CustomSelectList
          id="relative_verwandschaftsgrad"
          label="Verwandtschaftsgrad"
          state={relationship}
          options={relationships}
          onChangeHandler={(value) => setRelationship(value)}
          className="grey-background"
        />
        <CustomSelectList
          id="relative_geschlecht"
          label="Geschlecht"
          state={gender}
          options={["männlich", "weiblich", "divers"]}
          onChangeHandler={(value) => setGender(value)}
          className="grey-background"
        />
        <Input
          id="relatives_birthdate"
          value={birthdate}
          onChange={(e) => setBirthdate(dateFormatter(birthdate, e.target.value))}
          placeholder="DD.MM.YYYY"
          labelText="Geburtsdatum"
          showValidationBorder={birthdate.length > 0 && !isValid()}
        />
        <InputValidation
          isHidden={!birthdate.length > 0 || isDateValidation(birthdate)}
          text={registrationValidationErrorMessages.notValidDate}
        />

        <InputValidation
          isHidden={
            !birthdate.length > 0 || !IsDateSmallerThan_1900_1_1_Validation(birthdate) || !isDateValidation(birthdate)
          }
          text={registrationValidationErrorMessages.dateOfBirthLessThan_01_01_1900}
        />

        <InputValidation
          isHidden={!birthdate.length > 0 || IsDateBiggerThanTodayValidation(birthdate) || !isDateValidation(birthdate)}
          text={registrationValidationErrorMessages.dateBiggerThanToday}
        />
        <FilteredSelectList
          id="relative_nationality"
          label="Staatsangehörigkeit auswählen"
          state={nationality}
          options={pluck(JSON.parse(localStorage.getItem("countries")), "Name").filter((n) => !isIn(n))}
          onChangeHandler={(value) => setNationaliy(value)}
          className="grey-background"
        />
        <Input
          id="relative_email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-Mail-Adresse (optional)"
          type="email"
          labelText="E-Mail-Adresse"
          showValidationBorder={
            email.length > 0 && (!isValid() || !hasTwoCharactersAfterAtSymbolEmailValidation(email))
          }
        />

        <InputValidation
          isHidden={
            !email.length > 0 ||
            !hasAtSymbolEmailValidation(email) ||
            !hasTwoCharactersAfterAtSymbolEmailValidation(email) ||
            isEmailValidation(email)
          }
          text={registrationValidationErrorMessages.notValidEmail}
        />

        <InputValidation
          isHidden={!email.length > 0 || hasAtSymbolEmailValidation(email)}
          text={registrationValidationErrorMessages.missingAtSymbolEmail}
        />
        <InputValidation
          isHidden={
            !email.length > 0 ||
            !hasAtSymbolEmailValidation(email) ||
            hasTwoCharactersAfterAtSymbolEmailValidation(email)
          }
          text={registrationValidationErrorMessages.charactersAfterAtSymbolLessThanTwoEmail}
        />

        <Input
          id="relative_phoneNumber"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(phoneFormatter(phoneNumber, e.target.value))}
          placeholder="Mobiltelefonnummer"
          type="tel"
          labelText="Mobiltelefonnummer (inkl. Ländervorwahl) (optional)"
          labelStyle={{ display: "inline-block" }}
        />

        <InputValidation
          isHidden={isPhoneNumberValid()}
          text={registrationValidationErrorMessages.notValidPhoneNumber}
        />

        <HiddenSubmitInput />
        <WeiterButton
          disabled={!isValid()}
          path={""}
          text="Speichern & Schließen"
          positioned="static"
          onClick={onSave}
          ariaLabel={`Senden und gehen Sie zu ${""}`}
        />
      </div>
    </div>
  );
}
