import React from "react";
import ArrowRight from "../../../svg/ArrowRight.js";
import { Link } from "react-router-dom";

function NavigationCard({ id, text, to, href }) {
  return (
    <div>
      <Link className="navigation-info-link" style={{ textDecoration: "none", color: "#000000" }} id={id} to={to}>
        <div className="navigation-info-cards">
          <div className="country__name">{text}</div>

          <ArrowRight />
        </div>
      </Link>
    </div>
  );
}

export default NavigationCard;
