import React, { useEffect, useState } from "react";
import WeiterButton from "../utils/WeiterButton";
import ContentTitle from "../utils/ContentTitle";
import UserContext from "../../../context/UserContext";
import ControledRadioInput from "../utils/ControledRadioInput";
import ApplicationRoute from "../../../config/routes";
import { getKeyByValue, prefilledUserData } from "../../../utils/helpers";
import { useHistory } from "react-router-dom";
import Form from "../utils/Form";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import HeaderContext from "../../../context/HeaderContext";
import { getEnumFromGender, getGenderFromEnum } from "../../../utils/helpers";
import MetaDecorator from "../../../utils/MetaDecorator";
import HorizontalRule from "../../../common/HorizontalRule";

const applicationPageTitles = require("../../../utils/pageTitles.json");
const genders = {
  male: "männlich",
  female: "weiblich",
  other: "divers",
};

const initSelectValue = {
  male: false,
  female: false,
  other: false,
};

//HINT* NOT REGISTRATION
const GenderPage = ({ text }) => {
  const history = useHistory();
  const { user, setUser } = React.useContext(UserContext);
  const { setHeader } = React.useContext(HeaderContext);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((5 / 19) * 100),
        title: "Neue Reise anlegen",
      };
    });
  }, []);

  const [selectedValue, setSelectedValue] = useState(initSelectValue);

  const isValid = () => {
    return getKeyByValue(selectedValue, true);
  };

  const updateUser = () => {
    setUser((curr) => {
      return {
        ...curr,
        Gender: getEnumFromGender(getKeyByValue(selectedValue, true)),
      };
    });
  };

  const onClickHandler = () => {
    updateUser();
  };

  const onChangeHandler = (e) => {
    setSelectedValue(() => {
      return { ...initSelectValue, [e.target.id]: true };
    });
  };

  const onsubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateUser();
      history.push(ApplicationRoute.registerMobilePhone);
    }
  };

  useEffect(() => {
    if (user && user.Gender) {
      setSelectedValue(() => {
        return { ...initSelectValue, [getGenderFromEnum(user.Gender)]: true };
      });
    }
  }, [user]);

  return (
    <div className="registration-middle-container">
      <MetaDecorator title={applicationPageTitles.createTripPages} />
      <Form
        id="form"
        onSubmit={onsubmitHandler}
        ariaLabelledBy="register-gender"
      >
        <ContentTitle
          id="register-gender"
          text={prefilledUserData("Bitte wählen Sie Ihr Geschlecht aus:")}
        />
        <HorizontalRule className="horizontal-rule-desktop" />
        <ControledRadioInput
          id="male"
          name="gender"
          htmlFor="male"
          value="100000000"
          textInfo={genders.male}
          checked={selectedValue.male}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.male}
        />
        <ControledRadioInput
          id="female"
          name="gender"
          htmlFor="female"
          value="100000001"
          textInfo={genders.female}
          checked={selectedValue.female}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.female}
        />
        <ControledRadioInput
          id="other"
          name="gender"
          htmlFor="other"
          value="100000002"
          textInfo={genders.other}
          checked={selectedValue.other}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.other}
        />

        <HiddenSubmitInput />
        <HorizontalRule className="horizontal-rule-desktop" />
        <WeiterButton
          disabled={!isValid()}
          path={ApplicationRoute.registerMobilePhone}
          onClick={onClickHandler}
          ariaLabel={text}
          className={"floating-weiter-buton"}
        />
      </Form>
    </div>
  );
};

export default GenderPage;
