import WeiterButton from "../../registration/utils/WeiterButton";
import ChoiceBox from "./ChoiceBox";

const FirstPushStepComponentReceivingPush = ({
  onSubmit,
  onChoiceHandler,
  choices,
  checked,
  onCheckedHandler,
  isValid,
  className,
  weiterText,
  showTopPart,
  city,
  reason,
}) => {
  return (
    <div className={`push__container ${className ?? ""}`}>
      {showTopPart && (
        <div>
          <div className="push__container__top--container">
            <img
              src="./images/common/redcrisis.png"
              className="push__container__top--container__alert-triangle"
              alt=""
            />
          </div>
          <p className="push__container__message">{reason}</p>
        </div>
      )}

      <p className="push__container__assistance-text">
        Benötigen Sie Unterstützung?
      </p>

      <ChoiceBox
        id="needHelp"
        onClick={onChoiceHandler}
        text="Ja, ich benötige Unterstützung"
        active={choices.needHelp}
      />
      <ChoiceBox
        id="noHelp"
        onClick={onChoiceHandler}
        text="Nein, mir geht es gut"
        active={choices.noHelp}
      />

      <div className="push__container__toggle-container">
        <div className="push__container__toggle-container__toggle">
          <div className={"slider-checkbox"}>
            <input
              id="sliderInput"
              value={checked}
              onChange={onCheckedHandler}
              type="checkbox"
            />
          </div>
        </div>

        <label
          htmlFor="sliderInput"
          className="push__container__toggle-container__text"
        >
          Ich möchte meinen Standort mit dem Außenministerium teilen.
        </label>
      </div>

      <WeiterButton
        id="pushNextButtonPushStep"
        style={{ marginTop: "2.6rem", display: isValid() ? "block" : "none" }}
        text={weiterText}
        positioned="static"
        path="./"
        onClick={onSubmit}
      />
    </div>
  );
};

export default FirstPushStepComponentReceivingPush;
