import React from "react";
import ApplicationRoute from "../../config/routes";

const DesktopFooter = ({ path }) => {
  let toShow = path === "/" || path.includes(ApplicationRoute.TravelDashboardNoPermissions);
  let style;
  if (toShow) {
    style = { display: "block" };
  }

  return (
    <footer style={style} role="contentinfo" className="web-footer">
      <div className="desktop-footer-flex">
        <div className="desktop-footer-item">
          <a href="http://auslandsservice.at/" rel="noopener noreferrer" target="_blank">
            Auslandsservice
          </a>
        </div>

        <div className="desktop-footer-item">
          <a href="https://www.bmeia.gv.at/reise-services/auslandsservice/datenschutz/" rel="noopener noreferrer" target="_blank">
            Datenschutzerklärung
          </a>
        </div>

        <div className="desktop-footer-item">
          <a href="https://www.bmeia.gv.at/app-inhalte/bildquellen/" rel="noopener noreferrer" target="_blank">
            Bildquellen
          </a>
        </div>

        <div className="desktop-footer-item">
          <a href="https://www.bmeia.gv.at/barrierefreiheitserklaerung/" rel="noopener noreferrer" target="_blank">
            Barrierefreiheit
          </a>
        </div>

        <div className="desktop-footer-item">
          <a href="https://www.bmeia.gv.at/app-inhalte/impressum-app/" rel="noopener noreferrer" target="_blank">
            Impressum
          </a>
        </div>
      </div>
    </footer>
  );
};

export default DesktopFooter;
