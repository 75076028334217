import { useContext, useEffect } from "react";
import HeaderContext from "../../context/HeaderContext";
import MetaDecorator from "../../utils/MetaDecorator";

const DataProtection = () => {
  const { setHeader } = useContext(HeaderContext);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: "Datenschutzerklärung",
        progress: "no",
      };
    });
  }, []);
  return (
    <>
      <MetaDecorator title="Datenschutzerklärung" />
      <p className="context-text-dp-mt-24 "> DATA PROTECTION TEXT </p>
    </>
  );
};

export default DataProtection;
