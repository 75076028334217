import React, { useEffect, useState } from "react";
import TravelContext from "../../../../context/TravelContext";
import ControledRadioInput from "../../../travel-registration/utils/ControledRadioInput";
import ApplicationRoute from "../../../../config/routes";
import {
  getEnumFromGender,
  getGenderFromEnum,
  getKeyByValue,
  getTravelGroupMemberLastIndex_Array,
} from "../../../../utils/helpers";
import { useHistory } from "react-router-dom";
import Form from "../../../travel-registration/utils/Form";
import HiddenSubmitInput from "../../../../common/HiddenSubmitInput";
import PageTitle from "../../../travel-registration/utils/PageTitle";
import HeaderContext from "../../../../context/HeaderContext";
import WeiterButton from "../../../registration/utils/WeiterButton";
import MetaDecorator from "../../../../utils/MetaDecorator";
import HorizontalRule from "../../../../common/HorizontalRule";

const applicationPageTitles = require("../../../../utils/pageTitles.json");
const formLabel = "Geschlecht eingeben.";

const genders = {
  male: "männlich",
  female: "weiblich",
  other: "divers",
};

const initSelectValue = {
  male: false,
  female: false,
  other: false,
};

export function AddRelativesGender({ text }) {
  const { setHeader } = React.useContext(HeaderContext);
  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: "Als Auslandsösterreicher/in registrieren",
        ariaLabel: "Als Auslandsösterreicher:in registrieren",
        progress: parseInt((12 / 17) * 100),
      };
    });
  }, []);

  const history = useHistory();
  const { travel, setTravel } = React.useContext(TravelContext);

  const index = getTravelGroupMemberLastIndex_Array(travel);
  let initGender = travel?.TravelGroupMember[index]?.Gender
    ? getGenderFromEnum(travel?.TravelGroupMember[index]?.Gender)
    : "";

  const [selectedValue, setSelectedValue] = useState({
    ...initSelectValue,
    [initGender]: true,
  });

  const isValid = () => {
    return getKeyByValue(selectedValue, true);
  };

  const updateTravel = () => {
    const index =
      travel.TravelGroupMember.length > 0
        ? travel.TravelGroupMember.length - 1
        : 0;
    let newArray = [...travel.TravelGroupMember];
    newArray[index] = {
      ...newArray[index],
      Gender: getEnumFromGender(getKeyByValue(selectedValue, true)),
    };

    setTravel((curr) => {
      return {
        ...curr,
        TravelGroupMember: newArray,
      };
    });
  };

  const onClickHandler = () => {
    updateTravel();
  };

  const onChangeHandler = (e) => {
    setSelectedValue(() => {
      return { ...initSelectValue, [e.target.id]: true };
    });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateTravel();
      history.push(ApplicationRoute.foreignRegistrationAddRelativesEmail);
    }
  };

  return (
    <section className="foreign-registration registration-middle-container">
      <MetaDecorator title={applicationPageTitles.foreignRegistration} />
      <PageTitle text="Bitte wählen Sie das Geschlecht aus:" />
      <p className="page-paragraph page-paragraph--small">Angehörige/r</p>
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form
        id="form"
        onSubmit={onSubmitHandler}
        ariaLabel={formLabel}
        className="max-width-380"
      >
        <ControledRadioInput
          id="male"
          value="100000000"
          name="gender"
          textInfo={genders.male}
          checked={selectedValue.male}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.male}
        />
        <ControledRadioInput
          id="female"
          value="100000001"
          name="gender"
          textInfo={genders.female}
          checked={selectedValue.female}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.female}
        />
        <ControledRadioInput
          id="other"
          value="100000002"
          name="gender"
          textInfo={genders.other}
          checked={selectedValue.other}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.other}
        />

        <HiddenSubmitInput />
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content"> </div>
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.foreignRegistrationAddRelativesEmail}
        onClick={onClickHandler}
        ariaLabel={text}
        className={"floating-weiter-buton"}
      />
    </section>
  );
}
