import React from "react";

function InfoCard({ id, onClick, city, ArrowRight, type, isHidden, style, isH1 = false }) {
  return isH1 ? (
    <h1 hidden={isHidden} aria-hidden={isHidden} style={{ fontWeight: "normal" }}>
      <li className="info-cards" id={id}>
        <div className="upper-text">
          {type}
          <div className="info-card__main-text" style={style}>
            {city}
          </div>
        </div>
        <div className="arrow__right" onClick={onClick}>
          {ArrowRight}
        </div>
      </li>
    </h1>
  ) : (
    <div hidden={isHidden} aria-hidden={isHidden}>
      <li className="info-cards" id={id}>
        <div className="upper-text">
          {type}
          <div className="info-card__main-text" style={style}>
            {city}
          </div>
        </div>
        <div className="arrow__right" onClick={onClick}>
          {ArrowRight}
        </div>
      </li>
    </div>
  );
}

export default InfoCard;
