import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import { compare, getUIID } from "../../../utils/helpers";
import ApplicationRoute from "../../../config/routes";
import cvb from "../../travel-registration/utils/countriesVB.json";
import reactDom from "react-dom";
import { MemoPin } from "./Pin";

const getCountriesVB = () => {
  try {
    let x = JSON.parse(localStorage.getItem("countriesVB")).countries;
    if (!x || x.length === 0) {
      let vbJSON = cvb;
      vbJSON.countries.sort(compare);
      return vbJSON.countries;
    }

    return x;
  } catch (error) {
    let vbJSON = cvb;
    vbJSON.countries.sort(compare);
    return vbJSON.countries;
  }
};

const cr = getCountriesVB()
  .filter((c) => c.representations.length !== 0)
  .map((c) => {
    return { country: c.country, representations: c.representations, countryUid: c.countryUid };
  });

let lastId = "";

const onClick = (id, countryObject, representation, infoText, lat, lng) => {
  if (lastId && lastId !== id) {
    let prev = document.getElementById(lastId);
    if (prev) {
      prev.style.display = "none";
    }
  }

  let el = document.getElementById(id);
  if (el.style.display === "inline-block") {
    el.style.display = "none";
  } else {
    el.style.display = "inline-block";
    setPinInfo(el, countryObject, representation, infoText, lat, lng);
  }

  lastId = id;
};

const setPinInfo = (el, countryObject, representation, infoText, lat, lng) => {
  let isWien = false;
  let same = [];
  if (representation.city.toLowerCase() === "wien") {
    for (let index = 0; index < cr.length; index++) {
      const element = cr[index].representations;
      for (let i = 0; i < element.length; i++) {
        const r = element[i];
        if (r.latitude === lat && r.longitude === lng) {
          same.push({ ...r, country: cr[index].country, countryUid: cr[index].countryUid });
        }
      }
    }
    isWien = true;
  } else {
    same = cr
      .filter((c) => c.country === countryObject.country)[0]
      .representations.filter((r) => r.latitude === lat && r.longitude === lng);
  }

  const component =
    same.length === 1 ? (
      <a
        className={"react-pin-text-a"}
        href={
          "#" +
          ApplicationRoute.botschaftenKonsulateContacts
            .replace(":countryUid", countryObject.countryUid)
            .replace(":orgaUnitUid", representation.orgaUnitUid)
        }
      >
        {infoText.length > 51 ? (
          representation.type.length > 51 ? (
            //more
            <>
              {representation.type.match(/.{1,51}/g).map((a) => {
                return (
                  <>
                    {a} <br />
                  </>
                );
              })}
              {`${representation.city}, ${countryObject.country}`}
            </>
          ) : (
            <>
              {representation.type} <br /> {`${representation.city}, ${countryObject.country}`}
            </>
          )
        ) : (
          infoText
        )}
      </a>
    ) : (
      <div
        className={"react-pin-text__multiple"}
        href={
          "#" +
          ApplicationRoute.botschaftenKonsulateContacts
            .replace(":countryUid", countryObject.countryUid)
            .replace(":orgaUnitUid", representation.orgaUnitUid)
        }
      >
        {same.map((r) => {
          if (isWien) {
            countryObject.country = r.country;
            countryObject.countryUid = r.countryUid;
          }
          let txt = `${r.type} ${r.city}, ${countryObject.country}`;
          if (txt.length > 50) {
            //type bigger than 50 char
            if (r.type.length > 50) {
              const typeArray = r.type.match(/.{1,50}/g);
              return (
                <>
                  <a
                    href={
                      "#" +
                      ApplicationRoute.botschaftenKonsulateContacts
                        .replace(":countryUid", countryObject.countryUid)
                        .replace(":orgaUnitUid", r.orgaUnitUid)
                    }
                    className="react-pin-text__multiple__a"
                  >
                    {typeArray.map((ta) => {
                      return (
                        <>
                          {ta}
                          <br />
                        </>
                      );
                    })}
                    {`${r.city}, ${countryObject.country}`}
                  </a>
                  <span>
                    <div className="map-hr"></div>
                  </span>
                </>
              );
            } else {
              return (
                <>
                  <a
                    href={
                      "#" +
                      ApplicationRoute.botschaftenKonsulateContacts
                        .replace(":countryUid", countryObject.countryUid)
                        .replace(":orgaUnitUid", r.orgaUnitUid)
                    }
                    className="react-pin-text__multiple__a"
                  >
                    {r.type} <br /> {`${r.city}, ${countryObject.country}`}
                  </a>
                  <span>
                    <div className="map-hr"></div>
                  </span>
                </>
              );
            }
          } else {
            return (
              <>
                <a
                  href={
                    "#" +
                    ApplicationRoute.botschaftenKonsulateContacts
                      .replace(":countryUid", countryObject.countryUid)
                      .replace(":orgaUnitUid", r.orgaUnitUid)
                  }
                  className="react-pin-text__multiple__a"
                >
                  {txt}
                </a>
                <span>
                  <div className="map-hr"></div>
                </span>
              </>
            );
          }
        })}
      </div>
    );
  reactDom.render(component, el);
};

const truncate = (text) => {
  // if (text.length > 30) {
  //   text = text.substring(0, 30) + " ...";
  // }

  return text;
};

const MapsApiKey = process.env.REACT_APP_API_KEY;

//Changed with MemoPin
//
// const AnyReactComponent = ({ text, id, countryObject, representation, tag, infoText, setState, lat, lng }) => {
//   return (
//     <div>
//       <div className={"react-pin-text"} id={tag} style={{ display: "none" }}></div>
//       <div
//         onClick={() => {
//           onClick(tag, countryObject, representation, infoText, lat, lng);
//           setState();
//         }}
//       >
//         {text}
//       </div>
//     </div>
//   );
// };

const onRotate = (e) => {
  try {
    const h = localStorage.getItem("ch");
    const w = localStorage.getItem("w");
    const orientationHoriz = localStorage.getItem("orient") === "norm";
    if (e.target.screen.orientation.angle === 90) {
      //vertical
      if (orientationHoriz) {
        //init horizontal
        document.getElementById("google_map_c").style.height = parseInt(w) - 130 + "px";
      } else {
        //init vertical
        document.getElementById("google_map_c").style.height = parseInt(h) - 0 + "px";
      }
    } else {
      //horizontal
      if (orientationHoriz) {
        //init horizontal
        document.getElementById("google_map_c").style.height = h + "px";
      } else {
        //init vertical
        document.getElementById("google_map_c").style.height = parseInt(w) - 130 + "px";
      }
    }
  } catch (error) {}
};

const getMarkers = (setCenter) => {
  const countries = getCountriesVB();

  return countries
    .filter((c) => c.representations.length !== 0)
    .map((country) => {
      return country.representations
        .filter((r) => r.latitude !== null)
        .map((sub) => {
          return (
            <MemoPin
              key={sub.locationUid}
              lat={sub.latitude}
              lng={sub.longitude}
              //text={<MemoMapPin className="map-pin-spec-class" size="2rem" color="#9e0529" />}
              text={<img src="./images/common/mappin.png" alt="" className="map-pin-spec-class" />}
              countryObject={country}
              representation={sub}
              tag={getUIID()}
              infoText={truncate(sub.type + " " + sub.city + ", " + country.country)}
              setState={() => setCenter(sub.latitude, sub.longitude)}
              onClick={onClick}
            />
          );
        });
    });
};

const getHeightFromLS = () => {
  try {
    const h = parseInt(localStorage.getItem("fh"));
    const w = parseInt(localStorage.getItem("w"));

    if (w >= 1248) {
      return "calc(100vh - 282px)";
    }

    if (w >= 1180) {
      return "calc(100vh - 282px)";
    }

    if (h > w) {
      return h - 130 + "px";
    } else {
      return w - 130 + "px";
    }
  } catch (error) {
    return "calc(100vh - 130px)";
  }
};

const getHeight = () => {
  let body = document.body,
    html = document.documentElement;

  let height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

  height = height - 130;
  let heightForLocalStorage = height;

  let ori = true;
  try {
    ori = localStorage.getItem("orient") === "norm";
  } catch (error) {}

  if (!ori) {
    height -= 0;
  }

  if (height) {
    height += "px";
  } else {
    height = "calc(100vh - 130px)";
  }

  localStorage.setItem("ch", heightForLocalStorage);
  return height;
};

class Map extends Component {
  constructor(props) {
    super(props);
    //this._map = "";
    this.state = {
      error: null,
      isLoaded: false,
      mapCenter: this.props.center,
      zoom: 6,
      countries: [],
    };
  }

  componentDidMount() {
    //window.addEventListener("orientationchange", onRotate);

    this.setState({
      isLoaded: true,
      countries: getMarkers(this.setCenter),
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      mapCenter: nextProps.center,
      zoom: nextProps.center.zoom ?? 7,
    });
  }

  componentWillUnmount() {
    //window.removeEventListener("orientationchange", onRotate);

    document.getElementById("main").classList.remove("map-style-fix");
  }

  setCenter = (lat, lng) => {
    this.setState({
      zoom: 11,
      mapCenter: { lat, lng },
    });
  };

  render() {
    const { error, isLoaded } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        // Important! Always set the container height explicitly
        <>
          <div id="google_map_c" key={this.state.center} style={{ height: getHeightFromLS(), margin: "0 -24px" }}>
            <GoogleMapReact
              //ref={this._map}
              center={this.state.mapCenter}
              zoom={this.state.zoom}
              bootstrapURLKeys={{
                key: MapsApiKey,
              }}
            >
              {this.state.countries}
            </GoogleMapReact>
          </div>
        </>
      );
    }
  }
}

export default Map;
