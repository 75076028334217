import ApplicationRoute from "../../../config/routes";

const GoogleNavigation = ({ className, longitude, latitude }) => {
  let link = ApplicationRoute.googleMapURL
    .replace("%lat%", latitude)
    .replace("%long%", longitude);

  if (!latitude || !longitude) return <div></div>;

  return (
    <a href={link} className={`google-navigation-container ${className ?? ""}`}>
      <img
        className="google-navigation-container__img"
        src="images/common/location-black.png"
        alt=""
      />
      <p className="google-navigation-container__text">Navigation starten</p>
    </a>
  );
};

export default GoogleNavigation;
