import CrisisPhone from "./CrisisPhone";

const CrisisCardDefault = ({ className }) => {
  return (
    <div className={`crisis-card-default__container ${className ?? ""}`}>
      <div className="crisis-card-default__container__img-title__wrapper">
        <div className="crisis-card-default__container__title-copy"> </div>
        <div className="crisis-card-default__container__title">
          Bürgerservice des Außenministeriums bei Notfällen im Ausland
        </div>
        <div className="crisis-card-default__container__img-flag-container">
          <img className="crisis-card-default__container__img" src="images/crisis/gv.png" alt="" />
          <img className="crisis-card-default__container__flag" src="images/crisis/flag.png" alt="" />
        </div>
      </div>

      <CrisisPhone id="crisiscarddefaultphone" style={{ marginBottom: "0px" }} phone={"+43 1 90115 4411"} />
    </div>
  );
};

export default CrisisCardDefault;
