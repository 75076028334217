import React, { useEffect } from "react";
import apipath from "../../../apipath";
import HeaderContext from "../../../context/HeaderContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import LoadingPage from "../../travel-registration/LoadingPage";

function InfoReiseregistrierung() {
  const { setHeader } = React.useContext(HeaderContext);
  const [content, setContent] = React.useState("");

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "content",
        title: "Informationen zur Auslandsregistrierung",
      };
    });
    
    fetch(`${apipath.pathOrig}app-inhalte/informationen-zur-auslandsregistrierung/type/json/`, {
      method: "GET"
    }).then(data => data.json()).then(data => setContent(data.data[0].bodytext));
  }, []);
  const data = JSON.parse(localStorage.getItem("countries"));
  
  if(content.length === 0) {
    return <LoadingPage />
  }
  return (
    <div className="content-pages-container">
      <MetaDecorator title="Informationen zur Auslandsregistrierung" />
      <div dangerouslySetInnerHTML={{__html: content}}></div>
    </div>
  );
}

export default InfoReiseregistrierung;
