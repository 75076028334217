import React, { useEffect, useState } from "react";
import WeiterButton from "../utils/WeiterButton";
import { useHistory } from "react-router-dom";
import Input from "../utils/Input";
import ContentTitle from "../utils/ContentTitle";
import UserContext from "../../../context/UserContext";
import ApplicationRoute from "../../../config/routes";
import {
  isEmailValidation,
  isPhoneValidation,
  hasAtSymbolEmailValidation,
  hasTwoCharactersAfterAtSymbolEmailValidation,
  isValidFirstAndLastName,
  hasNumber,
} from "../../../utils/validation";
import Form from "../utils/Form";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import HeaderContext from "../../../context/HeaderContext";
import InputValidation from "../../../common/InputValidaiton";
import { registrationValidationErrorMessages } from "../../../utils/validationErrorMessages";
import { hasMiddleName, isValidContactPerson, phoneFormatter } from "../../../utils/helpers";
import MetaDecorator from "../../../utils/MetaDecorator";
import HorizontalRule from "../../../common/HorizontalRule";

const applicationPageTitles = require("../../../utils/pageTitles.json");
const optional = {
  FirstLastName: false,
  PhoneNumber: false,
  Email: false,
};

//HINT* NOT REGISTRATION
const ContactPersonPage = () => {
  const history = useHistory();

  const [optionals, setOptionals] = useState(optional);

  const { user, setUser } = React.useContext(UserContext);
  const { setHeader } = React.useContext(HeaderContext);
  const [active, setActive] = React.useState(false);

  const [contact, setContact] = useState({
    FirstLastName: user.EmergencyFirstLastName ? user.EmergencyFirstLastName : "",
    PhoneNumber: user.EmergencyPhoneNumber ? user.EmergencyPhoneNumber : "",
    Email: user.EmergencyEmail ? user.EmergencyEmail : "",
  });

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((9 / 19) * 100),
        title: "Neue Reise anlegen",
      };
    });
  }, []);

  const isValidPhone = () => {
    return contact.PhoneNumber ? isPhoneValidation(contact.PhoneNumber, true) : true;
  };

  const isValidEmail = () => {
    return contact.Email ? isEmailValidation(contact.Email) : true;
  };

  const isValid = () => {
    return (
      (hasTwoCharactersAfterAtSymbolEmailValidation(contact.Email) || contact.Email.length === 0) &&
      isValidFirstAndLastName(contact.FirstLastName) &&
      (isValidPhone() || contact.PhoneNumber.length === 0) &&
      (isValidEmail() || contact.Email.length === 0) &&
      isValidContactPerson(optionals)
    );
  };

  const onChangeHandler = (e) => {
    setActive(true);
    setContact((curr) => {
      if (e.target.id === "PhoneNumber") {
        return {
          ...curr,
          [e.target.id]: phoneFormatter(contact.PhoneNumber, e.target.value),
        };
      }

      return { ...curr, [e.target.id]: e.target.value };
    });
  };

  const setOptionalPhoneToTrue = (phone, email) => {
    if (email) {
      return true;
    }
    return isPhoneValidation(phone);
  };

  useEffect(() => {
    setOptionals((curr) => {
      return {
        ...curr,
        FirstLastName: !!contact.FirstLastName,
        PhoneNumber: setOptionalPhoneToTrue(contact.PhoneNumber, contact.Email),
        Email: !!contact.Email,
      };
    });
  }, [contact]);

  const onClickHandler = () => {
    let userCopy = Object.assign({}, user);
    userCopy.ContactPerson = { ...contact };
    userCopy.EmergencyFirstLastName = contact.FirstLastName;
    userCopy.EmergencyPhoneNumber = contact.PhoneNumber;
    userCopy.EmergencyEmail = contact.Email;
    setUser(userCopy);
  };

  const onsubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      onClickHandler();
      history.push(ApplicationRoute.addTravelTourOperator);
    }
  };

  useEffect(() => {
    if (user && user.ContactPerson) {
      setContact((curr) => {
        return {
          ...curr,
          FirstLastName: user.ContactPerson.FirstLastName ? user.ContactPerson.FirstLastName : "",
          PhoneNumber: user.ContactPerson.PhoneNumber ? user.ContactPerson.PhoneNumber : "",
          Email: user.ContactPerson.Email ? user.ContactPerson.Email : "",
        };
      });
    }
  }, [user]);

  return (
    <div className="registration-middle-container">
      <MetaDecorator title={applicationPageTitles.createTripPages} />
      <ContentTitle
        id="register-contactperson"
        text="Wen dürfen wir kontaktieren, falls Sie im Ausland in eine Notsituation geraten? Bitte informieren Sie diese Person, dass Sie sie als Notfallkontakt genannt haben. (optional)"
      />
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form className="max-width-380" id="form" onSubmit={onsubmitHandler} ariaLabelledBy="register-contactperson">
        <Input
          id="FirstLastName"
          name="FirstLastName"
          type="text"
          htmlFor="FirstLastName"
          value={contact.FirstLastName}
          onChange={onChangeHandler}
          placeholder="Vorname Familienname"
          labelText="Vorname Familienname"
          showValidationBorder={active && !isValidFirstAndLastName(contact.FirstLastName)}
        />

        <InputValidation
          isHidden={!active || isValidFirstAndLastName(contact.FirstLastName) || hasMiddleName(contact.FirstLastName)}
          text={
            hasNumber(contact.FirstLastName)
              ? registrationValidationErrorMessages.nameHasNumber
              : registrationValidationErrorMessages.notValidContactPersonFirstAndLastName
          }
        />
        <InputValidation
          isHidden={!hasMiddleName(contact.FirstLastName)}
          text={registrationValidationErrorMessages.middleName}
          ariaLabel={registrationValidationErrorMessages.middleName}
        />

        <Input
          id="PhoneNumber"
          name="PhoneNumber"
          type="text"
          htmlFor="PhoneNumber"
          value={contact.PhoneNumber}
          onChange={onChangeHandler}
          placeholder="Mobiltelefonnummer"
          ariaRequired={false}
          labelText="Mobiltelefonnummer (inkl. Ländervorwahl)"
          labelStyle={{ display: "inline-block" }}
        />

        <InputValidation isHidden={isValidPhone()} text={registrationValidationErrorMessages.notValidPhoneNumber} />

        <Input
          id="Email"
          name="Email"
          type="text"
          htmlFor="Email"
          value={contact.Email}
          onChange={onChangeHandler}
          placeholder="E-Mail-Adresse"
          ariaRequired={false}
          labelText="E-Mail-Adresse"
          showValidationBorder={
            contact.Email.length !== 0 &&
            (!hasAtSymbolEmailValidation(contact.Email) ||
              !hasTwoCharactersAfterAtSymbolEmailValidation(contact.Email) ||
              !isEmailValidation(contact.Email))
          }
        />

        <InputValidation
          isHidden={
            contact.Email.length === 0 ||
            !hasAtSymbolEmailValidation(contact.Email) ||
            !hasTwoCharactersAfterAtSymbolEmailValidation(contact.Email) ||
            isEmailValidation(contact.Email)
          }
          text={registrationValidationErrorMessages.notValidEmail}
        />

        <InputValidation
          isHidden={contact.Email.length === 0 || hasAtSymbolEmailValidation(contact.Email)}
          text={registrationValidationErrorMessages.missingAtSymbolEmail}
        />

        <InputValidation
          isHidden={
            contact.Email.length === 0 ||
            !hasAtSymbolEmailValidation(contact.Email) ||
            hasTwoCharactersAfterAtSymbolEmailValidation(contact.Email)
          }
          text={registrationValidationErrorMessages.charactersAfterAtSymbolLessThanTwoEmail}
        />

        <HiddenSubmitInput />
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />

      <div className="hide-weiter-on-keyboard-content"> </div>
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.addTravelTourOperator}
        onClick={onClickHandler}
        ariaLabel={`Senden und gehen Sie zu ${ApplicationRoute.addTravelTourOperator}`}
        className="hide-weiter-on-keyboard floating-weiter-buton"
      />
    </div>
  );
};

export default ContactPersonPage;
