import {useEffect, useContext, useState} from "react";
import {useHistory} from "react-router-dom";
import Separater from "../../../common/Separater";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import TravelContext from "../../../context/TravelContext";
import CheckBox from "../../registration/utils/CheckBox";
import ContentTitle from "../../registration/utils/ContentTitle";
import WeiterButton from "../../registration/utils/WeiterButton";
import EditInput from "../../../common/EditInput";
import HorizontalRule from "../../../common/HorizontalRule";
import {
    convertAppDateToMiddlewareDateFormat,
    convertMiddlewareDateToAppDateFormat,
    dateFormatter,
    GetBundeslandIdFromName,
    getGenderFromEnumGerman,
    getYearMonthDateFromNewDate,
    getYearMonthDateFromNewDateMiddlewareFormat,
    isValidContactPerson,
    phoneFormatter,
    setApiObjectAddTravel,
    setApiUserObjectUpdate,
    getUIID, lengthForTravelName,
} from "../../../utils/helpers";
import TravelMember from "../utils/TravelMember";
import Form from "../../registration/utils/Form";
import fetcher from "../../../services/fetcher";
import apipath from "../../../apipath";
import UserContext from "../../../context/UserContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import Loader from "../utils/Loader";
import InputCalendar from "../utils/InputCalendar";
import {getYMDfromMiddleware} from "../../../utils/helpers";
import ControledRadioInput from "../../registration/utils/ControledRadioInput";
import ProfileComponentStateless from "./profile-component-stateless";
import {
    hasMinLettersValidation,
    hasTwoCharactersAfterAtSymbolEmailValidation,
    IsDateBiggerThanTodayValidation,
    IsDateSmallerThan_1900_1_1_Validation,
    isDateValidation,
    isEmailValidation,
    isPassnumberValidation,
    isPersonalNumberValidation,
    isPhoneValidation,
    isValidFirstAndLastName,
    travelGroupMemberValidation,
} from "../../../utils/validation";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import SuccessContext from "../../../context/SuccessContext";
import {
    optionalDefault,
    editOnChangeGroupMembers,
    effectOptionals
} from "../../../utils/editUtils.js";

const countriesId = {
    Optional: null,
    Burgenland: "c166d9bb-3862-eb11-a829-000d3a46d73b",
    Kärnten: "c266d9bb-3862-eb11-a829-000d3a46d73b",
    Niederösterreich: "3275fbd4-1c6d-eb11-a838-000d3a46d73b",
    Oberösterreich: "3475fbd4-1c6d-eb11-a838-000d3a46d73b",
    Salzburg: "3675fbd4-1c6d-eb11-a838-000d3a46d73b",
    Steiermark: "3875fbd4-1c6d-eb11-a838-000d3a46d73b",
    Tirol: "3a75fbd4-1c6d-eb11-a838-000d3a46d73b",
    Vorarlberg: "3c75fbd4-1c6d-eb11-a838-000d3a46d73b",
    Wien: "3e75fbd4-1c6d-eb11-a838-000d3a46d73b",
};
const applicationPageTitles = require("../../../utils/pageTitles.json");

const EditTravel = ({text}) => {
    const {travel, setTravel} = useContext(TravelContext);
    const {setHeader} = useContext(HeaderContext);
    const {user, setUser} = useContext(UserContext);
    const {setSuccessBox} = useContext(SuccessContext);

    const {ContactPerson: contactPersonInfo, ...userInfo} = user;

    const [userInfoState, setUserInfoState] = useState({
        ...userInfo,
    });
    const [contactPersonInfoState, setContactPersonInfoState] = useState({
        ...contactPersonInfo,
    });
    const history = useHistory();
    const [checked, setChecked] = useState(false);

    const [, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [, setSubmitting] = useState(false);

    const [, setUpdateProfile] = useState(null);
    const [updateProfileError, setUpdateProfileError] = useState(null);
    const [updateProfileLoading, setUpdateProfileLoading] = useState(true);
    const [submttingUpdateProfile, setSubmttingUpdateProfile] = useState(false);

    const [GDPRDataRetention, setGDPRDataRetention] = useState(null);

    //Travelstops
    let [destinations, setDestinations] = useState(
        travel.TravelStops.map((ts) => {
            let se = getYMDfromMiddleware(ts.StopEnd);
            let ss = getYMDfromMiddleware(ts.StopStart);
            return {
                ...ts,
                StopEnd: new Date(se.year, se.month, se.day),
                StopStart: new Date(ss.year, ss.month, ss.day),
            };
        })
    );

    const [travelStartStop, setTravelStartStop] = useState("");

    const receiveDataForDestination = (index, StopStart, StopEnd) => {
        let newarr = [...destinations];
        newarr[index].StopStart = StopStart;
        newarr[index].StopEnd = StopEnd;

        setDestinations([...newarr]);
    };

    const onChangeCommentTravelStops = (e, index) => {
        let newarr = [...destinations];
        newarr[index].Comment = e.target.value;

        setDestinations([...newarr]);
    };

    useEffect(() => {
        let dates = [];
        destinations.forEach((d) => {
            dates.push(d.StopEnd);
            dates.push(d.StopStart);
        });
        let maxDate = new Date(Math.max(...dates));
        maxDate = getYearMonthDateFromNewDate(maxDate);

        let minDate = new Date(Math.min(...dates));
        minDate = getYearMonthDateFromNewDate(minDate);

        setTravelStartStop(minDate + " - " + maxDate);
    }, [destinations]);

    const submittingNow = () => {
        return updateProfileLoading && submttingUpdateProfile;
    };

    const updateUserMiddleware = () => {
        let toSend = {...user, ContactPerson: contactPersonInfoState};
        toSend = Object.assign(toSend, userInfoState);
        toSend.GDPRDataRetention = GDPRDataRetention;

        fetcher(
            `${apipath.path}users/CURRENT`,
            "PUT",
            setUpdateProfile,
            setUpdateProfileError,
            setUpdateProfileLoading,
            setSubmttingUpdateProfile,
            false,
            "application/json",
            JSON.stringify(setApiUserObjectUpdate(toSend))
        );

        let toSetUser = {
            ...toSend,
            EmergencyEmail: contactPersonInfoState.Email ?? "",
            EmergencyFirstLastName: contactPersonInfoState.FirstLastName ?? "",
            EmergencyPhoneNumber: contactPersonInfoState.PhoneNumber ?? "",
        };

        setUser(() => toSetUser);
    };

    const getTravelName = () => {
        let title = "";
        travel.TravelStops.forEach((ts) => {
            title += ` ${ts.Name},`;
        });

        title = title.substring(0, title.length - 1).trim();

        if (title.length > 400) {
            title = title.substring(0, 396).concat(" ...");
        }

        return title;
    };

    const addTravelApi = () => {
        let toSend = travel;
        let newTravelStopArray = destinations.map((ts) => {
            return {
                ...ts,
                StopEnd: getYearMonthDateFromNewDateMiddlewareFormat(new Date(ts.StopEnd)),
                StopStart: getYearMonthDateFromNewDateMiddlewareFormat(new Date(ts.StopStart)),
            };
        });
        let dates = [];
        destinations.forEach((d) => {
            dates.push(d.StopEnd);
            dates.push(d.StopStart);
        });

        const maxDate = getYearMonthDateFromNewDateMiddlewareFormat(new Date(Math.max(...dates)));
        const minDate = getYearMonthDateFromNewDateMiddlewareFormat(new Date(Math.min(...dates)));

        let tgmembers = [...travel.TravelGroupMember].map((tgm) => {
            return {
                ...tgm,
                Name: tgm.FirstLastName.trim(),
            };
        });

        toSend = {
            ...toSend,
            StartDate: minDate,
            EndDate: maxDate,
            Name: getTravelName(),
            TravelStops: newTravelStopArray,
            TravelGroupMember: tgmembers,
        };

        fetcher(
            `${apipath.path}users/CURRENT/travels`,
            "POST",
            setData,
            setError,
            setLoading,
            setSubmitting,
            false,
            "application/json",
            JSON.stringify(setApiObjectAddTravel(toSend))
        );
    };

    const [stopOvers,] = useState(
        [...travel.TravelStops].map((ts) => {
            return {
                ...ts,
                StopEnd: ts.StopEnd ? convertMiddlewareDateToAppDateFormat(ts.StopEnd) : "",
                StopStart: ts.StopStart ? convertMiddlewareDateToAppDateFormat(ts.StopStart) : "",
            };
        })
    );
    const [groupMembers, setGroupMembers] = useState(
        travel.TravelGroupMember.map((tg) => {
            return {
                ...tg,
                Birthdate: tg.Birthdate ? convertMiddlewareDateToAppDateFormat(tg.Birthdate) : "",
            };
        })
    );

    const [validGroupMembers, setValidGroupMembers] = useState(true);

    useEffect(() => {
        if (groupMembers.length === 0) {
            setValidGroupMembers(true);
        } else {
            let valid = true;
            groupMembers.forEach((tgm) => {
                if (!travelGroupMemberValidation(tgm)) valid = false;
            });

            setValidGroupMembers(valid);
        }
    }, [groupMembers]);

    const getDestinationDateInit = () => {
        let first = travel.StartDate ? convertMiddlewareDateToAppDateFormat(travel.StartDate) : "";

        let second = travel.EndDate ? convertMiddlewareDateToAppDateFormat(travel.EndDate) : "";

        return first + "-" + second;
    };

    const [destination, setDestionation] = useState({
        Reisezeitraum: getDestinationDateInit(),
        OrganisierteReiseveranstaltung: travel.IsTourGroup ? "Ja" : "Nein",
        Reiseveranstalter: travel.TourOperator,
    });

    useEffect(() => {
        setHeader((curr) => {
            return {
                ...curr,
                inUse: "registration",
                title: "Neue Reise anlegen",
                progress: 100,
            };
        });
    }, []);

    const onDestinationChangeHandler = (e) => {
        setDestionation((curr) => {
            return {...curr, [e.target.id]: e.target.value};
        });
    };

    const onChangeHandler = () => {
        setChecked(!checked);
    };

    const [optionals, setOptionals] = useState(optionalDefault);
    useEffect(() => {
        effectOptionals(setOptionals, contactPersonInfoState)
    }, [contactPersonInfoState]);

    const updateTravel = () => {
        let newGroupMembers = groupMembers.map((gm) => {
            return {
                ...gm,
                Birthdate: convertAppDateToMiddlewareDateFormat(gm.Birthdate),
            };
        });

        let newTravelStops = stopOvers.map((st) => {
            return {
                ...st,
                StopStart: convertAppDateToMiddlewareDateFormat(st.StopStart),
                StopEnd: convertAppDateToMiddlewareDateFormat(st.StopEnd),
            };
        });

        setTravel((curr) => {
            return {
                ...curr,
                IsTourGroup: destination.OrganisierteReiseveranstaltung === "Ja",
                TourOperator: destination.Reiseveranstalter,
                TravelGroupMember: newGroupMembers,
                TravelStops: newTravelStops,
            };
        });
    };

    const submit = (e) => {
        e.preventDefault();
        if (isValid()) {
            updateUserMiddleware();
        }
    };

    //HINT* POST TRAVELL
    useEffect(() => {
        if (!updateProfileLoading && !updateProfileError) {
            updateTravel();

            addTravelApi();
        }
    }, [updateProfileLoading]);

    useEffect(() => {
        if (!loading && !error) {
            setSuccessBox((curr) => {
                return {
                    ...curr,
                    hidden: false,
                    text: "Ihre Reise wurde erfolgreich angelegt!",
                    trigger: getUIID(),
                };
            });
            history.push(ApplicationRoute.TravelDashboardNoPermissions);
        }
    }, [loading, error]);

    const onClickHandler = (e) => {
        submit(e);
    };

    const onSubmitHandler = (e) => {
        submit(e);
    };

    const onChangeGroupMembers = (e) => {
        setGroupMembers((curr) => {
            return editOnChangeGroupMembers(e, curr);
        });
    };

    const onGDPRDataRetentionChange = (e) => {
        setGDPRDataRetention(e.target.value === "ja");
    };

    useEffect(() => {
        setUser((curr) => {
            return {
                ...curr,
                GDPRDataRetention: GDPRDataRetention,
            };
        });
    }, [GDPRDataRetention]);

    let countryNames = "Reise nach " + travel.TravelStops[0].Country;

    if (travel.TravelStops.length > 1) {
        travel.TravelStops.forEach((el, index) => {
            if (index > 0) countryNames += ", " + el.Country;
        });
    }

    countryNames = lengthForTravelName(countryNames);

    const onContactPersonInfoChangeHandler = (e) => {
        setContactPersonInfoState((curr) => {
            let key = e.target.id.replace("Notfalkontakt", "");

            if (key === "PhoneNumber") {
                return {
                    ...curr,
                    [key]: phoneFormatter(contactPersonInfoState.PhoneNumber, e.target.value),
                };
            }

            return {...curr, [key]: e.target.value};
        });
    };

    const onUserInfoChangeHandler = (e) => {
        setUserInfoState((curr) => {
            if (e.target.id === "CountyHomeRegionName") {
                curr.CountyHomeRegionName = e.target.value;
                curr.CountyHomeRegionId = GetBundeslandIdFromName(e.target.value);
            }

            if (e.target.id === "HomeRegion") {
                return {
                    ...curr,
                    [e.target.id]: countriesId[e.target.value],
                };
            }

            if (e.target.id === "BirthDate") {
                return {
                    ...curr,
                    [e.target.id]: dateFormatter(userInfoState.BirthDate, e.target.value),
                };
            }

            if (e.target.id === "PhoneNumber") {
                return {
                    ...curr,
                    [e.target.id]: phoneFormatter(userInfoState.PhoneNumber, e.target.value),
                };
            }

            return {...curr, [e.target.id]: e.target.value};
        });
    };

    const isValid = () => {
        const validDate1 = IsDateBiggerThanTodayValidation(userInfoState.BirthDate);
        const validDate2 = !IsDateSmallerThan_1900_1_1_Validation(userInfoState.BirthDate);
        const validDate3 = isDateValidation(userInfoState.BirthDate);

        const validDate = validDate3 && validDate2 && validDate1;

        return (
            GDPRDataRetention != null &&
            validDate &&
            validGroupMembers &&
            !!checked &&
            (hasTwoCharactersAfterAtSymbolEmailValidation(contactPersonInfoState.Email) ||
                contactPersonInfoState.Email.length === 0) &&
            hasTwoCharactersAfterAtSymbolEmailValidation(userInfoState.Email) &&
            isValidContactPerson(optionals) &&
            hasMinLettersValidation(userInfoState.FirstName) &&
            hasMinLettersValidation(userInfoState.LastName) &&
            isPassnumberValidation(userInfoState.PassNumber) &&
            isPersonalNumberValidation(userInfoState.IdNumber) &&
            isEmailValidation(userInfoState.Email) &&
            isPhoneValidation(userInfoState.PhoneNumber) &&
            isValidFirstAndLastName(contactPersonInfoState.FirstLastName) &&
            (isPhoneValidation(contactPersonInfoState.PhoneNumber, true) ||
                contactPersonInfoState.PhoneNumber.length === 0) &&
            (isEmailValidation(contactPersonInfoState.Email) || contactPersonInfoState.Email.length === 0)
        );
    };

    if ((!updateProfileLoading || !loading) && !error && !updateProfileError) return <Loader/>;
    if (submittingNow()) return <Loader/>;

    return (
        <>
            <MetaDecorator title={applicationPageTitles.createTripPages}/>

            <Form onSubmit={onSubmitHandler} className="max-width-780">
                <div className="specialAlertContainer">
                    <ContentTitle text="Gleich geschafft! Bitte überprüfen Sie abschließend Ihre Reisedaten:"/>
                    {/* Change to Country not Id */}
                    <Separater text={countryNames}/>
                    <EditInput
                        id={"Reisezeitraum"}
                        placeholder={"Reisezeitraum"}
                        value={travelStartStop}
                        labelText={"Reisezeitraum"}
                        ariaRequired={true}
                        onChange={onDestinationChangeHandler}
                        readonly={true}
                    />

                    <HorizontalRule/>
                    <EditInput
                        id={"OrganisierteReiseveranstaltung"}
                        placeholder={"Organisierte Reiseveranstaltung"}
                        value={destination.OrganisierteReiseveranstaltung}
                        labelText={"Organisierte Reiseveranstaltung"}
                        onChange={(value) =>
                            onDestinationChangeHandler({
                                target: {id: "OrganisierteReiseveranstaltung", value: value},
                            })
                        }
                        options={["Ja", "Nein"]}
                    />
                    {destination.OrganisierteReiseveranstaltung === "Ja" ? (
                        <>
                            <HorizontalRule/>
                            <EditInput
                                id={"Reiseveranstalter"}
                                placeholder={"Reiseveranstalter"}
                                value={destination.Reiseveranstalter}
                                labelText={"Reiseveranstalter"}
                                ariaRequired={true}
                                onChange={onDestinationChangeHandler}
                            />
                        </>
                    ) : null}

                    <HorizontalRule/>
                    <ProfileComponentStateless
                        userInfoState={userInfoState}
                        onUserInfoChangeHandler={onUserInfoChangeHandler}
                        contactPersonInfoState={contactPersonInfoState}
                        onContactPersonInfoChangeHandler={onContactPersonInfoChangeHandler}
                    />

                    {/* Change to Country not Id */}
                    {travel.TravelStops.length > 0 &&
                        travel.TravelStops.map((ts, index) => (
                            <>
                                <div>
                                    <Separater text={`Destination ${ts.Name}`}/>
                                    <InputCalendar
                                        labelText={`Reisezeitraum`}
                                        id={index + 1 + "-reisezeitram"}
                                        placeholder="Reisezeitraum"
                                        startDate={destinations[index].StopStart}
                                        endDate={destinations[index].StopEnd}
                                        onChange={(startDate, endDate) => receiveDataForDestination(index, startDate, endDate)}
                                        cImg="images/common/editpen.png"
                                        cClassName="edit-travel-container__calendar-container"
                                        cImgClassName="edit-travel-container__custom-img"
                                    />

                                    <HorizontalRule/>
                                    <EditInput
                                        id={`Stadt${index}`}
                                        placeholder={"Stadt"}
                                        value={destinations[index].Comment}
                                        labelText={"Stadt"}
                                        ariaRequired={true}
                                        onChange={(e) => onChangeCommentTravelStops(e, index)}
                                    />
                                </div>
                            </>
                        ))}

                    {!travel.IsAlone &&
                        groupMembers.map((tg, i) => {
                            return (
                                <TravelMember
                                    key={i}
                                    id={i}
                                    FirstLastName={tg.FirstLastName ? tg.FirstLastName : ""}
                                    Birthdate={tg.Birthdate ? tg.Birthdate : ""}
                                    MobilePhone={tg.MobilePhone ? tg.MobilePhone : ""}
                                    Email={tg.Email ? tg.Email : ""}
                                    Gender={getGenderFromEnumGerman(tg.Gender)}
                                    Nationality={tg.Nationality}
                                    DegreeOfRelationship={tg.DegreeOfRelationship}
                                    onChange={onChangeGroupMembers}
                                />
                            );
                        })}
                    <HorizontalRule/>
                    <div style={{marginTop: "1.7rem"}}>
                        <CheckBox
                            id="confirmBox"
                            state={checked}
                            onChangeHandler={onChangeHandler}
                            text="Ich stimme zu, dass meine Daten vom BMEIA zur Erleichterung der Gewährung konsularischen Schutzes im Krisen- oder Notfall verarbeitet werden. Ich bestätige, dass meine Angaben den Tatsachen entsprechen und ich von den angeführten Angehörigen bzw. Kontaktpersonen zur Angabe ihrer Daten bevollmächtigt bin."
                        />
                    </div>
                    <p style={{marginTop: "1.2rem"}}>
                        Weitere Informationen zu Ihren Rechten und zum Datenschutz im BMEIA finden Sie in der{" "}
                        <a
                            href="https://www.bmeia.gv.at/reise-aufenthalt/auslandsservice/datenschutz"
                            target="_blank"
                            style={{color: "black"}}
                            aria-label="Datenschutzinformation."
                            rel="noopener noreferrer"
                        >
                            Datenschutzerklärung
                        </a>
                        .
                    </p>
                    <p style={{marginTop: "2.4rem"}}>
                        Möchten Sie, dass Ihre Registrierungsdaten (Vorname, Familienname, Geburtsdatum, Passnummer,
                        E-Mail,
                        Telefonnummer) für zukünftige Reisen gespeichert werden?
                    </p>
                    <ControledRadioInput
                        role="radio"
                        id="gdprdataretention_yes"
                        value="ja"
                        name="gdprdataretention"
                        textInfo="Ja"
                        ariaLabel="No data retention"
                        checked={GDPRDataRetention}
                        onChange={onGDPRDataRetentionChange}
                        ariaChecked={GDPRDataRetention}
                    />
                    <ControledRadioInput
                        role="radio"
                        id="gdprdataretention_no"
                        value="nein"
                        name="gdprdataretention"
                        textInfo="Nein"
                        ariaLabel="No data retention"
                        checked={GDPRDataRetention != null && !GDPRDataRetention}
                        onChange={onGDPRDataRetentionChange}
                        ariaChecked={GDPRDataRetention != null && !GDPRDataRetention}
                    />

                    <p style={{marginTop: "2.4rem"}}>
                        Nähere Informationen zu den jeweiligen Lösch- und Aufbewahrungsfristen finden Sie in der{" "}
                        <a
                            href="https://www.bmeia.gv.at/reise-aufenthalt/auslandsservice/datenschutz"
                            target="_blank"
                            style={{color: "black"}}
                            aria-label="Datenschutzinformation."
                            rel="noopener noreferrer"
                        >
                            Datenschutzerklärung
                        </a>
                        .
                    </p>
                    <HiddenSubmitInput/>

                    <WeiterButton
                        disabled={!isValid() || submittingNow()}
                        onClick={onClickHandler}
                        ariaLabel={text}
                        path={ApplicationRoute.registerUserInfo}
                        positioned="static"
                        text="Reise anlegen"
                        style={{marginTop: "3.2rem", marginBottom: "5.6rem"}}
                    />
                </div>
            </Form>
        </>
    );
};

export default EditTravel;
