import React, { useEffect, useState } from "react";
import WeiterButton from "../../../registration/utils/WeiterButton";
import ContentTitle from "../../../registration/utils/ContentTitle";
import Input from "../../../registration/utils/Input";
import ApplicationRoute from "../../../../config/routes";
import { useHistory } from "react-router-dom";
import { getKeyByValue, prefilledUserData } from "../../../../utils/helpers";
import {
  hasOnlyLettersValidation,
  hasMinLettersValidation,
  hasNumber,
} from "../../../../utils/validation";
import Form from "../../../registration/utils/Form";
import HiddenSubmitInput from "../../../../common/HiddenSubmitInput";
import HeaderContext from "../../../../context/HeaderContext";
import InputValidation from "../../../../common/InputValidaiton";
import { registrationValidationErrorMessages } from "../../../../utils/validationErrorMessages";
import UserContext from "../../../../context/UserContext";
import MetaDecorator from "../../../../utils/MetaDecorator";
import HorizontalRule from "../../../../common/HorizontalRule";

const applicationPageTitles = require("../../../../utils/pageTitles.json");
//HINT* NOT REGISTRATION
export function ForeignName({ text }) {
  const history = useHistory();
  const { user, setUser } = React.useContext(UserContext);
  const { setHeader } = React.useContext(HeaderContext);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((2 / 19) * 100),
        title: "Als Auslandsösterreicher/in registrieren",
        ariaLabel: "Als Auslandsösterreicher:in registrieren",
      };
    });
  }, []);

  const [fullName, setFullName] = useState({
    firstName: "",
    lastName: "",
  });

  const [errors, setErrors] = useState({
    firstName: { hasOnlyLetters: false, hasMinTwoLetters: false },
    lastName: { hasOnlyLetters: false, hasMinTwoLetters: false },
  });

  const isValid = () => {
    return !(
      getKeyByValue(errors.firstName, false) ||
      getKeyByValue(errors.lastName, false)
    );
  };

  const updateUser = () => {
    setUser((curr) => {
      return {
        ...curr,
        FirstName: fullName.firstName,
        LastName: fullName.lastName,
      };
    });
  };

  useEffect(() => {
    setFullName((curr) => {
      return {
        ...curr,
        firstName: user.FirstName ? user.FirstName : "",
        lastName: user.LastName ? user.LastName : "",
      };
    });
  }, [user]);

  useEffect(() => {
    Object.keys(fullName).forEach((key) => {
      setErrors((currErrs) => {
        return {
          ...currErrs,
          [key]: {
            ...currErrs[key],
            hasOnlyLetters: hasOnlyLettersValidation(fullName[key]) || true,
            hasMinTwoLetters: hasMinLettersValidation(fullName[key], 2),
          },
        };
      });
    });
  }, [fullName]);

  const onChangeHandler = (e) => {
    setFullName((curr) => {
      return { ...curr, [e.target.id]: e.target.value };
    });
  };

  const onClickHandler = () => {
    updateUser();
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateUser();
      history.push(ApplicationRoute.foreignRegistrationRegistrationBirthdate);
    }
  };

  return (
    <section className="foreign-registration registration-middle-container">
      <MetaDecorator title={applicationPageTitles.permanentOtsideCountry} />
      <ContentTitle
        id="foreign-registration-name"
        text={prefilledUserData("Bitte geben Sie Ihren Namen ein:")}
      />
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form
        className="max-width-380"
        id="form"
        onSubmit={onSubmitHandler}
        ariaLabelledBy="foreign-registration-name"
      >
        <Input
          id="firstName"
          placeholder="Vorname"
          type="text"
          value={fullName.firstName}
          onChange={onChangeHandler}
          labelText="Vorname"
          showValidationBorder={
            fullName.firstName.length > 0 &&
            !hasMinLettersValidation(fullName.firstName)
          }
        />
        <InputValidation
          text={
            hasNumber(fullName.firstName)
              ? registrationValidationErrorMessages.nameHasNumber
              : registrationValidationErrorMessages.firstNameLessCharacters
          }
          isHidden={
            !fullName.firstName.length > 0 ||
            hasMinLettersValidation(fullName.firstName)
          }
        />

        <Input
          id="lastName"
          placeholder="Familienname"
          type="text"
          value={fullName.lastName}
          onChange={onChangeHandler}
          labelText="Familienname"
          showValidationBorder={
            fullName.lastName.length > 0 &&
            !hasMinLettersValidation(fullName.lastName)
          }
        />
        <InputValidation
          alert={registrationValidationErrorMessages.lastNameLessCharacters}
          text={
            hasNumber(fullName.lastName)
              ? registrationValidationErrorMessages.nameHasNumber
              : registrationValidationErrorMessages.lastNameLessCharacters
          }
          isHidden={
            !fullName.lastName.length > 0 ||
            hasMinLettersValidation(fullName.lastName)
          }
        />

        <HiddenSubmitInput />
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content"> </div>
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.foreignRegistrationRegistrationBirthdate}
        onClick={onClickHandler}
        ariaLabel={text}
        className="hide-weiter-on-keyboard floating-weiter-buton"
      />
    </section>
  );
}
