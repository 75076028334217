import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Input from "../../registration/utils/Input";
import InputValidation from "../../../common/InputValidaiton";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import { phoneFormatter } from "../../../utils/helpers";
import {
  isPhoneValidation,

} from "../../../utils/validation";
import { registrationValidationErrorMessages } from "../../../utils/validationErrorMessages";
import Form from "../../registration/utils/Form";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import WeiterButton from "../../registration/utils/WeiterButton";
import PageTitle from "../../travel-registration/utils/PageTitle";
import PageDescription from "../../travel-registration/utils/Description";
import MetaDecorator from "../../../utils/MetaDecorator";
import HorizontalRule from "../../../common/HorizontalRule";

const applicationPageTitles = require("../../../utils/pageTitles.json");
const AddRelative4 = ({ text }) => {
  const history = useHistory();

  const activate = () => {
    setActive(() => true);
  };

  const [active, setActive] = useState(false);

  useEffect(() => {
    document.getElementsByTagName("HTML")[0].addEventListener("click", activate);

    return () => document.getElementsByTagName("HTML")[0].removeEventListener("click", activate);
  }, []);

  const { setHeader } = React.useContext(HeaderContext);

  let initphone = "";
  try {
    initphone = JSON.parse(localStorage.getItem("newTGM")).MobilePhone ?? "";
  } catch (error) {}

  const [phoneNumber, setPhoneNumber] = useState(initphone);

  useEffect(() => {
    let title = "Mitreisende hinzufügen";

    try {
      title = localStorage.getItem("addTGMTitle");
    } catch (error) {}

    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: title,
        progress: "no",
      };
    });
  }, []);

  const isValid = () => {
    return isPhoneValidation(phoneNumber, true);
  };

  const onChangeHandler = (e) => {
    let phone = phoneFormatter(phoneNumber, e.target.value);

    setPhoneNumber(phone);
  };
  const updateTGM = () => {
    let tgm = JSON.parse(localStorage.getItem("newTGM"));
    localStorage.setItem(
      "newTGM",
      JSON.stringify({
        ...tgm,
        MobilePhone: phoneNumber.replace(/\s/g, ""),
      })
    );
  };

  const onClickHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateTGM();
      history.push(ApplicationRoute.addRelative5);
    }
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateTGM();
      history.push(ApplicationRoute.addRelative5);
    }
  };
  // console.log(registrationValidationErrorMessages.notValidPhoneNumber);

  return (
    <div className="add-relative-container registration-middle-container">
      <MetaDecorator title={applicationPageTitles.editTrip} />
      <PageTitle id="add-releatives-phone" text="Bitte geben Sie die Telefonnummer ein:" />

      <p className="page-paragraph page-paragraph--small">Mitreisende/r</p>
      <PageDescription text="Bitte geben Sie entweder die Mobiltelefonnummer oder die E-Mail-Adresse der mitreisenden Person an – falls vorhanden. Es ist wichtig, dass wir die Person im Notfall kontaktieren können." />

      <HorizontalRule className="horizontal-rule-desktop" />
      <Form id="form" ariaLabelledBy="add-releatives-phone" onSubmit={onSubmitHandler} className="max-width-380">
        <Input
          id="phoneNumber"
          value={phoneNumber}
          onChange={onChangeHandler}
          placeholder="Mobiltelefonnummer"
          type="tel"
          labelText="Mobiltelefonnummer (inkl. Ländervorwahl)"
          labelStyle={{ display: "inline-block" }}
        />

        <InputValidation
          isHidden={!active || isValid()}
          text={registrationValidationErrorMessages.notValidPhoneNumber}
        />

        <HiddenSubmitInput />
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content"> </div>
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.addTravelEmail}
        onClick={onClickHandler}
        ariaLabel={text}
        className="hide-weiter-on-keyboard floating-weiter-buton"
      />
    </div>
  );
};

export default AddRelative4;
