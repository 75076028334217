import React, { useEffect, useState } from "react";
import WeiterButton from "../../registration/utils/WeiterButton";
import TravelContext from "../../../context/TravelContext";
import ApplicationRoute from "../../../config/routes";
import { useHistory } from "react-router-dom";
import Form from "../utils/Form";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import PageTitle from "../utils/PageTitle";
import {
  hasAtSymbolEmailValidation,
  hasTwoCharactersAfterAtSymbolEmailValidation,
  isEmailValidation,
} from "../../../utils/validation";
import HeaderContext from "../../../context/HeaderContext";
import PageDescription from "../utils/Description";
import InputValidation from "../../../common/InputValidaiton";
import Input from "../../registration/utils/Input";
import { registrationValidationErrorMessages } from "../../../utils/validationErrorMessages";
import { getTravelGroupMemberLastIndex_Array } from "../../../utils/helpers";
import MetaDecorator from "../../../utils/MetaDecorator";
import HorizontalRule from "../../../common/HorizontalRule";

const applicationPageTitles = require("../../../utils/pageTitles.json");
const AddTravelEmail = ({ text }) => {
  const history = useHistory();

  const { travel, setTravel } = React.useContext(TravelContext);
  const { setHeader } = React.useContext(HeaderContext);

  const groupMemberIndex = getTravelGroupMemberLastIndex_Array(travel);
  let initEmail = travel?.TravelGroupMember[groupMemberIndex]?.Email;

  const [email, setEmail] = useState(initEmail ? initEmail : "");

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: "Neue Reise anlegen",
        progress: parseInt((16 / 19) * 100),
      };
    });
  }, []);

  //
  //
  const activate = () => {
    setActive(() => true);
  };

  const [active, setActive] = useState(false);

  useEffect(() => {
    document.getElementsByTagName("HTML")[0].addEventListener("click", activate);

    return () => document.getElementsByTagName("HTML")[0].removeEventListener("click", activate);
  }, []);
  //
  //

  const isValid = () => {
    return (isEmailValidation(email) && hasTwoCharactersAfterAtSymbolEmailValidation(email)) || email.length === 0;
  };

  const updateTravel = () => {
    const groupMemberIndex = travel.TravelGroupMember.length > 0 ? travel.TravelGroupMember.length - 1 : 0;
    let newArray = [...travel.TravelGroupMember];
    newArray[groupMemberIndex] = {
      ...newArray[groupMemberIndex],
      Email: email,
    };

    setTravel((curr) => {
      return {
        ...curr,
        TravelGroupMember: newArray,
      };
    });
  };

  const onChangeHandler = (e) => {
    setEmail(e.target.value);
  };

  const onClickHandler = () => {
    updateTravel();

    history.push(ApplicationRoute.addTravelNationality);
  };

  const onsubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateTravel();
      history.push(ApplicationRoute.addTravelNationality);
    }
  };

  return (
    <div className="registration-middle-container">
      <MetaDecorator title={applicationPageTitles.createTripPages} />
      <PageTitle id="contactperson-email" text="Bitte geben Sie die E-Mail-Adresse ein:" />

      <p className="page-paragraph page-paragraph--small">Mitreisende/r</p>

      <PageDescription text="Bitte geben Sie entweder die Mobiltelefonnummer oder die E-Mail-Adresse der mitreisenden Person an – falls vorhanden. Es ist wichtig, dass wir die Person im Notfall kontaktieren können." />
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form className="max-width-380" id="form" onSubmit={onsubmitHandler} ariaLabelledBy="contactperson-email">
        <Input
          id="email"
          name="email"
          type="text"
          htmlFor="email"
          value={email}
          onChange={onChangeHandler}
          placeholder="E-Mail-Adresse"
          labelText="E-Mail-Adresse"
          showValidationBorder={
            email.length > 0 && (!isValid() || !hasTwoCharactersAfterAtSymbolEmailValidation(email))
          }
        />

        <InputValidation
          isHidden={
            !active ||
            !email.length > 0 ||
            !hasAtSymbolEmailValidation(email) ||
            !hasTwoCharactersAfterAtSymbolEmailValidation(email) ||
            isValid()
          }
          text={registrationValidationErrorMessages.notValidEmail}
        />

        <InputValidation
          isHidden={!active || !email.length > 0 || hasAtSymbolEmailValidation(email)}
          text={registrationValidationErrorMessages.missingAtSymbolEmail}
        />

        <InputValidation
          isHidden={
            !active ||
            !email.length > 0 ||
            !hasAtSymbolEmailValidation(email) ||
            hasTwoCharactersAfterAtSymbolEmailValidation(email)
          }
          text={registrationValidationErrorMessages.charactersAfterAtSymbolLessThanTwoEmail}
        />

        <HiddenSubmitInput />
      </Form>

      <HorizontalRule className="horizontal-rule-desktop" />

      <div className="hide-weiter-on-keyboard-content"> </div>
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.addTravelNationality}
        onClick={onClickHandler}
        ariaLabel={text}
        className="hide-weiter-on-keyboard floating-weiter-buton"
      />
    </div>
  );
};

export default AddTravelEmail;
