const Form = ({ id, children, onSubmit, ariaLabel, ariaLabelledBy, className }) => {
  return (
    <form
      className={className}
      id={id}
      onSubmit={onSubmit}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      role="group"
    >
      {children}
    </form>
  );
};

export default Form;
